import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ContactDto } from './contact';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

// Probably want to re-use the user-service.
const API = {
  contact: 'api/contact',
};

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) {}

  /**
   * Get User details so we can pre-populate as much as possible.
   *
   * @returns
   *
   * @memberOf ContactService
   */
  getUserDetails(): Observable<ContactDto> {
    return this.http.get<ContactDto>(API.contact).pipe(catchError(err => observableThrowError(err.error)));
  }

  setHelpRequest(contactDto: ContactDto): Observable<object> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .put(API.contact, JSON.stringify(contactDto), { headers })
      .pipe(catchError(err => observableThrowError(err.error)));
  }
}
