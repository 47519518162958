'use strict';

// import Angular 2
import { AfterViewInit, Component, Input } from '@angular/core';

import { inlineSvg } from '../svg/inline-svg-img';

const SOCIAL_MEDIA_ICONS = ['chat', 'twitter', 'facebook', 'blog', 'youtube'];

@Component({
  selector: 'dm-footer',
  templateUrl: 'footer.template.html',
})
export class FooterComponent implements AfterViewInit {
  @Input()
  primaryColour: string;

  @Input()
  socialMediaIcons: string[] = SOCIAL_MEDIA_ICONS;

  ngAfterViewInit() {
    $("img[src$='.svg']").each(inlineSvg);
  }

  useIcon(name: string): boolean {
    return this.socialMediaIcons.indexOf(name) !== -1;
  }
}
