<div *ngIf="waitForLogout">We are logging you out, please wait...</div>

<div *ngIf="!waitForLogout">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="success" class="view">
      <div class="alert alert-warning">
        <p>To log out please do the following:</p>
        <ul>
          <li>Close this web browser (including ALL tabs and windows).</li>
        </ul>
      </div>
      <p>
        If you do not do this another person using this computer after you may be able to access Digimap using your
        credentials.
      </p>
    </div>

    <!-- If 401 Unauthorized error, display error message to user and allow navigation to home page
          This will most likely be the "error, must be logged in" message -->
    <div *ngIf="!success && errorStatus === 401" class="view">
      <div class="alert alert-danger">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p>
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <!-- For any other internal error, ask to close all windows to logout -->
    <div *ngIf="!success && errorStatus !== 401">
      <div class="alert alert-danger">
        <p>Digimap has encountered an unexpected error. To log out please do the following:</p>
        <ul>
          <li>Close this web browser (including ALL tabs and windows).</li>
        </ul>
      </div>
      <p>
        If you do not do this another person using this computer after you may be able to access Digimap using your
        credentials.
      </p>
    </div>
  </div>
</div>
