import { CommonModule } from '@angular/common';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';
import { DmCommonModule } from '../../commons/dm-common.module';
import { InputTrimModule } from 'ng2-trim-directive';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TemplateModule } from '../template/template.module';
import { UpdateUserFormComponent } from './update-user-form.component';
import { UpdateUserService } from './update-user.service';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, DmCommonModule, TemplateModule, ControlMessagesModule, InputTrimModule],
  declarations: [UpdateUserFormComponent],
  providers: [UpdateUserService],
  exports: [UpdateUserFormComponent],
})
export class UpdateUserModule {}
