import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const API = {
  addressBase: 'api/saleforce/addressbase',
};

@Injectable()
export class AddressBaseFormService {
  constructor(private http: HttpClient) {}

  sendFormToSaleforce(data): Observable<Response> {
    const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<Response>(API.addressBase, this.createParams(data), { headers });
  }

  private createParams(data): HttpParams {
    const succeedUrl: string = window.location.href.split('?')[0] + '?succeed=true';
    const params = new HttpParams()
      .set('oid', '00D0Y000001jhvA')
      .set('retURL', succeedUrl)
      .set('00N0Y00000H4uNI', 'Address base')
      .set('title', data.title)
      .set('first_name', data.firstName)
      .set('last_name', data.surname)
      .set('00N1v00000TZxMF', data.username)
      .set('email', data.email)
      .set('00N1v00000TZxMK', data.institution)
      .set('00N1v00000TZxMP', data.department)
      .set('00N1v00000TZxL2', data.products.join(';'))
      .set('00N1v00000TZxL7', data.extent)
      .set('00N1v00000TZxLC', data.postcode)
      .set('00N1v00000TZxLH', data.projectName)
      .set('00N1v00000TZxLM', data.projectDuration)
      .set('00N1v00000Ta8lu', data.grantReference)
      .set('00N1v00000Ta8lz', data.primaryContact)
      .set('00N1v00000TZxLb', data.investigators)
      .set('00N1v00000TZxLg', data.members);
    // for debug only, could be used to get email when fails
    //   .set('debug', 1)
    //   .set('debugEmail', 'w.petit@ed.ac.uk');
    // end for debug

    return params;
  }
}
