import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Collection } from './collection';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { COLLECTIONS } from './collection-config';

const API = {
  collections: 'api/collections',
  registerInterest: 'api/collections/register-interest',
};

@Injectable()
export class CollectionService {
  // These Subjects are to get around Collections components not being
  // able to sent events through <router-outlet> elements. This service is
  // shared between the HomeComponent and the CollectionList component and
  // brokers messages from the child to the parent component.

  // The currently selected collection on the home page
  currentCollection: ReplaySubject<Collection | null> = new ReplaySubject<Collection | null>(1);

  constructor(private http: HttpClient) {}

  /**
   * Query collections API endpoint to get a list of active collections
   * and return corresponding Collection config objects
   *
   * @return Observable<Collection[]>
   */
  getCollections(): Observable<Collection[]> {
    return this.http.get<{ collections: string[]; dependencies: Map<string, string[]> }>(API.collections).pipe(
      // return this.http.get<{ collections: string[] }>(API.collections).pipe(
      catchError(err => observableThrowError(err.error)),
      map(response => {
        // The collection ids in this array should be enabled
        const collectionIDs = response.collections;
        const dependencies = response.dependencies;
        // filter COLLECTIONS_KEY on ids supplied while keeping frontend collections
        const collections = COLLECTIONS.filter(
          collection => collectionIDs.indexOf(collection.id) > -1 || collection.isVisuallySpecial
        );
        collections.forEach(collection => {
          collection.dependencies = dependencies[collection.id];
        });
        return collections;
      })
    );
  }

  /**
   * Make API call to register interest in the specified collectionID
   *
   * @param collectionID
   * @param registerInterestReason
   */
  registerInterest(collectionID: string, registerInterestReason: string): Observable<{ message: string }> {
    const payload = {
      collectionID,
      registerInterestReason,
    };

    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http
      .post<{ message: string }>(API.registerInterest, payload, { headers })
      .pipe(catchError(err => observableThrowError(err.error)));
  }
}
