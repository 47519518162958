import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorResponse } from '../../commons/error-response';

import { LogoutService } from './logout.service';
import { UserService } from '../../modules/user/user.service';

@Component({
  templateUrl: 'logout.template.html',
  providers: [LogoutService],
})
export class LogoutComponent implements OnInit {
  // Indicates that the component is waiting for a response from the service to indicate
  // whether logout was successful or not
  waitForLogout: boolean;

  // Indicates that logout was successful
  success: boolean;

  errorMessage: string;
  errorStatus: number;

  constructor(private logoutService: LogoutService, private router: Router, private userService: UserService) {
    this.waitForLogout = true;
  }

  /**
   * Calls the logout API method to log the user out.
   */
  ngOnInit(): void {
    this.logoutService.logout().subscribe(
      (success: { success: string }) => {
        this.waitForLogout = false;
        this.success = true;
        this.userService.forceRefresh();
      },
      (err: ErrorResponse) => {
        console.error('Error Logging out: ' + JSON.stringify(err));
        this.errorMessage = err.message;
        this.errorStatus = err.status;
        this.waitForLogout = false;
        this.success = false;
      }
    );
  }

  navigate() {
    this.router.navigate(['/']);
  }
}
