import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from '../../notifications/notifications.service';
import { CollectionService } from '../collection.service';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user';

/**
 * Component to record a user's interest in the current collection, passes the supplied collectionName and
 * ID to the collectionService.
 */
@Component({
  selector: 'dm-register-interest',
  templateUrl: 'register-interest.component.html',
  styleUrls: ['./register-interest.component.scss'],
})
export class RegisterInterestComponent implements OnInit {
  @Input() collectionName: string;
  @Input() collectionId: string;
  @Output() interestSubmitted: EventEmitter<string>;

  registerInterestReason: string;
  formSubmitted: boolean = false;
  user: User | null;

  constructor(
    private notificationsService: NotificationsService,
    private collectionService: CollectionService,
    private userService: UserService
  ) {
    this.interestSubmitted = new EventEmitter();
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  registerInterest(): void {
    this.collectionService.registerInterest(this.collectionId, this.registerInterestReason).subscribe(() => {
      this.formSubmitted = true;
      this.interestSubmitted.emit(this.collectionId);
      this.notificationsService.success(
        'Thank You',
        `Your interest in the ${this.collectionName} collection has been recorded.`
      );
    });

    // Close notification after 5 seconds
    setTimeout(() => {
      this.notificationsService.remove();
    }, 5000);
  }
}
