import { CommonModule } from '@angular/common';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';
import { DmCommonModule } from '../../commons/dm-common.module';
import { InputTrimModule } from 'ng2-trim-directive';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterUserFormComponent } from './register-user-form.component';
import { RegisterUserService } from './register-user.service';
import { TemplateModule } from '../template/template.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TemplateModule, DmCommonModule, ControlMessagesModule, InputTrimModule],
  declarations: [RegisterUserFormComponent],
  providers: [RegisterUserService],
  exports: [RegisterUserFormComponent],
})
export class RegisterUserModule {}
