import { Component, Input } from '@angular/core';

import { AbstractControl } from '@angular/forms';
import { ValidationService } from './validation.service';

/**
 * Taken from: https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
 */

@Component({
  selector: 'dm-control-messages',
  templateUrl: 'control-messages.template.html',
})
export class ControlMessagesComponent {
  @Input() control: AbstractControl | null;

  get errorMessage(): string | null {
    if (!this.control || !this.control.errors) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        const maxlength = this.control.errors['maxlength'] ? this.control.errors['maxlength'].requiredLength : null;
        const minlength = this.control.errors['minlength'] ? this.control.errors['minlength'].requiredLength : null;
        const params = {
          maxlength,
          minlength,
        };
        return ValidationService.getValidatorErrorMessage(propertyName, params);
      }
    }

    return null;
  }
}
