import { ARROW_ICON } from './collection-config';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AppGroup, Application, Collection, isActivated, isSubscribed, noServiceRestriction } from './collection';

import { AbstractCollectionDirective } from './detail-pages/abstract-collection.directive';
import { CollectionService } from './collection.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../user/user';
import { UserService } from '../user/user.service';

const LOGIN_URL = '/redirect_login';

@Component({
  selector: 'dm-collection-detail',
  templateUrl: 'collection-detail.template.html',
  styleUrls: ['./collection-detail.component.scss'],
})
export class CollectionDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  collection: Collection;
  user: User | null;
  appGroups: AppGroup[] = [];
  arrow = ARROW_ICON;
  canRegisterInterestButtonBeDisplayed: boolean;
  shouldRegisterInterestFormBeHidden: boolean;

  @ViewChild('collectionDetails', { read: ViewContainerRef }) viewChild: ViewContainerRef;
  componentRef?: ComponentRef<AbstractCollectionDirective>;

  private watches: Subscription[] = [];

  constructor(
    private notificationsService: NotificationsService,
    private collectionService: CollectionService,
    private userService: UserService,
    private componentResolver: ComponentFactoryResolver,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  getAppLink(application: Application): string {
    const isAppAccessible = this.user || !this.collection.subscriptionRequired;
    return isAppAccessible ? application.link : `${LOGIN_URL}?context=${application.link}`;
  }

  canAccessApplication(application: Application): boolean {
    if (application.disabled) {
      return false;
    }

    if (!this.user) {
      return true;
    }

    if (!isSubscribed(this.user, this.collection)) {
      return false;
    }

    if (!noServiceRestriction(this.user, application)) {
      return false;
    }

    if (application.ipAccessRestricted && !this.user.canAccessDownloader) {
      return false;
    }

    return true;
  }

  accessClickedForLink(application: Application) {
    if (this.accessApplication(application)) {
      const link = this.getAppLink(application);
      if (application.isInternalLink && link.indexOf(LOGIN_URL) < 0) {
        this.router.navigate([link]);
      } else {
        window.location.href = link;
      }
      return true;
    }
    return false;
  }

  accessApplication(application: Application): boolean {
    if (application.disabled) {
      return false;
    }

    if (!this.collection.subscriptionRequired) {
      return true;
    }

    if (!this.user) {
      // The application link in getAppLink() handles this when the user
      // is not logged in
      return true;
    }

    if (!isSubscribed(this.user, this.collection)) {
      this.notificationsService.error(
        'Institution Not Subscribed',
        `Your institution does not subscribe to the ${this.collection.name} collection.`
      );
      return false;
    }

    if (this.user.state === 'UNREGISTERED') {
      this.router.navigate(['/register']);
      return false;
    }

    if (this.user.state === 'PENDING') {
      this.notificationsService.alert(
        'Registration Required',
        'You need to verify your email address. ' + 'Please respond to the registration email.'
      );
      return false;
    }

    if (!isActivated(this.user, this.collection)) {
      this.router.navigate(['/activate'], {
        queryParams: { activate: this.collection.id, returnLink: application.link },
      });
      return false;
    }

    if (!noServiceRestriction(this.user, application)) {
      this.notificationsService.error(
        'Access Revoked',
        'Sorry, you need to contact the helpdesk for access to this service for your account.'
      );
      return false;
    }

    if (application.ipAccessRestricted && !this.user.canAccessDownloader) {
      this.notificationsService.error(
        'Inaccessible due to IP restriction',
        `You are unable to access all data download facilities from this computer.
        This is because your institution has opted to restrict download access to
        computers belonging to .ac.uk networks. This computer does not appear to belong to such a network.
        Please contact your Digimap site representative if you believe this is a mistake.`
      );
      return false;
    }

    return true;
  }

  registerInterest(): void {
    // Form should be hidden
    this.shouldRegisterInterestFormBeHidden = true;
    // Button now be hidden by toggling canRegisterInterestButtonBeDisplayed
    this.canRegisterInterestButtonBeDisplayed = false;
  }

  /**
   * Hide Register interest button and show form
   */
  showRegisterInterestForm(): void {
    this.shouldRegisterInterestFormBeHidden = false;
    this.canRegisterInterestButtonBeDisplayed = false;
  }

  /**
   * Check users subscriptions to determine if register interest button should be displayed for current collection
   */
  shouldDisplayRegisterInterestButton(): boolean {
    if (!this.user) {
      // Wouldn't be able to log anything without a user
      return false;
    }

    // If user's institution is not subscribed to current collection and registerInterest
    // hasn't already been activated, register interest button should be displayed
    return (
      !(this.user.institution.subscribedCollections.indexOf(this.collection.id) > -1) &&
      this.canRegisterInterestButtonBeDisplayed &&
      !this.collection.isVisuallySpecial
    );
  }

  ngOnInit() {
    this.getUser();
    this.getSelectedCollection();
    this.canRegisterInterestButtonBeDisplayed = true;
    this.shouldRegisterInterestFormBeHidden = true;
  }

  ngAfterViewInit() {
    this.watches.push(
      this.collectionService.currentCollection.subscribe(c => {
        this.updateCollectionDetail();
      })
    );
  }

  ngOnDestroy() {
    this.watches.forEach(w => w.unsubscribe());
    if (this.componentRef) {
      this.componentRef.changeDetectorRef.detach();
      this.componentRef.destroy();
    }
  }

  private getUser() {
    this.watches.push(
      this.userService.getUser().subscribe(user => {
        this.user = user;
      })
    );
  }

  private getSelectedCollection() {
    this.watches.push(
      this.collectionService.currentCollection.subscribe(c => {
        if (c) {
          this.collection = c;
          this.appGroups = c.appGroups;
        }
      })
    );
  }

  private updateCollectionDetail() {
    // Force viewChild to be updated.  If we don't do this,
    // viewChild is undefined in case where page is loaded with collection name in the url
    if (!this.viewChild) {
      this.changeDetectorRef.detectChanges();
    }
    // Just to be extra safe, check viewChild exists
    if (this.viewChild) {
      this.viewChild.clear();
      if (!this.collection.collectionDetail) {
        throw Error(`Config error in ${this.collection.id}. Missing collectionDetail component.`);
      }
      const factory = this.componentResolver.resolveComponentFactory(this.collection.collectionDetail);
      this.componentRef = this.viewChild.createComponent(factory);
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }
}
