<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="view === formView.Wait">Please wait ...</div>
    <div *ngIf="view === formView.RequiresLogin">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> You need to sign it to access this page.</p>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> An unexpected error happened when sending your request:</p>
        <p>{{apiError}}</p>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
    </div>
    <form
      *ngIf="view === formView.Form"
      class="form-horizontal"
      [formGroup]="addressBaseForm"
      (ngSubmit)="onSubmit()"
      method="POST">
      <fieldset>
        <legend tabindex="0" class="addressbase-title">Request a quote for AddressBase data</legend>
        <p tabindex="0">
          Please use this form to submit a request for any OS AddressBase data products. Once the details have been
          received, EDINA will prepare a quotation for the data. Please ensure your email address is correct so that
          your quotation can be sent by email. If you have any queries, please contact EDINA on digimap@ed.ac.uk.
        </p>

        <legend>Your Details (You can update these under My Details when you log in to Digimap)</legend>

        <input type="hidden" name="oid" value="00D0Y000001jhvA" />
        <!--  EDINA Service  -->
        <input type="hidden" name="00N0Y00000H4uNI" value="Address base" />

        <!--  ----------------------------------------------------------------------  -->
        <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
        <!--  these lines if you wish to test in debug mode.                          -->
        <input type="hidden" name="debug" value="1" />
        <input type="hidden" name="debugEmail" value="w.petit@ed.ac.uk" />
        <!--  ----------------------------------------------------------------------  -->

        <div class="form-group form-group-inline">
          <label for="title" class="control-label">Title:</label>
          <input
            id="title"
            class="form-control"
            formControlName="title"
            tabindex="0"
            name="title"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['title']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline">
          <label for="first_name" class="control-label">First Name:</label>
          <input
            id="first_name"
            class="form-control"
            formControlName="firstName"
            tabindex="0"
            name="first_name"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['firstName']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline">
          <label for="last_name" class="control-label">Last Name:</label>
          <input
            id="last_name"
            class="form-control"
            formControlName="surname"
            tabindex="0"
            name="last_name"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['surname']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline hide-username">
          <label for="username" class="control-label">Digimap username:</label>
          <input
            id="username"
            class="form-control"
            formControlName="username"
            name="00N1v00000TZxMF"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['username']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline">
          <label for="email" class="control-label">Email:</label>
          <input id="email" type="email" class="form-control" formControlName="email" name="email" size="20" readonly />
          <dm-control-messages [control]="addressBaseForm.controls['email']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline">
          <label for="institution">Digimap institution:</label>
          <input
            id="institution"
            class="form-control"
            formControlName="institution"
            name="00N1v00000TZxMK"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['institution']"> </dm-control-messages>
        </div>

        <div class="form-group form-group-inline">
          <label for="department" class="control-label">Digimap department:</label>
          <input
            id="department"
            class="form-control"
            formControlName="department"
            name="00N1v00000TZxMP"
            size="20"
            type="text"
            readonly />
          <dm-control-messages [control]="addressBaseForm.controls['department']"> </dm-control-messages>
        </div>
      </fieldset>

      <fieldset class="fieldset-column">
        <legend>AddressBase Data Requested</legend>

        <div>
          <p>
            It is your responsibility to ensure you are requesting the right AddressBase product for your needs.
            Changing data products will incur additional and separate data licensing fees. Please check the product
            information on Ordnance Survey's website as follows to confirm you are requesting the right dataset:
          </p>
          <ul>
            <li>
              AddressBase:
              <a href="https://www.ordnancesurvey.co.uk/business-government/products/addressbase">
                https://www.ordnancesurvey.co.uk/business-government/products/addressbase
              </a>
            </li>
            <li>
              AddressBase Premium:
              <a href="https://www.ordnancesurvey.co.uk/business-government/products/addressbase-premium">
                https://www.ordnancesurvey.co.uk/business-government/products/addressbase-premium
              </a>
            </li>
            <li>
              AddressBase Plus:
              <a href="https://www.ordnancesurvey.co.uk/business-government/products/addressbase-plus">
                https://www.ordnancesurvey.co.uk/business-government/products/addressbase-plus
              </a>
            </li>
            <li>
              AddressBase Core:
              <a href="https://www.ordnancesurvey.co.uk/business-government/products/addressbase-core">
                https://www.ordnancesurvey.co.uk/business-government/products/addressbase-core
              </a>
            </li>
            <li>
              AddressBase Islands:
              <a href="https://www.ordnancesurvey.co.uk/business-government/products/addressbase-islands">
                https://www.ordnancesurvey.co.uk/business-government/products/addressbase-islands
              </a>
            </li>
          </ul>
        </div>

        <div class="form-group form-group-inline">
          <label for="product" class="control-label">Which data products do you require?</label>
          <div class="form-control-inline">
            <mat-form-field appearance="fill">
              <mat-label>Select Products</mat-label>
              <mat-select id="product" formControlName="products" multiple>
                <mat-option *ngFor="let product of productList" [value]="product">{{product}}</mat-option>
              </mat-select>
            </mat-form-field>
            <dm-control-messages [control]="addressBaseForm.controls['products']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="extent" class="control-label">What geographical extent do you require?</label>
          <div class="form-control-inline">
            <mat-form-field appearance="fill">
              <mat-label>Select extent</mat-label>
              <mat-select id="extent" formControlName="extent">
                <mat-option *ngFor="let extent of extentList" [value]="extent.value">
                  {{extent.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <dm-control-messages [control]="addressBaseForm.controls['extent']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline" *ngIf="this.showPostcode">
          <label for="postcode" class="control-label"
            >Please give postcode area codes you require (e.g. NW, EH, CM ):</label
          >
          <div class="form-control-inline">
            <textarea
              id="postcode"
              formControlName="postcode"
              class="form-control"
              name="00N1v00000TZxLC"
              rows="3"
              type="text"
              wrap="soft"></textarea>
            <dm-control-messages [control]="addressBaseForm.controls['postcode']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="projectName" class="control-label">Please give your project's name:</label>
          <div class="form-control-inline">
            <input
              id="projectName"
              formControlName="projectName"
              class="form-control"
              name="00N1v00000TZxLH"
              size="20"
              type="text" />
            <dm-control-messages [control]="addressBaseForm.controls['projectName']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="projectDuration" class="control-label">Please give your project duration:</label>
          <div class="form-control-inline">
            <input
              id="projectDuration"
              formControlName="projectDuration"
              class="form-control"
              name="00N1v00000TZxLM"
              size="20"
              type="text" />
            <dm-control-messages [control]="addressBaseForm.controls['projectDuration']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="grantReference" class="control-label"
            >Please give your project's grant reference number (if you have one):</label
          >
          <div class="form-control-inline">
            <input
              id="grantReference"
              formControlName="grantReference"
              class="form-control"
              name="00N1v00000Ta8lu"
              size="20"
              type="text" />
            <dm-control-messages [control]="addressBaseForm.controls['grantReference']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="primaryContact" class="control-label"
            >Primary contact details for this application (name, email address):</label
          >
          <div class="form-control-inline">
            <input
              id="primaryContact"
              formControlName="primaryContact"
              class="form-control"
              name="00N1v00000Ta8lz"
              size="20"
              type="text" />
            <dm-control-messages [control]="addressBaseForm.controls['primaryContact']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-inline">
          <label for="investigators" class="control-label">
            Principle Investigator details (names and email addresses):
          </label>
          <div class="form-control-inline">
            <textarea
              id="investigators"
              formControlName="investigators"
              class="form-control"
              name="00N1v00000TZxLb"
              rows="3"
              type="text"
              wrap="soft"></textarea>
            <dm-control-messages class="control-messages" [control]="addressBaseForm.controls['investigators']">
            </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-column">
          <label for="members" class="control-label">
            Names and email addresses of all individuals who will need to use the AddressBase data. Note that data is
            licensed to individuals so all team members should be listed and all should be registered Digimap users:
          </label>
          <div class="form-control-inline">
            <textarea
              id="members"
              formControlName="members"
              class="form-control"
              name="00N1v00000TZxLg"
              rows="3"
              type="text"
              wrap="soft"></textarea>
            <dm-control-messages [control]="addressBaseForm.controls['members']"> </dm-control-messages>
          </div>
        </div>

        <div class="form-group form-group-column">
          <mat-checkbox [checked]="hasAgreedToPrivacy" (change)="setPrivacyAgreement($event.checked)" tabindex="0">
            I agree to my details being held and processed in accordance with the
            <a class="header-link" href="https://digimap.edina.ac.uk/help/about/privacy_notice/" target="_blank">
              Digimap Privacy Notice.
            </a>
          </mat-checkbox>
        </div>

        <div class="form-group form-group-column">
          <mat-checkbox [checked]="hasAgreed" (change)="setAgreement($event.checked)" tabindex="0">
            Please read and agree to the attached
            <a
              class="header-link"
              href="https://digimap.edina.ac.uk/help/copyright-and-licensing/addressbase_eula/"
              target="_blank">
              End User Licence Agreement.
            </a>
          </mat-checkbox>
          <div class="form-group form-group-column">
            <p tabindex="0">
              I understand that only the above listed persons will be licensed to use the AddressBase data and only for
              the remainder of our Digimap subscription (ie. until 31 July). If use is required beyond this date, the
              licence can be renewed once your institution's Digimap subscription has been renewed.
            </p>
            <p tabindex="0">
              EDINA will reply to the primary contact given above with a quotation for the data requested. Invoicing
              details will be required before the data will be supplied.
            </p>
          </div>
        </div>
      </fieldset>

      <div *ngIf="errorMessages.length > 0" class="alert alert-danger" aria-live="polite" tabindex="0">
        <div *ngFor="let message of errorMessages">{{message}}</div>
      </div>

      <div class="dm-form-buttons form-group-inline">
        <button
          type="submit"
          class="btn btn-success"
          [disabled]="!(this.hasAgreedToPrivacy && this.hasAgreed && this.addressBaseForm.valid)">
          Send
        </button>
      </div>
    </form>

    <div *ngIf="view === formView.SuccessConfirmation">
      <div class="alert alert-success" aria-live="polite" tabindex="0">
        <p><strong>Thank you!</strong> Your request has been received.</p>
      </div>
      <div class="summary">
        <h1>Summary</h1>
        <table>
          <tr>
            <td><strong>Product:</strong></td>
            <td>{{addressBaseForm.value["products"]}}</td>
          </tr>
          <tr>
            <td><strong>Extent:</strong></td>
            <td>{{addressBaseForm.value["extent"]}}</td>
          </tr>
          <tr *ngIf="this.showPostcode">
            <td><strong>Postcode:</strong></td>
            <td>{{addressBaseForm.value["postcode"]}}</td>
          </tr>
          <tr>
            <td><strong>Project Name:</strong></td>
            <td>{{addressBaseForm.value["projectName"]}}</td>
          </tr>
          <tr>
            <td><strong>Project Duration:</strong>:</td>
            <td>{{addressBaseForm.value["projectDuration"]}}</td>
          </tr>
          <tr>
            <td><strong>Project Grant Reference Number:</strong></td>
            <td>{{addressBaseForm.value["grantReference"]}}</td>
          </tr>
          <tr>
            <td><strong>Primary Contact:</strong></td>
            <td>{{addressBaseForm.value["primaryContact"]}}</td>
          </tr>
          <tr>
            <td><strong>Investigator:</strong></td>
            <td>{{addressBaseForm.value["investigators"]}}</td>
          </tr>
          <tr>
            <td><strong>Members:</strong></td>
            <td>{{addressBaseForm.value["members"]}}</td>
          </tr>
        </table>
      </div>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
  </div>
</main>
