<main>
  <h1>Guest, non-academic and trial users</h1>

  <div *ngIf="error" class="alert-danger">Unknown username or password.</div>

  <form ngNoForm action="/api/pw-login-submit" method="POST">
    <div>
      <label for="username">Username:</label>
      <input id="username" name="username" type="text" />
    </div>
    <div>
      <label for="password">Password:</label>
      <input id="password" name="password" type="password" />
    </div>

    <div class="submit-login-container">
      <input class="btn btn-main btn-block" name="submit" type="submit" value="Log In" />
    </div>

    <div>
      <p>Forgotten password? Contact the <a class="dark-link" href="mailto:edina@ed.ac.uk">helpdesk</a>.</p>
    </div>
  </form>
</main>
