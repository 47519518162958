import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ActivateCollectionsDto } from './activatecollections';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

const API = {
  activated: 'api/activate',
};

@Injectable()
export class ActivationService {
  constructor(private http: HttpClient) {}

  /**
   * Get activations for subscribed collections.
   */
  getActivations(): Observable<ActivateCollectionsDto[]> {
    return this.http.get<ActivateCollectionsDto[]>(API.activated).pipe(catchError(err => throwError(err.error)));
  }

  /**
   * Set updated activations.
   */
  setActivations(collections: ActivateCollectionsDto[]): Observable<object> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .put(API.activated, JSON.stringify(collections), { headers })
      .pipe(catchError(err => throwError(err.error)));
  }

  getEula(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' });
  }
}
