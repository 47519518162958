import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactConfig, ContactDto, Question } from './contact';
import { cloneDeep } from 'lodash';
import { DisableSubmit } from '../../commons/disable-submit';
import { FormView } from '../../commons/form-view';
import { ContactConfigService } from './contact-config.service';
import { ContactService } from './contact-form.service';
import { ValidationService } from '../controlmessages/validation.service';

@Component({
  selector: 'dm-contact-form',
  templateUrl: 'contact-form.template.html',
  providers: [ContactConfigService],
})
export class ContactFormComponent implements OnInit {
  MAX_CHAR = 2000;

  original: ContactDto;
  help: ContactDto;

  questions: ContactConfig[];
  formQuestions: Question[];
  charsRemaining = this.MAX_CHAR;

  recipientExplanation = false;
  // eslint-disable-next-line max-len
  descriptionPlaceholder = `So that we may respond to your question quickly please enter as much information as possible, including:

    Which application you were using (e.g. Geology Roam, Aerial Download).
    What you are trying to.
    Details of any error message that is displayed, if one is displayed.
  `;

  readonly formView = FormView;
  // Which of the views is currently being displayed
  view: FormView;

  // If an error has occured, the message from the server
  errorMessage: string | null;

  helpForm: FormGroup;

  submitText: DisableSubmit;

  private recipients = {
    edina: 'EDINA Digimap Helpdesk',
    siterep: 'Your Institutions Digimap Support',
  };

  constructor(
    fb: FormBuilder,
    private router: Router,
    private helpService: ContactService,
    private helpConfigService: ContactConfigService,
    private route: ActivatedRoute
  ) {
    this.view = FormView.Wait;
    this.errorMessage = null;
    this.submitText = new DisableSubmit();

    this.helpForm = fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(72)]],
      email: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(255), ValidationService.emailValidator],
      ],
      institution: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
      description: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(this.MAX_CHAR)]],
      recipient: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(7)]],
      question: ['', [Validators.required]],
    });

    this.helpForm.controls['description'].valueChanges.subscribe(value => {
      this.charsRemaining = this.MAX_CHAR - value.length;
    });

    this.helpForm.valueChanges.subscribe(values => {
      // Since these 3 form elements may be disabled, they're not included in the values object
      // Make sure to check for values before updating the `help` object.
      this.help.name = values.name || this.help.name;
      this.help.email = values.email || this.help.email;
      this.help.institution = values.institution || this.help.institution;

      this.help.description = values.description;
      this.help.recipient = values.recipient;

      if (values.question) {
        const question = JSON.parse(values.question);
        this.help.question = question.text;
        this.help.recipient = question.recipient;
      }
    });
  }

  /**
   * Get User details from the server.
   *
   * @memberOf ContactFormComponent
   */
  getUser() {
    this.helpService.getUserDetails().subscribe(
      data => {
        this.help = data;
        this.original = cloneDeep(data);
        this.helpForm.reset(data);

        // Disable these controls if the user is logged in.
        if (this.help.name) {
          this.helpForm.controls['name'].disable();
        }
        if (this.help.email) {
          this.helpForm.controls['email'].disable();
        }
        if (this.help.institution) {
          this.helpForm.controls['institution'].disable();
        }
        this.view = FormView.Intro;

        this.route.queryParams.subscribe(params => {
          this.help.application = params['application'];
        });
      },
      err => {
        this.errorMessage = err.message;
        this.view = FormView.Error;
      }
    );
  }

  displayForm() {
    this.view = FormView.Form;
  }

  selectHelp(topic: ContactConfig): void {
    this.help.type = topic.id;
    this.help.title = topic.title;

    // Populate the questions array with the appropriate question for the help type e.g. logins, roam, downloader etc.
    const config = this.questions.find(c => c.id === topic.id);
    if (!config) {
      throw new Error('unknown help id - ' + topic.id);
    }
    this.formQuestions = !this.isLoggedIn()
      ? config.questions.filter(question => question.recipient === 'edina')
      : config.questions;
  }

  setHelp(): void {
    this.submitText.setSubmitting(true);

    // Improve robustness if a user goes to /help with out an `application` query parameter.
    if (!this.help.application) {
      this.help.application = 'interface';
    }

    this.helpService.setHelpRequest(this.help).subscribe({
      next: () => {
        this.submitText.setSubmitting(false);
        this.view = FormView.SuccessConfirmation;
      },
      error: () => {
        this.submitText.setSubmitting(false);
        this.view = FormView.ErrorConfirmation;
      },
    });
  }

  getRecipientDisplayName(recipient: string): string {
    return this.recipients[recipient];
  }

  isLoggedIn(): boolean {
    if (this.original.name !== '') {
      return true;
    }

    // If the user is not logged in, then we can only contact EDINA.
    // this.help.recipient = 'edina'
    this.helpForm.controls['recipient'].setValue('edina');
    return false;
  }

  toggleExplanation() {
    this.recipientExplanation = !this.recipientExplanation;
  }

  /**
   * Returns true when the form data has changed from the original values.
   */
  hasChanged(): boolean {
    return this.helpForm.dirty;
  }

  /**
   * Returns true if the form is in a state where submission of the data
   * is possible, i.e. the form is valid and thgetCollectionsere has been changes.
   */
  canSubmit(): boolean {
    return this.helpForm.valid && this.hasChanged();
  }

  /**
   * Run to reset form to original state.
   */
  resetUser(): void {
    this.help = cloneDeep(this.original);
    this.view = FormView.Form;
    this.helpForm.reset(this.help);
  }
  /**
   * Navigate back to the root (Home) page.
   */
  navigate() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.helpConfigService.getHelpConfig().subscribe(helpConfig => {
      this.questions = helpConfig;
    });

    this.getUser();
  }
}
