<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="userCollections.length > 0 && institutionMap.length > 0">
      <h3>List of Digimap Subscribing Institutions (total of {{ institutionMap.length }} institutions):</h3>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Institution Name</th>
            <th scope="col" *ngFor="let coll of userCollections">{{ coll }}</th>
          </tr>
        </thead>

        <tbody></tbody>

        <tr *ngFor="let inst of institutionMap">
          <th scope="row">{{ inst.name }}</th>
          <td *ngFor="let coll of inst.values">{{ coll !== undefined ? userCollections[coll] : '-' }}</td>
        </tr>
      </table>
    </div>

    <p tabindex="0">Your account is labelled as a data supplier, please contact us if that's incorrect.</p>
  </div>
</main>
