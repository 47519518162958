import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

const URL = 'api/global-config';

@Injectable()
export class ConfigService {
  config: Observable<Map<string, any>>;

  constructor(private http: HttpClient) {}

  getConfig(): Observable<Map<string, any>> {
    if (!this.config) {
      this.config = this.http.get<object>(URL).pipe(
        map(body => {
          const config = new Map<string, any>();

          Object.keys(body).forEach((key: string) => {
            config.set(key, body[key]);
          });

          return config;
        }),
        publishReplay(1),
        refCount()
      );
    }

    return this.config;
  }
}
