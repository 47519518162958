<mat-tab-group>
  <mat-tab label="About">
    <p>
      Global Digimap provides access to global datasets in cartographic styles and downloadable formats that are useful
      to you. The service provides the following:
    </p>
    <ul>
      <li>An easy to use interface to allow you to browse, annotate and print global maps.</li>
      <li>A data download facility providing access to global datasets for use in GIS software.</li>
    </ul>
    <p>
      You can view a list of all the data available in our help pages
      <a href="/help/our-maps-and-data/global_overview/" target="global_data">here</a>.
    </p>
    <p>
      If you have any feedback or have an idea for a new feature or dataset please let us know:
      <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. You can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 and A3 size in PDF, PNG or JPG format</li>
      <li>customise map feature display (coming soon)</li>
      <li>select a different basemap</li>
      <li>compare 2 maps (coming soon)</li>
      <li>search for and add WMS layers</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/rnODcub4JKk"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Download Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/ssEEdESGjwE"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Guides</h3>
    <p><a href="/help/learning-materials/">Application guides</a> are available for Global Digimap.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
