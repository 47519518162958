import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { Notification } from './notification';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'dm-simple-notifications',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div role="alertdialog" class="simple-notification-wrapper" aria-label="notification">
      <dm-simple-notification
        *ngFor="let a of notifications; let i = index"
        [item]="a"
        [timeOut]="timeOut"
        [clickToClose]="clickToClose"
        [maxLength]="maxLength"
        [showProgressBar]="showProgressBar"
        [pauseOnHover]="pauseOnHover"
        [theClass]="theClass"
        [rtl]="rtl"
        [position]="i">
      </dm-simple-notification>
    </div>
  `,
})
export class SimpleNotificationsComponent implements OnInit, OnDestroy {
  public notifications: Notification[] = [];

  // Input
  @Input() public options: any;

  // Sent values
  timeOut = 0;
  maxLength = 0;
  clickToClose = true;
  showProgressBar = true;
  pauseOnHover = true;
  theClass: string;
  rtl = false;

  private listener: any;

  // Received values
  private lastOnBottom = true;
  private maxStack = 8;
  private preventLastDuplicates: any = false;
  private preventDuplicates = false;

  private lastNotificationCreated: Notification;

  // Outputs
  @Output() private create = new EventEmitter();
  @Output() private destroy = new EventEmitter();

  constructor(private noteService: NotificationsService) {}

  ngOnInit() {
    // Listen for changes in the service
    this.listener = this.noteService.getChangeEmitter().subscribe((item: any) => {
      switch (item.command) {
        case 'cleanAll':
          this.notifications = [];
          break;

        case 'clean':
          this.cleanSingle(item.id);
          break;

        case 'set':
          if (item.add) {
            this.add(item.notification);
          } else {
            this.defaultBehavior(item);
          }
          break;

        default:
          this.defaultBehavior(item);
          break;
      }
    });

    this.attachChanges();
  }

  // Default behavior on event
  defaultBehavior(value: any) {
    this.notifications.splice(this.notifications.indexOf(value.notification), 1);
    this.destroy.emit(this.buildEmit(value.notification, false));
  }

  // Add the new notification to the notification array
  add(item: any) {
    item.createdOn = new Date();
    item.id = Math.random().toString(36).substring(3);

    const toBlock = this.preventLastDuplicates || this.preventDuplicates ? this.block(item) : false;

    // Save this as the last created notification
    this.lastNotificationCreated = item;

    if (!toBlock) {
      // Check if the notification should be added at the start or the end of the array
      if (this.lastOnBottom) {
        if (this.notifications.length >= this.maxStack) {
          this.notifications.splice(0, 1);
        }
        this.notifications.push(item);
      } else {
        if (this.notifications.length >= this.maxStack) {
          this.notifications.splice(this.notifications.length - 1, 1);
        }
        this.notifications.splice(0, 0, item);
      }

      this.create.emit(this.buildEmit(item, true));
    }
  }

  // Check if notifications should be prevented
  block(item: any) {
    const checkHtml = (checker: any) =>
      checker.html
        ? checker.type === item.type &&
          checker.title === item.title &&
          checker.content === item.content &&
          checker.html === item.html
        : false;
    const checkStandard = (checker: any) =>
      checker.type === item.type && checker.title === item.title && checker.content === item.content;
    const toCheck = item.html ? checkHtml : checkStandard;

    if (this.preventDuplicates && this.notifications.length > 0) {
      for (const n of this.notifications) {
        if (toCheck(n)) {
          return true;
        }
      }
    }

    if (this.preventLastDuplicates) {
      let comp: any;

      if (this.preventLastDuplicates === 'visible' && this.notifications.length > 0) {
        if (this.lastOnBottom) {
          comp = this.notifications[this.notifications.length - 1];
        } else {
          comp = this.notifications[0];
        }
      } else if (this.preventLastDuplicates === 'all' && this.lastNotificationCreated) {
        comp = this.lastNotificationCreated;
      } else {
        return false;
      }
      return toCheck(comp);
    }

    return false;
  }

  // Attach all the changes received in the options object
  attachChanges() {
    // Check options in case it's null
    if (this.options) {
      Object.keys(this.options).forEach(a => (this[a] = this.options[a]));
    }
  }

  buildEmit(notification: Notification, to: boolean) {
    const toEmit = {
      createdOn: notification.createdOn,
      type: notification.type,
      id: notification.id,
    };

    if (notification.html) {
      toEmit['html'] = notification.html;
    } else {
      toEmit['title'] = notification.title;
      toEmit['content'] = notification.content;
    }

    if (!to) {
      toEmit['destroyedOn'] = new Date();
    }

    return toEmit;
  }

  cleanSingle(id: string) {
    let indexOfDelete: number | undefined;
    let doDelete = false;

    this.notifications.forEach((a, idx) => {
      if (a.id === id) {
        indexOfDelete = idx;
        doDelete = true;
      }
    });

    if (!indexOfDelete) {
      throw new Error('Unknown notification id - ' + id);
    }

    if (doDelete) {
      this.notifications.splice(indexOfDelete, 1);
    }
  }

  ngOnDestroy() {
    if (this.listener) {
      this.listener.unsubscribe();
    }
  }
}
