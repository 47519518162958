import { NgModule } from '@angular/core';

import { DisableDirective } from './disable.directive';
import { DisablePasteDirective } from './disable-paste.directive';

@NgModule({
  declarations: [DisableDirective, DisablePasteDirective],
  exports: [DisableDirective, DisablePasteDirective],
})
export class DmCommonModule {}
