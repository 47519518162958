<div *ngIf="hasNewsflash()" class="header-alert" role="alert" aria-describedby="newsflash-content-0">
  <div class="alert alert-warning row no-gutters newsflash">
    <div class="page-padding-left">
      <a href="#" class="close" data-dismiss="alert">&times;</a>
      <ul>
        <li *ngFor="let item of newsflash; let i = index">
          <span id="newsflash-content-{{i}}" [innerHTML]="item"></span>
        </li>
      </ul>
    </div>
  </div>
</div>
