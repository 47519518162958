import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../../modules/user/user.service';
import { catchError } from 'rxjs/operators';

const API = {
  logout: 'api/logout',
};

interface Logout {
  success: string;
}

@Injectable()
export class LogoutService {
  constructor(private http: HttpClient, private userService: UserService) {}

  logout(): Observable<Logout> {
    this.userService.forceRefresh();
    return this.http.get<Logout>(API.logout).pipe(catchError(err => observableThrowError(err.error)));
  }
}
