<div class="app-container" [ngStyle]="getPrimaryBackgroundColour()">
  <h2 [ngClass]="{ emphasise: appGroup.emphasise }">{{ appGroup.groupName }}</h2>
  <h3 *ngIf="appGroup.subHeading">{{ appGroup.subHeading }}</h3>
  <hr class="dotted-white" />
  <ng-container *ngFor="let app of appGroup.apps">
    <a
      id="app-{{ app.id }}"
      *ngIf="!app.comingSoon"
      href="javascript:void(0)"
      (click)="onClick(app)"
      class="btn btn-block btn-primary app-link"
      [ngStyle]="getPrimaryLightBackgroundColour()"
      [class.app-disabled]="!isEnabled(app)">
      <dm-dynamic-svg [svg]="icon"></dm-dynamic-svg>
      <span>
        <span class="app-title">{{ app.name }}</span>
        <span *ngIf="app.appendText" class="app-append-text">{{ app.appendText }}</span>
      </span>
      <dm-dynamic-svg class="app-right-arrow" [svg]="arrow"></dm-dynamic-svg>
    </a>
    <span id="app-{{ app.id }}" *ngIf="app.comingSoon" class="btn btn-block btn-primary app-link app-disabled">
      <dm-dynamic-svg [svg]="icon"></dm-dynamic-svg>
      <span class="app-title">{{ app.name }} (Coming Soon)</span>
    </span>
  </ng-container>
</div>
