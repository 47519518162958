import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config/config.service';
import { NotificationsService } from '../notifications/notifications.service';

const ERROR_QUERY_PARAMS = ['notSubscribed', 'notActivated', 'domainRestricted'];

@Component({
  selector: 'dm-authentication-notification',
  templateUrl: 'authentication-notification.template.html',
})
export class AuthenticationNotificationComponent implements OnInit {
  constructor(
    private configService: ConfigService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.configService.getConfig().subscribe(config => {
      this.showErrors(config);
    });
  }

  private showErrors(config: Map<string, any>) {
    this.route.queryParams.subscribe(routeParams => {
      ERROR_QUERY_PARAMS.forEach(param => {
        const paramValue = routeParams[param];
        if (paramValue) {
          const message = config.get(`authorisation.${param}`) || 'Message error';

          const level = param === 'notActivated' ? 'alert' : 'error';
          const title = param === 'notActivated' ? `Activate '${_.capitalize(paramValue)}'` : 'Authorisation Error';

          const content = `
            <a href='#' class='close' data-dismiss='alert'>&times;</a>
            <div class='title'>${title}</div>
            <div class='content'>
              ${message}
            </div>
          `;

          this.notificationsService.html(content, level);
        }
      });
    });
  }
}
