import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ContactConfig } from './contact';
import { CONFIG } from './contact-config';

@Injectable()
export class ContactConfigService {
  getHelpConfig(): Observable<ContactConfig[]> {
    return of(CONFIG);
  }
}
