import { Component, OnInit } from '@angular/core';
import { User } from '../../user/user';
import { UserService } from '../../user/user.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { SupplierService } from './supplier.service';
import { COLLECTIONS } from '../collection-config';
import { Router } from '@angular/router';

const LOGIN_URL = '/redirect_login';

@Component({
  selector: 'dm-supplier',
  templateUrl: 'supplier.component.html',
})
export class SupplierComponent implements OnInit {
  user: User;
  userCollections: string[];
  institutionMap: { name: string; values: number[] }[];

  constructor(
    private userService: UserService,
    private notificationService: NotificationsService,
    private supplierService: SupplierService,
    private router: Router
  ) {
    this.institutionMap = [];
    this.userCollections = [];
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      user => {
        if (user == null) {
          window.location.href = `${LOGIN_URL}?context=${this.router.url}`;
        } else if (!user.roles.supplier) {
          window.location.href = '/';
        } else {
          this.user = user;
          this.supplierService.getInstitutionsForSupplier().subscribe(institutionsMap => {
            institutionsMap.forEach(institutionMap => {
              this.userCollections = this.user.institution.subscribedCollections;
              const collectionIndexer = new Array<number>(this.userCollections.length);
              for (const collection of institutionMap.collections) {
                const index = this.userCollections.indexOf(collection);
                collectionIndexer[index] = index;
              }
              this.institutionMap.push({
                name: institutionMap.institution,
                values: collectionIndexer,
              });
            });
            this.institutionMap.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.userCollections = this.userCollections.map(coll => {
              const collectionObject = COLLECTIONS.find(collection => collection.id === coll);
              return collectionObject ? collectionObject.name : coll;
            });
          });
        }
      },
      (err: any) => this.notificationService.error('Internal Error', err.message)
    );
  }
}
