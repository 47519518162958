import { AERIAL_COLLECTION } from './config/collection-config-aerial';
import { Collection } from './collection';
import { ENVIRONMENT_COLLECTION } from './config/collection-config-environment';
import { GEOLOGY_COLLECTOIN } from './config/collection-config-geology';
import { GLOBAL_COLLECTION } from './config/collection-config-global';
import { HISTORIC_COLLECTION } from './config/collection-config-historic';
import { LIDAR_COLLECTION } from './config/collection-config-lidar';
import { MARINE_COLLECTION } from './config/collection-config-marine';
import { OS_COLLECTION } from './config/collection-config-os';
import { SOCIETY_COLLECTION } from './config/collection-config-society';
import { SCOTIS_COLLECTION } from './config/collection-config-scotis';
import { PILOT_COLLECTION } from './config/collection-config-pilot';
import { VERISK_COLLECTION } from './config/collection-config-verisk';
import { WHATS_NEW_COLLECTION } from './config/collection-config-whats-new';
import { AGCENSUS_COLLECTION } from './config/collection-config-agcensus';
import { NTM_COLLECTION } from './config/collection-config-ntm';
import { LANDCLAN_COLLECTION } from './config/collection-config-landclan';
import { NGD_COLLECTION } from './config/collection-config-ngd';

/* eslint-disable max-len */

export const COLLECTIONS: Collection[] = [
  OS_COLLECTION,
  HISTORIC_COLLECTION,
  GEOLOGY_COLLECTOIN,
  MARINE_COLLECTION,
  ENVIRONMENT_COLLECTION,
  AERIAL_COLLECTION,
  LIDAR_COLLECTION,
  GLOBAL_COLLECTION,
  SOCIETY_COLLECTION,
  SCOTIS_COLLECTION,
  PILOT_COLLECTION,
  VERISK_COLLECTION,
  AGCENSUS_COLLECTION,
  NTM_COLLECTION,
  WHATS_NEW_COLLECTION,
  LANDCLAN_COLLECTION,
  NGD_COLLECTION,
];

export const INFO_ICON = `
  <svg viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029" xml:space="preserve">
  <metadata>
    <sfw  xmlns="&ns_sfw;">
      <slices></slices>
      <sliceSourceBounds  width="60.192" height="59.253" y="267.088" x="390.334" bottomLeftOrigin="true"></sliceSourceBounds>
    </sfw>
  </metadata>
  <path d="M25.815,52.71c-2.08,3.174-4.008,6.019-6.309,8.167c-2.287,2.145-5.102,3.569-8.517,3.55
          c-0.039,0-0.075,0-0.108,0c-2.671,0-4.647-1.117-5.705-2.62c-1.074-1.503-1.322-3.154-1.327-4.354
          c0.031-1.793,0.912-5.221,1.999-9.403c1.095-4.116,2.409-8.734,3.301-12.076c0.585-2.139,1.004-3.905,1.022-4.219
          c-0.062-0.705-0.095-0.577-0.342-0.776c-0.286-0.166-1.058-0.295-1.974-0.281c-0.284,0-0.572,0.006-0.854,0.017
          c-0.422,0.015-0.833-0.136-1.134-0.426c-0.306-0.29-0.477-0.695-0.477-1.12v-2.186c0-0.808,0.621-1.479,1.426-1.541
          c3.19-0.237,10.794-0.976,16.137-2.229c0.527-0.129,1.081,0.033,1.463,0.425c0.379,0.399,0.518,0.956,0.376,1.48
          c-2.366,8.704-5.745,20.504-7.271,26.885c-0.369,1.521-0.62,2.805-0.729,3.49c0.082-0.05,0.181-0.112,0.273-0.185
          c0.422-0.311,0.939-0.827,1.463-1.431c1.049-1.223,2.123-2.797,2.924-4.008c0.468-0.699,1.407-0.892,2.112-0.443l1.787,1.136
          c0.346,0.224,0.586,0.571,0.679,0.979C26.118,51.942,26.039,52.364,25.815,52.71z M20.96,19.125
          c-0.021,0.003-0.04,0.003-0.057,0.003c-2.353,0-4.205-1.149-5.273-2.568c-1.094-1.438-1.559-3.074-1.568-4.491
          c0.017-1.4,0.544-2.976,1.647-4.373c1.095-1.38,2.927-2.535,5.251-2.521c3.929,0.01,7.119,3.181,7.136,7.062
          C28.085,16.035,24.925,19.118,20.96,19.125z"/>
  <g>
    <path d="M51.741,19.145v12.83h12.301v5.532H51.741v12.947h-5.768V37.508h-12.3v-5.532h12.3v-12.83H51.741z"/>
  </g>
  </svg>
`;

export const ARROW_ICON = `
  <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"/>
    <path fill="none" d="M0,0h24v24H0V0z"/>
  </svg>
`;
