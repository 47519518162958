<mat-tab-group>
  <mat-tab label="About">
    <p>
      Marine Digimap provides two key marine mapping data products from
      <a href="https://www.oceanwise.eu/data/marine-themes/">OceanWise</a>, a leader in the field of marine mapping.
    </p>
    <p>
      Raster nautical charts are derived from UK Hydrographic Office paper charts and chart panels. This chart dataset
      comes in two versions: Raster Charts and Raster Charts XL, which excludes land-based features.
    </p>
    <p>
      Marine Themes Vector data covers all UK waters. It is a feature rich dataset derived from authoritative material
      from the UK Hydrographic Office, as well as comprehensive source data where available. Marine Themes Vector has
      several layers; Elevation, Shipwrecks and Obstructions, Industrial Facilities, Transport, Administrative and
      Management Units, and Geographical Regions.
    </p>
    <p>
      Marine data is extensively used in offshore engineering projects, management of marine and coastal environments,
      marine ecology studies, environmental impact assessments and tourism.
    </p>
    <p>
      Check our help page for a
      <a href="https://digimap.edina.ac.uk/help/our-maps-and-data/marine_products/#main">
        full list of the products available</a
      >.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Subscribing</h3>
    <p>
      Before you can access Marine Digimap your university or college needs to subscribe to the service. Institutional
      subscriptions are available to UK universities and colleges and are purchased for an annual fee which provides
      access for all staff and students.
    </p>
    <h3>Log in</h3>
    <p>
      If your university or college already subscribes, you can access Digimap by logging with your university or
      college login details. Use the login button on this page (top right) or select an application to be prompted to
      login.
    </p>
    <h3>Register</h3>
    <p>
      Once you have logged in you will need to register. This is an online process and requires you to verify your email
      address and agree to the licences. You need to agree to a different licence for each Collection, but the
      registration is a one-time process.
    </p>
    <h3>Requirements</h3>
    <p>
      Digimap is designed to work with the latest version of the most popular web browsers. Please ensure you are using
      an up-to-date version of Edge, Chrome, FireFox or Safari. Please note, the service has not been tested on all iPad
      Minis or Android devices.
    </p>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. Features include the ability to:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>use the Get Feature Info. button to check details of a marine feature</li>
      <li>use the Map Content menu to select which map layers to include on your map</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
      <li>compare 2 maps of the same area</li>
      <li>search for and add WMS layers</li>
    </ul>
    <h3>Marine Data Download</h3>
    <p>
      Digimap allows you to download the marine map data for use, for example, in GIS software or CAD software. You can:
    </p>
    <ul>
      <li>select your area of interest by drawing on the map, or with coordinates</li>
      <li>order multiple map data product(s) in one order</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Web Map Services</h3>
    <p>
      We provide a Chart Web Service and a Marine Web Service; which can be used to display the marine charts and the
      marine vector data, respectively, in most GIS software.
    </p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – a selection of topics follow:</p>
    <ul>
      <li><a href="/help/faqs/marine/">Marine Digimap</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
