import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateUserDto } from './update-user';
import { catchError } from 'rxjs/operators';

const API = {
  update: 'api/user/update',
  verify: 'api/user/update/verify',
};

@Injectable()
export class UpdateUserService {
  constructor(private http: HttpClient) {}

  /**
   * Get the current user state which can be viewed/modified by the update user
   * function. Rethrows errors as an observable.
   */
  getUpdateUser(): Observable<UpdateUserDto> {
    // return this.http.get<UpdateUserDto>(API.update)
    //            .pipe(catchError(err => observableThrowError(err.error)));
    return this.http.get<UpdateUserDto>(API.update).pipe(catchError(err => observableThrowError(err.error)));
  }

  /**
   * Set the details changed in the update user function. If an error occurs (e.g an
   * error response from the updateuser service, the exception will be rethrown for
   * use by the calling component.
   */
  setUpdateUser(updateUserDto: UpdateUserDto): Observable<object> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .put<UpdateUserDto>(API.update, JSON.stringify(updateUserDto), { headers })
      .pipe(catchError(err => observableThrowError(err.error)));
  }

  verify(token: string): Observable<object> {
    return this.http.get(`${API.verify}/${token}`).pipe(catchError(err => observableThrowError(err.error)));
  }
}
