<dm-simple-notifications></dm-simple-notifications>

<main *ngIf="isRegistrationPending()" class="header-alert">
  <div class="alert alert-warning row no-gutters verify-notification">
    <div class="page-padding-left">
      <a href="#" class="close" data-dismiss="alert">&times;</a>
      <h3>Verify Your Email Address - {{user?.email}}</h3>
      <p>
        Please click on the link sent to you at <strong>{{user?.email}}</strong> before {{user?.verificationExpiry |
        date:'EEEE d MMM y'}} {{user?.verificationExpiry | date:'shortTime'}} (UK Time)
      </p>
      <div class="verify-notification-button-container">
        <div class="verify-notification-button-label">No email? Check your spam.</div>
        <div class="verify-notification-button">
          <button
            class="btn btn-sm btn-warning"
            (click)="resendVerification()"
            [disabled]="resendSubmitText.getSubmitting() || deleteSubmitText.getSubmitting()"
            [innerHTML]="resendSubmitText.getSubmitText()"></button>
        </div>
      </div>
      <div class="verify-notification-button-container">
        <div class="verify-notification-button-label">Wrong email address?</div>
        <div class="verify-notification-button">
          <button
            class="btn btn-sm btn-warning"
            (click)="deleteRegistration()"
            [disabled]="deleteSubmitText.getSubmitting() || resendSubmitText.getSubmitting()"
            [innerHTML]="deleteSubmitText.getSubmitText()"></button>
        </div>
      </div>
      <p>
        If you need further help please contact the EDINA Digimap Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>
    </div>
  </div>
</main>

<main *ngIf="isEmailChangePending()" class="header-alert">
  <div class="alert alert-warning row no-gutters verify-notification">
    <div class="page-padding-left">
      <a href="#" class="close" data-dismiss="alert">&times;</a>
      <h3>Verify Your Changed Email Address - {{updatedUser?.pendingEmail}}</h3>
      <p>
        Please check your NEW email address for the verification link. You must click on this WITHIN 24 HOURS to confirm
        the change of email. If you do not do this, the change will not take effect. Changes to your name and department
        will take effect immediately.
      </p>
      <p>
        Any queries, please contact
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>
    </div>
  </div>
</main>
