<main>
  <dm-authentication-notification role="alert"></dm-authentication-notification>

  <dm-collection-header></dm-collection-header>

  <dm-newsflash role="alert"></dm-newsflash>

  <dm-verify-notification role="alert"></dm-verify-notification>

  <router-outlet></router-outlet>
  <div class="push"></div>
</main>
