import { flatMap } from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ALL, LICENSED, OPEN, Collection } from '../collection';
import { CollectionService } from '../collection.service';

const LOGIN_URL = '/redirect_login';

@Component({
  selector: 'dm-wms-links',
  templateUrl: 'wms-links.component.html',
  styleUrls: ['wms-links.component.scss'],
})
export class WMSLinksComponent implements OnInit, OnDestroy {
  collection: Collection;
  serviceId = '';
  user: User | null;
  OPEN = OPEN;
  LICENSED = LICENSED;
  private watches: Subscription[] = [];

  constructor(private router: Router, private userService: UserService, private collectionService: CollectionService) {}

  ngOnDestroy(): void {
    this.watches.forEach(w => w.unsubscribe());
  }

  ngOnInit(): void {
    this.watches.push(
      this.userService.getUser().subscribe(user => {
        this.user = user;
      })
    );

    const url = this.router.url;
    let urlCollection = url.substring(url.indexOf('/') + 1, url.indexOf('/wms'));
    this.serviceId = urlCollection;
    urlCollection = urlCollection === 'chart' ? 'marine' : urlCollection;

    // collecting collection name from path parameters
    this.collectionService.getCollections().subscribe(collections => {
      collections.filter(collection => {
        if (collection.id === urlCollection) {
          this.collection = collection;
          return;
        }
      });
    });
  }

  hasWMSLink(type: string): boolean {
    return (
      this.collection &&
      flatMap(this.collection.appGroups, group => group.apps)
        .filter(app => app.link === window.location.pathname)
        .filter(app => app.wmsLinks === ALL || app.wmsLinks === type).length > 0
    );
  }

  getWMSLink(type: string) {
    if (this.user) {
      // eslint-disable-next-line max-len
      return `${window.location.origin}/mapproxy/wms/${this.serviceId}-${type}/${this.user.wmsToken}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities`;
    }
    // if the user isn't defined, prompt login and redirect to same page
    return `${LOGIN_URL}?context=${this.router.url}`;
  }

  getCollectionName(): string {
    return this.collection ? this.collection.name : 'current';
  }
}
