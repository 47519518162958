import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user/user.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { User } from '../../user/user';
import { COLLECTIONS } from '../collection-config';

@Component({
  selector: 'dm-my-collections',
  templateUrl: 'my-collections.component.html',
})
export class MyCollectionsComponent implements OnInit {
  user: User | null;
  activatedCollections: string[] = [];
  missingCollections: string[] = [];

  constructor(private userService: UserService, private notificationService: NotificationsService) {
    this.user = null;
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      user => {
        this.user = user;
        this.formulateLists();
      },
      (err: any) => this.notificationService.error('Internal Error', err.message)
    );
  }

  collectionName(collectionId: string): string {
    const col = COLLECTIONS.find(collection => collectionId === collection.id);
    return col ? col.name : collectionId;
  }

  private formulateLists() {
    return (
      this.user &&
      this.user.institution.subscribedCollections.forEach(personalCollection => {
        if (this.user && this.user.activatedCollections.indexOf(personalCollection) < 0) {
          this.activatedCollections.push(personalCollection);
        } else {
          this.missingCollections.push(personalCollection);
        }
      })
    );
  }
}
