import { Collection, GroupName, LICENSED } from '../collection';

import { HistoricDetailComponent } from '../detail-pages/historic-detail.component';

/* eslint-disable max-len */

export const HISTORIC_COLLECTION: Collection = {
  id: 'historic',
  name: 'Historic',
  icon: `
    <svg x="0px" y="0px" width="68.029px" height="68.029px" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029">
      <path d="M54.867,24.929c5.514,0,9.984-4.473,9.984-9.985c0-5.515-4.471-9.985-9.984-9.985c-0.147,0-0.291,0.012-0.436,0.021
        c-0.081-0.949-0.867-1.695-1.838-1.695H16.599c-0.925,0-1.685,0.678-1.824,1.564c-0.07-0.001-0.137-0.01-0.208-0.01
        c-5.515,0-9.985,4.469-9.985,9.984s4.471,9.984,9.985,9.984c0.465,0,0.918-0.042,1.366-0.104v29.949
        c-2.723,0.338-4.835,2.658-4.835,5.473c0,3.049,2.472,5.52,5.519,5.52h37.527c3.048,0,5.518-2.471,5.518-5.52
        c0-3.004-2.4-5.443-5.39-5.512V24.9C54.47,24.911,54.666,24.929,54.867,24.929z M26.702,48.431c0,1.748-1.418,3.166-3.166,3.166
        c-1.75,0-3.166-1.418-3.166-3.166v-26.66c-1.145,0.744-2.44,1.249-3.832,1.456c-0.477,0.068-0.951,0.104-1.425,0.104
        c-2.05,0-4.037-0.656-5.712-1.899c-1.699-1.261-2.804-3.107-3.113-5.201c-0.308-2.091,0.216-4.179,1.477-5.878
        c2.159-2.906,6.28-3.514,9.184-1.356c2.426,1.802,2.935,5.24,1.133,7.667c-1.518,2.041-4.412,2.469-6.452,0.951l1.36-1.832
        c1.031,0.766,2.494,0.551,3.26-0.48c1.051-1.414,0.756-3.423-0.66-4.473c-1.896-1.41-4.584-1.012-5.992,0.885
        c-0.898,1.209-1.271,2.695-1.052,4.184c0.222,1.49,1.008,2.807,2.216,3.703c1.572,1.168,3.507,1.656,5.444,1.368
        c1.938-0.286,3.647-1.31,4.815-2.882l0.053,0.039c0.58-0.725,1.462-1.197,2.463-1.197c1.748,0,3.166,1.416,3.166,3.164V48.431z
        M38.425,47.822c0,1.814-1.446,3.289-3.229,3.289s-3.229-1.475-3.229-3.289V19.927c0-1.815,1.446-3.289,3.229-3.289
        s3.229,1.474,3.229,3.289V47.822z M54.24,22.853c-0.473,0-0.948-0.035-1.425-0.105c-1.327-0.196-2.57-0.662-3.677-1.352v26.521
        c0,1.748-1.414,3.166-3.162,3.166c-1.75,0-3.168-1.418-3.168-3.166V19.579c0-1.749,1.418-3.167,3.168-3.167
        c1.242,0,2.306,0.724,2.823,1.766c1.135,1.25,2.654,2.064,4.35,2.313c1.938,0.286,3.871-0.2,5.443-1.367
        c1.209-0.899,1.995-2.212,2.217-3.704c0.219-1.488-0.153-2.975-1.052-4.185c-1.408-1.894-4.097-2.292-5.99-0.886
        c-1.419,1.054-1.714,3.062-0.664,4.477c0.767,1.029,2.229,1.246,3.262,0.48l1.359,1.831c-2.04,1.517-4.934,1.089-6.45-0.952
        c-1.802-2.426-1.295-5.865,1.131-7.666c2.904-2.158,7.024-1.549,9.183,1.357c1.262,1.696,1.786,3.785,1.478,5.877
        c-0.31,2.094-1.415,3.939-3.113,5.201C58.279,22.199,56.29,22.853,54.24,22.853z"/>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'Landmark Information Group Logo',
      file: '/assets/images/collections/supplier-logos/landmark_information_group_logo_min.png',
      width: 250,
      height: 60,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: HistoricDetailComponent,
  licencePdf: '/help/copyright-and-licensing/historic_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/historic_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'HISTORICMAPPER',
          name: 'Historic Digimap',
          link: '/roam/map/historic',
        },
      ],
    },
    {
      groupName: GroupName.WEBSERVICES,
      apps: [
        {
          id: 'HISTORICWMS',
          name: 'Web Services',
          link: '/historic/wms',
          isInternalLink: true,
          wmsLinks: LICENSED,
        },
      ],
    },
  ],
};
