import { Collection, GroupName, LICENSED } from '../collection';

import { MarineDetailComponent } from '../detail-pages/marine-detail.component';

/* eslint-disable max-len */

export const MARINE_COLLECTION: Collection = {
  id: 'marine',
  name: 'Marine',
  icon: `
    <svg x="0px" y="0px" width="68.029px" height="68.029px" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029">
      <g>
        <g>
          <polygon points="61.52,45.134 61.392,43.374 61.233,41.808 61.073,40.527 60.912,39.599 60.752,39.214 60.593,39.183
            60.307,39.214 59.891,39.31 59.411,39.47 58.867,39.663 58.259,39.919 57.589,40.206 56.885,40.527 56.15,40.878 55.383,41.231
            54.647,41.648 53.913,42.03 53.209,42.447 52.506,42.863 51.899,43.279 51.323,43.663 50.94,43.983 50.588,44.271 50.365,44.494
            50.205,44.718 50.141,44.91 50.205,45.103 50.332,45.294 50.62,45.486 53.561,47.279 52.537,48.655 51.516,49.966 50.525,51.182
            49.533,52.333 48.543,53.421 47.553,54.413 46.53,55.31 45.538,56.141 44.484,56.876 43.461,57.517 42.375,58.062 41.287,58.508
            40.169,58.861 38.987,59.116 37.804,59.276 36.557,59.341 36.557,22.353 36.78,22.288 37.643,21.968 38.444,21.585 39.209,21.073
            39.912,20.529 40.584,19.888 41.16,19.217 41.703,18.449 42.15,17.649 42.534,16.785 42.854,15.857 43.077,14.897 43.237,13.906
            43.268,12.881 43.237,11.826 43.077,10.801 42.854,9.809 42.534,8.882 42.15,7.986 41.703,7.185 41.16,6.418 40.584,5.682
            39.912,5.074 39.209,4.498 38.444,4.018 37.643,3.602 36.78,3.282 35.885,3.027 34.99,2.899 34.032,2.834 33.073,2.899
            32.178,3.027 31.283,3.282 30.42,3.602 29.621,4.018 28.854,4.498 28.152,5.074 27.48,5.682 26.873,6.418 26.362,7.185
            25.881,7.986 25.499,8.882 25.178,9.809 24.954,10.801 24.795,11.826 24.764,12.881 24.764,13.201 24.795,13.489 24.795,13.809
            24.826,14.098 24.858,14.322 24.891,14.513 24.922,14.706 24.954,14.928 25.147,15.76 25.402,16.561 25.721,17.329 26.106,18.064
            26.552,18.737 27.031,19.377 27.575,19.985 28.152,20.529 28.758,21.008 29.429,21.456 30.133,21.841 30.836,22.161
            31.604,22.416 31.635,22.423 31.635,59.341 30.389,59.276 29.173,59.116 27.991,58.861 26.841,58.508 25.721,58.062
            24.666,57.517 23.612,56.876 22.557,56.141 21.533,55.31 20.543,54.413 19.522,53.421 18.53,52.333 17.507,51.182 16.516,49.966
            15.494,48.655 14.471,47.279 17.411,45.486 17.699,45.294 17.826,45.103 17.891,44.91 17.826,44.718 17.666,44.494 17.444,44.271
            17.092,43.983 16.708,43.663 16.133,43.279 15.526,42.863 14.823,42.447 14.119,42.03 13.384,41.648 12.65,41.231 11.882,40.878
            11.147,40.527 10.445,40.206 9.773,39.919 9.165,39.663 8.622,39.47 8.143,39.31 7.726,39.214 7.44,39.183 7.28,39.214
            7.119,39.599 6.96,40.527 6.8,41.808 6.64,43.374 6.513,45.134 6.448,46.894 6.448,48.589 6.544,50.126 6.609,50.67 6.672,51.085
            6.736,51.437 6.832,51.694 6.96,51.822 7.152,51.886 7.407,51.822 7.726,51.662 10.636,49.838 10.987,50.542 11.37,51.245
            11.818,51.918 12.266,52.621 12.778,53.325 13.287,53.997 13.831,54.7 14.438,55.373 15.046,56.044 15.685,56.684 16.356,57.325
            17.027,57.964 17.763,58.573 18.498,59.181 19.234,59.757 20.032,60.301 20.799,60.844 21.631,61.356 22.461,61.835
            23.292,62.284 24.155,62.732 25.02,63.116 25.881,63.5 26.776,63.852 27.672,64.14 28.567,64.427 29.494,64.653 30.42,64.845
            31.315,65.003 32.242,65.101 33.168,65.163 34.096,65.197 35.024,65.163 35.918,65.101 36.844,65.003 37.74,64.845 38.666,64.653
            39.563,64.427 40.456,64.14 41.351,63.852 42.215,63.5 43.11,63.116 43.972,62.732 44.803,62.284 45.633,61.835 46.465,61.356
            47.265,60.844 48.065,60.301 48.83,59.757 49.566,59.181 50.301,58.573 51.036,57.964 51.707,57.325 52.379,56.684 53.018,56.044
            53.625,55.373 54.2,54.7 54.744,53.997 55.287,53.325 55.766,52.621 56.215,51.918 56.662,51.245 57.045,50.542 57.397,49.838
            60.307,51.662 60.625,51.822 60.88,51.886 61.073,51.822 61.201,51.694 61.297,51.437 61.361,51.085 61.424,50.67 61.488,50.126
            61.582,48.589 61.582,46.894     "/>
        </g>
      </g>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'Oceanwise Logo',
      file: '/assets/images/collections/supplier-logos/oceanwise.svg',
      width: 167,
      height: 90,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: MarineDetailComponent,
  licencePdf: '/help/copyright-and-licensing/marine_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/marine_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'MARINEROAM',
          name: 'Marine Digimap',
          link: '/roam/map/marine',
        },
      ],
    },
    {
      groupName: GroupName.WEBSERVICES,
      apps: [
        {
          id: 'MARINEWMS',
          name: 'Marine Web Services',
          link: '/marine/wms',
          isInternalLink: true,
          wmsLinks: LICENSED,
        },
        {
          id: 'CHARTWMS',
          name: 'Chart Web Services',
          link: '/chart/wms',
          isInternalLink: true,
          wmsLinks: LICENSED,
        },
      ],
    },
  ],
};
