import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DisableSubmit } from '../../commons/disable-submit';
import { ErrorResponse } from '../../commons/error-response';
import { FormView } from '../../commons/form-view';
import { UpdateUserDto } from './update-user';
import { UpdateUserService } from './update-user.service';
import { UserService } from '../user/user.service';
import { ValidationService } from '../controlmessages/validation.service';

@Component({
  selector: 'dm-update-user-form',
  templateUrl: 'update-user-form.template.html',
})
export class UpdateUserFormComponent implements OnInit {
  public static EDU_EMAIL_DOMAIN: string = '.ac.uk';
  // Ideally would be const static but isn't supported by typescript
  titles: string[] = ['Mr', 'Miss', 'Mrs', 'Ms', 'Mx', 'Dr', 'Prof', 'Not specified'];

  user: UpdateUserDto;
  newDepartment: string;

  readonly formView = FormView;

  // Which of the views is currently being displayed
  view: FormView;

  // If an error has occured, the message from the server
  errorMessage: string;

  updateUserForm: FormGroup;

  submitText: DisableSubmit;

  emailChanged = false;

  // The original state of the update user DTO
  private original: UpdateUserDto;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private updateUserService: UpdateUserService
  ) {
    this.view = FormView.Wait;
    this.updateUserForm = fb.group(
      {
        title: ['', Validators.required],
        firstName: ['', [Validators.required, Validators.maxLength(32)]],
        surname: ['', [Validators.required, Validators.maxLength(32)]],
        email: ['', [Validators.required, Validators.maxLength(255), ValidationService.emailValidator]],
        department: '',
        newDepartment: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
        agreeToPrivacyNotice: ['', Validators.requiredTrue],
        marketingContactable: '',
        supportContactable: '',
      },
      {
        validator: this.departmentValidator,
      }
    );

    // Listens to changes in the form values, reflecting the department
    // and newDepartment values back onto the user model.
    this.updateUserForm.valueChanges.subscribe(values => {
      this.user.title = values.title;
      this.user.firstName = values.firstName;
      this.user.surname = values.surname;
      this.user.email = values.email;
      this.user.marketingContactable = values.marketingContactable;
      this.user.supportContactable = values.supportContactable;

      if (values.department === '') {
        this.user.department = values.newDepartment;
      } else {
        this.user.department = values.department;
      }
    });

    this.submitText = new DisableSubmit();
  }

  /**
   * Returns true if the form is in a state where submission of the data
   * is possible, i.e. the form is valid and there has been changes.
   */
  canSubmit(): boolean {
    return this.updateUserForm.valid && this.updateUserForm.dirty;
  }

  /**
   * Checks the validity of the existing department and new department fields. If the
   * existing department is empty then the other department must be valid. If the
   * existing department is non-empty then we don't care about the value of new
   * department.
   *
   * The new department value must be between 2-64 characters inclusively.
   */
  departmentValidator(group: FormGroup): { [key: string]: boolean } | null {
    const existingDepartment = group.controls['department'];
    const newDepartment = group.controls['newDepartment'];

    if (existingDepartment.value === '' && newDepartment.value === '') {
      group.controls['newDepartment'].setErrors({ required: true });
      return { required: true };
    } else if (existingDepartment.value === '' && !newDepartment.valid) {
      return newDepartment.errors;
    } else {
      newDepartment.setErrors(null);
      return null;
    }
  }

  /**
   * Get the Observable of a user from the service, then assign it.
   */
  ngOnInit() {
    this.route.params.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.verifyUser(token);
      } else {
        this.getUser();
      }
    });
  }

  /**
   * Initialise form model from backend.
   */
  getUser() {
    this.updateUserService.getUpdateUser().subscribe(
      data => {
        this.user = data;
        this.original = cloneDeep(data);
        this.newDepartment = '';
        this.view = FormView.Form;
        this.updateUserForm.reset(this.user);
      },
      err => {
        this.errorMessage = err.message;
        this.view = FormView.Error;
      }
    );
  }

  /**
   * Check if the new email address in under an educational domain
   */
  isNotEduEmail(): boolean {
    return !this.user.email.toLowerCase().endsWith(UpdateUserFormComponent.EDU_EMAIL_DOMAIN);
  }

  /**
   * Run when form submitted.
   */
  updateUser(): void {
    this.submitText.setSubmitting(true);
    this.updateUserService.setUpdateUser(this.user).subscribe({
      next: () => {
        this.userService.forceRefresh();
        this.submitText.setSubmitting(false);
        this.view = FormView.SuccessConfirmation;
      },
      error: error => {
        this.submitText.setSubmitting(false);
        this.errorMessage = error.message;
        this.view = FormView.ErrorConfirmation;
      },
    });
  }

  onChange(event: any): void {
    this.emailChanged = event.target.value === this.original.email ? false : true;
  }

  verifyUser(token: string) {
    this.updateUserService.verify(token).subscribe({
      next: () => {
        this.view = FormView.VerificationSuccess;
        console.log(`email change verified`);
      },
      error: (error: ErrorResponse) => {
        this.errorMessage = error.message;
        this.view = FormView.VerificationError;
        console.log(`email change failed`);
      },
    });
  }

  /**
   * Run to reset form to original state.
   */
  resetUser(): void {
    this.user = cloneDeep(this.original);
    this.newDepartment = '';
    this.view = FormView.Form;
    this.updateUserForm.reset(this.user);
  }

  /**
   * Takes the user back to the home page.
   */
  navigateHome(): void {
    this.router.navigate(['/']);
  }
}
