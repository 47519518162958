export const getQueryParameters = () => {
  // In an ideal world, this would use Angular's ActivatedRoute. However, ActivatedRoute waits for... something,
  // to actually resolve the queryParams from the URL. It seems to wait for the final route state to be determined.
  // In any case, it causes multiple triggers of globalConfiguration resolver, which is probably fine but is...
  // not something we need to tolerate.
  let result: Record<string, string> = {};
  try {
    // StackOverflow FTW: https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object
    let search = location.search;
    if (search.length > 1) {
      search = search.substring(1).replace(/&/g, '","').replace(/=/g, '":"');
      result = JSON.parse('{"' + search + '"}', (key, value) => (key === '' ? value : decodeURIComponent(value)));
    }
  } catch (e) {
    console.error(`Could not parse query parameters from location: '${location ? location.search : 'unknown'}'`);
  }
  return result;
};

export const isExperimental = (): boolean => getQueryParameters()['experimental'] === 'true';
