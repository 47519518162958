<h2>New for academic year 1st August 2024 onwards</h2>
<p>
  EDINA, on behalf of the UK academic community, continually engage with both public and private spatial data vendors to
  ensure that we are able to offer the latest and best data resources to support research and teaching. We negotiate
  access to data at substantial discount over normal list price and we always aim to strike the best deals for our
  community.
</p>
<p>EDINA are pleased to offer the following new products within Digimap at academic discount prices:</p>

<mat-tab-group [selectedIndex]="selectedIndex">
  <mat-tab label="NGD - National Geospatial Database from Ordnance Survey">
    <p>
      The OS National Geographic Database (NGD) contains authoritative data that describes the geography of Great
      Britain. It delivers improved data structures, richer attribution, increased currency of data supply and enhanced
      metadata.
    </p>
    <p>
      The data is structured into themes. Each theme is made up of one or more collections, which in turn have feature
      types. A theme is a macro grouping of features which all represent similar geographic entities. Themes are the
      highest level of grouping within the OS NGD; examples include ‘Buildings’ and ‘Transport’.
    </p>
    <p>The nine OS NGD themes included in the Education licence are:</p>
    <ul>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-building-features">OS NGD Building Features</a></li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-water-features">OS NGD Water Features</a></li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-water-network">OS NGD Water Network</a></li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-land-features">OS NGD Land Features</a></li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-land-use-features">OS NGD Land Use Features</a></li>
      <li>
        <a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-structures-features">OS NGD Structures Features</a>
      </li>
      <li>
        <a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-geographical-name-features"
          >OS NGD Geographical Name Features</a
        >
      </li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-transport-rami">OS NGD Transport RAMI</a></li>
      <li>
        <a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-transport-features">OS NGD Transport Features</a>
      </li>
      <li><a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-transport-network">OS NGD Transport Network</a></li>
      <li>
        <a href="/help/our-maps-and-data/os_ngd_overview/#os-ngd-administrative--statistical-units"
          >OS NGD Administrative &amp; Statistical Units</a
        >
      </li>
    </ul>

    More information:
    <ul>
      <li>
        <a href="https://www.youtube.com/watch?v=n_nSp_5-FHE">Introduction to the OS National Geographic Database</a>
        from Ordnance Survey (YouTube).
      </li>
      <li>
        <a href="https://docs.os.uk/osngd">Ordnance Survey NGD Documentation Platform</a>
      </li>
    </ul>

    <h3>Register your interest</h3>
    <dm-register-interest collectionId="ngd" collectionName="ngd"></dm-register-interest>
  </mat-tab>
  <mat-tab label="LandClan Enriched Land and Property Data">
    <p>
      EDINA are pleased to partner with <a href="https://landclan.com/home/">LandClan</a> to provide Digimap users
      access to an enriched database of Land and Property Data for the UK.
    </p>
    <p>
      The UK is a rich data environment, but the abundance and range of sources make it difficult and expensive to
      harness the critical pieces of information a researcher or teacher may need.
    </p>
    <p>
      LandClan’s‘s advanced spatial data engine has been created to overcome deficiencies in the cross-referencing
      ability of standard database and GIS technology.This has yielded an attribute rich spatial database of features,
      available for the first time to a UK academic audience.
    </p>
    <p>
      LandClan data are structured so that attributes are attached to the most relevant one of four linked geometries
      (points or polygons). Whether it’s a single shop or apartment, or a whole shopping centre or block; any property
      can be described using a combination of these tables.
    </p>

    <h3>Simplified view of the LandClan database Data Structure</h3>
    <figure>
      <img src="assets/images/new-for-23-24/landclan-db.png" />
    </figure>

    <h3>The Digimap LandClan product offers the following range of attributes</h3>
    <table>
      <thead>
        <tr>
          <th>Geometry</th>
          <th>Theme</th>
          <th>Number of Attributes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ADDRESS</td>
          <td></td>
          <td>74</td>
        </tr>
        <tr>
          <td></td>
          <td>Energy Performance Certificate (EPC)</td>
          <td>69</td>
        </tr>
        <tr>
          <td></td>
          <td>Overview</td>
          <td>5</td>
        </tr>
        <tr>
          <td>BUILDING</td>
          <td></td>
          <td>12</td>
        </tr>
        <tr>
          <td></td>
          <td>Overview</td>
          <td>12</td>
        </tr>
        <tr>
          <td>PARCEL</td>
          <td></td>
          <td>119</td>
        </tr>
        <tr>
          <td></td>
          <td>Building Metrics</td>
          <td>8</td>
        </tr>
        <tr>
          <td></td>
          <td>Constraints</td>
          <td>34</td>
        </tr>
        <tr>
          <td></td>
          <td>Infrastructure</td>
          <td>6</td>
        </tr>
        <tr>
          <td></td>
          <td>Overview</td>
          <td>10</td>
        </tr>
        <tr>
          <td></td>
          <td>Ownership</td>
          <td>20</td>
        </tr>
        <tr>
          <td></td>
          <td>Planning Applications and Policies</td>
          <td>2</td>
        </tr>
        <tr>
          <td></td>
          <td>Property Prices</td>
          <td>12</td>
        </tr>
        <tr>
          <td></td>
          <td>Proximity Distances</td>
          <td>9</td>
        </tr>
        <tr>
          <td></td>
          <td>Public Rights of Way</td>
          <td>4</td>
        </tr>
        <tr>
          <td></td>
          <td>Road Frontages and Miscellaneous</td>
          <td>6</td>
        </tr>
        <tr>
          <td></td>
          <td>Slope</td>
          <td>1</td>
        </tr>
        <tr>
          <td></td>
          <td>Subsidence</td>
          <td>7</td>
        </tr>
        <tr>
          <td>POSTCODE</td>
          <td></td>
          <td>70</td>
        </tr>
        <tr>
          <td></td>
          <td>Climate</td>
          <td>2</td>
        </tr>
        <tr>
          <td></td>
          <td>Demography & Social Economic</td>
          <td>56</td>
        </tr>
        <tr>
          <td></td>
          <td>Property Prices</td>
          <td>12</td>
        </tr>
        <tr>
          <td>Total</td>
          <td></td>
          <td>275</td>
        </tr>
      </tbody>
    </table>

    <h3>Register your interest</h3>
    <dm-register-interest collectionId="landclan" collectionName="Landclan"></dm-register-interest>
  </mat-tab>
</mat-tab-group>

<h1>New Last Year</h1>
<p>In 2023/24 we introduced the following data collections:</p>
<ul>
  <li><a href="/help/our-maps-and-data/aerial_products/#aerial-extra">Aerial Extra</a></li>
  <li><a href="/help/our-maps-and-data/national_tree_map/">National Tree Map</a></li>
</ul>
