<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <h3 tabindex="0">Collections available</h3>

    <div *ngIf="missingCollections.length > 0">
      <p>Your active Digimap collection(s):</p>
      <ul>
        <li *ngFor="let collection of missingCollections">
          {{ collectionName(collection) }}
        </li>
      </ul>
    </div>

    <div *ngIf="activatedCollections.length > 0">
      <p>Collection(s) available for activation:</p>
      <ul>
        <li *ngFor="let collection of activatedCollections">
          {{ collectionName(collection) }}
        </li>
      </ul>

      <p>Activate additional collections in <a [routerLink]="['/activate']">Licence Agreements</a>.</p>
    </div>

    <div *ngIf="user && user.institution.supportUrl && user.institution.supportUrl.indexOf('nolocalinfo') < 0">
      <h3 tabindex="0">Help from your institution</h3>

      <p><a href="{{ user.institution.supportUrl }}">Local Support Information</a>.</p>
    </div>

    <div *ngIf="user && user.institution.supportEmail">
      <h3 tabindex="0">Contact your institution</h3>

      <p>
        Contact your institution's support at
        <a href="mailto:{{ user.institution.supportEmail }}" target="_blank">{{ user.institution.supportEmail }}</a
        >.
      </p>
    </div>
  </div>
</main>
