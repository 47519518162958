/* eslint-disable max-len */

import { ContactConfig } from './contact';

export const CONFIG: ContactConfig[] = [
  {
    id: 'login',
    title: 'Logging in',
    links: [
      {
        text: 'How can I login?',
        url: '/help/about/login/',
      },
      {
        text: 'How do I register for Digimap?',
        url: '/help/about/register/',
      },
    ],
    questions: [
      {
        text: "I've forgotten my username or password",
        recipient: 'siterep',
      },
      {
        text: 'I get an error when attempting to log in or register',
        recipient: 'edina',
      },
      {
        text: 'The service appears to be unavailable',
        recipient: 'edina',
      },
      {
        text: 'Other',
        recipient: 'edina',
      },
    ],
  },
  {
    id: 'roam',
    title: 'Creating a map (using Roam)',
    links: [
      {
        text: 'How do I use Roam?',
        url: '/help/roam/overview/',
      },
      {
        text: 'How do I use the drawing tools in Roam?',
        url: '/help/roam/add_text/',
      },
    ],
    questions: [
      {
        text: 'Where can I print a large format map?',
        recipient: 'siterep',
      },
      {
        text: 'How do I create a high quality map for printing?',
        recipient: 'edina',
      },
      {
        text: 'I get an error when attempting to import annotations',
        recipient: 'edina',
      },
      {
        text: "I can't find the tools/functions I'm looking for",
        recipient: 'edina',
      },
      {
        text: 'Other',
        recipient: 'edina',
      },
    ],
  },
  {
    id: 'download',
    title: 'Downloading data',
    links: [
      {
        text: 'How do I use Data Download?',
        url: '/help/data-download/',
      },
      {
        text: 'How do I reorder data?',
        url: '/help/data-download/previous_downloads/',
      },
      {
        text: 'What products are available?',
        url: '/help/our-maps-and-data/os_overview/',
      },
      {
        text: 'What formats can I download the data in?',
        url: '/help/gis/data_formats/',
      },
      {
        text: 'When was the data last updated?',
        url: '/help/faqs/ordnance_survey/',
      },
    ],
    questions: [
      {
        text: 'I get an error when I try to download data from Digimap',
        recipient: 'edina',
      },
      {
        text: "I can't find the data I'm looking for",
        recipient: 'edina',
      },
      {
        text: 'Why have I not received the email to download the data I ordered?',
        recipient: 'edina',
      },
      {
        text: 'Is it possible to order the whole dataset for my project?',
        recipient: 'edina',
      },
      {
        text: 'Other',
        recipient: 'edina',
      },
    ],
  },
  {
    id: 'data',
    title: 'Using data in GIS/CAD',
    links: [
      {
        text: 'How do I use the data in GIS or CAD?',
        url: '/help/gis/',
      },
      {
        text: 'Projections and Transformation',
        url: '/help/gis/transformations/',
      },
    ],
    questions: [
      {
        text: 'Where can I access a computer with GIS/CAD software?',
        recipient: 'siterep',
      },
      {
        text: 'Where can I get support using GIS/CAD software?',
        recipient: 'siterep',
      },
      {
        text: "The data downloaded from Digimap doesn't load in to my GIS/CAD software",
        recipient: 'edina',
      },
      {
        text: 'What format of data do I need to load in to my GIS/CAD software?',
        recipient: 'edina',
      },
      {
        text: 'Other',
        recipient: 'edina',
      },
    ],
  },
  {
    id: 'licensing',
    title: 'Licensing',
    links: [
      {
        text: 'What is the correct copyright statement?',
        url: '/help/copyright-and-licensing/acknowledgements/',
      },
      {
        text: 'Where can I read the Collection licences?',
        url: '/help/copyright-and-licensing/',
      },
    ],
    questions: [
      {
        text: 'Can I share the data with other users?',
        recipient: 'edina',
      },
      {
        text: 'Can I publish the data on the web?',
        recipient: 'edina',
      },
      {
        text: 'Other',
        recipient: 'edina',
      },
    ],
  },
];
