import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { NotificationsService } from '../notifications/notifications.service';
import { RegisterUserService } from '../registeruser/register-user.service';
import { User } from '../user/user';
import { UserService } from '../user/user.service';
import { inlineSvg } from '../svg/inline-svg-img';

const SOCIAL_MEDIA_ICONS = ['chat', 'twitter', 'facebook', 'blog', 'youtube'];

@Component({
  selector: 'dm-header',
  templateUrl: 'header.template.html',
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user: User | null;
  loginUrl: string;
  openDropdown = false;
  openMenu = false;

  @Input()
  socialMediaIcons: string[] = SOCIAL_MEDIA_ICONS;

  private activeNotification = { timeOut: 0, clickToClose: true };

  constructor(
    private userService: UserService,
    private configService: ConfigService,
    private notificationService: NotificationsService,
    private registerUserService: RegisterUserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = null;
  }

  /**
   * Get the Observable of a user from the service, then assign it.
   */
  getUser() {
    this.userService.getUser().subscribe(
      user => {
        this.user = user;

        this.isUnregistered(this.user);
      },
      (err: any) => this.handleInternalError(err)
    );
  }

  setUser(user: User) {
    this.user = user;
  }

  getConfig() {
    this.configService.getConfig().subscribe(
      (config: Map<string, any>) => {
        this.loginUrl = config.get('url.wayf.login');
      },
      (err: any) => this.handleInternalError(err)
    );
  }

  /**
   * Functions that test user's state
   */
  isNewUser(user: User | null): boolean {
    return !!user && user.state === 'UNREGISTERED';
  }

  isRegisteredUser(user: User | null): boolean {
    return !!user && user.state === 'REGISTERED';
  }

  isPendingUser(user: User | null): boolean {
    return !!user && user.state === 'PENDING';
  }

  furtherActivations(user: User | null) {
    return user && this.isRegisteredUser(user) && !this.allCollectionsActive(user);
  }

  isCurrentStaff(user: User | null): boolean {
    return !!user && user.userType === 'Current Staff';
  }

  /**
   * If on subscriptions page we don't want to display subscriptions link
   */
  shouldSubscribeLinkBeDisplayed() {
    return this.router.url !== '/subscription';
  }

  /**
   * Test to check if all collections active.
   */
  allCollectionsActive(user: User): boolean {
    const activeCollections = _.clone(user.activatedCollections).sort();
    const subscribedCollections = _.clone(user.institution.subscribedCollections).sort();
    const equal = _.isEqual(activeCollections, subscribedCollections);
    return equal;
  }

  resendVerification() {
    this.registerUserService.resendConfirmation().subscribe(() => {
      this.notificationService.success('Success', `Your verification email has been resent to ${this.user!.email}`);
    });
  }

  toggleDropdown() {
    this.openDropdown = !this.openDropdown;
  }

  toggleMenu() {
    this.openMenu = !this.openMenu;
  }

  /**
   * Once this component has been loaded, initialise it by excuting this function.
   */
  ngOnInit() {
    this.getUser();
    this.getConfig();
  }

  /**
   * Navigate to a page based on User State.
   */
  navigate() {
    if (this.isNewUser(this.user)) {
      // Re-direct to register view.
      this.router.navigate(['/register']);
    }
  }

  ngAfterViewInit() {
    $("img[src$='.svg']").each(inlineSvg);
  }

  outstandingActivations(): string | number {
    if (!this.user) {
      return '';
    }

    const count = Math.max(
      this.user.institution.subscribedCollections.length - this.user.activatedCollections.length,
      0
    );
    return count === 0 ? '' : count;
  }

  useIcon(name: string): boolean {
    return this.socialMediaIcons.indexOf(name) !== -1;
  }

  private handleInternalError(err: Error) {
    this.notificationService.error('Internal Error', err.message);
  }

  private isUnregistered(user: User | null) {
    const isNewUser = this.isNewUser(user);

    this.route.url.subscribe(segment => {
      const routeName = segment.join('');
      if (isNewUser && routeName !== 'register') {
        this.notificationService.alert(
          'User Registration Required',
          'You need to register before using the Digimap service.',
          this.activeNotification,
          () => this.navigate()
        );
      }
    });
  }
}
