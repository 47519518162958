import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NotificationComponent } from './notification.component';
import { SimpleNotificationsComponent } from './simpleNotifications.component';
import { NotificationsService } from './notifications.service';
import { PushNotificationsService } from './pushNotifications.service';
import { MaxPipe } from './max.pipe';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [MaxPipe, NotificationComponent, SimpleNotificationsComponent],
  providers: [NotificationsService, PushNotificationsService],
  exports: [SimpleNotificationsComponent],
})
export class NotificationsModule {}
