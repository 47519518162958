import { ALL, Collection, GroupName } from '../collection';

import { GeologyDetailComponent } from '../detail-pages/geology-detail.component';

/* eslint-disable max-len */

export const GEOLOGY_COLLECTOIN: Collection = {
  id: 'geology',
  name: 'Geology',
  icon: `
    <svg x="0px" y="0px" width="68.029px" height="68.029px" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029">
      <g>
        <g>
          <path d="M30.815,17.591c0,0,11.232-11.873,31.766-13.524c0,0-17.323-6.039-35.294,8.616l-1.382-1.465l-5.719,4.023L19.798,17.3
            L4.311,25.906l7.523,8.909l13.19-12.446l32.843,42.827l3.155-2.424l3.039-2.315L30.815,17.591z"/>
        </g>
      </g>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'British Geological Survey Logo',
      file: '/assets/images/collections/supplier-logos/BGS-Logo-Pos-RGB.svg',
      width: 200,
      height: 80,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: GeologyDetailComponent,
  licencePdf: '/help/copyright-and-licensing/geology_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/geology_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'GEOLOGYMAPPER',
          name: 'Geology Digimap',
          link: '/roam/map/geology',
        },
      ],
    },
    {
      groupName: GroupName.WEBSERVICES,
      apps: [
        {
          id: 'GLOBALWMS',
          name: 'Web Services',
          link: '/geology/wms',
          isInternalLink: true,
          wmsLinks: ALL,
        },
      ],
    },
  ],
};
