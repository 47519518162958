import { Component, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { forEach } from 'lodash';

/**
 * This class exists because for some reason you can't do:
 * <span [innerHTML]='svg'></span>
 * Angular 2 is doing something to the SVG, so this class exists
 * to manipulate the DOM directly and set innerHTML on a real HTMLElement.
 */
@Component({
  selector: 'dm-dynamic-svg',
  template: `<span #svg></span>`,
})
export class DynamicSvgComponent implements AfterViewInit {
  @Input()
  svg: string;
  @ViewChild('svg', { static: true })
  element: ElementRef;

  ngAfterViewInit() {
    this.element.nativeElement.innerHTML = this.svg;
    // Set any SVG to focusable 'false'. This is to avoid a bug with Edge and IE whereby
    // SVG images are focusable by default (which causes problems for keyboard navigation):
    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8090208/
    const svgElements: HTMLCollection = this.element.nativeElement.getElementsByTagName('svg');
    forEach(svgElements, elem => {
      elem.setAttribute('focusable', 'false');
    });
  }
}
