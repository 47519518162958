import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

const URL = 'api/user/register/verify';

@Injectable()
export class VerifyService {
  constructor(private http: HttpClient) {}

  verify(token: string): Observable<object> {
    return this.http.get(`${URL}/${token}`).pipe(catchError(err => throwError(err.error)));
  }
}
