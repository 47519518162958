<dm-simple-notifications></dm-simple-notifications>
<header class="header access-text-color">
  <div class="row no-gutters">
    <nav class="header-content" aria-label="main navigation">
      <a [routerLink]="['/']" title="Digimap Home">
        <img
          class="digimap-logo"
          alt="Digimap Logo"
          src="assets/images/misc/mainlogo/digimap-logo.svg"
          data-inline="false" />
      </a>
      <div class="header-nav">
        <div class="header-top-right">
          <div class="social-icons hidden-xs hidden-sm hidden-md">
            <a
              class="social-icon"
              *ngIf="useIcon('chat')"
              href="https://app.purechat.com/w/cq1mph"
              title="Digimap Chat">
              <img class="icon-chat" src="assets/images/misc/chat.svg" alt="Digimap Chat" />
            </a>
            <a
              class="social-icon"
              *ngIf="useIcon('twitter')"
              href="https://twitter.com/EDINA_Digimap"
              title="Digimap X">
              <img class="icon-twitter" src="assets/images/misc/x-twitter.svg" alt="Digimap X" />
            </a>
            <a
              class="social-icon"
              *ngIf="useIcon('facebook')"
              href="https://www.facebook.com/EDINADigimap/"
              title="Digimap Facebook">
              <img class="icon-facebook" src="assets/images/misc/facebook.svg" alt="Digimap Facebook" />
            </a>
            <a class="social-icon" *ngIf="useIcon('blog')" href="https://blogs.ed.ac.uk/digimap/" title="Digimap Blog">
              <img class="icon-blog" src="assets/images/misc/blog.svg" alt="Digimap Blog" />
            </a>
            <a
              class="social-icon"
              *ngIf="useIcon('youtube')"
              href="https://www.youtube.com/EDINADigimap"
              title="Digimap Youtube">
              <img class="icon-youtube" src="assets/images/misc/youtube.svg" alt="Digimap Youtube" />
            </a>
          </div>

          <!-- Full sized screen, use the drop down and row of buttons.
               ** NB: any option added or removed here will also have to be added or removed from the small
               screen menu below. ** -->
          <ul class="nav nav-pills header-top-btns visible-sm visible-md visible-lg">
            <li *ngIf="user" role="presentation" class="dropdown" [class.open]="openDropdown">
              <a
                href="#"
                class="btn btn-main dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                aria-haspopup="true"
                (click)="this.toggleDropdown()">
                <!-- (blur)="this.openDropdown = false"> -->
                My Digimap <span class="caret"></span>
              </a>
              <ul class="dropdown-menu" role="menu">
                <li *ngIf="isRegisteredUser(user)"><a [routerLink]="['/update']">Change User Details</a></li>
                <li><a [routerLink]="['/collections']">My Collections</a></li>
                <li *ngIf="user?.roles?.supplier"><a [routerLink]="['/supplier']">Supplier Information</a></li>
                <li *ngIf="user?.roles?.admin || isCurrentStaff(user) || user?.roles?.siterep" class="divider"></li>
                <li *ngIf="user?.roles?.admin"><a href="/digiadmin/">DigiAdmin</a></li>
                <li *ngIf="isCurrentStaff(user) || user?.roles?.siterep"><a href="/siterep/">Digimap Statistics</a></li>
              </ul>
            </li>
            <li *ngIf="isNewUser(user)"><a [routerLink]="['/register']" class="btn btn-main">Register</a></li>
            <li *ngIf="isPendingUser(user)">
              <a (click)="resendVerification()" class="btn btn-main">Resend Email Verification</a>
            </li>
            <li *ngIf="furtherActivations(user)">
              <a [routerLink]="['/activate']" class="btn btn-main"
                >Licence Agreements <span class="badge">{{ outstandingActivations() }}</span></a
              >
            </li>
            <li><a class="btn btn-main" href="/help">Help</a></li>
            <li *ngIf="shouldSubscribeLinkBeDisplayed()">
              <a class="btn btn-main" [routerLink]="['/subscription']">Subscribing</a>
            </li>
          </ul>
        </div>
        <!-- Small screen, use a 'hamburger' menu or have a single login button.
             ** This list should be kept consistent with the version above for full sized screens. **
             ** Note also that the resource center link is part of this menu. ** -->
        <div class="visible-xs-block">
          <div *ngIf="user" class="btn-group" [class.open]="openMenu">
            <button
              type="button"
              class="btn btn-burger btn-lg"
              data-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true"
              (click)="this.toggleMenu()">
              <!-- (blur)="this.openMenu = false"> -->
              <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right xs-dropdown-menu" role="menu">
              <li *ngIf="isRegisteredUser(user)"><a [routerLink]="['/update']">Change User Details</a></li>
              <li><a [routerLink]="['/collections']">My Collections</a></li>
              <li *ngIf="user?.roles?.supplier"><a [routerLink]="['/supplier']">Supplier Information</a></li>
              <li *ngIf="user?.roles?.admin || user?.roles?.siterep" class="divider"></li>
              <li *ngIf="user?.roles?.admin"><a href="/digiadmin/">DigiAdmin</a></li>
              <li *ngIf="user?.roles?.siterep"><a href="/siterep/">Site Rep Service</a></li>
              <li *ngIf="isNewUser(user)"><a [routerLink]="['/register']">Register</a></li>
              <li *ngIf="isPendingUser(user)"><a (click)="resendVerification()">Resend Email Verification</a></li>
              <li *ngIf="furtherActivations(user)">
                <a [routerLink]="['/activate']"
                  >Licence Agreements <span class="badge">{{ outstandingActivations() }}</span></a
                >
              </li>
              <li><a href="/help">Help</a></li>
              <li><a [routerLink]="['/subscription']">Subscribing</a></li>
              <li *ngIf="user"><a title="Log Out" [routerLink]="['/logout']" accesskey="3">Log Out</a></li>
            </ul>
          </div>
          <a *ngIf="!user" title="Log In" class="btn btn-primary login-btn" href="{{loginUrl}}" accesskey="3"
            >Log In &raquo;</a
          >
        </div>

        <!-- Full sized screen, welcome message and resource center on right of screen below button bar.
             ** NB: The welcome message should be kept consistent with the version below for small screens. ** -->
        <div class="visible-sm visible-md visible-lg">
          <span *ngIf="!user" class="header-help-text">
            <a class="header-link" href="/help/about/login/">Please log in to access applications. How?</a>
            <a title="Log In" class="btn btn-primary login-btn" href="{{loginUrl}}" accesskey="3">Log In &raquo;</a>
          </span>
          <span *ngIf="user && (!isNewUser(user))">
            <a class="header-link" [routerLink]="['/notuser']"
              >Welcome {{user?.firstName}} {{user?.surname}}, not {{user?.firstName}}?</a
            >
          </span>
          <span *ngIf="isNewUser(user)">
            <a class="header-link" href="/help/about/register/">You need to register. How?</a>
          </span>
          <span *ngIf="user">
            <a title="Log Out" [routerLink]="['/logout']" class="btn btn-primary login-btn" accesskey="3">Log Out</a>
          </span>
        </div>
      </div>
    </nav>
  </div>

  <!-- Small screen, welcome message below rest of header.
       ** NB: The welcome message should be kept consistent with the version for full sized screens above. ** -->
  <div class="row no-gutters xs-welcome-message visible-xs">
    <div class="page-padding-left page-padding-right">
      <span *ngIf="!user">
        <a class="header-link" href="/help/about/login/">Please log in to access applications. How?</a>
      </span>
      <span *ngIf="user && (!isNewUser(user))">
        <a class="header-link" [routerLink]="['/notuser']"
          >Welcome {{user?.firstName}} {{user?.surname}}, not {{user?.firstName}}?</a
        >
      </span>
      <span *ngIf="isNewUser(user)">
        <a class="header-link" href="/help/about/register/">You need to register. How?</a>
      </span>
    </div>
  </div>
</header>
