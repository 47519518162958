import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Collection } from './collection';
import { CollectionService } from './collection.service';
import { SubscriptionLike as ISubscription, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { isExperimental } from '../../commons/query-params';
import { UserService } from '../user/user.service';

@Component({
  selector: 'dm-collection-header',
  templateUrl: './collection-header.component.html',
  styleUrls: ['./collection-header.component.scss'],
})
export class CollectionHeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  collections: Collection[] = [];
  selectedCollection: Collection | null = null;

  scrollButtonsRequired = false;

  // A reference to this component's viewport div and also the inner toggle group, which is required by IE
  @ViewChild('viewport', { static: true }) viewportRef: ElementRef;
  @ViewChild('collectionsHeader', { static: true }) collectionsHeaderRef: ElementRef;

  // Number of pixels to jump when left/right buttons are clicked
  readonly SCROLL_JUMP = 90;

  experimentalMode: boolean;
  serverTime: number;

  private watches: ISubscription[] = [];

  constructor(
    private collectionService: CollectionService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.experimentalMode = isExperimental();
  }

  /**
   * @param collection a null collection means going back to the Home page
   */
  selectCollection(collection: Collection | null): boolean {
    const routeName = collection ? collection.id : '';
    this.router.navigate([`${routeName}`]);

    this.onSelect(collection);

    return false;
  }

  /**
   * Returns the tab index for a collection, which will be 0 (the collection tabshould be
   * focusable in the default browser order) or -1 (collection tab should not be focusable)
   */
  setTabindex(coll: Collection): number {
    return this.selectedCollection && coll.id === this.selectedCollection.id ? 0 : -1;
  }

  scrollAtFarLeft(): boolean {
    return this.getViewport().scrollLeft <= 0;
  }

  scrollAtFarRight(): boolean {
    const viewport = this.getViewport();
    return viewport.scrollLeft + viewport.offsetWidth >= viewport.scrollWidth;
  }

  goLeft() {
    this.getViewport().scrollLeft -= this.SCROLL_JUMP;
  }

  goRight() {
    this.getViewport().scrollLeft += this.SCROLL_JUMP;
  }

  /**
   * Once this component has been loaded, initialise it by excuting this function.
   */
  ngOnInit(): void {
    this.watches.push(this.getCollections().subscribe());

    // Need to check the browser pop state events to update the selected collection
    // when the back/forward buttons are pressed.
    // This subscribes to the history change event.
    this.watches.push(
      this.location.subscribe(value => {
        if (!value.url) {
          return;
        }

        this.updateCollectionFromUrl(value.url);

        this.userService.getUser().subscribe(user => {
          if (user && user.serverTime) {
            this.serverTime = user.serverTime.getTime();
          }
        });
      })
    );

    this.watches.push(
      this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.updateCollectionFromUrl(e.url);
        }
      })
    );
  }

  ngOnDestroy() {
    // this.watches.forEach(w => w.unsubscribe());
  }

  ngAfterViewChecked() {
    this.updateScroll();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.updateScroll();
  }

  isCollectionTabDisplayed(collection: Collection): boolean {
    return (
      (!collection.isExperimental || this.experimentalMode) &&
      (!collection.startDisplayDate || collection.startDisplayDate <= this.serverTime) &&
      (!collection.endDisplayDate || collection.endDisplayDate >= this.serverTime)
    );
  }

  private updateScroll() {
    this.scrollButtonsRequired = this.checkScrollButtonsRequired();
  }

  private updateCollectionFromUrl(url: string) {
    const collection = this.getCollectionFromId(url.slice(1));
    this.onSelect(collection);
  }

  /**
   * Get the promise of a list of collections from the service, then assign the list to
   * the collections variable.
   */
  private getCollections(): Observable<Collection[]> {
    return this.collectionService.getCollections().pipe(
      map(collections => {
        this.collections = collections.filter(c => c.collectionDetail);
        this.route.firstChild!.url.forEach(segment => {
          const collectionId = segment.join('');
          this.onSelect(this.getCollectionFromId(collectionId));
        });
        return collections;
      })
    );
  }

  /**
   * Executed when a user clicks on a collection.
   */
  private onSelect(collection: Collection | null): void {
    this.selectedCollection = collection;
    this.collectionService.currentCollection.next(collection);

    if (!collection) {
      return;
    }

    // Ensure the corresponding collection tab is given focus. This ensures that when a user
    // presses 'tab' focus will go to the first focusable element in the collection detail view.
    const tab = document.getElementById('coll-tab-' + collection.id);
    if (tab) {
      tab.focus();
    }
  }

  /**
   * Gets the currently selected collection from a collection id
   */
  private getCollectionFromId(collectionId: string): Collection | null {
    const collection = this.collections.find(c => c.id === collectionId);

    return collection || null;
  }

  private checkScrollButtonsRequired() {
    const viewport = this.getViewport();
    return viewport.scrollWidth > viewport.offsetWidth;
  }

  /**
   * Return the native element which we manipulate as the "viewport". On most browsers this is just
   * the viewport div, but in IE, for some reason, we have to instead use the toggle group element.
   * We decide which to use based on whether scrollWidth is different from the offsetWidth.
   */
  private getViewport(): HTMLElement {
    if (this.viewportRef.nativeElement.scrollWidth === this.viewportRef.nativeElement.offsetWidth) {
      return this.collectionsHeaderRef.nativeElement;
    } else {
      return this.viewportRef.nativeElement;
    }
  }
}
