import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { RegisterUserDto } from './register-user';
import { catchError } from 'rxjs/operators';

const API = {
  register: 'api/user/register',
  resend: 'api/user/register/resend',
  delete: 'api/user/register/delete',
};

@Injectable()
export class RegisterUserService {
  constructor(private http: HttpClient) {}

  /**
   * Get the current user state which can be viewed/modified by the update user
   * function. Rethrows errors as an observable.
   */
  getRegisteringUser(): Observable<RegisterUserDto> {
    return this.http.get<RegisterUserDto>(API.register).pipe(catchError(err => observableThrowError(err.error)));
  }

  registerUser(user: RegisterUserDto): Observable<object> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .put<RegisterUserDto>(API.register, JSON.stringify(user), { headers })
      .pipe(catchError(err => observableThrowError(err.error)));
  }

  resendConfirmation(): Observable<object> {
    return this.http.get(API.resend).pipe(catchError(err => observableThrowError(err.error)));
  }

  deleteRegistration(): Observable<object> {
    return this.http.delete(API.delete).pipe(catchError(err => observableThrowError(err.error)));
  }
}
