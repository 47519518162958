/* eslint-disable max-len */
import { AbstractCollectionDirective } from './detail-pages/abstract-collection.directive';
import { Type } from '@angular/core';
import { User } from '../user/user';

export interface Collection {
  id: string;
  name: string;
  icon: string;
  supplierLogos?: LogoDetails[];
  subscriptionRequired: boolean;
  collectionDetail?: Type<AbstractCollectionDirective>; // undefined means collection won't appear in UI, activate only
  licenceUrl?: string;
  licencePdf?: string;
  appGroups: AppGroup[];
  dependencies?: string[]; // Collection ids
  autoAgree?: string; // Automatically agreed when this collection is agreed, e.g. aerial_extra and aerial
  isNew?: boolean;
  isExperimental?: boolean;
  isVisuallySpecial?: boolean;
  startDisplayDate?: number;
  endDisplayDate?: number;
}

export const OPEN: string = 'open';
export const LICENSED: string = 'licensed';
export const ALL: string = 'all';

export interface AppGroup {
  groupName: string;
  subHeading?: string;
  emphasise?: boolean;
  apps: Application[];
}

export interface Application {
  id: string;
  name: string;
  link: string;
  ipAccessRestricted?: boolean;
  comingSoon?: boolean; // shows a greyed out disabled button with Coming Soon text
  appendText?: string; // appends styled text to an application button, eg, for BETA or TRIAL
  isInternalLink?: boolean; // use router link if the link is internal, href if external
  wmsLinks?: string; // describes the wms links status, could be open, licensed, all
  disabled?: boolean;
}

export interface LogoDetails {
  name: string;
  file: string;
  width?: number;
  height?: number;
}

export enum GroupName {
  MAPPER = 'View, annotate & print a map',
  MAPPER_NO_PRINT = 'View and annotate a map',
  DOWNLOADER = 'Download data (for GIS/CAD)',
  WEBSERVICES = 'Web Services',
}

export const isSubscribed = (user: User, collection: Collection): boolean => {
  if (!user) {
    return true;
  }

  if (!collection.subscriptionRequired) {
    return true;
  }

  return user.institution.subscribedCollections.indexOf(collection.id) !== -1;
};

export const isActivated = (user: User, collection: Collection): boolean => {
  if (!user) {
    return true;
  }

  return user.activatedCollections.indexOf(collection.id) !== -1;
};

export const noServiceRestriction = (user: User, application: Application): boolean =>
  user.serviceRestrictions.indexOf(application.id) === -1;
