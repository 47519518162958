<mat-tab-group>
  <mat-tab label="About">
    <p>
      Geology Digimap provides geological maps and data of Great Britain from the
      <a href="https://www.bgs.ac.uk/">British Geological Survey</a>. Available data includes:
    </p>
    <ul>
      <li>Onshore geology at four scales; 1:10000, 1:25000, 1:50000 and 1:625000.</li>
      <li>Offshore geology with two themes; bedrock and seabed sediments.</li>
      <li>Rock information, including the Lexicon of Named Rock Units and the Onshore Borehole Index.</li>
      <li>Groundwater datasets; geological indicators of flooding, permeability indices and 1:625000 Hydrogeology.</li>
    </ul>
    <p>With Geology Digimap you can view and download both licensed and open data from the BGS.</p>
    <p>
      Our Geology collection also now includes a range of datasets licensed from The Coal Authority, for example
      Monitoring Points, Licence Areas, Working Dates etc.<br />
      See our
      <a href="https://digimap.edina.ac.uk/help/our-maps-and-data/geology_products/#coal-authority-data">
        complete summary list </a
      >.
    </p>
    <p>
      Check our help pages for a
      <a href="https://digimap.edina.ac.uk/help/our-maps-and-data/geology_products/#main"
        >full list of the BGS mapping available</a
      >.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Subscribing</h3>
    <p>
      Before you can access Geology Digimap your university or college needs to subscribe to the service. Institutional
      subscriptions are available to UK universities and colleges and are purchased for an annual fee which provides
      access for all staff and students.
    </p>
    <h3>Log in</h3>
    <p>
      If your university or college already subscribes, you can access Digimap by logging with your university or
      college login details. Use the login button on this page (top right) or select an application to be prompted to
      login.
    </p>
    <h3>Register</h3>
    <p>
      Once you have logged in you will need to register. This is an online process and requires you to verify your email
      address and agree to the licences. You need to agree to a different licence for each Collection, but the
      registration is a one-time process.
    </p>
    <h3>Requirements</h3>
    <p>
      Digimap is designed to work with the latest version of the most popular web browsers. Please ensure you are using
      an up-to-date version of Edge, Chrome, FireFox or Safari. Please note, the service has not been tested on all iPad
      Minis or Android devices.
    </p>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. With Geology Digimap you can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>use the Get Feature Info. button to check rock types on the map</li>
      <li>view geological maps at one of our pre-defined scales</li>
      <li>view geological photos on your map online</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
      <li>compare 2 maps of the same area</li>
      <li>search for and add WMS layers</li>
    </ul>
    <h3>Geology Data Download</h3>
    <p>Digimap allows you to download geological map data for use, for example, in GIS or CAD software. You can:</p>
    <ul>
      <li>select your area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Web Map Service</h3>
    <p>
      We provide a Geology Digimap Web Map Service (WMS), which can be used to display mapping images from Geology
      Digimap in most GIS software.
    </p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – a selection of topics follow:</p>
    <ul>
      <li><a href="/help/faqs/geology/">Geology Digimap</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
