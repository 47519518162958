<header>
  <h1><b>Open Digimap</b>&reg;</h1>
</header>

<div class="content">
  <img class="map-image" src="assets/images/misc/open-client-map.png" alt="Example of a zoomstack map" />
  <div class="apps">
    <dm-app-buttons
      [appGroup]="appGroup"
      (appClicked)="gotoOpenClient($event)"
      [isEnabled]="canAccessApplication"
      [icon]="icon"
      [colourScheme]="colourScheme"></dm-app-buttons>
  </div>
</div>

<div class="tabinator">
  <input type="radio" id="tab1" name="tabs" checked />
  <label for="tab1">About</label>
  <input type="radio" id="tab2" name="tabs" />
  <label for="tab2">Data</label>
  <input type="radio" id="tab3" name="tabs" />
  <label for="tab3">Getting Started</label>
  <input type="radio" id="tab4" name="tabs" />
  <label for="tab4">Access</label>
  <input type="radio" id="tab5" name="tabs" />
  <label for="tab5">FAQs</label>
  <div id="content1">
    <p>
      Open Digimap is a web-based service delivering digital high quality cartographic mapping products for viewing and
      printing. Access is convenient and on-demand providing access to the best and most detailed open GB mapping data
      currently available.
    </p>
    <p>
      Open Digimap as the name suggests, is free at the point of use. It is based on the award winning Digimap service
      that delivers high quality geospatial services to all UK Higher and Further Education establishments and to GB
      schools. We provide Open Digimap for personal use at no charge. No strings. No charge.
    </p>
  </div>
  <div id="content2">
    <p>Open Digimap currently offers access to the following data:</p>
    <ul>
      <li>
        OS Open Zoomstack which provides a single, customisable basemap of Great Britain, at whichever scale you want to
        use.
      </li>
      <li>Add overlays from other data sources using WMS feeds</li>
    </ul>
  </div>
  <div id="content3">
    <p>
      Open Digimap offers a mapping application built on our ‘Roam’ technology which allows you to view, customise and
      print maps. You can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 in PDF, PNG or JPG format</li>
    </ul>
  </div>
  <div id="content4">
    <p>
      Open Digimap&trade; is a service operated by EDINA at The University of Edinburgh. We are a charitable body,
      registered in Scotland, with registration number SC005336 and have our registered office at Old College, South
      Bridge, Edinburgh EH8 9YL.
    </p>
    <p>EDINA is based at Argyle House, 3 Lady Lawson Street, Edinburgh EH3 9DR. Our VAT number is GB 592 9507 00.</p>
    <p>
      To contact us, please email edina@ed.ac.uk or telephone our customer service line on +44 (0)131 650 3302 quoting
      "Open Digimap".
    </p>
  </div>
  <div id="content5">
    <dl>
      <dt>Does Open Digimap cost me anything?</dt>
      <dd>No</dd>
      <dt>What can I do with the maps I make?</dt>
      <dd>
        <p>The maps provided are made using OS Open Data. This means you are free to:</p>
        <ul>
          <li>copy, publish, distribute and transmit the maps</li>
          <li>adapt the maps</li>
          <li>
            exploit the maps commercially and non-commercially for example, by combining it with other Information, or
            by including it in your own product or application.
          </li>
          <li>
            You must acknowledge the source of the data. The full terms can be found here:
            http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/
          </li>
          <li>
            EDINA applies a FAIR USAGE POLICY. If you wish to use Open Digimap for business purposes, we recommend you
            contact us.
          </li>
        </ul>
      </dd>
      <dt>Do you offer support for Open Digimap?</dt>
      <dd>No</dd>
    </dl>
  </div>
</div>

<dm-footer primaryColour="#2e3c42" [socialMediaIcons]="socialMediaIcons"></dm-footer>
