<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="view === formView.Wait" aria-live="polite" tabindex="0">Please wait ...</div>

    <div *ngIf="view === formView.Intro" aria-live="polite">
      <h3>What can we help you with?</h3>

      <!-- Accordion Panel -->
      <mat-accordion class="questions-accordion">
        <mat-expansion-panel *ngFor="let question of questions" (opened)="selectHelp(question)">
          <!-- Panel Header -->
          <mat-expansion-panel-header class="help-heading">
            <mat-panel-title class="help-title"> {{question.title}} </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Panel Body -->
          <ul>
            <li *ngFor="let link of question.links">
              <a href="{{link.url}}">{{link.text}}</a>
            </li>
          </ul>

          <p>
            Still not found what you are looking for?
            <button type="button" class="btn btn-warning" (click)="displayForm()">Contact Us</button>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <form class="form-horizontal" [formGroup]="helpForm" (ngSubmit)="setHelp()">
      <div *ngIf="view === formView.Form">
        <span tabindex="0">
          <strong>All fields are mandatory.</strong>
        </span>

        <fieldset>
          <legend>Personal Information</legend>
          <div class="form-group">
            <label for="name" class="dm-form-label control-label text-nowrap"> Name: </label>
            <div class="dm-form-control">
              <input id="name" type="text" class="form-control" formControlName="name" tabindex="0" />
              <dm-control-messages [control]="helpForm.controls['name']"> </dm-control-messages>
            </div>
          </div>

          <div class="form-group">
            <label for="email" class="dm-form-label control-label text-nowrap"> Email: </label>
            <div class="dm-form-control">
              <input id="email" type="email" class="form-control" formControlName="email" tabindex="0" />
              <dm-control-messages [control]="helpForm.controls['email']"> </dm-control-messages>
            </div>
          </div>

          <div class="form-group">
            <label for="institution" class="dm-form-label control-label text-nowrap"> Institution: </label>
            <div class="dm-form-control">
              <input id="institution" type="text" class="form-control" formControlName="institution" tabindex="0" />
              <dm-control-messages [control]="helpForm.controls['institution']"> </dm-control-messages>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>What can we help you with?</legend>

          <div class="form-group">
            <label for="question" class="dm-form-label control-label text-nowrap"> Question: </label>
            <div class="dm-form-control">
              <select id="question" class="form-control" formControlName="question" tabindex="0">
                <option value="">Please select...</option>
                <option *ngFor="let question of formQuestions" [value]="question | json">{{question.text}}</option>
              </select>
              <dm-control-messages [control]="helpForm.controls['question']"> </dm-control-messages>
            </div>
          </div>

          <div class="form-group">
            <label *ngIf="charsRemaining >= 0" for="description" class="dm-form-label control-label text-nowrap">
              <div>Description:</div>
              <div class="sub-heading">Characters remaining: <span class="important">{{charsRemaining}}</span></div>
            </label>
            <label *ngIf="charsRemaining < 0" for="description" class="dm-form-label control-label text-nowrap">
              <div>Description:</div>
              <div class="sub-heading"><span class="important">{{-charsRemaining}}</span> characters too long</div>
            </label>
            <div class="dm-form-control">
              <textarea
                id="description"
                rows="10"
                class="form-control"
                formControlName="description"
                tabindex="0"
                placeholder="{{descriptionPlaceholder}}"></textarea>

              <dm-control-messages [control]="helpForm.controls['description']"> </dm-control-messages>
            </div>
          </div>
        </fieldset>

        <div *ngIf="isLoggedIn()">
          <div role="radiogroup" aria-labelledby="send-query-to-label" class="form-group help-checkboxes">
            <label id="send-query-to-label" class="dm-form-label control-label text-nowrap">Send query to:</label>
            <label>
              <input name="recipient" type="radio" formControlName="recipient" value="edina" />
              {{ getRecipientDisplayName('edina') }}
            </label>
            <label>
              <input name="recipient" type="radio" formControlName="recipient" value="siterep" />
              {{ getRecipientDisplayName('siterep') }}
            </label>
            <strong><a class="info-link" (click)="toggleExplanation()">Who should I choose?</a></strong>
          </div>
          <div>
            <div class="explanation-panel" *ngIf="recipientExplanation">
              <p class="alert alert-warning explanation-content">
                Select <strong>EDINA Digimap Helpdesk</strong> for all questions relating to problems using the Digimap
                service or errors encountered whilst using the service. The <strong>EDINA Digimap Helpdesk</strong> can
                also advise on questions relating to the different datasets available in the service, licensing, and
                offer basic help in loading the data in to a GIS or CAD package.
                <strong>Your Institutions Digimap Support</strong> should be selected for all queries relating to local
                resources such as location of large format printers and software availability.
              </p>
            </div>
          </div>
        </div>

        <div class="dm-form-buttons">
          <button
            id="resetButton"
            type="button"
            class="btn btn-default"
            [disabled]="!hasChanged()"
            (click)="resetUser()">
            Reset
          </button>
          <button type="button" class="btn btn-default" (click)="view = formView.Intro">Back</button>
          <button
            id="nextButton"
            type="button"
            class="btn btn-success"
            [disabled]="!canSubmit()"
            (click)="view = formView.Summary">
            Next
          </button>
        </div>
      </div>

      <div *ngIf="view === formView.Summary">
        <h3 tabindex="0">Please check the following details before pressing submit:</h3>
        <p *ngIf="!isLoggedIn()" class="alert alert-warning" tabindex="0">
          Please ensure your email address is correct.
        </p>

        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Question:</label>
          <p class="dm-form-control form-control-static">{{ help.question }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Name:</label>
          <p class="dm-form-control form-control-static">{{ help.name }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Email:</label>
          <p class="dm-form-control form-control-static">{{ help.email }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Institution:</label>
          <p class="dm-form-control form-control-static">{{ help.institution }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Your query will be sent to:</label>
          <p class="dm-form-control form-control-static">{{ getRecipientDisplayName(help.recipient) }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Description:</label>
          <pre class="dm-form-control form-control-static wrap-text">{{ help.description }}</pre>
        </div>
        <br />

        <div class="dm-form-buttons">
          <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
          <button
            type="submit"
            class="btn btn-success"
            [disabled]="submitText.getSubmitting()"
            [innerHTML]="submitText.getSubmitText()"></button>
        </div>
      </div>
    </form>

    <!-- Success view: form has been submitted successfully -->
    <div *ngIf="view === formView.SuccessConfirmation" tabindex="0">
      <div class="alert alert-success"><strong>Success!</strong></div>
      <p>Your request will be dealt with as soon as possible.</p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <!-- Error view: form has been submitted but there's been an error -->
    <div *ngIf="view === formView.ErrorConfirmation" tabindex="0">
      <div class="alert alert-danger"><strong>Error!</strong> There has been a problem submitting your details.</div>
      <p>
        Please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <p>
        It is a breach of the terms and conditions of the service to log in to the service with another person's
        details.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
  </div>
</main>
