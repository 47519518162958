<mat-tab-group>
  <mat-tab label="About">
    <p>
      Historic Digimap provides historical Ordnance Survey maps of Great Britain. The historical mapping is licensed
      from <a href="https://www.landmark.co.uk/">Landmark Information Group</a>. Historic Digimap offers the following
      map images, the originals of which were published between 1846 and 1996:
    </p>
    <ul>
      <li>All available County Series maps at 1:2,500 and 1:10,560 scales</li>
      <li>All available British National Grid maps (1943 to 1996) at 1:1,250, 1:2,500 and 1:10,560/10,000</li>
      <li>Selected Town Plan maps (1848 and 1939) at scales of 1:500, 1:528 and 1:1056</li>
      <li>Over 400,000 geo-referenced digital map images are available for use in GIS and CAD.</li>
      <li>
        Available coverage of Great Britain varies between the historical map editions. Generally, the 1st editions in
        each Map Series provide full coverage of Great Britain. The subsequent revisions of each Series tend to have
        increasingly sparse coverage.
      </li>
    </ul>
    <p>
      Check our help pages for a
      <a href="https://digimap.edina.ac.uk/help/our-maps-and-data/historic_products/#main"
        >full list of the historical mapping available</a
      >.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Subscribing</h3>
    <p>
      Before you can access Historic Digimap your university or college needs to subscribe to the service. Institutional
      subscriptions are available to UK universities and colleges and are purchased for an annual fee which provides
      access for all staff and students.
    </p>

    <h3>Login</h3>
    <p>
      If your university or college already subscribes, you can access Digimap by logging with your university or
      college login details. Use the login button on this page (top right) or select an application to be prompted to
      log in.
    </p>

    <h3>Register</h3>
    <p>
      Once you have logged in you will need to register. This is an online process and requires you to verify your email
      address and agree to the licences. You need to agree to a different licence for each Collection, but the
      registration is a one-time process.
    </p>

    <h3>Requirements</h3>
    <p>
      Digimap is designed to work with the latest version of the most popular web browsers. Please ensure you are using
      an up-to-date version of Edge, Chrome, FireFox or Safari. Please note, the service has not been tested on all iPad
      Minis or Android devices.
    </p>

    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All the collections work in the same way but some offer
      additional features to make the most of their particular maps. With Historic Digimap you can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>use the Get Feature Info. button to check which historical map you are viewing</li>
      <li>select from the available decades with our timeline</li>
      <li>view historical maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
      <li>compare 2 maps of the same area</li>
      <li>search for and add WMS layers</li>
    </ul>

    <h3>Download Historical Data</h3>
    <p>
      Digimap allows you to download historical mapping images for use, for example, in GIS or CAD software. You can:
    </p>
    <ul>
      <li>select your area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>choose between original map sheet(s) or tiles of map data referenced to the British National Grid</li>
      <li>amend and reorder any previous orders</li>
    </ul>

    <h3>Web Map Service</h3>
    <p>
      We provide a Historic Digimap Web Map Service (WMS), which can be used to display mapping images from Historic
      Digimap in most GIS software.
    </p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/historic/">Historic Digimap</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
