import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../controlmessages/validation.service';
import { UserService } from '../user/user.service';
import { User } from '../user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { FormView } from '../../commons/form-view';
import { AddressBaseFormService } from './address-base-form-service';

@Component({
  selector: 'dm-address-base-form',
  templateUrl: 'address-base-form.template.html',
  styleUrls: ['./address-base-form.component.scss'],
})
export class AddressBaseFormComponent implements OnInit {
  addressBaseForm: FormGroup;
  hasAgreed: boolean = false;
  hasAgreedToPrivacy: boolean = false;
  user: User | null;
  formView = FormView;
  // Which of the views is currently being displayed
  view: FormView;
  productList: string[] = [
    'AddressBase',
    'AddressBase Premium',
    'AddressBase Plus',
    'AddressBase Core',
    'AddressBase Islands',
  ];
  extentList = [
    { value: 'Great Britain', displayName: 'Great Britain' },
    { value: 'England', displayName: 'England' },
    { value: 'Wales', displayName: 'Wales' },
    { value: 'Scotland', displayName: 'Scotland' },
    { value: 'England and Wales', displayName: 'England and Wales' },
    { value: 'England and Scotland', displayName: 'England and Scotland' },
    { value: 'Scotland and Wales', displayName: 'Scotland and Wales' },
    { value: 'Other', displayName: 'Other - please give postcode areas in next question' },
  ];
  errorMessages: string[] = [];
  apiError: string = '';
  showPostcode: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    fb: FormBuilder,
    private userService: UserService,
    private addressBaseFormService: AddressBaseFormService
  ) {
    this.view = FormView.Wait;

    this.addressBaseForm = fb.group({
      title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
      surname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(255)]],
      username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
      institution: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      department: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      products: ['', [Validators.required]],
      extent: ['', [Validators.required]],
      postcode: [''],
      projectName: ['', [Validators.required, Validators.maxLength(255)]],
      projectDuration: ['', [Validators.required, Validators.maxLength(255)]],
      grantReference: ['', [Validators.maxLength(255)]],
      primaryContact: ['', [Validators.required, Validators.maxLength(255)]],
      investigators: ['', [Validators.required]],
      members: ['', [Validators.required]],
    });

    this.addressBaseForm.controls['extent'].valueChanges.subscribe(value => {
      if (value === 'Other') {
        this.showPostcode = true;
        this.addressBaseForm.controls['postcode'].setValidators(Validators.required);
        this.addressBaseForm.controls['postcode'].updateValueAndValidity();
      } else {
        this.showPostcode = false;
        this.addressBaseForm.controls['postcode'].clearValidators();
        this.addressBaseForm.controls['postcode'].updateValueAndValidity();
        this.addressBaseForm.controls['postcode'].setErrors(null);
      }
    });
  }

  /**
   * Get User details from the server.
   *
   * @memberOf ContactFormComponent
   */
  getUser() {
    this.userService.getUser().subscribe(
      data => {
        this.user = data;

        // Disable these controls if the user is logged in.
        if (this.user) {
          this.addressBaseForm.reset(data);
          if (this.user.institution) {
            this.addressBaseForm.controls['institution'].setValue(this.user.institution.name);
          }
          this.view = this.formView.Form;
        } else {
          this.view = this.formView.RequiresLogin;
        }
      },
      err => {
        this.view = this.formView.RequiresLogin;
      }
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.succeed === 'true') {
        this.view = this.formView.SuccessConfirmation;
      } else {
        this.getUser();
      }
    });
  }

  setAgreement(checked: boolean) {
    this.hasAgreed = checked;
  }

  setPrivacyAgreement(checked: boolean) {
    this.hasAgreedToPrivacy = checked;
  }

  /**
   * Takes the user back to the home page.
   */
  navigate(): void {
    this.router.navigate(['/']);
  }

  onSubmit() {
    if (this.hasAgreedToPrivacy && this.hasAgreed && this.addressBaseForm.valid) {
      this.addressBaseFormService.sendFormToSaleforce(this.addressBaseForm.value).subscribe(
        data => {
          console.log('Form submitted successfully');
          this.view = this.formView.SuccessConfirmation;
        },
        error => {
          console.log(error);
          this.apiError = error.error.message;
          this.view = this.formView.Error;
        }
      );
      console.log('Form submitted');
    } else {
      this.errorMessages = [];
      let missingRequiredField = false;
      const controls = this.addressBaseForm.controls;
      for (const name in controls) {
        if (missingRequiredField === false && controls[name].invalid && controls[name].hasError('required')) {
          missingRequiredField = true;
          this.errorMessages.push('Some required field are missing.');
        }
      }
      if (!this.hasAgreedToPrivacy) {
        this.errorMessages.push('Please check Digimap Privacy Notice.');
      }
      if (!this.hasAgreed) {
        this.errorMessages.push('Please check End User Licence Agreement.');
      }
    }
  }
}
