import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivateCollectionsDto, Purpose } from './activatecollections';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';

import { ActivationService } from './activation.service';
import { Collection } from '../collections/collection';
import { CollectionService } from '../collections/collection.service';
import { ErrorResponse } from '../../commons/error-response';
import { FormView } from '../../commons/form-view';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { SafeHtml } from '@angular/platform-browser';
import { filter, mergeMap, toArray } from 'rxjs/operators';

@Component({
  selector: 'dm-activate-collections-form',
  templateUrl: 'activate-collections-form.template.html',
})
export class ActivateCollectionsFormComponent implements OnInit {
  // Plural forms if related to more than 1 licence
  relatedActivationMapping: { [k: string]: string } = {
    '=0': '',
    '=1': 'Related Licence: ',
    other: 'Related Licences:',
  };

  activations: ActivateCollectionsDto[] = [];

  formView = FormView;

  // Which of the views is currently being displayed
  view: FormView;

  // If an error has occurred, the message from the server
  errorMessage: string;

  activateCollectionsForm: FormGroup;

  // Model variable to hold the Accordion Panel heading styling
  // for each collection.
  headingStyles: { [key: string]: string } = {};

  private defaultPanelHeadingStyle = 'panel-heading';
  private original: ActivateCollectionsDto[];

  // List of licences which don't have an associated collection config (will automatically be hidden)
  private hiddenActivations: ActivateCollectionsDto[];

  private collections: Collection[] = [];
  private eula: { [id: string]: SafeHtml } = {};

  private states: { id: string; name: string }[];

  private includeLicences: string[] | undefined;
  private returnLink: string | undefined;

  constructor(
    private fb: FormBuilder,
    private activationService: ActivationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private collectionService: CollectionService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = FormView.Wait;

    this.states = [
      {
        id: 'ACTIVE',
        name: 'Licence accepted',
      },
      {
        id: 'EXPIRED',
        name: 'Expired (re-accept licence)',
      },
      {
        id: 'LICENCE_EXPIRED',
        name: 'Expired (re-accept licence)',
      },
      {
        id: 'QUERIED',
        name: 'Access queried',
      },
      {
        id: 'REJECTED',
        name: 'Access rejected (choose a different purpose)',
      },
      {
        id: 'DEACTIVATED',
        name: 'Access blocked',
      },
    ];
  }

  /**
   * Submit the form and inform the user of the result.
   */
  activateCollections() {
    // Pre-process activations purpose.
    // FIXME: I'd like to see a better way to do this but didn't get it to work.
    const processedActivations: ActivateCollectionsDto[] = this.processActivations();

    // Generate any activations which should be triggered automatically, e.g. activating aerial_extra when the user
    // activates aerial.
    const autoActivations: ActivateCollectionsDto[] = [];
    processedActivations.forEach(activation => {
      const newActivation = this.generateAutoActivatiation(activation);
      if (newActivation) {
        autoActivations.push(newActivation);
      }
    });
    const allActivations = processedActivations.concat(autoActivations);

    this.activationService.setActivations(allActivations).subscribe({
      next: () => {
        this.userService.forceRefresh();
        this.view = FormView.SuccessConfirmation;
      },
      error: () => {
        this.view = FormView.ErrorConfirmation;
      },
    });
  }

  /**
   * A test to check if there are any Activations in given state.
   * This is used in the summary to render portions of the view.
   *
   * @return boolean
   */
  hasActivationsInState(state: string): boolean {
    let hasState = false;

    this.activations.forEach((activation: ActivateCollectionsDto) => {
      if (activation.state === state) {
        hasState = true;
      }
    });

    return hasState;
  }

  /**
   * Check whether activation licence has been accepted fully, i.e value from list dropdown selected or
   * value for otherPurpose has been entered
   *
   * @param activation
   * @return boolean
   */
  isAgreedAndPurposeSupplied(activation: ActivateCollectionsDto): boolean {
    let agreed: boolean = false;

    if (activation.state === 'QUERIED') {
      agreed = false;
    } else if (activation.licenceAccepted) {
      if (!((activation.purpose.id === '8' && activation.otherPurpose.length < 3) || activation.purpose.id === '0')) {
        agreed = true;
      }
    }
    return agreed;
  }

  /**
   * Return true if the collection can be activated by the user.
   *
   * @return boolean
   */
  canActivate(activation: ActivateCollectionsDto): boolean {
    return (
      !this.shouldBeDisabled(activation) &&
      (activation.state === 'NOT_ACTIVATED' ||
        activation.state === 'EXPIRED' ||
        activation.state === 'REJECTED' ||
        activation.state === 'LICENCE_EXPIRED')
    );
  }

  /**
   * Check if given activation has a relationship with an active 'parent' activation and if licence has been accepted:
   * e.g Society activation will be related to OS activation.  Society should not be enabled unless OS licence has been
   * accepted.
   *
   * @param activation
   * @return boolean
   */
  shouldBeDisabled(activation: ActivateCollectionsDto): boolean {
    // Check if any of the active activations corresponding to these ids haven't had their licence accepted
    return this.activations
      .filter(a => this.getDependencyIDsFromActivation(activation).indexOf(a.id) > -1)
      .some(a => (!a.licenceAccepted && a.state !== 'ACTIVE') || a.state === 'QUERIED');
  }

  /**
   * Given an activation, find it's collection and return a list of associated collection ids
   *
   * @param activation
   * @return string []
   */
  getDependencyIDsFromActivation(activation: ActivateCollectionsDto): string[] {
    return this.collections
      .filter(c => c.id === activation.id)
      .filter(c => c.dependencies)
      .map(c => c.dependencies!)
      .reduce((prev, cur) => prev.concat(cur), []); // Flatten [[]]
  }

  /**
   * When a licenceAccepted checkbox is unchecked, we check to see if any related activation licences have to be reset
   *
   * @param event
   * @param activation
   */
  licenceAcceptedClicked(event: any, activation: ActivateCollectionsDto): void {
    if (!event.target.checked) {
      this.resetRelatedLicences(activation);
    }
  }

  /**
   * Given an activation, get list of other activations that depend on it and reset licence details for each.
   *
   * @param activation
   */
  resetRelatedLicences(activation: ActivateCollectionsDto): void {
    this.getChildActivations(activation).forEach(a => {
      a.licenceAccepted = false;
      a.purpose.id = '0';
      a.otherPurpose = '';
      // Notify changeDetector of model changes - get error due to form and model being out of sync otherwise
      this.changeDetector.detectChanges();
    });
  }

  /**
   * Search collection dependencies for passed in activation id and retrieve a list of the corresponding activations
   *
   * @param activation
   * @return ActivateCollectionsDto []
   */
  getChildActivations(activation: ActivateCollectionsDto): ActivateCollectionsDto[] {
    const childCollectionIDs = this.collections
      .filter(c => c.dependencies)
      .filter(c => c.dependencies!.indexOf(activation.id) > -1)
      .map(c => c.id);
    return this.activations.filter(a => childCollectionIDs.indexOf(a.id) > -1);
  }

  /**
   * Get array of activations that are related to the given activation (i.e 'parent' activations)
   *
   * @param activation
   * @return ActivateCollectionsDto []
   */
  getParentActivations(activation: ActivateCollectionsDto): ActivateCollectionsDto[] {
    return this.activations.filter(a => this.getDependencyIDsFromActivation(activation).indexOf(a.id) > -1);
  }

  getParentActivationsCommaSeperated(activation: ActivateCollectionsDto): string {
    return this.getParentActivations(activation)
      .map(a => a.name)
      .join(',');
  }
  /**
   * Check if new activation set ready to be sent to the server.
   *
   * @return boolean
   */
  hasNewActivations(): boolean {
    let hasState = false;

    this.activations.forEach((activation: ActivateCollectionsDto) => {
      if (activation.licenceAccepted === true && this.canActivate(activation)) {
        hasState = true;
      }
    });

    return hasState;
  }

  /**
   * Are there any subscribed collections not yet activated, or ready to be sent.
   *
   * @return boolean
   */
  hasOutstandingActivations(): boolean {
    let hasState = false;

    this.activations.forEach((activation: ActivateCollectionsDto) => {
      if (activation.licenceAccepted === false && this.canActivate(activation)) {
        hasState = true;
      }
    });

    return hasState;
  }

  /**
   * Run to reset form to original state.
   */
  reset(): void {
    this.activations = cloneDeep(this.original);
    this.view = FormView.Form;
    this.resetForm(this.activations);
  }

  /**
   * Called to disable the purpose fields if a collection's licence hasn't
   * been accepted (click checkbox) and is not either activated or not yet expired.
   *
   * @param ActivateCollectionsDto
   */
  isPurposeDisabled(activation: ActivateCollectionsDto) {
    return !this.canActivate(activation) || !activation.licenceAccepted;
  }

  /**
   * Check to see if 'Other Purpose' field should be visible on form based on
   * whether the activation already has a purpose with numbers 1-7. If it is 8 which
   * corresponds to 'Other' then we return true.
   *
   * @param ActivateCollectionsDto
   */
  showOtherPurpose(activation: ActivateCollectionsDto) {
    return activation.purpose.id === '8';
  }

  /**
   * Returns true when the form data has changed from the original values.
   *
   * @return boolean
   */
  hasChanged(): boolean {
    return this.activateCollectionsForm.dirty;
  }

  /**
   * Returns true if the form is in a state where submission of the data
   * is possible, i.e. the form is valid and there has been changes.
   *
   * @return boolean
   */
  canSubmit(): boolean {
    return this.hasChanged() && this.activateCollectionsForm.valid;
  }

  /**
   * Takes the user back to the home page.
   */
  navigate(): void {
    this.router.navigate(['/']);
  }

  /**
   * If there is a `returnLink` query parameter (eg from clicking on an application link directly as opposed to the
   * header link), *and* the user didn't select "Other" (which requires manual checking) then direct the user straight
   * back into the app they clicked.
   *
   * Otherwise take the user back to the home page.
   */
  navigateToApp(): void {
    if (this.returnLink && this.hasOtherActivations().length === 0) {
      window.location.href = this.returnLink;
      return;
    }

    this.router.navigate(['/']);
  }

  /**
   * Get the path to the icon associated with the activation.
   *
   * @param ActivateCollectionsDto
   *
   * @return string
   */
  getIcon(dto: ActivateCollectionsDto): string | undefined {
    const collection = this.collections.find(c => c.id === dto.id);

    if (collection) {
      return collection.icon;
    }

    return undefined;
  }

  /**
   * Get the link to the printer friendly version of the licence associated with the activation.
   *
   * @param ActivateCollectionsDto
   *
   * @return string
   */
  getPrinterFriendlyLink(dto: ActivateCollectionsDto): string | undefined {
    const collection = this.collections.find(c => c.id === dto.id);

    if (collection) {
      return collection.licencePdf;
    }

    return undefined;
  }

  hasOtherActivations(): string[] {
    return this.activations
      .filter(a => a.licenceAccepted)
      .filter(a => a.state !== 'ACTIVE')
      .filter(a => a.purpose.id === '8')
      .map(a => a.name);
  }

  /**
   * Get the display state text for the supplied activation
   * state.
   *
   * @param stateId
   *
   * @returns string
   */
  getStateName(stateId: string): string | undefined {
    const state = this.states.find(s => s.id === stateId);

    if (state) {
      return state.name;
    }

    return undefined;
  }

  getPurposeName(purposeId: string, purposes: Purpose[]): string {
    const purpose = purposes.find(p => p.id === purposeId);

    if (!purpose) {
      throw new Error('Unknown purpose if - ' + purposeId);
    }

    return purpose.purpose;
  }

  getEula(id: string): SafeHtml {
    return this.eula[id];
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.includeLicences = params.activate;
      this.returnLink = params.returnLink;

      // Get a list of the collections, these will be used to supply the template with the necessary icons and
      // licences, then initialise the activations.
      this.collectionService.getCollections().subscribe(collections => {
        this.collections = collections;
        this.initEulas(collections);
        this.initActivations();
      });
    });
  }

  /**
   * Initialise the EULA content, which is retrieved from the help pages.
   */
  private initEulas(collections: Collection[]) {
    collections.forEach(collection => {
      if (collection.licenceUrl) {
        this.activationService.getEula(collection.licenceUrl).subscribe(content => {
          this.eula[collection.id] = content;
        });
      } else {
        console.warn(`EULA for collection ${collection.id} is not available`);
        this.eula[collection.id] = 'No EULA currently available.';
      }
    });
  }

  /**
   * Get a list of DTOs to populate the form using the activation service.
   */
  private initActivations() {
    this.activations.length = 0;
    this.activationService
      .getActivations()
      .pipe(
        mergeMap(a => a),
        filter(activation => {
          if (!this.includeLicences || !this.includeLicences.length) {
            return true;
          }

          return this.includeLicences.includes(activation.id);
        }),
        toArray()
      )
      .subscribe(
        (data: ActivateCollectionsDto[]) => {
          // Filter out any activations that should be hidden from the user
          this.activations = data.filter(a => !this.shouldBeHidden(a));
          this.original = cloneDeep(this.activations);

          // Also keep track of any hidden activations, since they may be automatically activated
          // when another licence is agreed
          this.hiddenActivations = data.filter(a => this.shouldBeHidden(a));

          // Now create the activateCollectionsForm control group
          this.initActivateCollectionForm();
          // Initialise headingStyles
          this.initHeadingStyles();

          this.view = FormView.Form;
          this.resetForm(this.activations);
        },
        (err: ErrorResponse) => {
          this.errorMessage = err.message;
          this.view = FormView.Error;
        }
      );
  }

  /**
   * Set the header style based on the state of the activation e.g. DEACTIVATED, QUERIED etc.
   */
  private initHeadingStyles() {
    this.activations.forEach((activation: ActivateCollectionsDto) => {
      const style = `${this.defaultPanelHeadingStyle} ${activation.state.toLowerCase()}`;
      this.headingStyles[activation.id] = style;
    });
  }

  /**
   * Create a control group for a collection.
   *
   * @return AbstractControl
   */
  private createCollectionControlGroup(activation: ActivateCollectionsDto): AbstractControl {
    return this.fb.group(
      {
        collection: [''],
        licence: [''],
        licenceAccepted: ['', Validators.required],
        purpose: ['', Validators.required],
        otherPurpose: [''],
      },
      { validator: this.collectionGroupValidator }
    );
  }

  /**
   * This is a validator for each control group for a collection. It encapsulates the various validation rules
   * for input data we require before for a collection can be activated.
   *
   * FIXME: This is important enough to warant it's own file and be imported.
   *
   * @param ControlGroup
   *
   * @return
   */
  private collectionGroupValidator(
    collectionGroup: FormGroup
  ): { required: boolean } | { minlength: boolean } | { maxlength: boolean } | null {
    if (collectionGroup.controls['licenceAccepted'].value) {
      const purposeValue = collectionGroup.controls['purpose'].value;
      if (purposeValue === '0') {
        collectionGroup.controls['purpose'].setErrors({ required: true });
        return { required: true };
      }

      if (purposeValue === '8') {
        const otherPurpose = collectionGroup.controls['otherPurpose'];

        if (otherPurpose.value.length < 3) {
          otherPurpose.setErrors({
            minlength: {
              actualLength: otherPurpose.value.length,
              requiredLength: 3,
            },
          });
          return { minlength: true };
        }

        if (otherPurpose.value.length > 254) {
          otherPurpose.setErrors({
            maxlength: {
              actualLength: otherPurpose.value.length,
              requiredLength: 255,
            },
          });
          return { maxlength: true };
        }
      }
    }

    collectionGroup.controls['licenceAccepted'].setErrors(null);
    collectionGroup.controls['purpose'].setErrors(null);
    collectionGroup.controls['otherPurpose'].setErrors(null);
    return null;
  }

  /**
   * Initialise the activateCollectionsForm
   */
  private initActivateCollectionForm() {
    // Create ControlGroups for each subscribed collection.
    const controlGroupsForCollections: { [key: string]: AbstractControl } = {};

    this.activations.forEach((activation: ActivateCollectionsDto) => {
      // create  a control group with name (e.g, for OS this will be 'osGroup')
      // controlGroupsForCollections[activation.collection.id + 'Group'] = this.createCollectionControlGroup()
      controlGroupsForCollections[activation.id + 'Group'] = this.createCollectionControlGroup(activation);
    });

    // Create form with subscribed collections
    this.activateCollectionsForm = this.fb.group(controlGroupsForCollections);

    this.activateCollectionsForm.valueChanges.subscribe(formGroup => {
      for (const group in formGroup) {
        if (formGroup.hasOwnProperty(group)) {
          const groupId = group.substr(0, group.indexOf('Group'));
          const changedValues = formGroup[group];

          // Lookup associated DTO.
          this.activations
            .filter(dto => dto.id === groupId)
            .map(dto => {
              dto.licenceAccepted = changedValues.licenceAccepted;
              dto.purpose.id = changedValues.purpose;
              dto.otherPurpose = changedValues.otherPurpose;

              return dto;
            })
            .filter(dto => dto.licenceAccepted === false)
            .forEach(dto => {
              dto.purpose.id = '0';
              dto.otherPurpose = '';
            });
        }
      }
    });
  }

  /**
   * Process activation DTOs before sending to the server. This is to fix data lost
   * in the form, e.g. the purpose type and display name are lost so again we re-set these.
   *
   * FIXME: It would be better not to have to do this, not sure if it is possible though.
   *
   * @return ActivateCollectionsDto[]
   */
  private processActivations(): ActivateCollectionsDto[] {
    const activations = this.activations
      .filter((activation: ActivateCollectionsDto) => activation.licenceAccepted)
      .map((dto: ActivateCollectionsDto) => {
        // Don't require these on the server so to save transfer, setting to empty string.
        dto.icon = '';

        // Ensure other purpose is empty if id 1-7 (Do we need to be more specific?).
        // This is for when an expired activation with other purpose is re-activated
        // with one of the BUILT-IN purposes.
        if (+dto.purpose.id < 8) {
          dto.otherPurpose = '';
        }

        // Get purpose type and display text corresponding to the collection id.
        const purposeData = dto.purposes.find(p => p.id === dto.purpose.id);

        if (!purposeData) {
          throw new Error('error - unknown purpose - ' + dto.purpose.id);
        }

        dto.purpose.purposeType = purposeData.purposeType;
        dto.purpose.purpose = purposeData.purpose;

        return dto;
      });

    return activations;
  }

  // Maps the data from the activated DTOs on to the form group schema
  private resetForm(data: ActivateCollectionsDto[]) {
    const formData = data.reduce((formValue, dto) => {
      const formGroupValues = {
        licenceAccepted: dto.licenceAccepted,
        purpose: dto.purpose.id,
        otherPurpose: dto.otherPurpose,
      };
      formValue[dto.id + 'Group'] = formGroupValues;

      return formValue;
    }, {});

    this.activateCollectionsForm.reset(formData);
  }

  /**
   * Check if the activation should be hidden, i.e. it doesn't have an associated collection config.
   */
  private shouldBeHidden(activation: ActivateCollectionsDto): boolean {
    const collection = this.collections.find(c => c.id === activation.id);
    return collection === undefined;
  }

  /**
   * If the activation has an associated 'auto' activation, generates it (e.g. aerial_extra from aerial).
   */
  private generateAutoActivatiation(activation: ActivateCollectionsDto): ActivateCollectionsDto | undefined {
    const collection = this.collections.find(c => c.id === activation.id);
    if (collection && collection.autoAgree) {
      // Lookup activation from those hidden. Note that the auto-activation must only occur for licences the
      // user is eligible to use.
      const newActivation = this.hiddenActivations.find(a => a.id === collection.autoAgree);
      if (newActivation) {
        newActivation.licenceAccepted = activation.licenceAccepted;
        newActivation.purpose = activation.purpose;
        newActivation.otherPurpose = activation.otherPurpose;
        return newActivation;
      }
    }
    return undefined;
  }
}
