import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { NotUserDto } from './not-user';
import { catchError, map } from 'rxjs/operators';

const API = {
  notuser: 'api/user/not',
};

@Injectable()
export class NotUserService {
  constructor(private http: HttpClient) {}

  /**
   * Get the current user state which can be viewed/modified by the update user
   * function. Rethrows errors as an observable.
   */
  getNotUser(): Observable<NotUserDto> {
    return this.http.get<NotUserDto>(API.notuser).pipe(catchError(err => observableThrowError(err.error)));
  }

  /**
   * Set the details changed in the update user function. This will return an observable
   * boolean which will be true on success, as a placeholder. If an error occurs (e.g an
   * error response from the updateuser service, the exception will be rethrown for
   * use by the calling component.
   */
  setNotUser(notUserDto: NotUserDto): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.put(API.notuser, JSON.stringify(notUserDto), { headers }).pipe(
      catchError(err => observableThrowError(err.error)),
      map((res: Response) => true)
    );
  }
}
