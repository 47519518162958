import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './pw-login.component.html',
  styleUrls: ['./pw-login.component.scss'],
})
export class PwLoginComponent implements OnInit {
  error = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.error = params.has('error');
    });
  }
}
