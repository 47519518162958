import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { TemplateModule } from '../template/template.module';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';

import { NotUserFormComponent } from './not-user-form.component';
import { NotUserService } from './not-user.service';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, TemplateModule, ControlMessagesModule],
  declarations: [NotUserFormComponent],
  providers: [NotUserService],
})
export class NotUserFormModule {}
