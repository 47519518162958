<form (ngSubmit)="registerInterest()" [hidden]="formSubmitted || user === null" #registerInterestForm="ngForm">
  <div class="form-group">
    <textarea
      type="text"
      class="form-control"
      id="reason"
      minlength="1"
      maxlength="512"
      placeholder="Please tell us why you are interested in this collection"
      aria-label="Please enter text to tell us why you are interested in this collection"
      required
      [(ngModel)]="registerInterestReason"
      name="registerInterestReason"
      #value="ngModel">
    </textarea>
    <div [hidden]="value.valid || value.pristine" class="alert alert-danger">
      Please supply a reason why you are interested in this collection.
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-success no-gutters btn btn-lg btn-block"
    [disabled]="!registerInterestForm.form.valid"
    aria-label="Register Interest">
    Register Interest
  </button>
</form>

<div *ngIf="formSubmitted">
  <b>Thank you for submitting your interest in {{ collectionName }}.</b>
</div>

<div *ngIf="user === null">
  <b>Please log in to Digimap to register your interest in {{ collectionName }}.</b>
</div>
