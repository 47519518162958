<div *ngIf="waitForVerification">We are verifying your code, please wait...</div>

<div *ngIf="!waitForVerification">
  <div class="row no-gutters">
    <div *ngIf="success" class="col-lg-offset-1 col-lg-10 dm-viewport clearfix">
      <div class="alert alert-success">
        <p><strong>Success!</strong> You have successfully verified your email address.</p>
      </div>
      <p>You now need to agree to the licence agreements for each collection.</p>
      <p>Click on the button below to access Digimap and agree to the licences for each collection.</p>
      <div *ngIf="user" class="pull-right">
        <button class="btn btn-success" (click)="navigateLicence()">Agree Licences</button>
      </div>
      <div *ngIf="!user" class="pull-right">
        <button class="btn btn-success" (click)="navigateHome()">Access Digimap</button>
      </div>
    </div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="!success" class="col-lg-offset-1 col-lg-10 dm-viewport">
      <div class="alert alert-danger">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p>
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button class="btn btn-success" (click)="navigateHome()">Continue</button>
    </div>
  </div>
</div>
