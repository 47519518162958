import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dmMax' })
export class MaxPipe implements PipeTransform {
  transform(value: any, args: any): any {
    const allowed = args[0];
    const received = value.length;

    if (received > allowed && allowed !== 0) {
      const toCut = allowed - received;
      return value.slice(0, toCut);
    }

    return value;
  }
}
