import { Component } from '@angular/core';
import { OS_COLLECTION } from '../collections/config/collection-config-os';
import { ARROW_ICON } from '../collections/collection-config';
import { AppGroup, Application, GroupName } from '../collections/collection';
import { ColourScheme } from '../collections/app-buttons/app-buttons.component';

@Component({
  selector: 'dm-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss'],
})
export class OpenComponent {
  icon = OS_COLLECTION.icon;
  arrow = ARROW_ICON;
  socialMediaIcons = ['twitter'];
  colourScheme: ColourScheme = {
    primaryColour: '#2e3c42',
    primaryColourLight: '#55585d',
  };

  appGroup: AppGroup = {
    groupName: GroupName.MAPPER,
    apps: [
      {
        id: 'OPENMAPPER',
        name: 'Start',
        link: '/roam/map/open',
      },
    ],
  };

  gotoOpenClient(app: Application) {
    window.location.href = app.link;
  }

  canAccessApplication(_: Application): boolean {
    return true;
  }
}
