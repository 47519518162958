import { Injectable } from '@angular/core';

declare let Notification: any;

@Injectable()
export class PushNotificationsService {
  private canCreate = false;
  private notificationBuffer: PushNotification | null;

  create(data: PushNotification): any {
    if (!this.canCreate) {
      this.notificationBuffer = data;
      this.getPermission();
      return;
    }

    const notification = new Notification(data.title, {
      body: data.body,
    });

    return notification;
  }

  getPermission(): void {
    if (!('Notification' in window)) {
      return;
    }

    if (Notification.permission === 'granted') {
      this.createBuffered();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((a: any) => {
        if (a === 'denied') {
          console.error("Permission wasn't granted");
        } else if (a === 'default') {
          console.warn('The permission request was dismissed');
        } else {
          this.createBuffered();
        }
      });
    }
  }

  private createBuffered() {
    this.canCreate = true;
    if (this.notificationBuffer) {
      this.create(this.notificationBuffer);
      this.notificationBuffer = null;
    }
  }
}

export interface PushNotification {
  title: string;
  body: string;
}
