<mat-tab-group>
  <mat-tab label="About">
    <p>
      Environment Digimap provides mapping data from the
      <a href="https://www.ceh.ac.uk/">UK Centre for Ecology and Hydrology</a>.
    </p>
    <p>
      UKCEH’s Land Cover Map can be viewed or downloaded for different years. The first published Land Cover Map was in
      1990. The most recently available in Digimap was published in 2020.
    </p>
    <p>
      The <a href="https://www.ceh.ac.uk/data/information-products">UKCEH Land Cover Maps</a> map UK Land Cover. They do
      this by describing the physical material on the surface of the United Kingdom providing an uninterrupted national
      dataset of land cover classes from grassland, woodland and fresh water to urban and suburban built-up areas. UKCEH
      has a long history of using satellite imagery to map land cover from the first national Land Cover Map of Great
      Britain in 1990 to the current production of annual Land Cover Maps and land cover change data. The UKCEH land
      cover classes are based on UK Biodiversity Action Plan (BAP) Broad Habitats (Jackson, 2000). Land Cover Plus:
      Crops maps from 2015 onwards can also be viewed and queried from the online maps with our Get Feature Information
      tool. Dudley Stamp’s maps of the 1930’s Land Utilisation Survey of Britain can also be viewed online. Users can
      compare two basemaps at the same scale for their research area. Additional UKCEH datasets are available to
      download; Land Cover Plus: Fertiliser, Land Cover Plus: Pesticides, Woody Linear Features Framework, Integrated
      Hydrological DTM and Digital River Network. You can view a list of all the data available in our help pages
      <a href="/help/our-maps-and-data/environment_products/" target="environment_data">here</a>.
    </p>
    <p>
      The Land Cover Map data can be used to plan, manage or monitor agriculture, ecology, conservation, forestry,
      environmental assessment, water supplies, urban spread, transport, telecommunications, recreation and mineral
      extraction. It is particularly useful when combined with other datasets. Use the land cover data to examine the
      coincidence of a given species with particular land cover types, make assessments of habitats at risk from a
      specific development, or look at national distributions of land cover types and how it is affected by other
      environmental and human factors.
    </p>
    <p>
      Current examples of the application of the Land Cover Map include detection of changing land cover, landscape
      management, mapping bracken in the context of health studies (bracken supports ticks carrying human disease),
      environmental assessments of motorway extensions, and planning of telecommunication lines.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Subscribing</h3>
    <p>
      Before you can access Environment Digimap your university or college needs to subscribe to the service.
      Institutional subscriptions are available to UK universities and colleges and are purchased for an annual fee
      which provides access for all staff and students.
    </p>
    <h3>Login</h3>
    <p>
      If your university or college already subscribes, you can access Digimap by logging with your university or
      college login details. Use the login button on this page (top right) or select an application to be prompted to
      login.
    </p>
    <h3>Register</h3>
    <p>
      Once you have logged in you will need to register. This is an online process and requires you to verify your email
      address and agree to the licences. You need to agree to a different licence for each Collection, but the
      registration is a one-time process.
    </p>
    <h3>Requirements</h3>
    <p>
      Digimap is designed to work with the latest version of the most popular web browsers. Please ensure you are using
      an up-to-date version of Edge, Chrome, FireFox or Safari. Please note, the service has not been tested on all iPad
      Minis or Android devices.
    </p>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. They all work in the same way but some offer additional
      features to make the most of their particular maps. With Environment Digimap you can view Land Cover maps or Crops
      maps from different years by selecting a different basemap. You can also:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>select a basemap</li>
      <li>use the Get Feature Info. button to check the land cover categorisation</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
    </ul>
    <h3>Downloading Data</h3>
    <p>
      Digimap allows you to download the UKCEH map data for use, for example, in GIS software or CAD software. You can:
    </p>
    <ul>
      <li>select your area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Web Map Services</h3>
    <p>The WMS can be used to display the environment data in most GIS software.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/environment/">Environment Digimap</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
