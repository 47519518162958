import { Collection } from '../collection';
import { AgcensusDetailComponent } from '../detail-pages/agcensus-detail.component';

/* eslint-disable max-len */

export const AGCENSUS_COLLECTION: Collection = {
  id: 'agcensus',
  name: 'agCensus',
  icon: `
  <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

<svg
   xmlns="http://www.w3.org/2000/svg"
   focusable="false"
   viewBox="0 0 68.029 68.029"
   enable-background="new 0 0 68.029 68.029"
   xml:space="preserve"
   >

<g
   transform="matrix(0.13755586,0,0,0.13755586,-1.1997963,-1.0882807)"><path
     d="M 492.4375,145.34375 446.417,114.66339 423.35352,60.84863 a 8.00048,8.00048 0 0 0 -3.46875,-3.84179 l -72,-40 A 8.00029,8.00029 0 0 0 344,16 H 208 a 7.97807,7.97807 0 0 0 -3.88354,1.00891 l -10e-4,-0.0021 -72,40 a 8.00048,8.00048 0 0 0 -3.46875,3.84179 l -24,56 A 7.99916,7.99916 0 0 0 104,120 v 40 H 88 V 144 H 72 v 16 H 48 V 144 H 32 v 16 H 16 v 16 h 16 v 16 H 16 v 16 h 472 a 8.00008,8.00008 0 0 0 8,-8 v -48 a 7.99943,7.99943 0 0 0 -3.5625,-6.65625 z M 312,134.94794 344,156.28125 V 192 H 312 Z M 360,160 h 72 v 32 h -72 z m -5.57812,-16 -24,-16 h 107.15624 l 24,16 z m 73.44628,-32 H 309.27539 L 292.13257,72 H 410.72534 Z M 341.92676,32 385.1272,56 H 282.07251 L 238.87305,32 Z M 142.27539,69.665 208,33.15137 273.72461,69.665 296,121.64282 V 192 h -48 v -56 a 8.00008,8.00008 0 0 0 -8,-8 h -64 a 8.00008,8.00008 0 0 0 -8,8 v 56 H 120 V 121.64209 Z M 216,144 h 16 v 48 h -16 z m -16,48 h -16 v -48 h 16 z m -96,-16 v 16 H 88 v -16 z m -56,0 h 24 v 16 H 48 Z m 400,16 v -32 h 32 v 32 z"
     /><path
     d="m 152,80 h 16 v 16 h -16 z"/><path
     d="m 248,80 h 16 v 16 h -16 z"/><path
     d="m 200,48 h 16 v 16 h -16 z"/><path
     d="m 112,368 a 24,24 0 1 0 24,24 24.02718,24.02718 0 0 0 -24,-24 z m 0,32 a 8,8 0 1 1 8,-8 8.00917,8.00917 0 0 1 -8,8 z"
      /><circle
     r="8"
     cy="408"
     cx="280" /><path
     d="M 320,408 A 40.05231,40.05231 0 0 0 296,371.35 V 336 a 8.00023,8.00023 0 0 0 -6.86816,-7.91943 L 280,326.776 V 304 h -16 v 20.4903 l -30.86816,-4.40973 a 7.9937,7.9937 0 0 0 -6.37305,1.876 c -0.20166,0.17487 -0.39063,0.361 -0.57227,0.55323 l -7.873,-5.24866 4.84179,-9.68347 -14.31054,-7.15528 -16,32 14.31054,7.15528 3.94727,-7.89466 L 224,340.28125 V 360 h -38.68848 a 79.742,79.742 0 0 0 -4.50732,-8.781 L 167.84473,286.43067 A 8,8 0 0 0 160,280 h -32 a 8.00008,8.00008 0 0 0 -8,8 v 24.40546 C 117.34058,312.14069 114.66943,312 112,312 a 79.667,79.667 0 0 0 -26.6709,4.55908 l 5.3418,15.082 A 63.705,63.705 0 0 1 112,328 a 63.8179,63.8179 0 0 1 54.42285,30.30908 63.43542,63.43542 0 0 1 5.93262,12.3501 l 4.9e-4,-1.8e-4 0.004,0.0153 a 64.33391,64.33391 0 0 1 2.04883,35.55029 A 8.00068,8.00068 0 0 0 182.21,416 h 58.595 a 39.83418,39.83418 0 0 0 7.217,16 h -96.88357 a 56,56 0 1 0 -78.27686,0 H 16 v 16 H 496 V 432 H 311.978 A 39.79015,39.79015 0 0 0 320,408 Z M 72,392 a 40,40 0 1 1 40,40 40.04552,40.04552 0 0 1 -40,-40 z m 64,-76.306 V 296 h 17.44141 l 6.39038,31.94708 A 80.35676,80.35676 0 0 0 136,315.694 Z M 190.40137,376 H 232 a 8.00008,8.00008 0 0 0 8,-8 v -30.77588 l 40,5.71436 V 368 a 40.068,40.068 0 0 0 -39.19507,32 H 191.60156 C 191.86719,397.34619 192,394.67432 192,392 a 80.30752,80.30752 0 0 0 -1.59863,-16 z M 256,408 a 24,24 0 1 1 24,24 24.0275,24.0275 0 0 1 -24,-24 z"
      /><path
     d="m 16,480 h 480 v 16 H 16 Z"/><path
     d="m 16,240 h 480 v 16 H 16 Z"/><path
     d="m 296,288 h 200 v 16 H 296 Z" /><path
     d="m 16,288 h 88 v 16 H 16 Z"/><path
     d="m 312,336 h 184 v 16 H 312 Z"/><path
     d="m 16,336 h 40 v 16 H 16 Z" /><path
     d="m 336,384 h 160 v 16 H 336 Z"/><path
     d="m 16,384 h 24 v 16 H 16 Z"/></g></svg>
  `,
  supplierLogos: [
    {
      name: 'Rural and Environment Science and Analytical Services logo',
      file: '/assets/images/collections/supplier-logos/resas.jpeg',
      width: 200,
      height: 100,
    },
    {
      name: 'Welsh Government Logo',
      file: '/assets/images/collections/supplier-logos/welsh-government-logo-vector.svg',
      width: 250,
      height: 100,
    },
    {
      name: 'Department for Environment Food and Rural Affairs logo',
      file: '/assets/images/collections/supplier-logos/DEFRA_logo_min.svg',
      width: 190,
      height: 103,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: AgcensusDetailComponent,
  licencePdf: '/help/copyright-and-licensing/agcensus_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/agcensus_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View and annotate maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'AGCENSUSROAM',
          name: 'agCensus Digimap',
          link: '/roam/map/agcensus',
        },
      ],
    },
  ],
};
