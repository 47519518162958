<main>
  <div class="section">
    <h1>Cookie Policy</h1>
  </div>
  <div class="section">
    <h2 id="what-is-a-cookie">What is a cookie?</h2>

    <p>
      A cookie is a small file downloaded on to your phone, tablet or computer when you visit a website. Cookies allow
      websites to recognise your device when you make a return visit.
    </p>

    <p>
      We use several types of cookies on the Digimap website. Some are essential, like cookies that make sure our
      website works properly with your browser. They always need to be on.
    </p>

    <p>
      Some cookies you can opt out of or block completely. Opting out or blocking these cookies might affect how the
      website looks or works for you.
    </p>

    <p>
      When you leave our website or click on links that take you to an external website, we can’t control what cookies
      they set. They will have their own cookie policy and you will need to set your cookie preferences for those
      websites too.
    </p>
  </div>
  <div class="section">
    <h2 id="why-and-how-we-use-cookies">Why and how we use cookies</h2>

    <p>There are four reasons why a cookie might be stored on your device when visiting digimap.edina.ac.uk:</p>

    <ul>
      <li>
        Cookies that make the website work properly for you and enable you to make use of the secure online services
        that we provide;
      </li>
      <li>
        Cookies that collect data about your use of the website which is then anonymised and used to help us improve our
        online services
      </li>
      <li>Cookies that remember your preferences and make the site easier for you to use</li>
      <li>
        Cookies that are placed by third party services we make use of to enhance the information we present online. We
        have no control over these third party cookies.
      </li>
    </ul>

    <p>
      These cookies are placed on your device either by Digimap or by the third parties whose services we use as part of
      the website.
    </p>

    <p>
      Some cookies are retained in your browser for only as long as you visit our website, while others persist for a
      longer specified or unspecified period.
    </p>
  </div>
  <div class="section">
    <h2 id="strictly-necessary-cookies">Strictly necessary cookies</h2>

    <p>
      Some cookies we place on your browser ensure that our services and sites deliver you information and services
      securely and optimally. You must accept these cookies to be able to make use of our online systems.
    </p>

    <h2 id="functionality-cookies">Functionality cookies</h2>

    <p>
      Some cookies enable websites to remember choices their users make, for example, user name, language or text size.
      These cookies are known as “functionality cookies” and help to improve a user’s experience of a website by
      providing a more personalised service.
    </p>
  </div>
  <div class="section">
    <h2 id="performance-cookies">Performance cookies</h2>

    <p>
      We use a number of tools that monitor visitor behaviour on our website to help us improve our information and
      services.
    </p>

    <p>
      The tool we use most consistently is Google Analytics. Anonymous data about your visit is collected and
      amalgamated with that of other visitors so we can better understand how people use our website.
    </p>

    <p>
      You can opt out of providing us with this information if you wish, with no impact on your experience of our
      website.
    </p>

    <p><em>Opt out of providing us with website usage data via Google Analytics</em></p>
  </div>
  <div class="section">
    <h2 id="third-party-cookies">Third party cookies</h2>

    <p>
      Digimap uses third party services or software, such as maps, online videos or social networking features. Many of
      these services may set cookies on your device.
    </p>

    <p>
      However, you can block or remove cookies yourself by altering the settings of your browser. Blocking these cookies
      is unlikely to impact on your experience of our website.
    </p>
  </div>
  <div class="section">
    <h2 id="how-to-block-cookies">How to block cookies</h2>

    <p>
      Most browsers allow some control of cookies through browser settings. To find out more about cookies, including
      how to see what cookies have been set and how to manage and delete them, visit www.aboutcookies.org or
      www.allaboutcookies.org.
    </p>

    <p>
      To opt out of being tracked by Google Analytics across all websites, visit
      http://tools.google.com/dlpage/gaoptout.
    </p>
  </div>
  <div class="section">
    <h2 id="cookies-set-across-digimap">Cookies set across digimap.edina.ac.uk</h2>

    <h3 id="strictly-necessary-cookies-1">Strictly necessary cookies</h3>

    <p>
      Some cookies we place on your browser ensure that our services and sites deliver you information and services
      securely and optimally. You must accept these cookies to be able to make use of our online systems.
    </p>

    <table class="table table-bordered">
      <caption>
        List of Strictly necessary cookies
      </caption>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Cookie name(s)</th>
          <th>Data stored</th>
          <th>When does it expire?</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cookie Preferences</td>
          <td>enable_analytics</td>
          <td>True or false cookie retention preferences plus time stamp</td>
          <td>The cookie is not deleted when you close your web browser.</td>
          <td>
            The cookie preferences are stored to retain visitor choices in the main cookie banner. This means that the
            main cookie banner can be minimised on future visits, and so that analytics and advertising cookies are not
            set for visitors who opt out of this tracking.<br /><br />
            This cookie does not identify you personally and is not linked to any other information we store about you.
          </td>
        </tr>
        <tr>
          <td>Session ID</td>
          <td>LIVE-DigimapID</td>
          <td>A random unique number or string of letters and numbers.</td>
          <td></td>
          <td>
            A session cookie is required to follow your progress through Digimap. It is essential to ensure that any
            information you enter or routes you take are remembered by the Digimap. Without this cookie, every page you
            visited would treat you as a completely new visitor. <br /><br />
            This cookie does not identify you personally and is not linked to any other information we store about you.
            <br /><br />
            <a href="http://en.wikipedia.org/wiki/Session_ID">http://en.wikipedia.org/wiki/Session_ID</a>
          </td>
        </tr>

        <tr>
          <td>Load balancer</td>
          <td>RCKBMHKB</td>
          <td>A random unique number or string of letters and numbers.</td>
          <td>The cookie is deleted when you close your web browser.</td>
          <td>
            EDINA uses more than one server to provide Digimap web pages. When you visit digimap.edina.ac.uk, you are
            assigned to one of several servers. This cookie is required to track which server you are communicating with
            in order to present a consistent user experience and remember information about the data you have entered.
            <br /><br />
            This cookie does not identify you personally and is not linked to any other information we store about you.
          </td>
        </tr>
        <tr>
          <td>Shibboleth Authentication</td>
          <td>_shibbsession_&lt;string&gt;</td>
          <td>A random unique number or string of letters and numbers.</td>
          <td>The cookie is deleted when you close your web browser.</td>
          <td>
            This cookie is required by the shibboleth authentication system used between Digimap and your institution.
            Without it you will not be able to log in to Digimap. This cookie is unique to you and allows us to connect
            the details your institution provides about you to the user registration details help with Digimap.
            <br /><br />
            This cookie does not identify you personally but is linked to other information we store about you and
            allows us to link your login session with activity undertaken within Digimap.
          </td>
        </tr>
      </tbody>
    </table>

    <h3 id="performance-cookies-1">Performance cookies</h3>

    <p>
      We use a number of tools that monitor visitor behaviour on digimap.edina.ac.uk to help us improve our performance
      in provision of information and services. These cookies collect anonymous information on the pages visited.
    </p>

    <table class="table table-bordered">
      <caption>
        List of Performance cookies
      </caption>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Cookie name(s)</th>
          <th>Data stored</th>
          <th>When does it expire?</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Google Analytics</td>
          <td>_ga, _gid, _fbp, __opix_uid</td>
          <td>A random unique number or string of letters and numbers.</td>
          <td>This cookie expires 2 years after your last visit to the website.</td>
          <td>
            Google Analytics is a web analytics service provided by Google, Inc. (“Google”), to help us see how our
            website is used. In doing so information about your use of our website, including your IP address, may be
            transmitted to Google and stored on servers in the United States <br /><br />
            <ul>
              <li><a href="https://policies.google.com/privacy">Google’s Privacy Policy</a></li>
              <li><a href="https://tools.google.com/dlpage/gaoptout">Opt out of Google Analytics</a></li>
            </ul>
            The information stored in this cookie is used to identify how frequently the same people revisit the Digimap
            website, and to detect how long people take between each visit. This information is combined with data from
            thousands of other users to create an overall picture of website use, and is never identified individually.
            <br /><br />
            This cookie does not identify you personally and is not linked to any other information we store about you.
          </td>
        </tr>
      </tbody>
    </table>

    <h3 id="third-party-cookies-1">Third party cookies</h3>

    <p>
      Digimap uses third party services or software, such as maps, online videos or social networking features. Many of
      these services may set cookies in your browser in accordance with their own privacy policies, although many can be
      blocked by you without impact on your experience. We have no control over these cookies.
    </p>

    <table class="table table-bordered">
      <caption>
        List of Third party cookies
      </caption>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Cookie name(s)</th>
          <th>Data stored</th>
          <th>When does it expire?</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>YouTube</td>
          <td>
            SID, LOGIN_INFO, use_hotbox, PREF, SSID, HSID, watched_video_id_list, __utma, __utmz, demographics,
            VISITOR_INFO1_LIVE
          </td>
          <td>Various unique identifiers, as well as login information that may relate to a Google account.</td>
          <td>
            Most of the cookies expire 10 years after your last visit to a page containing a YouTube video, although
            some expire sooner.
          </td>
          <td>
            Google set a number of cookies on any page that includes a YouTube video. While we have no control over the
            cookies set by Google, they appear to include a mixture of pieces of information to measure the number and
            behaviour of YouTube users, including information that links your visits to our website with your Google
            account if you are signed in to one. <br /><br />
            In doing so, information about your use of our website, including your IP address, may be transmitted to
            Google and stored on servers in the United States.
            <br /><br />
            This cookie does not identify you personally unless you are logged into Google, in which case it is linked
            to your Google account.
            <br /><br />
            <ul>
              <li><a href="https://policies.google.com/privacy">Google’s Privacy Policy</a></li>
              <li><a href="https://tools.google.com/dlpage/gaoptout">Opt out of Google Analytics</a></li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>
