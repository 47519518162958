export class DisableSubmit {
  private originalText: string;
  private readonly submittingText = '<div class="submit-loader"></div>Submitting...';

  private isSubmitting = false;
  private submitButtonText: string;

  constructor(originalButtonText?: string) {
    this.originalText = originalButtonText || 'Submit';
    this.submitButtonText = this.originalText;
  }

  public setSubmitting(submit: boolean): void {
    this.isSubmitting = submit;
    this.submitButtonText = submit ? this.submittingText : this.originalText;
  }

  public getSubmitting(): boolean {
    return this.isSubmitting;
  }

  public getSubmitText(): string {
    return this.submitButtonText;
  }
}
