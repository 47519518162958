import { Component, OnInit } from '@angular/core';

import { DisableSubmit } from '../../commons/disable-submit';
import { NotificationsService } from '../notifications/notifications.service';
import { RegisterUserService } from '../registeruser/register-user.service';
import { UpdateUserDto } from '../updateuser/update-user';
import { UpdateUserService } from '../updateuser/update-user.service';
import { User } from '../user/user';
import { UserService } from '../user/user.service';

@Component({
  selector: 'dm-verify-notification',
  templateUrl: 'verify-notification.template.html',
})
export class VerifyNotificationComponent implements OnInit {
  user: User | null;
  updatedUser: UpdateUserDto;
  resendSubmitText: DisableSubmit;
  deleteSubmitText: DisableSubmit;

  private passiveNotification = { timeOut: 5, clickToClose: true };

  constructor(
    private userService: UserService,
    private notificationsService: NotificationsService,
    private registerUserService: RegisterUserService,
    private updateUserService: UpdateUserService
  ) {
    this.resendSubmitText = new DisableSubmit('Resend Email');
    this.deleteSubmitText = new DisableSubmit('Delete Registration');
  }

  isRegistrationPending(): boolean {
    if (this.user) {
      return this.user.state === 'PENDING';
    }

    return false;
  }

  isEmailChangePending(): boolean {
    if (this.updatedUser) {
      return this.updatedUser.pendingEmailChange;
    }

    return false;
  }

  resendVerification() {
    this.resendSubmitText.setSubmitting(true);
    this.registerUserService.resendConfirmation().subscribe(() => {
      this.notificationsService.success(
        'Success',
        `Your verification email has been resent to ${this.user!.email}`,
        this.passiveNotification
      );
      this.resendSubmitText.setSubmitting(false);
    });
  }

  deleteRegistration() {
    this.deleteSubmitText.setSubmitting(true);
    this.registerUserService.deleteRegistration().subscribe(() => {
      this.notificationsService.success(
        'Success',
        `Your registration has been deleted. Click Register to start again.`,
        this.passiveNotification
      );
      this.deleteSubmitText.setSubmitting(false);
      this.userService.forceRefresh();
    });
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.user = user;

      // If and only if logged in and REGISTERED, check if user has any
      // pending email changes.
      if (this.user && this.user.state === 'REGISTERED' && this.updateUserService) {
        // Check if user has any pending email changes.
        this.updateUserService.getUpdateUser().subscribe(updatedUser => (this.updatedUser = updatedUser));
      }
    });
  }
}
