import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const API = {
  supplier: 'api/supplier',
};

export interface InstitutionToCollection {
  institution: string;
  collections: string[];
}

@Injectable()
export class SupplierService {
  constructor(private http: HttpClient) {}

  /**
   * Query collections API endpoint to get a list of active collections
   * and return corresponding Collection config objects
   *
   * @return Observable<Collection[]>
   */
  getInstitutionsForSupplier(): Observable<InstitutionToCollection[]> {
    return this.http.get<InstitutionToCollection[]>(API.supplier).pipe(
      catchError(err => observableThrowError(err.error)),
      map(response => response)
    );
  }
}
