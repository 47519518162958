import { Collection } from '../collection';

import { PilotDetailComponent } from '../detail-pages/pilot-detail.component';

/* eslint-disable max-len */

export const PILOT_COLLECTION: Collection = {
  id: 'pilot',
  name: 'Pilot',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029" xml:space="preserve">
  <path d="M 61.57735,16.550989 C 58.957304,12.575124 54.611936,8.362998 50.950156,6.24966 l -1.07089,-0.618047 0.385952,-0.16228 c 1.05553,-0.443817 4.388429,-1.295457 6.717933,-1.7166004 1.19977,-0.2169029 5.506434,-0.7865812 7.778759,-1.0289611 0.730618,-0.077932 0.750842,-0.07249 0.750771,0.2020104 -1.2e-4,0.4771343 -0.476377,4.5152391 -0.734619,6.2287961 -0.570444,3.785138 -1.79669,8.890011 -2.135478,8.890011 -0.04453,0 -0.523889,-0.67212 -1.065234,-1.4936 z"/><path
     d="M 29.261329,46.497631 C 28.810307,46.105012 28.009914,45.34566 27.482675,44.810184 L 26.52406,43.83659 27.182337,43.266593 c 1.730374,-1.498319 4.218337,-3.995436 5.595674,-5.616263 1.741359,-2.049202 3.109148,-4.315041 2.852414,-4.725221 -0.428405,-0.684448 -2.134707,0.266472 -5.110034,2.847818 -1.286997,1.116576 -4.440699,4.273052 -5.416632,5.421394 l -0.561542,0.660744 -1.640289,-1.646488 -1.640289,-1.646489 0.06044,-0.628956 c 0.04706,-0.489773 0.336346,-1.174086 1.307256,-3.092349 4.205968,-8.309901 7.842827,-13.434551 13.39822,-18.879259 3.528151,-3.457858 6.354293,-5.68652 9.513231,-7.5020229 l 1.001361,-0.575502 0.7406,0.517477 c 3.782281,2.6427859 10.145746,9.0742889 12.740686,12.8769159 l 0.467107,0.684498 -0.516965,0.875874 c -1.980768,3.355944 -4.420602,6.434941 -7.745499,9.774588 -4.18581,4.204379 -8.653612,7.611875 -13.550921,10.334993 -1.91605,1.065408 -7.886911,4.067982 -8.334608,4.191235 -0.201361,0.05543 -0.448996,-0.09159 -1.081218,-0.641949 z M 47.99317,26.998526 c 1.155369,-0.251532 2.867707,-1.702977 3.436405,-2.912831 1.187921,-2.527192 0.01405,-5.791092 -2.525571,-7.022263 -2.078434,-1.007595 -4.284871,-0.671335 -5.997187,0.913967 -1.26494,1.171111 -1.570925,1.957738 -1.565296,4.024066 0.0033,1.209035 0.03339,1.381874 0.363525,2.087345 0.497359,1.062834 1.412684,1.996642 2.464999,2.514777 1.245033,0.613023 2.314118,0.723463 3.823125,0.394939 z"/><path
     d="m 1.1309984,36.166908 c 0.3903935,-1.026814 1.8389651,-3.053776 3.2394277,-4.532879 2.8337719,-2.992897 6.1508969,-4.813656 10.2649689,-5.634408 1.037697,-0.207018 1.762014,-0.265981 3.310553,-0.269493 1.70606,-0.0038 3.964464,0.209197 3.964464,0.374023 0,0.117553 -0.75964,1.536059 -1.502704,2.806061 -0.444262,0.759309 -1.428436,2.54287 -2.187051,3.96347 -0.758615,1.4206 -1.446642,2.653579 -1.52895,2.739955 -0.122871,0.128943 -0.362624,0.103722 -1.339836,-0.140952 -3.895769,-0.975417 -8.027444,-0.816662 -12.4373099,0.477889 -0.817968,0.240121 -1.5829972,0.463577 -1.7000648,0.49657 -0.1878999,0.05296 -0.1976876,0.02011 -0.083498,-0.280236 z"/><path
     d="M 8.3501387,59.288817 C 9.7170937,53.42286 12.028921,47.953844 14.11692,45.64653 c 0.908717,-1.004164 2.943584,-2.547811 3.358578,-2.547811 0.313414,0 7.904339,7.419552 7.904339,7.72589 0,0.806373 -2.465192,3.645493 -4.212996,4.852039 -1.527521,1.054478 -5.936756,2.721277 -10.902411,4.121381 -0.9631453,0.271565 -1.8490563,0.530631 -1.9686903,0.575703 -0.158483,0.0597 -0.143726,-0.234727 0.05441,-1.084915 z m 5.3244753,-3.211102 c 3.27397,-1.797075 5.047823,-3.038564 6.420282,-4.493441 1.332699,-1.412733 2.272102,-2.711796 2.09156,-2.892337 -0.06251,-0.06251 -0.634562,0.225335 -1.271232,0.639647 -1.392008,0.905854 -2.844916,1.570386 -3.095215,1.415693 -0.38658,-0.23892 -0.14658,-1.137639 0.745914,-2.793212 0.510468,-0.946916 0.863006,-1.718724 0.783421,-1.715132 -0.637229,0.02875 -3.025094,2.172404 -4.337694,3.894042 -1.012346,1.327821 -4.009883,6.518085 -4.009883,6.943156 0,0.392182 0.436373,0.229179 2.672847,-0.998416 z" /><path
     d="m 31.618028,66.632335 c 0.0352,-0.159486 0.2531,-0.965735 0.484219,-1.791665 0.672087,-2.401785 0.833229,-3.52452 0.897289,-6.251753 0.06159,-2.622186 -0.083,-4.171855 -0.57557,-6.168719 -0.128554,-0.521154 -0.233735,-1.04203 -0.233735,-1.157503 0,-0.276237 0.151205,-0.368805 3.213225,-1.967135 1.437335,-0.750269 3.262066,-1.741659 4.05496,-2.203091 1.884002,-1.096412 2.436666,-1.372607 2.5098,-1.254274 0.148959,0.241021 0.313316,2.27125 0.31267,3.862277 -0.0029,7.015086 -3.318458,12.873952 -9.330277,16.487112 -1.27586,0.766804 -1.413829,0.812852 -1.332581,0.444751 z"/></svg>
`,
  subscriptionRequired: true,
  collectionDetail: PilotDetailComponent,
  licencePdf: '/help/copyright-and-licensing/pilot_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/pilot_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'PILOTROAM',
          name: 'Pilot Digimap',
          link: '/roam/map/pilot',
        },
      ],
    },
  ],
};
