import { AbstractCollectionDirective } from './abstract-collection.directive';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  templateUrl: './whats-new-detail.component.html',
  styleUrls: ['./whats-new-detail.component.scss'],
})
export class WhatsNewDetailComponent extends AbstractCollectionDirective implements OnInit {
  // Order of the tabs, used for navigation to a particular tab, e.g. ...#ntm
  tabList = { ngd: 0, landclan: 1 };
  selectedIndex: number | null = null;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    const urlFragment = this.route.snapshot.fragment;
    this.selectedIndex = urlFragment ? this.tabList[urlFragment] : 0;
  }
}
