<footer class="container-fluid footer">
  <div class="row">
    <div class="edina-footer">
      <div>
        <a href="http://edina.ac.uk" accesskey="4" title="EDINA Homepage">
          <img class="icon-edina" src="assets/images/misc/edina.svg" alt="EDINA Homepage" />
        </a>
      </div>
      <div class="footer-centre hidden-xs hidden-sm">
        <a *ngIf="useIcon('chat')" href="https://app.purechat.com/w/cq1mph" title="Digimap Chat">
          <img class="icon-chat" src="assets/images/misc/chat.svg" alt="Digimap Chat" />
        </a>
        <a *ngIf="useIcon('twitter')" href="https://twitter.com/EDINA_Digimap" title="Digimap Twitter">
          <img class="icon-twitter" src="assets/images/misc/x-twitter.svg" alt="Digimap Twitter" />
        </a>
        <a *ngIf="useIcon('facebook')" href="https://www.facebook.com/EDINADigimap/" title="Digimap Facebook">
          <img class="icon-facebook" src="assets/images/misc/facebook.svg" alt="Digimap Facebook" />
        </a>
        <a *ngIf="useIcon('blog')" href="https://blogs.ed.ac.uk/digimap/" title="Digimap Blog">
          <img class="icon-blog" src="assets/images/misc/blog.svg" alt="Digimap Blog" />
        </a>
        <a *ngIf="useIcon('youtube')" href="https://www.youtube.com/EDINADigimap" title="Digimap Youtube">
          <img class="icon-youtube" src="assets/images/misc/youtube.svg" alt="Digimap Youtube" />
        </a>
      </div>
      <div class="footer-right">
        <a
          [routerLink]="['/contact']"
          [queryParams]="{application: 'interface'}"
          title="Contact Us"
          class="btn btn-inverse"
          accesskey="9"
          >Contact Us</a
        >
        <a class="btn btn-inverse" href="help/about/privacy_notice" title="Privacy Notice" accesskey="5"
          >Privacy Notice</a
        >
        <a class="btn btn-inverse" href="/cookie-policy" title="Cookie Policy" accesskey="7">Cookie Policy</a>
        <a class="btn btn-inverse" href="/accessibility" title="Accessibility Statement" accesskey="0"
          >Accessibility Statement</a
        >
      </div>
    </div>

    <div class="well-sm copyright" [ngStyle]="primaryColour ? {'background-color': primaryColour} : {}">
      <div class="col-xs-12 text-center">
        <a href="http://www.ed.ac.uk/">&copy; The University of Edinburgh</a>
      </div>
    </div>
  </div>
</footer>
