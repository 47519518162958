import { Collection, GroupName, LICENSED } from '../collection';

import { EnvironmentDetailComponent } from '../detail-pages/environment-detail.component';

/* eslint-disable max-len */

export const ENVIRONMENT_COLLECTION: Collection = {
  id: 'environment',
  name: 'Environment',
  icon: `
    <svg x="0px" y="0px" width="68.029px" height="68.029px" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029">
      <g>
        <circle cx="27.81" cy="25.247" r="3.169"/>
        <path d="M53.256,25.344c-0.426-0.327-1.43-0.896-1.914-0.989c-2.103-0.403-3.595-0.65-6.503-0.929
          c-5.927-0.567-6.969-0.677-7.958-0.393c-1.158,0.333-2.233,1.047-3.47,1.261c-0.326,0.056-0.666,0.1-0.985,0.135
          c0.047,0.266,0.076,0.539,0.076,0.819c0,0.73-0.172,1.418-0.469,2.034c0.299,0,0.67,0,1.129,0c1.545,0,3.401,0.265,4.575,0.336
          c0.449,0.027,4.266-0.139,8.607-0.347c2.835-0.136,5.91-0.357,6.696-0.543C53.645,26.584,53.68,25.667,53.256,25.344z"/>
        <path d="M26.682,20.696c-0.121-0.209-0.261-0.452-0.424-0.735c-0.773-1.338-1.471-3.077-1.996-4.129
          c-0.201-0.404-2.254-3.625-4.605-7.282c-1.535-2.387-3.264-4.94-3.818-5.527c-0.425-0.45-1.236-0.022-1.306,0.505
          c-0.069,0.533-0.062,1.688,0.101,2.153c0.703,2.022,1.234,3.438,2.447,6.097c2.472,5.416,2.897,6.374,3.639,7.088
          c0.867,0.836,2.023,1.411,2.827,2.375c0.254,0.305,0.501,0.635,0.719,0.937C24.889,21.458,25.728,20.932,26.682,20.696z"/>
        <path d="M24.419,28.486c-0.125,0.216-0.271,0.469-0.441,0.765c-0.773,1.339-1.93,2.812-2.579,3.793
          c-0.249,0.376-2.013,3.764-4.003,7.629c-1.299,2.522-2.646,5.296-2.878,6.069c-0.177,0.594,0.599,1.082,1.091,0.879
          c0.497-0.207,1.491-0.791,1.814-1.164c1.4-1.619,2.359-2.787,4.057-5.167c3.454-4.849,4.07-5.696,4.318-6.696
          c0.291-1.168,0.211-2.458,0.645-3.635c0.139-0.378,0.304-0.765,0.459-1.108C25.933,29.66,25.075,29.172,24.419,28.486z"/>
        <path d="M31.182,64.16c-0.083-1.274-1.602-34.401-1.602-34.401s-0.291,0.134-0.588,0.208c-0.312,0.079-0.727,0.079-0.727,0.079
          c-0.358,0.732-0.571,1.944-0.648,3.319c-0.075,1.32-0.363,2.439-0.805,3.243c-0.305,0.556-0.867,1.366-1.141,1.79
          c0,0-1.177,24.417-1.236,25.762C24.378,65.506,31.276,65.575,31.182,64.16z"/>
      </g>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'UK Centre for Ecology and Hydrology Logo',
      file: '/assets/images/collections/supplier-logos/UKCEH-Logo_Long_Positive_RGB.png',
      width: 270,
      height: 80,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: EnvironmentDetailComponent,
  licencePdf: '/help/copyright-and-licensing/environment_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/environment_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'ENVIROROAM',
          name: 'Environment Digimap',
          link: '/roam/map/environment',
        },
      ],
    },
    {
      groupName: GroupName.WEBSERVICES,
      apps: [
        {
          id: 'ENVIRONMENTWMS',
          name: 'Web Services',
          link: '/environment/wms',
          isInternalLink: true,
          wmsLinks: LICENSED,
        },
      ],
    },
  ],
};
