import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dm-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./_cookie-policy.component.scss'],
})
export class CookiePolicyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
