<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="view === formView.Wait">Please wait ...</div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <div *ngIf="(view === formView.Form || view === formView.Summary) && activations" class="activate-collections">
      <form class="form-horizontal" [formGroup]="activateCollectionsForm" (ngSubmit)="activateCollections()">
        <div *ngIf="view === formView.Form">
          <h3 tabindex="0">Digimap Collections Licence Agreements</h3>
          <p tabindex="0">
            Please choose the Digimap collection(s) you wish to access and agree to their licence agreements.
          </p>
          <mat-accordion id="activation-accordion">
            <mat-expansion-panel *ngFor="let activation of activations">
              <mat-expansion-panel-header [ngClass]="headingStyles[activation.id]" id="{{activation.id}}-activation">
                <mat-panel-title>
                  <div class="panel-title-left">
                    <div>
                      <dm-dynamic-svg svg="{{getIcon(activation)}}"></dm-dynamic-svg>
                      {{activation.name}} Collection
                    </div>
                    <div
                      class="licence-status padding-left"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="In order to access this collection, please ensure you have read and agreed to the related licences">
                      <strong>
                        {{ getParentActivations(activation).length | i18nPlural: relatedActivationMapping }}
                      </strong>
                      <div *ngFor="let activation of getParentActivations(activation)" class="related-licences">
                        <span
                          *ngIf="isAgreedAndPurposeSupplied(activation); else elseBlock"
                          class="glyphicon glyphicon-ok text-success"></span>
                        <ng-template #elseBlock>
                          <span class="glyphicon glyphicon-exclamation-sign text-danger"></span>
                        </ng-template>
                        <span>{{activation.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="licence-status text-right">
                    <strong>{{getStateName(activation.state)}}</strong>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div formGroupName="{{activation.id}}Group">
                <div class="form-group">
                  <div class="col-xs-11">
                    <div class="form-control-static licence-area" rows="6">
                      <div class="licence">
                        <div class="licence-text" [innerHTML]="getEula(activation.id)"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-xs-10">
                    <div>
                      <input
                        id="{{activation.id}}-licence"
                        class="licence-accepted"
                        type="checkbox"
                        formControlName="licenceAccepted"
                        (change)="licenceAcceptedClicked($event, activation)"
                        [dmDisable]="!canActivate(activation)"
                        tabindex="0" />
                      <label [attr.for]="activation.id + '-licence'">
                        I Accept. By checking this box I agree to the terms of use for this collection.
                      </label>

                      <p *ngIf="shouldBeDisabled(activation)">
                        In order to access this collection, please ensure you have read and agreed to the related
                        licences: {{ getParentActivationsCommaSeperated(activation) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-xs-2">
                    <a href="{{getPrinterFriendlyLink(activation)}}">Printer friendly version</a>
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-xs-12">
                    <label [attr.for]="activation.id + '-purpose'" class="control-label">Purpose</label>
                  </div>
                  <div class="col-xs-5">
                    <select
                      id="{{activation.id}}-purpose"
                      class="form-control"
                      formControlName="purpose"
                      [dmDisable]="this.isPurposeDisabled(activation)"
                      tabindex="0">
                      <option value="0">Please select...</option>
                      <option *ngFor="let purpose of activation.purposes" [value]="purpose.id">
                        {{purpose.purpose}}
                      </option>
                    </select>
                    <dm-control-messages
                      [control]="activateCollectionsForm.get(activation.id + 'Group.purpose')"></dm-control-messages>
                  </div>
                </div>
                <div *ngIf="showOtherPurpose(activation)" class="form-group">
                  <div class="col-xs-12">
                    <label for="otherPurpose" class="control-label"
                      >Please provide details of your intended use of the {{activation.name}} Collection.</label
                    >
                  </div>
                  <div class="col-xs-11">
                    <textarea
                      id="otherPurpose"
                      class="form-control"
                      formControlName="otherPurpose"
                      [dmDisable]="!canActivate(activation)"></textarea>
                    <dm-control-messages
                      [control]="activateCollectionsForm.get(activation.id + 'Group.otherPurpose')"></dm-control-messages>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <button type="button" class="btn btn-default" [disabled]="!hasChanged()" (click)="reset()">Reset</button>
          <button type="button" class="btn btn-success" [disabled]="!canSubmit()" (click)="view = formView.Summary">
            Next
          </button>
        </div>

        <!-- Next view -->
        <div *ngIf="view === formView.Summary">
          <div *ngIf="hasNewActivations()" tabindex="0">
            <p>You are requesting access to the following Collection(s):</p>
            <ul *ngFor="let activation of activations">
              <li *ngIf="activation.licenceAccepted === true && canActivate(activation)">
                <strong>{{activation.name}}</strong> with purpose '{{getPurposeName(activation.purpose.id,
                activation.purposes)}}'
                <span *ngIf="activation.purpose.id === '8'">{{activation.otherPurpose}}</span>
              </li>
            </ul>
          </div>
          <div *ngIf="hasActivationsInState('ACTIVE')" tabindex="0">
            <br />
            <p>You have existing activated access to the following Collection(s):</p>
            <ul *ngFor="let activation of activations">
              <li *ngIf="activation.state === 'ACTIVE'">
                <strong>{{activation.name}}</strong> with purpose '{{activation.purpose.purpose}}'
              </li>
            </ul>
          </div>
          <div *ngIf="hasActivationsInState('QUERIED')" tabindex="0">
            <br />
            <p>You have previously selected 'Other' as your purpose for using the following Collection(s):</p>
            <ul *ngFor="let activation of activations">
              <li *ngIf="activation.state === 'QUERIED'">
                <strong>{{activation.name}}</strong> with purpose '{{activation.otherPurpose}}'
              </li>
            </ul>
            <p>
              Your request for access will be checked by the EDINA Helpdesk for approval. We will email you if we
              require further information or to confirm we have approved your request.
            </p>
          </div>
          <div *ngIf="hasActivationsInState('DEACTIVATED')" tabindex="0">
            <br />
            <p>The following collection(s) have been <strong>DEACTIVATED</strong>:</p>
            <ul *ngFor="let activation of activations">
              <li *ngIf="activation.state === 'DEACTIVATED'"><strong>{{activation.name}}</strong></li>
            </ul>
            <p>You must contact the EDINA Helpdesk to resolve these.</p>
          </div>
          <div *ngIf="hasOutstandingActivations()" tabindex="0">
            <br />
            <p>If you want to access other collections available to you, click Back and agree to their licences.</p>
            <ul *ngFor="let activation of activations">
              <li *ngIf="activation.licenceAccepted === false && canActivate(activation)">
                <strong>{{activation.name}}</strong>
              </li>
            </ul>
          </div>
          <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
          <button type="submit" class="btn btn-success">Submit</button>
        </div>
      </form>
    </div>

    <!-- Success view: form has been submitted successfully -->
    <div *ngIf="view === formView.SuccessConfirmation">
      <div class="alert alert-success" tabindex="0">
        <strong>Success!</strong>
        You have successfully activated the collection(s) requested.
      </div>

      <div *ngIf="hasOtherActivations().length > 0" tabindex="0">
        <p>Note: as you selected 'Other' as your purpose for using the following collection(s):</p>
        <ul>
          <li *ngFor="let activationName of hasOtherActivations()">{{ activationName }}</li>
        </ul>
        <p>
          your request will be reviewed. We will email you if we require any further information or to confirm we have
          approved your request.
        </p>
      </div>

      <p tabindex="0">
        If you need further assistance please contact the EDINA Digimap Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>

      <button type="button" class="btn btn-success" (click)="navigateToApp()">Continue</button>
    </div>

    <!-- Error view: form has been submitted but there's been an error -->
    <div *ngIf="view === formView.ErrorConfirmation" tabindex="0">
      <div class="alert alert-danger">
        <strong>Error!</strong> There has been a problem activating your collections.
      </div>
      <p>
        Please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
  </div>
</main>
