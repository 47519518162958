<mat-tab-group>
  <mat-tab label="About">
    <p>
      Digimap’s Ordnance Survey Collection is our original collection, providing a full range of topographic Ordnance
      Survey data for the UK.
    </p>
    <p>
      Digimap Ordnance Survey provides licensed and open mapping data at scales ranging from 1:1,250 up to 1:750,000.
      Digital versions of Ordnance Survey's paper maps are available as well as vector maps in a range of cartographic
      styles. Several layers of OS MasterMap™, the most detailed available large-scale mapping of the UK, are available
      to browse and download.
    </p>
    <p>
      The service includes many other products from Ordnance Survey including postcodes, administrative boundaries, OS
      identifiers, contours and digital terrain models. Check our
      <a href="/help/our-maps-and-data/os_products/#main">help pages for a full product listing</a>.
    </p>
    <p>
      Complete coverage of Great Britain is available for all the products listed with the exception of the Isle of Man,
      which is covered only by some data products. The Manx government is responsible for mapping the Isle of Man.
    </p>
    <p>
      Open data from Ordnance Survey Northern Ireland (OSNI) Open Data is also available in this collection, as well as
      a wide range of local government data from the
      <a href="https://www.improvementservice.org.uk/">Improvement Service Scotland</a>.
    </p>
    <p>
      Upon payment of an additional subscription fee by your institution, Bluesky's National Tree Map&trade; data is
      also available from the Digimap OS Collection.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Subscribing</h3>
    <p>
      Before you can access Digimap’s Ordnance Survey Collection your university or college needs to subscribe to the
      service. Institutional subscriptions are available to UK universities and colleges and are purchased for an annual
      fee which provides access for all staff and students.
    </p>
    <h3>Login</h3>
    <p>
      If your university or college already subscribes, you can access Digimap by logging with your university or
      college login details. Use the login button on this page (top right) or select an application, in this case, OS
      Digimap, to be prompted to login.
    </p>
    <h3>Register</h3>
    <p>
      Once you have logged in you will need to register. This is an online process and requires you to verify your email
      address and agree to the licences. You need to agree to a separate licence for each Collection, but the
      registration is a one-time process.
    </p>
    <h3>Requirements</h3>
    <p>
      Digimap is designed to work with the latest version of the most popular web browsers. Please ensure you are using
      an up-to-date version of Edge, Chrome, FireFox or Safari. Please note too that the service has not been tested on
      all iPad Minis or Android devices and the interface is not intended to be used on mobile phones.
    </p>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. With OS Digimap you can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
      <li>customise map feature display</li>
      <li>select a different basemap</li>
      <li>compare 2 maps</li>
      <li>search for and add WMS layers</li>
    </ul>
    <h3>Download Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>select the version (publication date) – not available for all products</li>
      <li>select a cartographic style – not all available for all products</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Web Map Service</h3>
    <p>
      We provide two Digimap Ordnance Survey Web Map Services (WMS), which can be used to display mapping images from
      the Ordnance Survey Collection in most GIS software. One WMS provides access to the licensed mapping data, the
      other to OS open data. Within each service you can select the individual data products/layers you wish to view.
    </p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/ordnance_survey/">Digimap Ordnance Survey Collection</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
