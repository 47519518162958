import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dm-digimap-layout',
  templateUrl: 'digimap-layout.component.html',
})
export class DigimapLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log('init DigimapLayoutComponent');
  }
}
