import { Collection } from '../collection';

import { SocietyDetailComponent } from '../detail-pages/society-detail.component';

/* eslint-disable max-len */

export const SOCIETY_COLLECTION: Collection = {
  id: 'society',
  name: 'Society',
  icon: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve">
  <path d="M22.334,37.937c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054s5.052-2.268,5.052-5.054  C27.386,40.203,25.119,37.937,22.334,37.937L22.334,37.937z"/>
  <path d="M77.718,37.937c-2.784,0-5.052,2.267-5.052,5.05c0,2.786,2.268,5.054,5.052,5.054c2.785,0,5.052-2.268,5.052-5.054  C82.77,40.203,80.503,37.937,77.718,37.937L77.718,37.937z"/>
  <path d="M50.026,37.937c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054c2.787,0,5.052-2.268,5.052-5.054  C55.078,40.203,52.812,37.937,50.026,37.937L50.026,37.937z"/>
  <path d="M36.18,37.937c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054c2.787,0,5.053-2.268,5.053-5.054  C41.232,40.203,38.967,37.937,36.18,37.937L36.18,37.937z"/><g>
  <path d="M50.026,50.237c-3.102,0-12.28-0.006-12.28,9.207v9.148h3.086V57.837c0-0.848,0.688-1.535,1.535-1.535   c0.85,0,1.535,0.688,1.535,1.535v9.093v1.814v21.402h4.589V73.27c0-0.851,0.689-1.537,1.535-1.537c0.847,0,1.535,0.688,1.535,1.537   v16.877h4.57V68.744V66.93v-9.093c0-0.848,0.688-1.535,1.535-1.535c0.852,0,1.537,0.688,1.537,1.535v10.756h3.104v-9.147   C62.309,50.27,53.127,50.237,50.026,50.237z"/></g>
  <path d="M63.872,37.937c-2.785,0-5.054,2.267-5.054,5.05c0,2.786,2.269,5.054,5.054,5.054c2.787,0,5.053-2.268,5.053-5.054  C68.925,40.203,66.659,37.937,63.872,37.937L63.872,37.937z"/>
  <path d="M50.026,24.092c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054c2.787,0,5.052-2.268,5.052-5.054  C55.078,26.358,52.812,24.092,50.026,24.092L50.026,24.092z"/>
  <path d="M36.18,24.092c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054c2.787,0,5.053-2.268,5.053-5.054  C41.232,26.358,38.967,24.092,36.18,24.092L36.18,24.092z"/>
  <path d="M63.872,24.092c-2.785,0-5.054,2.267-5.054,5.05c0,2.786,2.269,5.054,5.054,5.054c2.787,0,5.053-2.268,5.053-5.054  C68.925,26.358,66.659,24.092,63.872,24.092L63.872,24.092z"/>
  <path d="M50.026,10.247c-2.785,0-5.053,2.267-5.053,5.05c0,2.786,2.268,5.054,5.053,5.054c2.787,0,5.052-2.268,5.052-5.054  C55.078,12.514,52.812,10.247,50.026,10.247L50.026,10.247z"/>
  <path d="M25.76,57.835c-0.223-0.821,0.229-1.533,1.078-1.533c0.847,0,1.317,0.666,1.535,1.533l3.154,10.758l3.086,0.001  l-2.693-9.148c-2.729-9.254-7.765-9.208-9.586-9.208h-0.001c-1.82,0-6.854-0.046-9.583,9.208l-2.697,9.147h3.088l3.155-10.758  c0.218-0.867,0.687-1.533,1.533-1.533c0.85,0,1.302,0.712,1.078,1.533l-6.175,20.822h0.015l-0.015,0.053h4.422l1.079,11.437h3.241  L21.019,78.71h2.634l-0.457,11.437h3.221l1.082-11.437h4.439l-0.039-0.134l0.039,0.081L25.76,57.835z"/>
  <path d="M81.145,57.835c-0.223-0.821,0.229-1.533,1.078-1.533c0.848,0,1.317,0.666,1.535,1.533l3.154,10.758l3.086,0.001  l-2.693-9.148c-2.729-9.254-7.765-9.208-9.586-9.208h-0.001c-1.819,0-6.854-0.046-9.583,9.208l-2.697,9.147h3.088l3.156-10.758  c0.217-0.867,0.687-1.533,1.532-1.533c0.851,0,1.302,0.712,1.078,1.533l-5.571,18.784l0,0l-0.002,0.005l-0.602,2.033h0.015  l-0.015,0.053h4.422l1.079,11.437h3.241L76.402,78.71h2.635L78.58,90.146h3.221l1.082-11.438h4.439l-0.039-0.134l0.039,0.081  L81.145,57.835z"/></svg>
  `,
  supplierLogos: [
    {
      name: 'Office for National Statistics Logo',
      file: 'assets/images/collections/supplier-logos/ons-logo.svg',
      width: 290,
      height: 70,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: SocietyDetailComponent,
  licencePdf: '/help/copyright-and-licensing/society_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/society_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'SOCIETYROAM',
          name: 'Society Digimap',
          link: '/roam/map/society',
        },
      ],
    },
  ],
};
