import { Collection } from '../collection';

/* eslint-disable max-len */

export const LANDCLAN_COLLECTION: Collection = {
  id: 'landclan',
  name: 'LandClan',
  icon: `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   version="1.0"
   width="49.201141pt"
   height="56.928158pt"
   viewBox="0 0 49.201141 56.928158"
   preserveAspectRatio="xMidYMid meet"
   id="svg190"
   sodipodi:docname="landclan-logo.svg"
   inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs194" />
  <sodipodi:namedview
     id="namedview192"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:pageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:document-units="pt"
     showgrid="false"
     inkscape:zoom="1.9510251"
     inkscape:cx="292.41039"
     inkscape:cy="37.92878"
     inkscape:window-width="1864"
     inkscape:window-height="1163"
     inkscape:window-x="0"
     inkscape:window-y="0"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg190" />
  <path
     d="M 16.9,0.97924449 C 15.4,1.8792445 0,26.779244 0,28.279244 c 0,0.4 1.9,4.1 4.2,8.2 l 4.1,7.4 4.1,-3.7 c 3.6,-3.2 4.1,-4.2 4.2,-8.2 0.2,-8.5 6,-13.7 14.1,-12.6 4.4,0.6 4.6,0.5 11.8,-6.1 7.3,-6.4999995 7.4,-6.6999995 6,-9.3999995 -0.8,-1.4 -2.2,-2.90000001 -3,-3.30000001 -2.6,-1 -26.8,-0.6 -28.6,0.4 z"
     id="path162"
     style="fill:#000000;stroke:none;stroke-width:0.1" />
  <path
     d="m 25.2,27.579244 c -4.1,2.8 -2.2,9.4 2.8,9.4 5.5,0 7.8,-5.2 4,-9 -2.4,-2.4 -3.8,-2.5 -6.8,-0.4 z"
     id="path184"
     style="fill:#000000;stroke:none;stroke-width:0.1" />
  <path
     d="m 15.6,45.479244 -4.8,4.3 1.8,3.1 c 1,1.8 2.8,3.4 3.9,3.7 3.9,0.9 29.8,-0.1 30.2,-1.2 0.2,-0.6 -2.3,-3.9 -5.5,-7.4 -5.2,-5.7 -6,-6.2 -7.9,-5.2 -2.9,1.6 -6.8,1.5 -10.1,-0.2 -2.6,-1.3 -2.9,-1.2 -7.6,2.9 z"
     id="path186"
     style="fill:#000000;stroke:none;stroke-width:0.1" />
</svg>
  `,
  subscriptionRequired: true,
  licencePdf: '/help/copyright-and-licensing/landclan_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/landclan_eula/embeddable/',
  appGroups: [],
};
