<mat-tab-group>
  <mat-tab label="About">
    <p>
      Verisk’s Collection provides access to data from our commercial data supplier
      <a href="https://www.verisk.com/en-gb/3d-visual-intelligence/" target="_new">
        https://www.verisk.com/en-gb/3d-visual-intelligence/ [opens in new window]".</a
      >
    </p>
    <p>
      Verisk Digimap is free at the point of use for staff and students at subscribing higher and further education
      institutions and research councils.
    </p>
    <p>
      Verisk Digimap comprises two main functions, one for creating and viewing maps online, the other for downloading
      data which enables further analysis and investigation in other packages. Use the tabs in the left hand panel to
      select the function you need.
    </p>
    <p>
      You can view a list of all the data available in our help pages
      <a href="/help/our-maps-and-data/verisk_products/" target="verisk_data">here</a>.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. You can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>customise map feature display</li>
      <li>search for and add WMS layers</li>
    </ul>
    <h3>Download Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS software or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Guides</h3>
    <p><a href="/help/learning-materials/" target="dm_guides"> Application guides</a> are available.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
