import { Collection } from '../collection';

/* eslint-disable max-len */

export const SCOTIS_COLLECTION: Collection = {
  id: 'scotis',
  name: 'Improvement Service',
  icon: `
    <svg  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.91 93.54"><title>IS LogoCMYK</title>
    <path id="navy_i" data-name="navy i" d="M114.65,64.62c0,2.59-1.52,4.2-3.93,4.2s-3.93-1.61-3.93-4.2V24.46c0-2.59,1.52-4.2,3.93-4.2s3.93,1.61,3.93,4.2V64.62" style="fill:#03243d"/>
    <path id="navy_s" data-name="navy s" d="M140.09,20.26c6.51,0,15.79,3.48,15.79,8.39,0,1.7-1.16,3.22-3.57,3.22-3.39,0-4.73-4.82-12.22-4.82-4.47,0-8.31,2-8.31,6,0,8.3,25.35,7.4,25.35,21.15,0,9.91-8.48,14.64-17.31,14.64-10,0-17.85-5-17.85-9a3.35,3.35,0,0,1,3.21-3.57c3.48,0,6.34,5.8,14.91,5.8,5.71,0,9.19-3.21,9.19-6.42,0-9.28-25.35-8.75-25.35-22,0-8.75,8.21-13.39,16.16-13.39" style="fill:#03243d"/>
    <path id="i1" d="M5.1,84.79a.75.75,0,0,1-.79.84.75.75,0,0,1-.78-.84V76.74a.75.75,0,0,1,.78-.84.75.75,0,0,1,.79.84v8.05M4.31,72.68a1,1,0,1,1-1,1A1,1,0,0,1,4.31,72.68Z" style="fill:#03243d"/>
    <path id="m1" d="M12.82,79.46a2,2,0,0,0-2-2.2,2,2,0,0,0-2,2.2v5.33a.79.79,0,1,1-1.57,0V76.74a.79.79,0,1,1,1.57,0V77l0,0a2.72,2.72,0,0,1,2.4-1.16,2.91,2.91,0,0,1,2.61,1.61,3.3,3.3,0,0,1,2.92-1.61A3.26,3.26,0,0,1,20,79.51v5.28a.79.79,0,1,1-1.58,0V79.46a2,2,0,0,0-2-2.2,2,2,0,0,0-2,2.2v5.33a.79.79,0,1,1-1.57,0V79.46" style="fill:#03243d"/>
    <path id="p" d="M26.2,84.28c1.94,0,2.62-1.9,2.62-3.51s-.68-3.51-2.62-3.51-2.61,1.9-2.61,3.51.68,3.51,2.61,3.51m-4-7.54a.79.79,0,1,1,1.57,0v.47h0a2.61,2.61,0,0,1,2.54-1.31c2.58,0,4.06,2.34,4.06,4.87s-1.48,4.86-4.06,4.86a3.11,3.11,0,0,1-2.54-1.28h0v4.2a.79.79,0,1,1-1.57,0Z" style="fill:#03243d"/>
    <path id="r1" d="M32.06,76.74a.79.79,0,1,1,1.57,0v.81h0a2.81,2.81,0,0,1,2.15-1.65.8.8,0,0,1,.87.8c0,.45-.32.74-1,.86a2.49,2.49,0,0,0-2.08,2.71v4.52a.79.79,0,1,1-1.57,0V76.74" style="fill:#03243d"/>
    <path id="o" d="M41.47,84.28c1.94,0,2.62-1.9,2.62-3.51s-.68-3.51-2.62-3.51-2.61,1.9-2.61,3.51.68,3.51,2.61,3.51m0-8.38c2.69,0,4.19,2.34,4.19,4.87s-1.5,4.86-4.19,4.86-4.19-2.34-4.19-4.86S38.79,75.9,41.47,75.9Z" style="fill:#03243d"/>
    <path id="v1" d="M46.54,77.28a1.83,1.83,0,0,1-.16-.65.7.7,0,0,1,.71-.73.77.77,0,0,1,.83.61l2.16,6.58h0l2.16-6.58a.77.77,0,0,1,.83-.61.7.7,0,0,1,.71.73,1.83,1.83,0,0,1-.16.65L50.92,85c-.16.46-.32.67-.82.67s-.66-.21-.82-.67l-2.74-7.68" style="fill:#03243d"/>
    <path id="e1" d="M61.18,80.19a2.68,2.68,0,0,0-2.53-2.93C56.7,77.19,56,78.74,56,80.19h5.23M56,81.38a2.72,2.72,0,0,0,2.81,2.9c2.1,0,2.63-1.29,3.22-1.29a.63.63,0,0,1,.58.69c0,.77-2,1.95-3.82,1.95-3,0-4.36-2.34-4.36-4.86S56,75.9,58.65,75.9s4.1,2.34,4.1,4.63c0,.56-.19.85-.87.85Z" style="fill:#03243d"/>
    <path id="m2" d="M69.9,79.46a2,2,0,1,0-4.07,0v5.33a.79.79,0,1,1-1.57,0V76.74a.79.79,0,1,1,1.57,0V77l0,0a2.7,2.7,0,0,1,2.4-1.16,2.91,2.91,0,0,1,2.61,1.61A3.3,3.3,0,0,1,73.8,75.9a3.26,3.26,0,0,1,3.31,3.61v5.28a.79.79,0,1,1-1.58,0V79.46a2,2,0,1,0-4.06,0v5.33a.79.79,0,1,1-1.57,0V79.46" style="fill:#03243d"/>
    <path id="e2" d="M85.41,80.19a2.67,2.67,0,0,0-2.52-2.93c-2-.07-2.65,1.48-2.7,2.93h5.22m-5.22,1.19A2.71,2.71,0,0,0,83,84.28c2.09,0,2.63-1.29,3.22-1.29a.62.62,0,0,1,.57.69c0,.77-2,1.95-3.81,1.95-3,0-4.37-2.34-4.37-4.86s1.61-4.87,4.28-4.87S87,78.24,87,80.53c0,.56-.2.85-.88.85Z" style="fill:#03243d"/>
    <path id="n" d="M88.49,76.74a.79.79,0,1,1,1.58,0v.38l0,0A3.34,3.34,0,0,1,96,79.51v5.28a.79.79,0,1,1-1.57,0V79.6a2.2,2.2,0,1,0-4.4,0v5.19a.79.79,0,1,1-1.58,0V76.74" style="fill:#03243d"/>
    <path id="t" d="M98.62,74a.79.79,0,1,1,1.58,0V76h.64c.52,0,.79.25.79.68s-.27.68-.79.68h-.64v7.42a.79.79,0,1,1-1.58,0V77.37H98c-.52,0-.79-.25-.79-.68S97.46,76,98,76h.64V74" style="fill:#03243d"/>
    <path id="s" d="M112.37,78.8c-.48,0-1.47-.75-2.31-.75-.46,0-.88.21-.88.71,0,1.2,4.48,1,4.48,4,0,1.72-1.45,3-3.67,3-1.45,0-3.6-.82-3.6-2a1.21,1.21,0,0,1,1.11-1.15c1,0,1.45.88,2.67.88.78,0,1-.25,1-.74,0-1.18-4.48-1-4.48-4,0-1.79,1.45-3,3.49-3,1.27,0,3.23.59,3.23,1.86a1.12,1.12,0,0,1-1.06,1.15" style="fill:#f50004"/>
    <path id="e3" d="M120.69,79.91a2,2,0,0,0-2.06-2,2.08,2.08,0,0,0-2,2h4.1m-4,1.72a2.22,2.22,0,0,0,2.43,1.86c1.33,0,2.24-1,2.92-1a1.14,1.14,0,0,1,1,1.11c0,1.11-2.29,2.18-4.2,2.18A4.69,4.69,0,0,1,114,80.79a4.63,4.63,0,1,1,9.25-.31c0,.77-.34,1.15-1.13,1.15Z" style="fill:#f50004"/>
    <path id="r2" d="M124.15,77.19a1.27,1.27,0,0,1,1.32-1.29c.68,0,1.25.25,1.25,1.07h0a2,2,0,0,1,1.81-1.07,1.26,1.26,0,0,1,1.25,1.38c0,.84-.77.89-1.54,1.25s-1.56.7-1.56,1.72v4a1.29,1.29,0,1,1-2.57,0v-7" style="fill:#f50004"/>
    <path id="v" d="M129.55,77.9a2,2,0,0,1-.16-.75,1.24,1.24,0,0,1,1.34-1.25,1.18,1.18,0,0,1,1.15.91l1.65,5.08h0l1.65-5.08a1.17,1.17,0,0,1,1.14-.91,1.25,1.25,0,0,1,1.35,1.25,1.84,1.84,0,0,1-.17.75l-2.59,6.66c-.25.66-.52,1.07-1.4,1.07s-1.14-.41-1.39-1.07l-2.6-6.66" style="fill:#f50004"/>
    <path id="i2" d="M138.38,77.33a1.29,1.29,0,1,1,2.57,0V84.2a1.29,1.29,0,1,1-2.57,0V77.33m2.73-3.88a1.47,1.47,0,0,1-1.45,1.48,1.51,1.51,0,0,1-1.45-1.48,1.45,1.45,0,0,1,2.9,0Z" style="fill:#f50004"/>
    <path id="c" d="M148.79,78.65c-.64,0-1.21-.5-2-.5-1.47,0-2.16,1.31-2.16,2.64a2.37,2.37,0,0,0,2.16,2.59,14.2,14.2,0,0,0,1.92-.54A1.13,1.13,0,0,1,149.78,84c0,1.33-2.22,1.77-3.15,1.77A4.68,4.68,0,0,1,142,80.79a4.8,4.8,0,0,1,4.62-5c1,0,3.15.38,3.15,1.63,0,.54-.38,1.23-1,1.23" style="fill:#f50004"/>
    <path id="e4" d="M156.79,79.91a2,2,0,0,0-2-2,2.07,2.07,0,0,0-2,2h4.09m-4,1.72a2.22,2.22,0,0,0,2.43,1.86c1.33,0,2.24-1,2.92-1a1.15,1.15,0,0,1,1.06,1.11c0,1.11-2.3,2.18-4.21,2.18a4.69,4.69,0,0,1-4.83-4.95,4.63,4.63,0,1,1,9.25-.31c0,.77-.34,1.15-1.13,1.15Z" style="fill:#f50004"/>
    <path id="full_stop" data-name="full stop" d="M165,60.1a4.56,4.56,0,1,1-4.55,4.55A4.58,4.58,0,0,1,165,60.1" style="fill:#f50004"/>
    <path id="I_dot" data-name="I dot" d="M110.34,4.15A4.82,4.82,0,1,1,105.52,9a4.8,4.8,0,0,1,4.82-4.82" style="fill:#f50004"/>
  </svg>
  `,
  subscriptionRequired: true,
  licencePdf: '/help/copyright-and-licensing/scotis_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/scotis_eula/embeddable/',
  appGroups: [],
};
