<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="view === formView.Wait" aria-live="polite" tabindex="0">Please wait ...</div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <form class="form-horizontal" [formGroup]="notUserForm" (ngSubmit)="setNotUser()">
      <!-- Main form view, including preamble -->
      <div *ngIf="view === formView.Form">
        <h3 tabindex="0">Not you?</h3>
        <div class="alert alert-warning" tabindex="0">
          It is a breach of the terms and conditions of the service to log in to the service with another person's
          details.
        </div>

        <p tabindex="0">
          If you <em>are</em>&nbsp;<strong>{{user.fullname}}</strong> and have reached this page in error, please return
          to the <a (click)="navigate()">Homepage</a>.
        </p>

        <p tabindex="0">If you <em>are not</em>&nbsp;<strong>{{user.fullname}}</strong>:</p>

        <ul>
          <li tabindex="0">
            The previous user of the computer failed to log out correctly. You should log out of Digimap by closing your
            browser windows or logging back in using your own personal account.
          </li>
          <li tabindex="0"><em>or:</em></li>
          <li tabindex="0">
            If you are sure you have logged in with your own account, please fill out your details in the form below and
            then click on the <strong>Next</strong> button. We will contact you within one working day.
          </li>
        </ul>

        <span tabindex="0">Fields marked with * are mandatory.</span>
        <div class="form-group">
          <label for="name" class="dm-form-label control-label text-nowrap"
            >Name: *<span class="visuallyhidden">mandatory</span></label
          >
          <div class="dm-form-control">
            <input id="name" type="text" class="form-control" formControlName="name" tabindex="0" />
            <dm-control-messages [control]="notUserForm.controls['name']"></dm-control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="email" class="dm-form-label control-label text-nowrap"
            >Email: *<span class="visuallyhidden">mandatory</span></label
          >
          <div class="dm-form-control">
            <input id="email" type="email" class="form-control" formControlName="email" tabindex="0" />
            <dm-control-messages [control]="notUserForm.controls['email']"></dm-control-messages>
          </div>
        </div>

        <div class="form-group">
          <label for="institution" class="dm-form-label control-label text-nowrap"
            >Institution: *<span class="visuallyhidden">mandatory</span></label
          >
          <div class="dm-form-control">
            <select id="institution" class="form-control" formControlName="institution" tabindex="0">
              <option value="">Please select...</option>
              <option *ngFor="let inst of user.institutions" [value]="inst">{{inst}}</option>
            </select>
            <dm-control-messages [control]="notUserForm.controls['institution']"></dm-control-messages>
          </div>
        </div>

        <div class="form-group">
          <label for="comments" class="dm-form-label control-label">Comments:</label>
          <div class="dm-form-control">
            <textarea id="comments" class="form-control" formControlName="comments" rows="10" tabindex="0"></textarea>
          </div>
        </div>

        <div class="dm-form-buttons">
          <button id="resetButton" type="button" class="btn btn-default" [disabled]="!hasChanged()" (click)="reset()">
            Reset
          </button>
          <button
            id="nextButton"
            type="button"
            class="btn btn-success"
            [disabled]="!canSubmit()"
            (click)="view = formView.Summary">
            Next
          </button>
        </div>
      </div>

      <!-- Summary page: summarise information for user before submission -->
      <div *ngIf="view === formView.Summary">
        <h3 tabindex="0">Please check the following details before pressing submit.</h3>
        <p class="alert alert-warning" tabindex="0">
          Note that your email address MUST be correct, otherwise your request cannot be processed.
        </p>

        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Name:</label>
          <p class="dm-form-control form-control-static">{{ user.name }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Email:</label>
          <p class="dm-form-control form-control-static">{{ user.email }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Institution:</label>
          <p class="dm-form-control form-control-static">{{ user.institution }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Comments:</label>
          <p class="dm-form-control form-control-static">{{ user.comments }}</p>
        </div>

        <div class="dm-form-buttons">
          <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
          <button type="submit" class="btn btn-success">Submit</button>
        </div>
      </div>
    </form>

    <!-- Success view: form has been submitted successfully -->
    <div *ngIf="view === formView.SuccessConfirmation" tabindex="0">
      <div class="alert alert-success"><strong>Success!</strong></div>
      <p>
        Your request will be dealt with as soon as possible and you will receive an email outlining what to do next.
        Please do not use the Digimap service until you have been contacted and the problem has been resolved.
      </p>
      <p>
        It is a breach of the terms and conditions of the service to log in to the service with another person's
        details.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <!-- Error view: form has been submitted but there's been an error -->
    <div *ngIf="view === formView.ErrorConfirmation" tabindex="0">
      <div class="alert alert-danger"><strong>Error!</strong> There has been a problem submitting your details.</div>
      <p>
        Please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <p>
        It is a breach of the terms and conditions of the service to log in to the service with another person's
        details.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
  </div>
</main>
