import { Collection } from '../collection';

/* eslint-disable max-len */

export const NGD_COLLECTION: Collection = {
  id: 'ngd',
  name: 'OS National Geographic Database (NGD)',
  icon: `
    <?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="500.000000pt" height="500.000000pt" viewBox="0 0 500.000000 500.000000"
     preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M2186 4974 c-1135 -146 -2028 -1047 -2161 -2180 -120 -1023 397
    -2012 1306 -2497 583 -311 1299 -374 1933 -170 843 271 1477 967 1670 1834 75
    335 75 743 0 1078 -231 1036 -1090 1813 -2140 1936 -159 18 -459 18 -608 -1z
    m229 -373 c82 -38 148 -104 188 -189 28 -59 32 -77 32 -152 0 -74 -4 -93 -31
    -150 -54 -113 -118 -171 -235 -210 -95 -33 -185 -25 -284 24 -271 134 -282
    508 -19 662 107 63 235 69 349 15z m1136 -273 c313 -115 484 -434 408 -757
    -21 -87 -86 -207 -150 -275 -78 -84 -152 -132 -259 -170 -75 -27 -98 -30 -195
    -30 -92 0 -122 5 -183 27 -107 37 -175 82 -256 168 -323 342 -179 900 269
    1042 76 24 92 26 200 22 75 -2 117 -9 166 -27z m-2095 -129 c162 -29 325 -167
    379 -319 115 -327 -125 -669 -470 -670 -142 0 -251 46 -356 150 -197 194 -198
    498 -4 695 121 123 284 175 451 144z m1028 -703 c-2 -29 -14 -47 -75 -107 -41
    -40 -78 -71 -82 -70 -5 1 -6 -3 -2 -8 4 -7 15 -7 30 -1 14 5 25 6 25 2 -1 -13
    -27 -42 -33 -36 -3 3 -14 1 -24 -5 -17 -10 -17 -10 0 -11 17 0 17 -1 0 -27
    -17 -28 -17 -28 2 -12 32 25 115 60 127 53 5 -4 52 -6 104 -5 92 2 94 1 106
    -24 10 -21 8 -32 -10 -64 -12 -21 -22 -46 -22 -55 0 -23 -68 -151 -91 -172
    -24 -21 -24 -40 -1 -53 15 -9 15 -12 2 -21 -8 -5 -22 -10 -31 -10 -9 0 -24 -9
    -34 -20 -10 -11 -25 -20 -34 -21 -9 0 -1 -5 18 -10 26 -8 37 -7 50 5 16 14 21
    14 59 -4 23 -11 42 -23 42 -25 0 -3 -89 -5 -197 -5 -176 1 -196 2 -179 15 19
    15 19 15 -2 15 -13 0 -22 -6 -22 -15 0 -18 -12 -19 -28 -3 -9 9 -12 9 -12 -1
    0 -7 -4 -9 -10 -6 -16 10 -12 22 22 62 l32 38 -37 -34 c-20 -19 -37 -43 -37
    -53 0 -20 -8 -22 -29 -9 -10 7 -11 13 -1 31 7 12 10 33 7 46 -2 14 0 27 5 30
    4 3 6 10 3 15 -4 5 -1 9 4 9 6 0 11 5 11 11 0 5 -6 7 -12 4 -7 -4 -5 0 5 8 9
    8 17 19 17 26 0 6 5 11 10 11 6 0 10 5 10 12 0 6 -13 0 -28 -15 -31 -30 -54
    -34 -71 -13 -9 11 -9 15 1 19 7 3 1 6 -14 6 -16 1 -28 6 -28 11 0 10 14 14 38
    11 13 -2 15 9 12 46 -1 18 2 22 17 16 16 -6 16 -5 4 10 -12 15 -11 17 5 17 14
    0 16 2 5 13 -10 10 -16 7 -31 -13 -10 -14 -22 -22 -26 -18 -4 4 -1 15 6 23 12
    14 10 15 -13 11 -28 -5 -60 21 -55 45 2 7 -6 13 -17 14 -11 0 -21 8 -23 19 -3
    13 0 16 9 11 9 -6 11 -3 6 8 -7 20 4 22 21 5 9 -9 12 -6 12 15 0 15 4 27 9 27
    17 0 33 -30 29 -55 -4 -20 -3 -23 8 -14 11 9 14 8 14 -5 0 -9 -4 -16 -10 -16
    -5 0 -10 -5 -10 -11 0 -6 7 -9 15 -5 8 3 15 1 15 -4 0 -6 9 -10 21 -10 11 0
    17 5 14 10 -3 6 -1 10 4 10 6 0 11 4 11 9 0 5 -9 7 -20 4 -16 -4 -20 0 -20 22
    0 23 3 25 23 20 21 -7 21 -6 3 8 -11 8 -17 21 -13 29 3 9 1 20 -5 26 -6 6 -7
    15 -3 22 5 8 11 6 21 -7 14 -17 15 -17 10 2 -5 18 -2 21 29 20 l35 -2 -20 22
    c-22 23 -27 40 -9 29 9 -5 10 1 5 20 -5 22 -3 26 14 26 12 0 18 4 15 10 -3 5
    -1 28 5 50 9 34 14 40 31 35 41 -13 80 -13 151 -1 114 21 114 21 112 -18z
    m-454 -30 c0 -14 -4 -28 -10 -31 -16 -10 -12 -25 5 -19 8 4 15 3 15 0 0 -4
    -12 -11 -26 -16 -19 -8 -23 -14 -16 -23 11 -13 -7 -47 -24 -47 -5 0 -19 -12
    -31 -27 -19 -21 -26 -24 -36 -14 -9 10 -8 14 6 22 21 12 23 29 3 29 -10 0 -13
    10 -12 33 4 45 5 47 14 45 4 -2 14 3 22 9 8 7 29 24 45 37 38 32 45 32 45 2z
    m-142 -247 c-8 -19 -13 -54 -13 -78 1 -38 0 -41 -12 -25 -18 25 -13 94 7 95
    10 0 8 5 -7 16 -13 8 -23 19 -23 24 0 10 43 17 53 8 5 -3 2 -22 -5 -40z m2444
    -26 c66 -20 158 -91 196 -149 49 -77 65 -130 65 -219 0 -358 -431 -529 -675
    -267 -144 155 -140 391 10 541 106 106 256 141 404 94z m-2315 -119 c-9 -9
    -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m-1032 1 c191 -50 358 -221
    410 -420 19 -76 19 -224 0 -300 -53 -202 -216 -367 -415 -420 -70 -19 -210
    -19 -280 0 -197 52 -362 220 -414 421 -20 78 -20 210 -1 293 46 195 218 374
    406 425 72 20 220 20 294 1z m865 0 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6
    0 6 -6 -2 -15z m140 -84 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9
    -9z m67 -15 c4 -7 18 -18 31 -25 18 -10 20 -15 12 -32 -6 -11 -15 -17 -20 -14
    -5 3 -20 0 -35 -7 -19 -9 -28 -9 -37 0 -9 9 -9 12 3 12 8 0 20 10 28 21 11 19
    11 21 -2 16 -19 -7 -32 23 -15 35 18 11 26 10 35 -6z m-107 -20 c0 -2 -7 -6
    -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m140 -136 c-7
    -16 -19 -30 -26 -30 -20 0 -17 13 7 44 26 34 36 27 19 -14z m-50 26 c0 -3 -4
    -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-200 -214
    c111 -55 107 -213 -6 -264 -83 -36 -177 -1 -210 79 -19 44 -18 69 6 118 37 75
    132 106 210 67z m291 8 c25 -14 27 -56 2 -45 -36 16 -79 17 -91 2 -18 -21 -6
    -41 38 -66 22 -12 48 -34 60 -48 57 -73 -16 -156 -108 -121 -29 11 -38 20 -40
    41 l-3 27 40 -21 c39 -20 43 -20 66 -5 33 21 24 37 -47 91 -42 32 -54 48 -56
    73 -6 62 76 105 139 72z m384 -96 l90 -105 3 106 c3 103 3 106 25 103 21 -3
    22 -7 25 -145 l3 -143 -25 0 c-19 0 -45 24 -113 105 l-88 105 -3 -105 c-3
    -104 -3 -105 -27 -105 l-25 0 0 138 c0 76 3 142 7 145 18 18 45 -3 128 -99z
    m440 94 c28 -12 37 -55 9 -44 -64 25 -120 17 -158 -21 -55 -55 -24 -155 54
    -172 53 -11 84 0 88 32 3 24 0 27 -27 27 -24 0 -31 4 -31 20 0 17 7 20 50 20
    l51 0 -3 -62 -3 -63 -44 -14 c-134 -42 -254 88 -193 209 32 65 138 100 207 68z
    m300 -8 c77 -37 107 -134 62 -200 -34 -51 -72 -68 -152 -70 -38 -1 -76 -1 -82
    -1 -10 1 -13 36 -13 139 0 76 3 142 7 145 15 16 137 7 178 -13z m976 -396
    c275 -80 411 -391 284 -650 -81 -167 -279 -280 -455 -260 -278 32 -472 289
    -417 552 35 166 143 291 299 350 77 28 206 32 289 8z m-1282 -51 c36 -44 37
    -71 6 -103 -27 -28 -27 -28 -7 -39 29 -16 39 -14 45 9 4 16 14 20 52 20 55 0
    95 -21 120 -63 16 -26 16 -35 5 -86 -7 -31 -24 -67 -37 -82 -12 -14 -23 -30
    -23 -37 0 -12 -13 -16 -39 -13 -7 2 -11 -4 -8 -11 7 -20 -11 -48 -31 -48 -11
    0 -18 -8 -18 -20 0 -22 12 -27 19 -7 3 9 7 9 20 -2 9 -7 30 -11 45 -9 26 4 29
    1 30 -24 0 -22 -5 -31 -24 -38 -13 -5 -27 -17 -30 -25 -4 -8 -14 -15 -23 -15
    -9 0 -35 -11 -57 -24 -31 -17 -46 -21 -59 -13 -22 12 -89 11 -112 -1 -13 -8
    -21 -6 -30 6 -7 11 -12 12 -13 5 0 -9 -7 -10 -23 -4 -15 6 -29 4 -42 -5 -10
    -8 -34 -14 -52 -14 -24 0 -31 -4 -27 -14 4 -11 -4 -14 -35 -12 -23 1 -46 7
    -53 14 -26 26 -126 1 -129 -33 -2 -23 -4 -29 -18 -53 l-12 -23 -33 19 c-41 24
    -97 21 -128 -8 -12 -11 -25 -20 -29 -20 -4 0 -9 -10 -12 -22 -6 -21 -7 -21
    -26 -4 -13 11 -27 15 -40 11 -12 -3 -21 -2 -21 3 0 14 26 33 34 25 3 -4 19 8
    34 27 15 18 44 51 65 72 21 23 37 50 37 63 0 17 6 25 23 27 12 2 21 8 20 13
    -1 6 4 16 10 24 9 10 29 12 89 6 74 -6 78 -5 78 14 0 11 10 28 23 38 12 10 29
    27 37 38 14 19 14 20 -6 3 -11 -10 -26 -18 -33 -18 -6 0 -25 -9 -40 -21 -27
    -20 -30 -20 -58 -5 -17 8 -33 22 -37 31 -5 13 -10 14 -27 5 -11 -6 -28 -8 -37
    -5 -15 6 -15 8 3 15 16 7 17 9 3 9 -9 1 -18 6 -20 13 -3 8 -17 5 -47 -11 -43
    -22 -44 -22 -62 -3 -13 15 -15 22 -6 31 8 8 7 11 -6 11 -9 0 -17 3 -17 6 0 11
    34 33 52 34 9 0 18 4 20 8 2 4 21 17 43 28 40 20 83 83 65 94 -4 3 -8 12 -8
    20 4 62 -8 71 -58 45 -40 -21 -43 -9 -6 25 18 16 32 32 32 35 0 4 -11 16 -25
    27 -20 16 -23 23 -15 39 13 24 25 24 55 0 19 -14 21 -21 12 -30 -9 -10 -6 -10
    16 0 15 7 27 15 27 18 0 12 78 5 94 -9 16 -12 17 -11 10 16 l-7 29 27 -24 c30
    -28 36 -21 6 9 -11 11 -20 25 -20 30 0 6 81 10 224 10 l225 0 20 -27z m-1535
    -413 c305 -50 469 -386 321 -659 -33 -60 -124 -150 -183 -180 -271 -138 -594
    28 -644 333 -37 224 120 452 345 501 77 17 88 17 161 5z m1344 -229 c10 -6 10
    -10 -2 -20 -12 -10 -20 -10 -38 0 -22 12 -22 12 -3 20 26 10 28 10 43 0z m785
    -114 c99 -46 172 -118 218 -212 33 -67 34 -74 34 -185 0 -109 -2 -119 -33
    -184 -164 -347 -641 -352 -810 -7 -36 74 -37 79 -37 187 0 107 1 112 38 187
    44 89 109 159 187 201 80 43 134 56 235 53 81 -2 99 -7 168 -40z m-1108 -231
    c131 -35 247 -128 299 -241 128 -271 -41 -589 -336 -635 -294 -45 -554 216
    -507 510 40 255 303 431 544 366z"/>
    <path d="M2122 4343 l-2 -53 33 1 c29 1 30 2 7 8 -21 6 -26 15 -31 51 l-6 45
    -1 -52z"/>
    <path d="M2380 4376 c0 -7 -9 -16 -20 -19 -41 -11 -32 -67 11 -67 16 0 19 8
    19 50 0 28 -2 50 -5 50 -3 0 -5 -6 -5 -14z m0 -57 c0 -21 -7 -23 -29 -10 -12
    8 -12 12 -2 25 16 19 31 12 31 -15z"/>
    <path d="M2194 4349 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -12 -15 -15
    -28 -9 -20 -29 12 -33 24 -3 25 -1 19 30 -7 34 -33 49 -47 27z m36 -33 c0 -3
    -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
    <path d="M2261 4323 c1 -36 2 -36 8 -9 9 42 31 46 31 6 0 -18 4 -29 10 -25 22
    14 -2 65 -31 65 -16 0 -19 -7 -18 -37z"/>
    <path d="M2154 4181 c1 -30 7 -47 19 -55 29 -19 52 4 53 55 1 36 1 37 -3 9 -6
    -47 -20 -62 -43 -50 -12 6 -20 25 -23 48 -4 33 -4 32 -3 -7z"/>
    <path d="M2254 4178 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
    -4 0 -13 27 -13 35 1 4 5 -1 19 -10 29 -15 17 -15 19 2 23 15 4 16 5 1 6 -9 0
    -20 -4 -24 -11z"/>
    <path d="M2311 4176 c-9 -11 -10 -20 -1 -35 11 -21 50 -30 50 -11 0 5 -6 7
    -14 4 -8 -3 -17 -1 -21 5 -3 7 3 11 15 11 15 0 20 5 18 17 -4 23 -31 28 -47 9z
    m39 -6 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
    -10z"/>
    <path d="M2946 3857 c-18 -48 -21 -71 -5 -44 13 22 55 22 63 1 4 -9 9 -15 12
    -12 6 7 -37 98 -47 98 -3 0 -14 -19 -23 -43z m32 -16 c-15 -5 -21 7 -13 26 7
    17 8 17 15 -2 4 -11 3 -22 -2 -24z"/>
    <path d="M3080 3886 c0 -7 -9 -16 -20 -19 -41 -11 -32 -67 11 -67 16 0 19 8
    19 50 0 28 -2 50 -5 50 -3 0 -5 -6 -5 -14z m0 -51 c0 -27 -12 -32 -29 -12 -8
    10 -7 17 1 25 19 19 28 14 28 -13z"/>
    <path d="M3427 3872 c-14 -24 14 -85 30 -67 5 4 2 10 -4 12 -16 6 -17 40 -1
    45 8 3 6 7 -5 11 -9 3 -18 3 -20 -1z"/>
    <path d="M3606 3871 c-8 -12 9 -64 22 -69 7 -2 12 1 12 7 0 6 -4 11 -10 11
    -14 0 -12 38 2 42 8 3 6 7 -5 11 -9 3 -19 3 -21 -2z"/>
    <path d="M3111 3832 c1 -35 2 -36 8 -8 9 42 31 46 31 5 0 -18 3 -29 8 -25 4 4
    7 18 7 29 0 13 6 22 15 22 9 0 15 -9 15 -22 0 -12 4 -26 9 -30 4 -4 6 8 4 27
    -3 34 -4 35 -50 37 l-48 2 1 -37z"/>
    <path d="M3229 3868 c-5 -16 -3 -61 3 -64 4 -3 8 11 8 30 0 20 -2 36 -5 36 -3
    0 -5 -1 -6 -2z"/>
    <path d="M3261 3833 c1 -35 2 -36 8 -9 9 42 29 44 34 4 l5 -33 -2 35 c-1 28
    -5 36 -23 38 -21 3 -23 -1 -22 -35z"/>
    <path d="M3333 3835 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
    <path d="M3364 3858 c-4 -6 0 -16 9 -23 21 -15 22 -29 1 -21 -8 3 -12 2 -9 -4
    8 -14 32 -13 41 2 4 6 -1 17 -12 25 -16 12 -17 15 -4 23 13 8 13 10 -2 10 -9
    0 -20 -5 -24 -12z"/>
    <path d="M3481 3833 c1 -37 2 -37 8 -8 4 17 12 29 19 28 7 -2 12 2 12 7 0 6
    -9 10 -20 10 -17 0 -20 -6 -19 -37z"/>
    <path d="M3540 3861 c0 -6 7 -8 15 -5 22 9 18 -9 -5 -24 -12 -7 -18 -17 -15
    -22 10 -16 25 -11 19 5 -4 8 -1 15 5 15 6 0 11 -7 11 -15 0 -8 4 -15 9 -15 16
    0 -4 65 -22 68 -9 2 -17 -1 -17 -7z"/>
    <path d="M3663 3835 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
    <path d="M3691 3860 c0 -8 6 -26 13 -38 l13 -24 13 31 c7 18 10 34 7 37 -3 3
    -8 -5 -12 -17 l-7 -22 -14 24 c-8 14 -13 18 -13 9z"/>
    <path d="M3759 3854 c-9 -11 -10 -20 -2 -32 11 -19 53 -29 53 -13 0 6 -6 8
    -14 5 -8 -3 -17 -1 -21 5 -3 6 2 11 13 11 26 0 34 11 21 27 -15 17 -34 17 -50
    -3z m41 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
    15 -10z"/>
    <path d="M3025 3730 c-3 -6 -4 -19 0 -29 3 -11 1 -22 -5 -26 -15 -10 -12 -32
    6 -39 9 -4 22 -2 29 4 7 6 21 8 31 4 15 -5 16 -4 5 4 -12 9 -12 13 -2 26 8 10
    9 16 3 16 -6 0 -14 -5 -17 -10 -11 -18 -26 0 -15 19 14 27 -19 56 -35 31z m25
    -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
    m0 -45 c0 -8 -7 -15 -15 -15 -9 0 -12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z"/>
    <path d="M3163 3723 c-4 -10 2 -23 15 -34 28 -22 29 -43 2 -36 -11 3 -20 1
    -20 -5 0 -13 53 -6 57 9 3 7 -7 22 -21 34 -26 22 -26 45 0 35 8 -3 14 -1 14 4
    0 17 -40 11 -47 -7z"/>
    <path d="M3723 3690 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
    <path d="M3238 3705 c-2 -3 -3 -18 0 -35 3 -20 10 -30 21 -29 10 0 11 2 4 6
    -16 6 -17 43 -2 43 5 0 7 5 4 10 -7 11 -19 13 -27 5z"/>
    <path d="M3358 3705 c-2 -3 -3 -18 0 -35 3 -23 9 -30 26 -29 17 1 17 2 4 6
    -19 5 -25 43 -7 43 5 0 7 5 4 10 -7 11 -19 13 -27 5z"/>
    <path d="M3450 3700 c-12 -7 -11 -12 4 -29 17 -18 17 -19 -1 -24 -15 -4 -14
    -5 4 -6 28 -1 37 13 17 29 -11 10 -12 15 -3 20 6 4 7 10 2 13 -5 3 -15 2 -23
    -3z"/>
    <path d="M3502 3678 c2 -24 9 -34 23 -36 16 -2 17 -1 6 7 -17 11 -24 41 -10
    41 6 0 7 5 4 10 -13 21 -26 9 -23 -22z"/>
    <path d="M3303 3694 c21 -7 22 -13 0 -29 -21 -15 -9 -35 18 -28 31 9 22 57
    -11 60 -14 1 -18 0 -7 -3z"/>
    <path d="M3412 3670 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
    <path d="M3562 3670 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
    <path d="M3586 3685 c-9 -24 4 -45 28 -44 16 1 17 2 4 6 -10 2 -18 13 -18 23
    0 10 8 21 18 23 13 4 12 5 -4 6 -12 0 -24 -6 -28 -14z"/>
    <path d="M3663 3694 c21 -7 22 -20 2 -28 -24 -9 -17 -26 11 -26 22 0 25 4 22
    28 -2 21 -9 27 -28 29 -14 1 -18 0 -7 -3z"/>
    <path d="M3224 3515 l1 -50 35 0 35 0 1 50 2 50 -7 -45 c-5 -40 -9 -45 -31
    -45 -22 0 -26 5 -31 45 l-7 45 2 -50z"/>
    <path d="M3430 3501 c0 -30 24 -59 34 -43 3 6 -1 12 -9 16 -17 6 -21 36 -4 36
    5 0 7 5 4 10 -12 19 -25 10 -25 -19z"/>
    <path d="M3493 3523 c-18 -7 -16 -24 5 -39 16 -13 16 -13 -1 -14 -10 0 -16 -4
    -12 -10 8 -13 35 -1 35 16 0 8 -8 18 -17 21 -15 6 -15 7 2 13 10 4 14 9 9 12
    -5 3 -15 3 -21 1z"/>
    <path d="M3321 3488 c1 -29 2 -30 8 -9 9 34 31 39 31 7 0 -14 5 -26 11 -26 6
    0 9 12 7 28 -2 21 -9 28 -30 30 -26 3 -28 1 -27 -30z"/>
    <path d="M3390 3490 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
    -5 0 -10 -13 -10 -30z"/>
    <path d="M1060 3740 c0 -5 7 -10 15 -10 11 0 15 -11 15 -40 0 -22 5 -40 10
    -40 6 0 10 18 10 40 0 29 4 40 15 40 8 0 15 5 15 10 0 6 -18 10 -40 10 -22 0
    -40 -4 -40 -10z"/>
    <path d="M1630 3691 c0 -30 24 -59 34 -43 3 6 -1 12 -9 16 -17 6 -21 36 -4 36
    5 0 7 5 4 10 -12 19 -25 10 -25 -19z"/>
    <path d="M1158 3680 c-3 -16 -1 -30 4 -30 4 0 8 11 8 24 0 15 7 26 18 29 13 4
    13 5 -4 6 -17 1 -23 -6 -26 -29z"/>
    <path d="M1233 3703 c21 -5 23 -23 3 -23 -16 0 -31 -18 -23 -26 3 -3 17 -7 32
    -8 19 -3 25 0 21 11 -3 8 -6 23 -6 34 0 13 -7 19 -22 18 -17 -1 -19 -2 -5 -6z
    m17 -37 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
    -4z"/>
    <path d="M1281 3678 c1 -29 2 -30 8 -9 9 34 31 39 31 7 0 -14 5 -26 11 -26 6
    0 9 12 7 28 -2 21 -9 28 -30 30 -26 3 -28 1 -27 -30z"/>
    <path d="M1356 3701 c-3 -5 1 -14 9 -21 20 -17 19 -31 -2 -23 -14 4 -15 3 -7
    -6 11 -12 44 -5 44 9 0 4 -7 13 -15 20 -13 11 -13 15 -3 21 10 7 9 9 -4 9 -9
    0 -19 -4 -22 -9z"/>
    <path d="M1410 3659 l0 -51 31 30 c37 36 36 66 -4 70 -26 3 -27 2 -27 -49z
    m48 18 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z"/>
    <path d="M1492 3698 c-16 -16 -15 -23 4 -42 19 -20 29 -20 48 0 12 12 13 20 6
    35 -12 21 -40 25 -58 7z m46 -21 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0
    25 33 22 38 -4z"/>
    <path d="M1571 3673 c1 -27 3 -31 6 -13 2 14 12 31 21 38 14 11 13 12 -6 12
    -19 0 -22 -5 -21 -37z"/>
    <path d="M2464 2924 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
    <path d="M2395 2830 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
    0 -7 -4 -4 -10z"/>
    <path d="M4002 2840 c0 -8 7 -33 17 -55 l18 -40 12 38 c11 33 31 51 31 28 0
    -5 5 -22 11 -38 l11 -28 19 49 c10 28 16 52 14 55 -3 2 -11 -10 -17 -27 -15
    -40 -25 -40 -33 -2 -9 39 -21 38 -36 -5 l-13 -34 -17 37 c-10 20 -17 30 -17
    22z"/>
    <path d="M4159 2809 c-11 -7 -9 -9 6 -9 14 0 21 -7 22 -20 1 -11 -3 -20 -8
    -20 -6 0 -7 5 -3 11 4 7 1 10 -6 7 -27 -9 -22 -28 7 -28 27 0 29 3 25 29 -4
    33 -21 44 -43 30z"/>
    <path d="M4222 2788 c2 -24 8 -33 23 -35 11 -2 14 0 8 3 -7 3 -13 14 -13 25 0
    10 6 19 13 19 8 0 7 4 -3 10 -25 16 -31 11 -28 -22z"/>
    <path d="M4280 2805 c-20 -24 -8 -50 25 -52 18 -1 22 1 10 4 -34 9 -41 23 -12
    23 31 0 35 15 8 29 -14 8 -22 7 -31 -4z"/>
    <path d="M4349 2798 c0 -7 -2 -21 -4 -30 -2 -10 1 -18 6 -18 5 0 9 11 9 24 0
    15 7 26 18 29 13 4 11 5 -5 6 -13 0 -23 -5 -24 -11z"/>
    <path d="M426 2584 c-21 -21 -20 -47 3 -68 26 -24 65 -17 69 12 2 15 -2 22
    -14 22 -13 0 -14 -3 -5 -14 15 -18 -1 -30 -28 -21 -22 7 -33 29 -25 50 7 18
    48 30 65 19 8 -4 9 -3 5 4 -10 17 -52 15 -70 -4z"/>
    <path d="M942 2550 c0 -39 2 -47 7 -26 9 41 31 46 31 7 0 -17 5 -31 10 -31 11
    0 13 30 4 54 -3 9 -14 16 -24 16 -10 0 -21 8 -23 18 -3 9 -5 -8 -5 -38z"/>
    <path d="M1183 2550 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
    <path d="M520 2550 c-9 -16 -8 -25 1 -36 13 -16 49 -19 49 -4 0 5 -6 7 -14 4
    -8 -3 -17 -1 -21 5 -3 7 3 11 15 11 15 0 20 5 18 17 -5 26 -35 28 -48 3z m35
    0 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z"/>
    <path d="M589 2554 c-17 -21 0 -49 32 -49 18 0 25 6 27 24 6 38 -34 55 -59 25z
    m48 -10 c9 -23 -13 -40 -32 -24 -12 10 -13 16 -4 26 15 18 28 18 36 -2z"/>
    <path d="M679 2568 c-1 -2 -3 -23 -4 -48 -2 -50 9 -64 39 -48 20 11 20 11 -2
    28 -18 15 -19 19 -7 24 23 8 19 46 -5 46 -11 0 -21 -1 -21 -2z m25 -29 c-3 -5
    -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m6 -49 c0 -5
    -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
    <path d="M741 2530 l1 -35 7 30 c4 17 12 29 19 28 7 -2 12 1 12 6 0 6 -9 9
    -20 8 -16 -1 -20 -8 -19 -37z"/>
    <path d="M800 2560 c0 -5 7 -7 15 -4 8 4 15 2 15 -4 0 -5 -7 -12 -15 -16 -25
    -9 -18 -36 10 -36 21 0 25 4 23 23 -2 12 -7 28 -11 35 -10 14 -37 16 -37 2z
    m30 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
    -10z"/>
    <path d="M866 2562 c-8 -14 -4 -92 5 -92 5 0 9 7 9 15 0 8 7 15 15 15 21 0 35
    32 24 53 -10 17 -44 23 -53 9z m39 -27 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13
    22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"/>
    <path d="M1017 2563 c-11 -10 -8 -63 3 -63 6 0 10 16 10 35 0 36 -2 40 -13 28z"/>
    <path d="M1049 2554 c-16 -19 -1 -47 27 -52 16 -3 16 -1 -4 13 -18 13 -21 20
    -11 31 6 7 15 11 20 8 5 -3 9 0 9 5 0 16 -26 13 -41 -5z"/>
    <path d="M1115 2561 c-3 -7 0 -8 9 -5 9 3 16 2 16 -4 0 -5 -7 -12 -15 -16 -25
    -9 -18 -36 11 -36 23 0 25 3 22 32 -3 33 -29 50 -43 29z m30 -41 c-3 -5 -11
    -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
    <path d="M602 2378 c1 -52 2 -52 5 -10 l4 43 39 -43 39 -43 -1 55 c-1 52 -1
    53 -7 15 l-6 -40 -29 38 c-15 20 -32 37 -37 37 -5 0 -8 -24 -7 -52z"/>
    <path d="M710 2391 c0 -6 7 -8 15 -5 8 4 15 2 15 -4 0 -5 -7 -12 -15 -16 -25
    -9 -18 -36 11 -36 23 0 25 3 22 33 -2 24 -8 33 -25 35 -13 2 -23 -1 -23 -7z
    m30 -41 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
    -10z"/>
    <path d="M782 2362 l1 -37 4 33 c5 40 26 38 32 -4 l4 -29 6 29 c8 42 29 44 34
    4 l5 -33 -2 35 c-1 33 -3 34 -43 37 l-43 3 2 -38z"/>
    <path d="M897 2393 c-4 -3 -7 -17 -7 -29 0 -27 36 -43 54 -24 8 9 6 11 -11 6
    -12 -4 -24 -2 -28 4 -4 6 5 10 20 10 21 0 26 4 21 16 -3 9 -6 18 -6 20 0 7
    -36 5 -43 -3z m33 -13 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10
    5 0 9 -4 9 -10z"/>
    <path d="M964 2388 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15 -4
    0 -13 27 -13 35 1 4 5 -1 19 -10 29 -15 17 -15 19 2 23 15 4 16 5 1 6 -9 0
    -20 -4 -24 -11z"/>
    <path d="M1724 2600 c-36 -14 -64 -59 -64 -102 0 -87 109 -131 179 -72 93 78
    -1 220 -115 174z"/>
    <path d="M3120 2506 l0 -106 54 0 c73 0 106 27 113 91 5 40 2 50 -23 78 -24
    27 -38 33 -86 38 l-58 6 0 -107z"/>
    <path d="M3962 1773 l-2 -53 32 0 c17 0 28 3 24 7 -4 4 -15 8 -24 8 -13 0 -19
    12 -23 45 l-6 45 -1 -52z"/>
    <path d="M4210 1806 c0 -7 -9 -16 -20 -19 -27 -7 -27 -53 0 -62 11 -3 24 -3
    30 0 13 8 13 95 0 95 -5 0 -10 -6 -10 -14z m6 -49 c3 -21 -8 -28 -25 -17 -16
    9 -6 43 11 38 6 -2 12 -12 14 -21z"/>
    <path d="M4030 1781 c0 -6 7 -8 15 -5 8 4 15 2 15 -4 0 -5 -7 -13 -16 -16 -22
    -8 -12 -31 15 -32 19 -1 22 3 19 30 -2 23 -8 32 -25 34 -13 2 -23 -1 -23 -7z
    m35 -41 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9
    -10z"/>
    <path d="M4102 1753 c1 -38 1 -38 5 -5 5 40 25 38 35 -4 6 -27 6 -27 5 6 -2
    28 -6 36 -24 38 -21 3 -23 -1 -21 -35z"/>
    <path d="M1039 1364 c-12 -14 -10 -19 14 -38 34 -27 35 -40 1 -32 -17 5 -23 3
    -19 -4 3 -5 17 -10 31 -10 31 0 33 33 3 54 -24 16 -17 39 8 29 11 -4 14 -3 9
    5 -10 17 -31 15 -47 -4z"/>
    <path d="M1110 1321 c0 -31 24 -59 35 -41 3 5 1 10 -4 10 -6 0 -11 9 -11 20 0
    11 5 20 11 20 5 0 7 5 4 10 -13 21 -35 10 -35 -19z"/>
    <path d="M1364 1334 c3 -9 6 -24 6 -35 0 -12 7 -19 18 -18 10 0 12 3 5 6 -16
    6 -17 43 -2 43 5 0 7 5 4 10 -10 17 -38 12 -31 -6z"/>
    <path d="M1162 1310 c-1 -16 2 -30 8 -30 5 0 10 11 10 24 0 15 7 26 18 29 11
    3 9 5 -8 6 -22 1 -26 -4 -28 -29z"/>
    <path d="M1222 1313 c2 -22 9 -29 31 -31 25 -3 27 -1 26 30 -1 29 -2 30 -8 9
    -9 -34 -31 -39 -31 -7 0 14 -5 26 -11 26 -6 0 -9 -12 -7 -27z"/>
    <path d="M1300 1325 c-16 -20 4 -46 34 -44 20 2 20 2 -1 6 -27 5 -31 39 -5 46
    14 4 14 5 1 6 -9 0 -22 -6 -29 -14z"/>
    <path d="M1421 1313 c-1 -29 2 -33 24 -33 22 0 25 4 24 33 -2 30 -2 30 -6 5
    -2 -16 -10 -28 -18 -28 -8 0 -16 12 -18 28 -4 25 -4 25 -6 -5z"/>
    <path d="M1491 1308 c1 -29 2 -30 8 -9 3 13 15 28 26 32 17 6 16 8 -7 8 -25 1
    -28 -2 -27 -31z"/>
    <path d="M1552 1313 c2 -22 9 -28 28 -30 22 -1 22 -1 3 4 -31 6 -29 23 2 23
    18 0 23 4 19 15 -4 8 -17 15 -31 15 -21 0 -24 -4 -21 -27z m35 11 c-3 -3 -12
    -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
    <path d="M1620 1329 c0 -7 8 -18 18 -25 16 -13 16 -13 -1 -14 -10 0 -16 -4
    -12 -10 8 -12 35 -1 35 15 0 7 -8 16 -17 20 -17 7 -16 8 2 15 19 8 19 9 -2 9
    -13 1 -23 -4 -23 -10z"/>
    <path d="M3090 1009 l0 -50 33 3 c29 3 32 6 32 38 0 44 -11 60 -41 60 -22 0
    -24 -4 -24 -51z m45 21 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13
    18 6 -3 12 -11 12 -18z m13 -47 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18
    31 15 38 -3z"/>
    <path d="M3280 1010 c0 -27 5 -50 10 -50 6 0 10 23 10 50 0 28 -4 50 -10 50
    -5 0 -10 -22 -10 -50z"/>
    <path d="M3360 1041 c0 -13 -8 -21 -22 -23 -17 -2 -23 -10 -23 -28 0 -20 6
    -26 28 -28 26 -3 27 -2 27 47 0 28 -2 51 -5 51 -3 0 -5 -9 -5 -19z m0 -51 c0
    -21 -24 -28 -35 -10 -8 14 3 30 21 30 8 0 14 -9 14 -20z"/>
    <path d="M3181 993 c-1 -29 2 -33 24 -33 22 0 25 4 24 33 -2 30 -2 30 -6 5 -2
    -16 -10 -28 -18 -28 -8 0 -16 12 -18 28 -4 25 -4 25 -6 -5z"/>
    <path d="M3250 990 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
    0 -10 -13 -10 -30z"/>
    <path d="M3392 990 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
    <path d="M3421 988 c1 -29 2 -30 8 -9 9 34 31 39 31 7 0 -14 5 -26 11 -26 6 0
    9 12 7 28 -2 21 -9 28 -30 30 -26 3 -28 1 -27 -30z"/>
    <path d="M3501 993 c1 -16 -1 -37 -3 -48 -2 -16 3 -20 22 -20 16 0 26 6 28 18
    2 10 -3 17 -12 17 -20 0 -21 16 -1 24 25 9 18 36 -10 36 -20 0 -24 -5 -24 -27z
    m29 7 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
    -10z m5 -60 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
    9 -10z"/>
    <path d="M3566 1011 c-3 -5 1 -15 10 -22 14 -12 14 -14 -2 -19 -15 -6 -15 -7
    -2 -12 8 -4 21 -2 29 2 11 7 10 12 -5 28 -13 15 -14 21 -4 25 9 4 7 6 -4 6 -9
    1 -19 -3 -22 -8z"/>
    <path d="M2099 744 c-11 -26 -17 -49 -14 -52 2 -3 7 3 11 12 8 21 50 21 63 -1
    6 -11 11 -13 11 -5 0 15 -38 92 -46 92 -2 0 -14 -21 -25 -46z m41 -6 c0 -5 -7
    -8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
    <path d="M2230 776 c0 -7 -9 -16 -20 -19 -41 -11 -32 -67 11 -67 16 0 19 8 19
    50 0 28 -2 50 -5 50 -3 0 -5 -6 -5 -14z m0 -51 c0 -27 -12 -32 -29 -12 -8 10
    -7 17 1 25 19 19 28 14 28 -13z"/>
    <path d="M2310 776 c0 -8 -10 -16 -22 -18 -42 -6 -30 -68 13 -68 16 0 19 8 19
    50 0 28 -2 50 -5 50 -3 0 -5 -6 -5 -14z m-3 -42 c8 -20 -8 -36 -26 -25 -15 10
    -8 41 9 41 6 0 13 -7 17 -16z"/>
    <path d="M2341 720 c1 -27 3 -29 6 -10 2 14 11 30 20 37 13 10 13 12 -6 10
    -17 -1 -21 -7 -20 -37z"/>
    <path d="M2400 746 c-24 -29 17 -73 43 -47 9 9 8 11 -4 6 -10 -4 -20 -2 -24 4
    -3 7 3 11 15 11 15 0 20 5 18 17 -4 23 -31 28 -48 9z m35 -6 c3 -5 -1 -10 -9
    -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z"/>
    <path d="M2466 751 c-3 -5 1 -14 9 -21 20 -17 19 -31 -2 -23 -11 4 -14 3 -9
    -5 4 -7 13 -12 21 -12 24 0 29 22 9 37 -16 12 -17 15 -4 23 13 8 13 10 -2 10
    -9 0 -19 -4 -22 -9z"/>
    <path d="M2524 748 c-4 -6 0 -16 9 -23 21 -15 22 -29 1 -21 -8 3 -12 2 -9 -4
    9 -15 25 -12 32 6 3 9 0 20 -8 25 -12 8 -12 11 1 19 13 8 13 10 -2 10 -9 0
    -20 -5 -24 -12z"/>
    </g>
    </svg>
  `,
  subscriptionRequired: true,
  licencePdf: '/help/copyright-and-licensing/os_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/os_eula/embeddable/',
  appGroups: [],
};
