import { Collection } from '../collection';

/* eslint-disable max-len */

export const NTM_COLLECTION: Collection = {
  id: 'ntm',
  name: 'National Tree Map',
  icon: `
<?xml version="1.0" encoding="utf-8"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="284.0014 -24.8982 1991.9987 1650.4712" enable-background="new 284.0014 -24.8982 1991.9987 1650.4712"
  xml:space="preserve">
<g>
 <path fill="#010101" d="M2204.5391,1551.95"/>
 <path fill="#010101" d="M2200.8662,1555.5739"/>
 <path fill="#010101" d="M2239.6616,1578c-4.9849-6.2275-10.2566-12.244-15.749-18.0358c-0.1338,2.079-0.2307,4.1821-0.292,6.2976
  c-0.802-0.0609-2.4189-0.1945-3.2217-0.2554c-0.2065-3.1002-0.1216-6.2125-0.5713-9.252c-3.781,3.392-4.79,10.0665-10.4675,10.9053
  c-0.4133-3.1365-0.3162-6.2853-0.6079-9.3977c-0.1184-0.9427-3.3528-1.2556-5.9534-1.3558l0.0439,2.2943l-1.2698-2.3298
  c-1.1726-0.0208-2.0171-0.0037-2.0171-0.0037l1.3103-1.2932l-2.4756-4.5427l-4.1304,0.1172l-4.043,2.3658l-4.4385-3.7397
  l-4.3464,6.3909l-3.6672-2.2084l-3.1514-7.9828l-4.5195,10.9006l-3.5015-2.4001l-5.113-6.2736l-5.4812-2.8535l-4.3447-3.4548
  l-4.3289,3.1046l-4.4812,5.6155l-4.2336-1.4697l-4.2048-1.6993l-4.6533,6.1954l-3.8149-8.0389l-4.26-0.488l-3.9685-4.7957
  l-5.0486,10.8452l-4.1458-2.2925l-3.9727-4.8008l-4.2542-0.8323l-4.9236,9.4318l-4.4292,2.4236l-3.5093-13.3934l-4.9578,12.4802
  l-3.8186-9.7399l-4.7847,5.8236l-4.2456-5.5524l-4.5435,0.4744l-4.6152,1.7833l-4.6948,3.4519l-4.1206-8.1658l-4.4741-1.4149
  l-4.796,6.8805l-4.1561-11.5859l-4.6342,2.2372l-4.6469,7.5605l-4.4849,1.6134l-4.6189-9.9053l-4.3485,8.3817l-4.5209-0.8326
  l-4.3167,2.8344l-3.6187,3.7352l-4.3104-8.4818l-3.4935,7.6458l-4.0005-3.528l-4.0459-10.2417l-3.6802,6.2047l-3.8773,8.839
  l-3.7147-7.8069l-5.053,3.0059l-5.3677,3.2377l-2.8015-11.8378l-8.1626-2.7975l-5.5002,6.4229l-0.2963,4.5754l10.7465,5.2957
  l-6.0471,5.6132c0,0-3.8091,0.9414-5.6205-0.3713c-2.3585-1.1553-4.6683-3.4894-7.4525-2.2737
  c-3.5986,1.1306-7.3308,0.3162-10.978,0.6809c-2.1885,0.7902-3.4651,3.0272-5.5684,3.8782
  c-2.1154,0.1826-2.7598-2.2489-3.4771-3.7081c-1.5925-4.5712-4.3523-8.5951-7.5618-12.1575
  c-1.386,4.0608-0.9119,8.3889-1.2645,12.6075c-1.6168,1.1427-3.2703,2.2367-4.8873,3.3555
  c0.0487-5.4222-0.1821-10.796-0.6321-16.1818c-1.1428,0.0487-3.4041,0.1461-4.5349,0.1945
  c0.5107,3.1489,1.6172,6.2491,1.3132,9.5072c-2.687,0.5349-5.7627-0.0121-8.0117,1.7993c-2.8572,1.8845-4.0243,5.24-5.7749,8.0363
  c-5.5073-4.1703-8.4861-10.9905-8.4252-17.8231c-3.4042,1.6658-7.3795,3.7689-8.1698,7.854
  c-0.7416,3.2217,2.5287,6.8445-0.3892,9.5192c-3.4771-2.9299-4.717-7.7928-8.7776-10.0786
  c-0.8269,1.8479-1.6169,3.7324-2.395,5.6168c-3.4526-4.6442-3.5135-12.0239-10.1758-13.738
  c-0.3406,3.5864,0.1824,7.1971-0.1582,10.8082c-2.8206-4.0851-6.3583-9.422-3.7687-14.431
  c2.2003-6.2004,9.0817-9.033,11.6346-15.0146c1.1064-3.0756,1.8966-6.2367,2.8447-9.349
  c-2.7354,0.9482-5.3977,2.4923-8.3765,2.1154c0-0.7415,0-2.249,0.0122-2.9907c4.9967-3.6837,11.6592-7.5376,11.5496-14.6498
  c-5.8599-1.4468-10.2002,4.2672-15.9869,2.9906c1.7749-4.2064,3.6593-10.2607,8.9479-10.7592
  c3.5986-0.8752,8.3644-0.3892,10.5161-4.0486c2.1276-2.4436,0.9482-5.9205,1.1064-8.802
  c-2.5531,0.1582-5.0818,0.4379-7.5985,0.6566c0.4133-4.1821,0.9484-8.4373-0.243-12.5466
  c-5.0211-1.0089-10.3583,0.7174-13.8352,4.4255c-0.3162,2.8446-0.1094,5.7261-0.2433,8.5951
  c-4.705-0.3523-9.2032,1.1552-13.1787,3.5745c-0.3405,4.3279,5.9329,3.9756,8.5345,6.3219
  c4.9846,2.3341-0.1094,7.8536-0.7903,11.4158c-5.3492-1.325-10.2852-4.4252-15.9141-4.3401
  c0.8632,7.8538,8.7048,12.1576,15.2334,15.0996c-0.0487,1.5319-0.0852,3.0758-0.1096,4.6442
  c-2.4314,0.2552-4.9236,0.2552-6.9175-1.3252c-3.4163-2.3463-7.6106-2.9907-11.6348-2.2007
  c2.0425,7.4285,10.6257,9.4951,14.978,15.2334c0.6808,5.5682,0.3162,11.2578-0.4011,16.826
  c-1.5197,3.4647-6.176,3.6838-9.3369,4.863c-0.7781-4.3159,1.0454-9.3494-2.0304-12.9844
  c-2.0059-2.8568-4.3645-5.5801-7.6349-6.9906c0.5957,4.1946,3.1488,9.9329-1.8844,12.4857
  c-5.5195-4.6804-5.9207-12.3518-6.5529-19.0262c-0.3162-6.8083-3.6472-12.9355-6.9906-18.6863
  c-0.389,0.4135-1.1671,1.2401-1.5563,1.6536c-0.7051,4.7291-0.1458,9.5437-0.2795,14.3094
  c-1.5197,0.3647-3.0393,0.6808-4.5469,0.9362c-0.8267-7.1853-0.8025-14.9417,3.3069-21.1421
  c2.5652-2.2855,5.6411-3.9147,8.1212-6.2975c2.4923-2.3828,4.948-5.1061,8.4615-5.9205c2.6382-0.6931,5.0332-2.2128,6.7109-4.3768
  c0.1216-2.2006-0.3162-4.3646-0.4863-6.5166c4.0972,0.3405,12.0968,2.3832,12.1453-3.9752
  c1.2645-4.2916-4.0605-5.4346-6.0543-8.2184c3.55-3.8785,5.9572-9.1304,5.0089-14.4799c-2.3464-1.4954-4.7292-3.2825-7.5376-3.635
  c-2.4194-0.6566-3.6229,1.9331-4.9116,3.465c-1.5684,2.5286-0.9363,7.3916-4.9968,7.0876c-1.6777-3.015-3.0393-6.2975-5.8113-8.486
  c-1.7871-1.7141-4.4739-4.6561-6.9662-2.468c-3.234,2.0427-2.6017,6.2979-2.5774,9.5925c0.7294,2.6501,2.5287,4.8873,3.2825,7.5741
  c-3.5621,0.4618-6.9419,1.6897-10.431,2.4071c-0.693-3.6959-1.8359-7.2944-2.2614-11.0026
  c0.4376-3.8052,2.6747-7.0876,3.8418-10.6743c-5.8478,0.4012-10.9054,5.2156-13.203,10.3947
  c-2.0425,4.8387,2.8936,8.8142,2.1276,13.6163c-1.3009,2.1763-3.1246,3.9877-4.9117,5.787
  c-0.6443-6.7593,1.4226-13.9811-1.8601-20.2664c-2.5774-4.8875-1.7384-10.5161-2.0789-15.817
  c5.1548-0.6443,10.1515,2.9299,15.1604,0.6079c4.1943-0.6687,6.8933-6.176,4.6807-9.8112
  c-1.1063-3.4769-5.2034-3.8539-7.9509-5.3978c2.7595-3.6107,8.0967-5.9692,8.0239-11.1362
  c0.3889-3.9633-0.0244-11.0513-5.6289-10.2609c-4.0972,0.8632-8.8994,2.869-9.7017,7.5376
  c-0.839,0.0122-2.541,0.0244-3.3921,0.0365c-1.2036-3.4285-3.4404-6.9299-7.4282-7.2096c-3.4648,2.079-6.5164,5.2157-8.036,8.9846
  c0.7902,3.088,2.1761,5.9935,3.3555,8.9598c-2.6139,1.228-5.9816,1.8845-7.3553,4.7417
  c-2.468,4.4495-0.5107,10.8566,4.5713,12.3518c2.5895-0.6687,5.1547-1.5319,7.8173-1.9937
  c0.5227,4.1821,1.2399,8.4008,0.9481,12.6559c-6.3826,1.5439-11.6467-4.8873-18.1145-3.8296
  c-3.7932,2.2125-8.0361,5.714-7.1973,10.65c0.5349,6.2123,2.3706,14.5282-4.2307,18.1997
  c-3.623-7.9753-4.5348-16.7896-5.2886-25.3971c0.0486-8.1455-1.5928-16.1818-1.1915-24.3271
  c0.3038-5.41-0.5836-10.9053,0.7296-16.2179c1.1548-5.8966,0.1337-11.9022-0.4742-17.7866
  c-0.4135-6.103-0.6078-12.2184-1.2035-18.2849c0.6929-12.9354,0.9602-25.9318,0.5713-38.8796
  c0.3768-6.2366,0.0729-12.4736-0.7903-18.6372c-0.8997-6.407,1.3495-12.6804,0.9241-19.0631
  c-0.377-7.7563,1.5684-15.3669,1.2886-23.0992c-0.231-6.1519,1.3738-12.1332,1.6536-18.2363
  c0.1337-4.6926,2.7961-9.191,1.5317-13.8959c-1.5439-8.2306-1.4954-16.6315-2.0181-24.9471
  c-1.7264-8.4738-0.5229-17.13-1.0093-25.6766c-1.7992-15.282-8.5952-29.5062-9.7622-44.8976
  c-0.7539-8.0726,4.9358-14.431,8.7046-20.9717c3.6594-6.48,10.9054-11.7806,18.6619-9.8962
  c6.8567,2.699,13.1057,7.2944,17.2635,13.4584c1.3373,4.012,0.6079,8.5223-2.7111,11.3429
  c-6.5164,6.6016-11.9995,15.5009-10.0908,25.166c6.2368-0.1215,12.8384-2.5652,17.0449-7.3309
  c2.7476-5.2885,1.5927-11.817,4.9723-16.8746c5.3494,4.9238,9.422,13.8838,4.5712,20.4733
  c-4.7899,5.714-10.7837,10.808-13.2759,18.0538c-0.6321,2.9786-0.6078,6.0544-0.2432,9.0815
  c5.6411,0.2797,9.8596-3.9512,13.1545-7.9874c0.5835-2.9178,0.8022-5.8964,1.5559-8.7777c0.7418,0.0244,2.225,0.0608,2.9666,0.073
  c2.2369,3.1001,2.7598,7.1,3.939,10.6986c-0.8752,7.3794-6.3218,12.9478-8.0117,20.0598c-1.6169,6.6381,1.313,13.1422,3.8175,19.16
  c1.6047,0.7538,3.4648,0.4984,5.2155,0.6565c2.1033-5.5681,7.1365-9.5072,8.5833-15.3914
  c2.8691-6.48,1.7144-14.2972-2.1154-20.1328c-2.9178-4.6442-5.0089-9.7502-6.1884-15.0996c1.0579,0.0973,3.1733,0.304,4.231,0.4012
  c4.2429,4.1578,7.1243,9.9448,12.8505,12.4006c3.6107,1.9817,9.8597,2.4923,11.5253-2.2612
  c-1.9452-6.0787-5.2155-12.1453-10.7107-15.7074c-5.9572-3.9878-12.887-7.2338-16.7773-13.5435
  c-3.2461-5.3979-7.2094-10.3826-9.7988-16.1453c6.3705-1.009,12.5342,4.4618,18.7954,1.6656
  c2.9421-2.2734,7.7443-5.5438,5.7383-9.8719c-3.6838-4.4861-9.5801-5.7748-14.9902-6.6987
  c-5.4829-0.8754-10.3824,2.8569-15.7803,2.6139c-2.2734-1.1307-3.4407-3.4771-5.0576-5.2643
  c-3.1002-3.9147-7.8295-5.9572-11.3672-9.3612c-0.1338-0.7052-0.4135-2.1033-0.5471-2.8085
  c4.2188-2.5287,9.1301-5.1547,14.2122-3.9512c3.5498,0.8024,7.2944,0.5714,10.7715,1.8358
  c5.4465,1.5441,9.9205,5.3737,15.4521,6.7474c8.3765,2.8083,17.458-1.7993,25.6038,2.2249
  c4.4982,0.7659,8.3279,4.9237,7.4646,9.6774c-2.0547,6.0179-6.6987,11.0997-7.4282,17.5918
  c-0.231,4.2795,0.9969,8.4617,1.6898,12.6681c4.2916-1.1428,6.8568-5.0818,9.6896-8.1819
  c3.2947-4.085,2.1884-9.4707,2.1761-14.2729c5.8478-0.5106,10.6743,6.2125,9.8719,11.7198
  c-1.6899,3.5135-3.3796,7.1365-3.1001,11.1727c-0.4014,5.556,4.6563,9.2032,5.7869,14.3701
  c0.4863,0.0244,1.4468,0.073,1.9331,0.0973c1.3738-2.9542,3.1244-5.7018,4.863-8.4495c1.0454-5.0818-4.4983-9.8354-0.9363-14.7714
  c2.8691,2.1519,5.6047,4.4861,8.2915,6.8811c0.4984,4.6077-0.9849,9.8232,2.2004,13.7379
  c2.5288,4.9603,8.3401,6.2368,12.4858,9.4221c1.933,1.5682,4.5225,1.4224,6.8933,1.8358
  c-0.1824-4.1337,0.6929-8.486-1.0334-12.3763c-1.6292-3.5258-3.9998-6.6622-6.6866-9.4221
  c-3.6594-3.9512-9.0573-5.4222-13.2881-8.5588c-2.2128-1.6412-3.3798-4.1699-4.7292-6.4556
  c4.2794,0.1581,8.8141-0.6929,12.8868,1.082c3.0881,1.0577,6.0059,3.0028,9.4343,2.5288c1.167-6.7717-6.3462-12.51-12.6682-12.1941
  c-5.3007-0.6199-10.3823,1.386-15.6101,1.3253c-3.3799-0.7052-8.2551-4.547-5.2399-8.1091
  c4.0605-1.5562,8.3885,0.693,12.2668,1.9209c5.0939-2.1761,9.8231-5.6532,12.0967-10.8809
  c-6.6989-2.5045-13.1787-7.1001-20.6312-6.1517c-4.7537,0.085-8.7412,3.3798-13.4098,3.8052
  c-4.097-0.6809-7.0391-4.0485-10.6985-5.7627c0.936-4.9116,7.0391-3.8661,10.0177-6.7839
  c4.8022-4.0848,10.8323-7.3674,17.2759-7.2214c4.6077,1.5682,5.1669,7.6227,8.8141,10.5283
  c6.2977,5.1061,14.4918,6.8081,21.7863,10.0542c0.1216-6.2124-3.4285-11.7076-6.4922-16.838
  c-4.0483-4.3524-10.0056-6.1031-15.0995-8.8872c1.9208-4.1213,6.5408-6.2367,10.9661-5.9449
  c2.9664,0.2917,6.9784-0.0973,8.4738,3.1852c2.553,4.5835,6.5773,7.9875,10.1515,11.7563c2.4801,2.4801,4.3888,6.103,8.2672,6.4921
  c0.2551-4.2065,0.9117-8.498,0.158-12.6802c-2.3951-4.3524-6.6987-7.0027-11.1119-8.9115c0.1337-0.9969,0.4133-2.9907,0.547-3.9877
  c4.4011-0.7781,9.641-1.6049,13.0693,2.0424c3.1002,3.392,7.8901,3.2339,11.8901,4.9116c4.1578-3.6472,10.8079-3.4769,13.8595-8.34
  c-5.7992-3.1367-10.723-7.7079-16.6923-10.4434c-3.0273-1.5682-6.3098-0.1094-8.8628,1.6292
  c-3.6716,2.772-8.3523,3.319-12.6438,4.5956c-2.0911-0.6444-5.4343-0.9362-4.2917-3.939
  c0.8997-4.8752,7.1609-3.7324,10.5164-5.7627c2.2856-2.6505,4.1823-5.7992,5.1548-9.1547
  c-0.073-3.9026-0.3768-7.7808-0.3647-11.6711c-1.1793-0.0486-2.3342-0.0243-3.465,0.073
  c-5.7626,3.3798-10.662,7.9874-15.598,12.437c-4.6805,4.8022-3.9146,12.036-6.407,17.7986
  c-4.1821,2.2979-8.5468,4.2551-12.887,6.2125c-2.6382-6.6136,3.5258-11.1119,6.2368-16.3761
  c0.1216-2.2979-0.1216-4.6077,0.0972-6.8811c1.6536-2.152,4.085-3.4771,6.1761-5.1548
  c7.2701-5.3737,10.8201-14.1877,17.6891-19.9625c5.9816-4.9603,11.6711-10.5406,18.6497-14.1027
  c4.9359-0.4255,9.811-1.6292,14.7834-1.8602c6.9055,0.304,14.7227-1.313,20.6677,3.1974c2.2371,2.9786,4.158,7.015,2.6139,10.723
  c-3.2825,5.7262-11.6591,5.3372-14.285,11.5981c-2.699,6.4071-5.5437,13.045-5.9694,20.0842
  c5.3737-0.7052,9.8112-4.0972,14.0419-7.2216c2.7234-4.9602,4.9968-10.1758,7.9512-15.0144
  c0.6929-1.5684,2.4922-1.4833,3.9146-1.8602c2.3101,6.9541,2.6625,14.4431,1.7507,21.6647
  c-3.8904,4.9725-11.7563,5.7019-13.9568,12.0968c-2.0425,5.3978-5.7262,10.0664-7.2458,15.671
  c3.1244,0.0243,6.3584-0.0852,9.3247-1.2887c7.1244-2.5774,7.6958-13.4219,15.6104-14.5282
  c0.5835,3.1852,2.3706,6.6622,0.5348,9.7867c-1.7871,5.1061-6.6014,8.0483-9.8961,12.0602
  c-1.8601,2.3707-1.5684,5.787-0.4377,8.4373c2.2371,5.6046,3.9147,11.4159,5.7263,17.1786
  c2.0424-1.459,3.8782-3.2461,4.9602-5.5681c2.8447-5.6046,7.0514-10.4799,9.2031-16.437
  c0.9362-8.3644-3.9875-16.5342-2.4072-24.8256c4.6807-0.3405,5.9086,5.4708,7.9268,8.7291
  c3.2583,1.9816,6.8569,3.5377,10.7837,2.8083c3.3069-0.2917,9.7261,0.4498,9.5801-4.3524
  c-3.7444-6.1273-8.96-11.4889-15.2698-14.9659c-4.6199-2.0424-12.4128-0.6322-14.0176-6.6622
  c-1.1306-5.6897-4.1335-10.9053-4.0848-16.8138c2.7233,0.377,6.1151-0.7538,8.3035,1.4468
  c4.1942,3.0515,7.1608,7.3796,11.1364,10.6743c1.5803-9.9084-4.012-19.8533-12.1697-25.2146
  c-4.0728-1.6656-8.4858-2.1276-12.6316-3.4042c-3.7203-2.3342-6.334-6.5286-5.9573-11.0146
  c1.6049-0.2188,3.2218-0.4498,4.863-0.7051c9.7625-2.4923,20.1085-1.0213,29.6521,1.8114
  c4.8508,1.5562,7.1121,6.4556,10.4919,9.8476c3.5378,3.4528,7.3553,6.7231,11.9022,8.802c3.0758,1.4711,5.4586,4.097,8.7047,5.325
  c3.9634-2.9907,0.1946-8.2549-1.1184-11.817c-2.4802-6.5772-12.4008-4.3767-14.6499-10.7593
  c7.7444-2.2735,17.0449-3.8052,23.6584,2.0181c3.2339,2.0911,1.3131,6.018,1.3616,9.0816
  c0.3162,6.0422,4.6321,10.6259,7.5742,15.5859c1.9087-0.62,3.8176-1.313,5.7627-1.9573
  c-0.0486-3.3555,0.1824-6.7231-0.1824-10.0543c-1.4954-3.9877-4.5104-7.2823-5.325-11.5253
  c6.3705-0.9726,12.656,3.477,16.0115,8.6804c3.8904,7.2095,12.4978,10.03,20.084,11.3552
  c-0.1094-4.17,0.1216-8.6198-1.8843-12.4128c-2.1519-3.9512-6.5894-5.5682-9.7625-8.486
  c-2.3828-1.6292-4.3162-4.7415-7.5498-4.2795c-5.1184,0.2554-10.2367-0.0972-15.1483-1.5804
  c-0.0607-1.386-0.0973-2.7719-0.0973-4.1456c4.2917-1.4954,9.3494-1.2887,13.1423-3.9755
  c3.1731-3.6838,6.6987-8.3766,5.7871-13.507c-0.9849-4.0242-5.7263-3.1974-8.6929-2.3586
  c-4.7048,0.0973-7.9631,3.7567-11.5374,6.2975c-3.9026,2.8084-5.325,7.5863-8.0118,11.3552
  c-1.0211,1.4711-2.942,1.8965-4.6075,1.8115c-5.6168-0.0729-11.2094,1.0698-16.814,0.4741
  c-0.4983-3.0515,2.3586-4.4131,4.1702-6.2368c2.0667-1.9817,4.0361-4.1093,5.7017-6.4556
  c1.6899-4.0607,1.5076-8.5832,1.6534-12.8869c-4.5833,0.5471-9.1544,1.1914-13.7743,1.1307
  c-2.3101-3.2947-4.4011-6.7839-7.2825-9.6044c-3.6836-3.3676-8.3521-5.5681-11.4888-9.5193
  c2.9907-2.0546,6.0544-4.2186,9.7261-4.8386c4.5955-0.9484,8.7776-3.2096,13.3246-4.2916c3.0028,1.6534,5.325,4.2551,8.1454,6.2125
  c2.7598,1.4832,5.9816,1.7628,9.0453,2.3464c5.3129-0.924,10.6134-2.9664,15.0145-6.1395c2.2249-1.1914,2.322-4.5225,0.2917-5.945
  c-5.4343-4.8873-13.4827-6.6137-20.4731-4.6928c-2.0789,1.0697-2.5046-2.5046-1.2523-3.4528
  c2.3344-2.9178,5.1061-5.5438,8.2794-7.5498c5.3491-1.8602,11.1604-0.7538,16.3882,1.0333
  c5.3857-0.851,11.0876-2.1519,15.7195-5.2885c1.3496-0.8389,2.0303-2.0546,2.0547-3.6838
  c-4.2065-2.2249-8.7046-3.7931-12.9355-5.9085c-6.6622-2.5895-13.6771,0.231-19.4276,3.6473
  c-4.8751,3.8174-8.9115,8.571-13.6044,12.5829c-1.082,0.0121-3.2458,0.0365-4.3279,0.0486
  c0.863-4.3766,2.9177-8.3036,5.0696-12.1453c2.9421-5.2155,0.2676-11.732-4.0605-15.2576
  c-2.2614-1.7142-4.2065-4.8873-7.4039-4.1943c-2.4193,5.3979-4.243,11.0512-6.6379,16.4612
  c-0.7052,6.9297,3.3796,13.203,5.7505,19.4885c0.1094,2.0303,0.9117,4.9116-1.4954,5.9207
  c-5.4467,3.0759-11.1486,6.2732-17.4946,6.8325c-2.5289-4.7778,4.097-7.5255,4.5712-12.0359
  c0.6686-5.9085,0.4377-11.9508-0.4377-17.8228c-0.6199-0.4863-1.86-1.4346-2.48-1.9088c-3.0151,1.617-5.4587,4.0607-8.3037,5.9207
  c-4.2065,2.3585-5.6775,7.3553-6.6987,11.7441c0.0364,5.3736,2.4436,10.8202,0.9604,16.1816
  c-9.0452,4.559-19.9747,4.8265-28.3027,10.8688c-2.5896,1.5927-4.5347,5.1791-7.9995,4.0363
  c-0.3647-4.6928,0.4011-9.3856,0.1824-14.0784c-0.2795-1.7628,0.2795-3.5135,1.5925-4.7171
  c5.4587-5.7504,15.0266-1.5804,20.5948-7.2945c1.9696-2.1641,6.0787-3.6472,5.3129-7.1486
  c-2.9421-0.9118-5.9573-1.6899-9.0088-2.0303c-6.8447,0.2918-12.4492,4.6806-18.3456,7.5499
  c-3.9025-2.0181,0.0729-6.3219,0.2917-9.4586c1.4954-2.9908-1.5562-6.2976,0.4133-9.0695
  c3.3798-5.4587,6.7231-11.1606,7.416-17.677c0.7174-9.2761,6.3098-16.9719,11.5011-24.3028
  c4.3767-7.9631,13.4949-10.8688,20.5947-15.7318c4.5469-2.9907,10.1636-2.3585,15.2334-3.6716
  c6.5042-1.848,13.4219,2.8449,19.7559-0.3647c5.5073-3.1123,11.5496-5.0333,17.3975-7.3796
  c6.103-2.4558,12.6437-0.4376,18.6494,1.2766c3.7568,2.6139,7.0879,5.8234,10.48,8.9114c2.4678,1.9331,1.9937,5.3372,1.9087,8.1212
  c-1.9209,6.176-6.0544,11.9508-6.2854,18.5402c0.9241,2.8327,0.6443,7.9023,4.2551,8.5103
  c3.4038-2.1519,6.6379-4.6685,9.3855-7.6228c3.2339-3.5378,1.3008-8.6197,2.2126-12.8748
  c1.7021,0.3161,3.8418-0.0121,4.9482,1.7021c3.1487,3.1489,6.6987,7.9389,4.2549,12.4371
  c-5.8113,7.0149-7.7197,16.4247-6.4189,25.324c5.1912-0.5958,8.875-5.009,11.3428-9.2518
  c1.7993-3.1367-0.4497-6.4434-1.1306-9.5315c2.3586,0.4134,5.2764,0.4255,6.6257,2.8327c3.5867,5.2764,1.8115,11.8901,2.468,17.835
  c0.073,5.8234,4.8997,9.799,8.8752,13.3976c2.5894,1.6048,5.0938,4.5104,8.4492,3.9147
  c2.6504-5.4465,1.9453-11.9387,1.3738-17.7986c-0.7903-7.4039-7.5618-11.6226-12.5466-16.2546
  c-2.2976-2.2491-6.3218-4.3402-5.1304-8.1334c3.0515-0.2188,6.0789,0.5471,8.7534,2.0425
  c5.7505,3.2582,12.3398,0.2917,18.4673,0.1215c0.6321-5.2277-5.1304-7.5619-8.9236-9.6165
  c-7.866-3.8782-17.8108,1.6534-24.7649-4.4496c-3.3066-2.9056-6.9419-5.7869-8.7168-9.9205
  c2.626-0.3525,5.5559-0.8267,7.7441,1.1307c3.8418,3.1974,9.0696,2.6989,13.738,2.5287c2.3708-0.6443,6.249-1.2643,5.9573-4.4983
  c-6.2126-6.3583-14.9417-9.3856-22.8926-12.9234c-2.9663-1.8723-5.8115,0.3525-8.5952,1.3373
  c-3.6719,1.6777-7.2095-1.4467-10.0786-3.3798c-3.8298-3.2582-9.4102-3.7202-12.425-8.0361
  c5.5073-5.787,14.1636-5.8721,21.5916-5.1304c4.5471,2.0911,7.1853,6.869,11.3794,9.6044
  c4.3889,4.2916,10.6016,5.2156,16.4248,6.0544c-0.9727-3.4406-1.7505-7.1-3.9634-9.9692
  c-4.0725-3.2096-9.2881-4.5834-13.1543-8.0848c2.8328-2.6746,6.7595-3.2947,10.4189-4.097
  c5.8357-1.2765,12.729,1.2279,15.4644,6.7596c4.012,7.416,12.9355,9.3248,19.4155,13.896c0.9238-0.389,2.772-1.1793,3.6958-1.5683
  c-2.0544-5.3372-3.854-11.1484-8.3887-14.9537c-2.7717-2.9908-7.197-3.7932-9.5679-7.1608
  c3.4648-1.0455,7.0391-2.1397,10.7473-1.8722c6.2854,0.3647,10.978,5.2764,16.9839,6.6136
  c4.4739,1.386,9.2275,0.8146,13.8596,0.924c-5.5925-7.4647-9.4829-16.8017-17.7134-21.8349
  c-4.8997-1.6412-9.2397,1.5804-13.2275,3.9147c-3.4771,2.3829-7.9023,2.6868-11.8535,1.5075
  c0.2795-2.7476,1.0212-5.7018,3.5137-7.2945c2.7231-2.4558,7.4402-4.3037,6.8933-8.7047c0.6685-3.9877-2.8086-6.7231-5.4587-9.0208
  c-2.9666-1.7387-5.5073,1.313-7.3918,3.1852c-3.3191,3.7445-7.9753,6.869-8.8992,12.121
  c-0.6689,4.401-0.7053,8.9236-1.9817,13.2395c-5.0212,1.3738-9.4829,5.8721-14.9539,4.1579
  c2.8206-4.2186,6.0786-8.1212,8.875-12.3642c1.8237-4.3037,1.7385-9.1425,1.5803-13.7379
  c-8.7532,0.7537-17.7134,6.7109-19.3423,15.7925c-0.7295,3.6108,0.2673,7.7687-1.9333,10.9539
  c-4.3765,2.8084-9.7988,2.857-14.6982,4.1093c-7.0999,1.7993-14.2241,3.5378-21.1782,5.8113
  c-6.9664,1.5805-14.2122,2.1519-20.8258,5.0089c-9.8478,4.2916-20.7772,1.6656-31.1233,2.6381
  c-2.2491,0.4742-3.6473-2.2125-2.6138-4.0361c1.5438-2.9544,3.6229-5.6533,4.6075-8.8749
  c1.2766-3.9632-4.9966-5.4344-3.7566-9.4341c1.2886-8.9966-0.3889-19.598-8.2671-25.2025
  c-0.1581-1.1428-0.304-2.2856-0.4255-3.4041c3.55-0.5836,7.5498-0.8024,10.103-3.7324c6.115-4.9603,6.9297-13.3367,5.9692-20.6434
  c-0.7903-0.8996-1.6047-1.7628-2.4316-2.5652c-6.2488,2.541-12.6436,4.875-18.4307,8.3765c-1.24,1.8358-1.8235,4.012-2.6138,6.0667
  c-0.6201,0.3525-1.8358,1.0334-2.4558,1.386c-3.538-3.3798-8.1699-5.5195-11.2457-9.337
  c-1.4103-5.0332,5.3129-6.8932,7.3066-10.7473c2.4315-4.6806,6.1152-8.4008,9.7988-12.1089
  c3.5743-5.945,6.6381-12.5951,12.4008-16.8138c4.7778-3.7688,11.7563-6.2733,17.4703-3.0394
  c4.8021,2.2248,7.8903,6.6622,11.0754,10.6985c6.3219,5.5803,15.3671,9.0938,23.6705,5.8478
  c-1.9451-4.5347-3.9268-9.0695-6.1759-13.4462c-2.4193-2.6625-5.4465-4.8508-8.5833-6.5651
  c-5.0331-0.4498-10.1028-0.1337-15.1239-0.693c-0.0364-1.0942-0.1094-3.3068-0.1337-4.401
  c3.5742-1.2643,7.2336-2.322,10.8688-3.4528c4.097-1.3616,8.4373,0.4863,12.0359,2.4315
  c2.7963,3.6351,3.0515,8.6075,5.8964,12.2426c2.6991,3.0029,6.0787,5.2885,8.9237,8.1577c1.7141,1.5197,3.0635,3.8297,5.6289,3.939
  c4.4617-4.2429,2.7961-11.2092,0.6199-16.2059c-1.7141-5.8964-11.6104-5.6533-11.3185-12.2548
  c8.2062-1.9452,10.0907,8.9357,17.3365,10.1878c3.4163,2.1032,6.8447-0.6928,10.3218-1.155
  c2.5894,0.6322,4.5469,2.6503,6.9419,3.8053c-1.2766-4.0606-2.2004-8.1819-3.0518-12.3277
  c-4.0117-5.0089-9.4949-9.2397-15.9263-10.3582c-5.5437,2.3829-11.3062,5.1791-17.5674,3.7445
  c-0.1581-1.0942-0.2795-2.1761-0.3892-3.246c3.3191-2.3707,9.5923-3.9877,8.5347-9.1789
  c-0.8875-6.2003,1.4224-12.7532-1.5076-18.5887c-1.4709-2.6989-0.3284-5.5803,0.304-8.3036
  c-1.228-4.5347-2.1276-9.6165-5.5924-13.0571c-3.8175-2.7598-8.7291-4.3159-13.2882-2.5045
  c-4.0728-0.4498-9.1304-0.0973-11.8657-3.7688c1.5806-1.4102,3.1002-2.9299,4.9238-4.0606
  c3.6472-0.4863,7.4525,0.231,11.0632-0.7051c2.6626-1.3616,5.0454-3.2339,7.2339-5.2764c-0.377-3.319-3.9392-3.9755-6.553-4.8022
  c-4.7415-1.2279-9.4463-2.9057-14.3823-2.8327c-4.2308-0.6808-7.6228,2.3464-11.1484,4.1092
  c-3.2582,3.2947-2.8812,8.34-4.4618,12.4249c-4.8873,2.0303-10.0786,3.5743-15.4036,3.3919
  c0.0243-0.851,0.0851-2.541,0.1095-3.3919c2.2369-1.4832,4.6685-2.6381,7.1851-3.5986c2.395-4.9481,3.3798-10.8566-0.2432-15.5008
  c-7.0027,2.237-13.3367,6.48-17.7986,12.352c-2.0424,3.7931-1.7993,8.2913-2.8813,12.3763
  c-3.1123,1.8358-5.6776,5.7748-9.4463,5.7991c-3.7446-4.6927-8.2307-10.2244-14.7593-10.3582
  c-0.6929,5.7018,2.2612,11.0632,1.5319,16.7531c-5.6896,4.3037-9.7382,10.5283-15.8533,14.3215
  c-5.0941,3.1123-10.4799,5.6897-15.671,8.6075c-1.5441,0.9847-3.3921,1.2158-5.1184,1.5683
  c-0.5349-3.0637-0.1581-6.1395,0.9482-9.033c1.2644-3.55-0.1458-7.5012,1.3982-10.9661c0.8875-2.1519,2.2856-4.0363,3.5135-6.0058
  c3.1974-0.1824,6.5529-0.1945,9.4098-1.8723c4.4619-2.5895,7.6228-6.7961,11.197-10.4068
  c-3.8174-0.9118-7.5862-1.9088-11.2578-3.2582c-2.6138-1.2279-3.4283,2.0303-4.6685,3.55
  c-1.1548-0.0973-2.2734-0.1945-3.3798-0.2796c0.535-3.5012,0.0973-7.9267,3.2704-10.2607
  c2.9299-2.3221,5.7627-4.8387,8.9966-6.7231c2.7596-0.5714,5.5925-0.0365,8.4008-0.2917c0.462-2.8692-0.924-5.629-2.8691-7.6106
  c-3.246-1.1793-6.9419-1.3982-10.2609-0.5593c-3.1611,2.4195-5.0332,6.0422-7.866,8.7534
  c-3.3433-1.4224-3.8539-5.2399-5.0818-8.2063c-1.9817-4.3766,1.5198-8.7534,0.9119-13.2638
  c0.547-4.1214-5.4832-6.3098-3.9878-10.4919c4.5469-6.7109,8.4009-14.1514,14.6985-19.4763
  c5.3492-5.3736,12.6316-7.9144,19.4277-10.8931c5.0088-1.4832,9.9326,1.6048,13.1909,5.1791
  c2.2246,3.2217,0.7659,7.4769,2.3828,10.9417c2.9543,7.027,11.3794,9.6652,14.0419,16.8868
  c1.0455,0.0729,2.1031,0.1459,3.1731,0.2432c0.6079-4.6685,2.0911-9.5193,0.243-14.1027
  c-1.2642-4.8995-6.0422-7.4161-8.8384-11.2822c-0.4255-2.4558,0.6199-3.5013,3.1123-3.1609
  c5.0576-0.5714,10.8809,0.8146,14.0784,5.0697c0.5227,3.4041-1.9817,6.6501-0.8511,10.0664
  c2.0425,6.3462,3.0273,12.9963,3.3799,19.6465c-0.0852,1.7629,0.8022,2.626,2.6746,2.5652
  c4.6929-4.8386,9.1425-10.3946,10.4189-17.2028c0.3892-5.2885-3.4041-9.4464-5.4465-13.9689
  c1.2644-0.0121,3.7688-0.0243,5.0332-0.0365c4.6807,2.5652,5.5924,8.34,5.9937,13.1909
  c0.3403,6.7596,6.8447,10.1271,11.3916,14.0419c1.2765,1.3859,3.0759,1.5683,4.7778,0.7902
  c0.6201-8.5345,0.377-17.5068-3.2581-25.397c-3.4893-5.6168-10.8325-5.9085-16.4614-8.0483
  c-1.8965-0.2796-2.5652-2.0303-3.1365-3.5621c3.3796-4.0484,6.8446-8.0969,8.9966-12.972
  c4.4009,0.0729,8.8384-0.0366,13.2393,0.4011c5.9451,1.9573,12.0239,3.6351,17.823,6.0423
  c8.1575,5.5073,8.3523,16.1451,11.0027,24.6797c1.6045,3.4527,3.0515,6.9662,3.8901,10.7107
  c1.7871-0.012,3.5986-0.0243,5.4346-0.085c0.7295-4.0484,2.7839-7.7808,3.3069-11.8415c-0.8997-2.4194-2.2615-4.632-3.1853-7.027
  c3.635-0.4133,6.4922,1.8236,9.2275,3.9025c-0.061,2.237,0,4.5834-1.167,6.6017c-2.6384,5.3615-2.7478,11.4644-2.833,17.3243
  c0.0974,4.0728-2.9785,7.0757-4.644,10.5163c9.1909,0.158,14.4431-8.8871,17.5432-16.3154
  c1.6292,1.5805,3.1245,3.5135,3.2097,5.9207c0.8267,6.7352-4.4983,12.4249-3.6108,19.1602
  c1.4102,3.3676,3.6836,6.3219,5.0332,9.738c1.0454,2.3342,1.4346,5.2156,3.7566,6.7231c2.8569-1.5197,3.5378-5.0211,5.2764-7.5377
  c3.5378-5.2764,3.489-12.2791,1.8479-18.1755c-3.1001-5.3128-5.3127-11.0269-7.7686-16.6314
  c-0.231-1.6169-0.292-3.2217-0.1704-4.8387c6.7964,1.3373,9.6045,8.7291,15.7808,11.2456
  c3.6472,2.5288,8.1455,2.395,12.4004,1.9817c0.6809-6.6136-5.0938-11.0633-7.7808-16.5463
  c-5.6775-3.8296-12.4858-7.2945-19.5735-6.7839c-2.5046,0-5.2764,0.1945-7.1243-1.7871
  c-3.8784-3.5014-8.1455-6.4921-12.1941-9.7503c-1.2886-0.8997-0.8997-2.7111-1.3372-3.9998c4.717-0.2919,9.4707,0.8996,14.1636,0
  c3.27-1.0091,6.4434-2.395,9.7136-3.4649c0.0486-0.7295,0.1582-2.2127,0.2068-2.9543
  c-6.4436-3.9147-12.7046-8.2793-19.8533-10.8445c-2.3828-1.1063-5.1182-1.3981-7.6228-0.6808
  c-3.8782,2.4437-6.8447,6.2367-11.1482,7.9996c-7.1609-3.5256-14.5891-6.5163-21.7014-10.03
  c1.2645-2.8083,4.5835-2.8569,7.1974-3.3918c3.8175-0.5228,7.3431-2.1519,10.7594-3.8783
  c7.1487-3.0394,11.8777-10.5162,19.999-11.5253c6.2976-1.9087,12.4978,1.8965,16.4126,6.6014
  c5.6047,7.4769,15.9993,8.0361,24.5825,7.8658c-1.8357-10.0664-11.1121-17.7499-21.1907-18.7711
  c-0.085-0.6808-0.2795-2.0424-0.3645-2.7233c6.188-3.0029,13.3975-3.4041,19.8896-1.1063
  c2.3948,2.5895,3.8296,5.9937,6.6743,8.2185c4.5591,3.7809,9.5681,7.0027,14.6255,10.1029
  c2.4194-4.7415,1.3374-10.4189-1.386-14.7593c-2.7476-3.1123-7.5862-4.2065-8.729-8.6196
  c4.0605-0.6687,8.2793-2.0425,12.4248-0.9847c3.2583,0.693,5.7021,3.088,8.5591,4.6563
  c4.4253,2.7112,10.1392,2.6625,14.8928,0.8268c3.0029-2.2493,5.5195-5.167,9.0088-6.7354
  c3.1243-1.3738,5.2034-4.1091,6.9661-6.9177c-0.4253-0.8632-1.2642-2.6016-1.6899-3.4768
  c-10.6011,0.0483-20.9836-3.4894-31.6094-2.2129c-5.5559,1.0212-10.1758,4.936-15.9629,5.1305
  c0.1216-2.1884,0.9121-4.2308,2.7234-5.5681c3.939-3.611,7.9023-7.489,9.8235-12.6073c-5.7144-1.8358-11.7322-1.8115-17.592-2.7233
  c-3.4163,0.8754-7.4282,2.8691-8.3645,6.6136c-2.4434,5.9449-3.6836,12.6072-7.8901,17.6526
  c-3.9634,1.1671-8.3643,0.4255-12.0479,2.4801c-3.2461,1.4955-6.1152,4.4132-9.9692,3.5258
  c1.082-3.9148,3.0757-7.5377,3.8052-11.5498c0.3892-5.9813-3.7202-11.0875-8.0117-14.7227c-3.9026,2.5896-5.5562,7.72-7.3674,11.89
  c-2.2249,5.4467,0.4619,11.197-0.5227,16.6801c-5.3494,3.8296-11.4768,6.3948-16.9841,9.9812
  c-13.0328,7.2339-29.3481,8.3887-43.1105,2.5167c-3.0028-1.0699-4.1456-4.4983-6.8082-5.9816
  c-3.3433-0.6079-6.6865-1.155-9.969-1.9209c-0.0122-3.8539,3.1853-7.8171,6.9053-8.5466
  c4.0972,1.2644,7.1365,4.7778,11.3066,6.0059c6.6744,1.8601,13.823-1.1672,18.2605-6.249
  c-4.1091-2.7233-8.7778-4.3767-13.3003-6.2368c-3.2703-1.2888-7.3917-0.4619-9.9207-3.2338
  c3.5258-4.8267,9.4829-7.7201,15.4158-8.1821c6.6866-0.7052,12.5223-7.0391,12.1697-13.8595
  c0.2432-5.1913,2.3706-10.2,1.6412-15.4401c-2.2734-0.0121-4.3523,0.7538-5.9813,2.3341
  c-7.0879,5.8601-17.4825,9.3613-20.0598,19.1846c-1.5078,5.7626-4.4133,12.121-11.0149,13.2153
  c-1.0576-4.7173,2.8691-8.571,2.3707-13.2518c0.4379-9.8839-9.8475-16.2909-18.601-17.3729
  c-0.073,4.2065,2.0668,7.9509,2.6504,12.0481c0.5957,3.319,0.7781,6.6865,1.0941,10.042c0.535,5.6775,6.5529,9.5679,6.0059,15.4644
  c-0.8633,2.8328-1.9453,7.1973-5.787,6.3462c-0.7416-3.5378-0.8146-7.2822-2.2249-10.6377
  c-3.3069-5.3494-9.3613-8.1821-15.0023-10.3947c-0.0122,5.7141,0.073,11.4523,0.2311,17.1664
  c0.9846,8.3523,9.8596,12.2791,11.9994,20.072c0.0486,2.2979-1.6293,4.2917-3.0272,5.9695
  c-5.6532,5.2156-13.045,7.72-20.1936,10.1028c-5.5195,3.2096-12.0845,3.6716-17.6892,6.6501
  c-5.2034,2.1884-10.7715,4.1821-14.5525,8.5954c-3.7688,4.3524-6.8689,9.2761-11.0391,13.2881
  c-6.5649,6.0787-13.1178,13.2396-22.4426,14.6498c0.1946-2.7476,0.6686-5.4466,1.2886-8.1091
  c2.1398-8.2914,1.3374-17.0085,3.1853-25.3605c2.6869-12.5587,9.3127-23.7192,15.2942-34.9285
  c2.8448-4.8508,2.9178-10.5892,4.0972-15.9508c1.0941-5.0817,0.7659-10.2853,0.3403-15.4155
  c3.2825,1.629,6.261,3.8417,9.5437,5.5194c5.4951,1.1306,10.0056-3.2339,13.5435-6.8447
  c-5.4709-3.3796-10.8933-8.0481-17.6528-7.963c-0.4254-0.7052-1.2765-2.1033-1.702-2.796
  c1.4103-1.7874,2.8448-3.5381,4.2551-5.3374c0.8997,0,1.8358,0.0122,2.7841,0.0244c3.2582,4.1578,8.3279,6.6624,13.4705,7.5619
  c5.5195,0.0853,10.188-3.7079,13.9083-7.4281c0.0972-1.1792-0.377-2.0303-1.3981-2.5774
  c-4.0972-2.6382-8.4618-5.3979-13.5071-5.3127c-0.0365-1.8723-0.0486-3.7446,0.0242-5.6047
  c5.7992-0.7902,10.6865,3.1732,16.2789,3.8174c5.24-2.5043,10.6986-4.6074,15.8048-7.3916
  c0.0243-1.4712-0.2675-2.9664-1.3008-4.0486c-3.9878-5.4344-9.6774-10.3945-16.7166-10.6621
  c-4.6563,1.155-6.48,6.0908-9.7991,9.0088c-2.3098,2.1398-5.6045,2.1519-8.5344,1.9452
  c-2.8572-3.8905,1.5925-8.9235-0.8755-13.0328c-1.6655-2.9299-2.9177-6.6379-6.407-7.7928
  c-3.866,2.7596-5.7869,7.4645-7.5011,11.7318c-1.7992,6.0302,3.4891,11.0513,3.1853,16.9478
  c-1.459,3.9512-4.2308,7.2458-6.5286,10.735c-0.7538-0.0243-2.2614-0.0487-3.015-0.0731
  c1.1914-9.6165-6.7354-20.9836-17.2759-18.7588c-1.1671,7.9146,1.8357,15.5129,4.6077,22.771
  c5.021,9.5923,7.9995,20.6069,6.2368,31.4636c-2.6382,8.7047-4.7659,17.92-10.7593,25.0686
  c-4.8752,2.0304-6.1519-4.5955-7.2217-7.9509c1.9453-2.7112,4.9724-5.0089,5.325-8.571
  c-0.6322-7.3917-5.325-14.2001-11.8779-17.6284c1.0577,4.9116,2.6748,9.7139,3.0031,14.7593
  c-0.8024,0.0122-2.4071,0.0122-3.2096,0.0122c-2.9056-4.6077-5.5439-9.4464-7.9998-14.2972
  c0.1824-4.6443,6.4314-6.942,5.3859-11.963c-0.0972-6.1152-5.7992-9.4221-9.5314-13.3733
  c-1.2644-1.5319-3.1731-1.5198-4.8995-1.0454c-0.0973,4.0847-1.1549,8.0603-1.5684,12.121
  c0.4012,3.1731,1.5074,6.2489,1.2279,9.5193c-8.8628-4.024-10.2852-15.0509-17.373-20.9352
  c-4.8265-1.2036-9.8597-0.2676-14.7228,0.0242c1.2766,2.1519,2.8329,4.146,4.0121,6.3827c0.2552,2.9545-1.009,5.7384-1.3982,8.632
  c3.5378,4.012,8.9844,4.1093,13.9082,4.0728c4.5469,1.2158,9.9691,3.5378,11.0999,8.644
  c-3.9999,0.5347-9.0088-1.848-12.1211,1.5439c-4.085,3.0758-2.8083,8.729-2.9299,13.1422c6.7839,0,13.665-2.4193,18.9292-6.7352
  c1.0333-1.1064,2.6017-1.0336,3.9756-0.6444c3.9268,3.2218,8.2671,6.9176,9.0571,12.267
  c-5.2153-0.1824-10.0055-4.4983-15.2332-2.1155c-4.231,1.9453-8.8628,3.8175-11.6835,7.6958
  c4.8873,2.4194,9.714,5.1669,15.0631,6.4799c5.7627,0.693,10.5649-4.8873,16.2305-3.8661c2.4435,2.6382,2.7473,6.407,4.0483,9.6166
  c2.8447,6.7838,2.6624,14.5404,0.6929,21.5309c-4.2551-0.1702-8.5225-2.3706-10.735-5.9937
  c0.2308-1.7506,0.7903-3.4283,1.2887-5.1062c-3.4528-7.0635-10.9904-11.4523-18.8198-11.3794
  c-0.1094,4.0607,3.392,6.6624,4.7657,10.2488c1.4225,3.6594,3.6594,6.8569,5.9451,10.0422
  c-4.1215,3.5378-9.4586-0.1095-14.0055-0.7539c-7.1973,3.9269-7.5376,13.0815-9.6409,20.0721
  c0.45,0.4255,1.3496,1.2887,1.7993,1.7142c5.4709,0.3526,6.3828-7.9388,11.8778-7.7321c0.4012,3.8782-1.86,7.1486-2.8691,10.7229
  c0.9362,4.012,2.8207,7.7565,3.6108,11.8292c0.6564-0.0851,1.9574-0.2675,2.6138-0.3647
  c5.702-7.1973,8.6198-16.0479,11.7076-24.5703c0.9849-2.6017,3.8053-3.4892,6.3464-3.6229
  c1.3129,1.4954,2.7839,2.9056,3.8781,4.6198c1.0699,6.334-2.5652,11.8536-4.7657,17.4703
  c-2.9663,9.3613-12.1816,15.598-21.5916,17.0448c-5.6655,0.851-10.7351,3.5743-16.0845,5.4343
  c-6.7351,2.2856-11.9021,7.5984-18.6497,9.8475c3.3191-11.5616,1.2158-23.634,1.7386-35.4634
  c4.486-0.6566,9.118-1.4711,13.1056-3.7445c2.225-2.4922,2.9788-5.9815,4.6078-8.8749c2.6505-4.5348,2.7841-9.9084,4.3524-14.82
  c-5.5195-0.9482-10.6013,2.0912-14.8199,5.2519c-2.7355,3.5623-4.158,9.7017-9.8597,8.7534
  c1.3373-5.7141,0.5957-12.2426,4.085-17.215c3.1365,0.4135,6.4919,2.0668,9.5923,0.5106c3.6472-1.702,8.2791-3.5742,9.3855-7.8781
  c1.5562-4.5348,3.1853-9.3004,2.2612-14.1635c-5.7261,4.7415-13.1057,8.7412-20.7042,8.0725
  c0.5715-5.4707,6.5044-8.109,11.1729-9.6409c4.3766-0.8875,4.3523-5.9084,6.0179-9.1909
  c-2.6504-4.8267-3.7688-10.2367-5.5559-15.3915c-6.9055,3.2582-9.884,10.9904-14.3094,16.7773
  c-4.8751,5.4344-4.0363,13.1908-4.3038,19.9504c-0.8387,0.4012-2.5287,1.2157-3.3674,1.6169
  c-0.9727-2.5287-1.7751-5.1305-3.1854-7.4282c-3.0636-5.5924-9.9204-6.8203-15.2455-9.3004
  c-1.386,7.4038,0.1216,15.282,4.1458,21.6768c2.6868,3.5012,6.9055,5.2277,10.1879,8.0969
  c3.0031,3.6594,3.8053,8.5588,3.5743,13.1786c-4.0605,2.1762-4.2429-2.699-5.884-5.082c-3.5623-3.7322-8.948-4.5347-13.6044-6.1637
  c-1.009,7.1243,1.1063,14.6133,6.1881,19.7679c2.6504,1.8723,6.7839,1.702,8.3523,4.9724
  c2.0303,3.0151,2.0668,6.9419,0.8145,10.2609c-4.2429,2.6382-8.7898,4.7414-13.1179,7.2216
  c-2.6503,1.3252-4.9602,3.8539-8.1698,3.1852c-0.5714-3.9147,1.9695-7.6227,0.9604-11.5131
  c-2.5895-4.4739-5.7383-8.7534-9.9205-11.8414c-1.7142,4.5591-2.4436,9.3978-2.8569,14.2365
  c0.304,5.6533,3.6837,10.4676,5.4465,15.7075c0.2189,3.0029,0.1094,6.0422-0.1338,9.0452
  c-3.6107,1.6777-4.1458-2.772-5.5802-4.9725c-2.5653-6.1517-10.6743-6.5651-16.3641-5.6897
  c0.5593,5.6776,4.2673,10.3217,6.9055,15.1969c2.7598,4.6806,8.7777,4.1214,12.9235,6.6866
  c2.5531,2.3221,1.0211,5.8721,0.3282,8.6683c-0.7295,3.1488-2.3099,6.4434-5.4343,7.8051
  c-3.9878,1.5441-9.0574,3.2947-12.8019,0.304c-4.097-3.3677-7.2579-7.951-12.3033-10.0419
  c-1.6293-3.9392-3.4529-8.0604-2.699-12.4007c0.8632-5.1912,2.3098-10.2731,3.6472-15.3549
  c2.1882-7.5862,7.7322-13.4219,11.5497-20.1571c4.7169-7.4282,8.3522-15.4521,12.2789-23.2937
  c1.6899-6.6745,4.3645-13.2031,4.3767-20.1815c0.0366-6.1395,0.8632-12.2305,1.7506-18.297
  c0.9969-6.5529,4.1823-12.6438,4.2673-19.3547c0.1824-6.8082,0.0609-13.665,1.5441-20.3517
  c1.5927-6.0908,0.377-12.4614,1.4954-18.6254c1.082-4.8629-0.7295-9.7017-0.2917-14.589c0.316-6.4919-0.693-13.0206,0.1458-19.4641
  c2.7842-12.9841,0.9119-26.3939,3.5015-39.4023c1.9573-4.5591,5.7505-7.9146,8.9357-11.6104
  c3.6959-4.3401,9.0087-6.7231,13.6287-9.9082c3.8297-2.2129,6.1759,2.5408,8.8992,4.4009
  c4.9724,2.1396,10.2489,4.5712,15.8291,3.6715c4.097-1.6046,8.6196-2.942,11.2821-6.7716
  c-3.9146-4.1458-9.6895-5.386-14.2122-8.6682c-3.5498-2.4558-7.72-3.5258-11.9872-2.8329
  c-0.0485-0.6929-0.1581-2.0912-0.2067-2.7839c3.3191-2.8082,5.7991-6.407,8.7777-9.5558c2.4315-2.6747,6.3462-2.5654,9.4706-3.9755
  c3.7932-1.3131,5.1792-5.5439,6.553-8.9358c0.5715-7.489-0.5105-15.0023-0.4377-22.5035
  c-1.6047,0.2676-3.5498-0.0728-4.5347,1.5076c-4.2795,4.6807-8.1577,9.6775-12.6073,14.1877
  c-4.316,4.5105-2.6017,10.9539-4.2186,16.4248c-1.9088,4.4374-5.3372,8.0239-8.9722,11.124
  c-0.6566-5.7505,2.8691-10.9905,2.4314-16.6558c-1.4224-2.3584-2.2006-6.2246-5.2885-6.7354
  c-5.9208,9.6167-8.4496,20.9717-9.2518,32.1323c-0.0244,1.6899-1.6171,2.7234-2.6382,3.8296
  c-3.5623,2.8813-7.489,5.3979-11.7563,7.0513c-1.4468-7.6591,3.1245-14.6375,4.17-22.0779
  c2.6746-8.8386,3.9877-18.7225,10.5891-25.7252c6.1759-7.5741,13.1423-14.4431,20.4974-20.8622
  c4.5713-5.5681,9.0697-11.2092,13.3977-16.9722c3.4404-3.416,6.8081-6.9297,10.3337-10.2729
  c7.6715-4.7534,14.6377-11.8779,24.303-11.8901c5.8113,0.4988,11.8657,2.4558,16.0115,6.772
  c8.1941,7.2092,11.0632,18.6008,12.0481,29.0806c0.2795,2.2493,0.7415,4.8267-1.082,6.5774
  c-2.8086,0.8875-6.5044-0.2917-8.2185,2.7112c-2.9421,4.0118-7.2823,7.1362-8.8386,12.0237
  c-1.5317,3.4407-1.2035,7.2822-0.8389,10.9174c3.4772-2.5165,5.8721-6.1637,9.0452-9.0208
  c4.5469-3.2947,9.8719-5.5194,15.282-6.9784c0.1217,3.7324-0.0729,7.5498-1.4346,11.0754
  c-1.6533,1.4225-3.4891,2.6505-5.3979,3.6594c-3.4648,0.4012-7.2581-0.62-10.3459,1.4469
  c-7.1365,3.1608-8.6685,11.6466-11.4038,18.1388c2.6626,0.2188,5.3979,0.1216,7.8901-1.0333
  c3.4407-1.5563,7.7566-1.2644,10.5527-4.097c2.6626-2.0182,4.2188-5.9329,8.0483-5.8479c-0.3405,7.9023-7.3066,14.431-6.334,22.698
  c0.7051,6.48,2.2979,13.4583,6.8203,18.3943c2.7476,0.4862,6.3584,1.3738,8.1941-1.4589
  c3.3312-4.4498,7.3674-9.6654,6.5164-15.5494c-3.0149-5.325-6.1395-10.6013-8.5466-16.2426
  c-1.3738-3.2581-0.2554-6.7594,0.4135-10.0299c0.5835-0.0122,1.7262-0.0242,2.3097-0.0242
  c1.2158,3.635,1.7751,7.5862,3.8784,10.8809c4.6927,5.155,11.2943,8.6075,18.2969,9.1304
  c0.4741-3.5986-1.5317-6.5286-3.5864-9.2275c-3.0271-3.8174-3.7566-8.9358-7.027-12.5831c-2.8083-3.6957-6.8204-6.1758-9.8962-9.58
  c-2.2247-2.5775-1.5197-6.0909-1.5197-9.1912c0.936,0.0122,2.8083,0.0608,3.7567,0.0852c2.5531,3.4161,4.8751,7.027,7.647,10.2974
  c3.7931,2.0059,7.7201,4.705,12.2549,4.0242c4.182,0.1824,7.416-2.8571,10.431-5.325c-2.7961-4.6927-8.6926-6.407-12.7776-9.8477
  c-4.3645-3.8296-9.7988-6.1272-15.3549-7.5741c-3.9512-1.7264-9.5071-2.5409-10.808-7.3673
  c-1.9209-8.6199-1.3008-17.7866-5.1304-25.9564c7.3187-0.4376,14.1392,2.7841,21.1174,4.5348
  c-0.8752,3.8784-5.2034,7.3676-2.8813,11.4402c1.3739,5.5437,5.1184,10.2489,10.8203,11.7563
  c0.2067-4.8022-0.8147-9.8478,1.167-14.4189c1.3131-3.0757,1.7144-6.3948,1.8237-9.7139c3.3676-0.2554,7.574,0.3525,8.1212,4.4862
  c-1.3981,2.3344-3.2948,4.3401-4.4984,6.7838c-0.851,3.5745-0.7294,7.3553-0.085,10.9661
  c2.3342,6.4801,8.5103,10.5042,14.5159,13.2275c0.2676-4.1703-0.3402-8.4739,1.1187-12.4857
  c0.4741-2.4072,2.0181-4.9237,0.9482-7.3798c-1.2036-3.1853-2.626-6.2974-3.4526-9.6044
  c5.6775-0.1824,11.0146,4.8629,11.6104,10.4188c1.2035,7.9631,6.1638,16.3035,14.358,18.5522
  c4.2794,1.1794,8.3035,3.6473,12.9113,3.2705c0.3646-5.8721-3.2583-10.9175-4.5956-16.4611
  c-1.1793-5.5195-4.6564-10.3947-9.1912-13.6649c-3.7688-2.5654-8.5465-1.8359-12.7289-3.0759
  c-1.629-1.3252-2.9907-2.9058-4.4009-4.4011c5.1062-3.6958,11.7197,0.0364,17.1541-3.0515
  c7.6228-2.0424,10.188-10.2974,12.4614-16.9109c-8.8384-0.8997-17.3123,3.3433-23.5612,9.3246
  c-6.1031,5.4587-14.8199,5.9816-22.3818,3.7689c0.0729-1.3009,0.1581-2.6017,0.2552-3.9026
  c4.9602-1.386,10.9296-2.0547,14.1514-6.5894c3.1123-4.8022,6.0178-10.3582,5.1548-16.2666
  c-6.249,3.5133-13.2152,5.5802-19.3427,9.3003c-5.8235,3.5137-7.7321,11.0635-13.6893,14.3215
  c-5.3007,0.7053-10.7715,0.304-16.0601-0.0973c-9.2032-2.8448-18.601-5.5194-26.9409-10.4677
  c-1.9817-1.009-2.6262-3.2581-3.708-5.0088c8.8384-0.3767,17.4702-2.3466,26.1508-3.8418
  c14.6254-9.1667,28.4484-20.692,45.6027-24.7648c5.1184-0.7538,9.495-3.6958,14.3701-5.252
  c9.9813-2.4801,19.9626-5.7141,30.406-5.0819c6.4312,1.1914,13.6406,2.8326,17.4824,8.7047
  c4.6807,7.0028,0.5592,16.0721,4.4495,23.3546c2.7235,5.5925,8.4009,8.8994,13.0574,12.7532
  c1.1184,1.24,2.7596,1.1184,4.3158,1.1307c0.7782-4.6564,1.1307-9.568-0.5592-14.066c-1.5927-5.8113-7.6228-8.3767-11.7319-12.1333
  c-3.1245-3.1123-1.2766-7.9509-1.8115-11.8414c5.7261,0,7.6835,6.1881,11.4524,9.4585c4.1335,3.781,5.021,9.4949,7.9387,14.054
  c2.4314,5.1183,5.3494,10.4191,5.0818,16.2668c-0.1213,3.4647,0.2676,7.0026-0.377,10.443
  c-2.103,4.0364-7.6833,1.1067-10.2607,4.2795c-2.626,2.699-3.635,6.4678-4.4497,10.0177
  c-0.2308,2.6261-0.6321,6.1273,2.0669,7.6592c6.1394-1.2643,10.0178-7.3187,15.9019-8.7047
  c1.1794,2.6503,1.9087,5.6045,1.532,8.5345c-2.626,2.9664-6.8203,3.6837-9.9812,5.9329
  c-4.0851,2.1154-5.7749,6.7596-6.7598,10.9659c-0.3038,4.9604,0.9485,11.6105,6.4922,13.1545
  c3.3311-4.7051,5.2278-11.5011,11.2092-13.4706c0.2917,2.942,0.4741,6.0544-1.24,8.632
  c-2.2979,3.7563-3.1123,8.6924-0.9119,12.6802c4.3157,5.6653,11.2092,8.1698,17.0447,11.9021
  c0.9727,0.7659,2.1155-0.4014,3.1611-0.5835c-0.0122-4.8267,3.1851-9.6411,1.1792-14.3704
  c-2.8447-8.2063-12.8992-11.5131-14.3096-20.4854c6.1155-1.3494,5.9209,6.322,10.1638,8.6804
  c4.9724,2.9177,11.0754,2.4922,16.5828,3.6594c0.4619-0.4133,1.386-1.2157,1.8359-1.6292
  c-1.6777-5.1548-7.5742-6.9053-10.103-11.501c-2.0181-3.5864-5.4587-5.8965-8.5466-8.4495
  c-2.9543-2.2493-6.8203-3.4164-9.0815-6.4801c-1.3496-3.4769-2.1155-7.1971-1.8237-10.9296
  c0.7295,0.0852,2.1882,0.2554,2.9177,0.3405c3.2705,3.9512,5.6289,8.8628,10.1636,11.6469
  c4.5835,0.9482,9.3005,0.1216,13.957,0.0122c-0.3284-6.9419-6.5894-11.1121-11.3916-15.1726
  c-3.8418-3.6837-9.6289-3.6716-13.6895-6.942c-2.0547-2.0424-3.5742-4.547-5.5437-6.6501
  c-0.073-8.5468-2.9421-16.6923-5.8599-24.619c2.4314,0.0608,5.106-0.243,7.2581,1.2644
  c8.9478,4.4253,19.2207,5.1184,28.3877,9.0452c-3.2947,3.8783-7.3188,7.4282-8.7168,12.5222
  c-1.1794,1.8114-0.0974,3.6107,0.9116,5.1912c3.4285,6.6259,10.4797,9.9205,16.218,14.2244
  c1.1914-4.6927-0.4011-9.4099-0.1213-14.127c0.2188-4.4862-0.2676-8.9602-0.1826-13.4463
  c4.8875-1.1185,8.4495,2.9298,11.8049,5.7869c0.2434,3.0637-2.5896,5.4465-3.2217,8.3887
  c0.9727,4.0728,1.5925,8.4739,4.3645,11.8049c2.541,2.9907,4.1821,7.5378,8.6563,7.9146
  c1.4709-8.4252,2.9785-16.9354,2.4556-25.5186c4.0608-0.0608,7.8296,2.395,9.8721,5.8721
  c-1.2644,4.6199-4.8145,8.802-3.8052,13.9447c0.158,10.5405,8.9722,17.8837,16.7407,23.6949
  c3.1851-4.1577,3.1245-9.6409,2.9663-14.6255c-0.0972-6.6135-5.884-11.3064-6.5405-17.8228
  c7.0269,4.6443,14.3215,9.9568,18.1875,17.6527c1.2158,4.0485,1.5562,8.8263,5.4951,11.3551
  c4.8508,5.021,12.1089,5.0696,18.4917,6.5164c5.3613-2.7233,10.9417,1.5439,16.522,0.0608
  c1.3252-1.3738,3.4285-2.9058,2.699-5.1062c-2.9055-2.4558-6.334-4.2551-8.8508-7.1609
  c-6.6013-6.0787-11.5251-14.4308-20.1689-17.908c-4.1216-1.775-8.5713,0.7903-12.7412-0.2432
  c-3.3677-1.3981-5.4829-4.6199-7.4041-7.5498c4.3162-0.4135,9.0452,0.9119,12.9478-1.6292c2.553-1.4467,6.699-2.7355,6.9055-6.0789
  c-2.7354-4.0605-6.2976-8.2184-11.3064-9.2639c-8.1819-4.0242-16.0356,4.2551-24.3635,2.5044
  c-3.9756-0.8145-7.9146-1.7263-11.793-2.8569c-0.085-1.4954-0.1458-2.9908-0.1824-4.4863
  c4.863-2.2125,10.0422,0.5352,14.9905-0.7051c7.6226-3.623,10.5039-12.51,12.3154-20.1451
  c-3.1731,0.1946-6.2368,1.082-9.021,2.5653c-4.2793,2.5895-9.738,0.3162-13.9324,3.1853
  c-5.4346,2.1154-5.9573,9.4706-11.4768,11.5374c-4.717,2.4802-10.1028,0.8146-14.5159-1.4344
  c-0.5229-1.1183-0.6931-2.2977-0.4985-3.5256c4.9116-2.1395,11.27-1.4225,15.0144-5.9086
  c5.5439-5.3372,5.24-13.4219,4.5225-20.4854c-4.7412,2.1396-8.8625,5.3372-13.2273,8.109
  c-3.4526,2.152-7.4888,3.7201-9.9204,7.1606c-2.2126,3.161-4.5955,6.2004-7.27,8.9845
  c-6.0667,0.3281-11.5618-2.4436-17.3975-3.4772c-9.738-1.313-17.3853-8.036-25.3484-13.203
  c-10.8445-4.6318-19.2209-13.665-30.5396-17.385c-4.3889-0.4744-8.802,0.1094-13.1909-0.1339
  c0.3162-1.7262,0.0608-3.7567,1.5076-5.0575c3.5986-4.0972,6.8447-8.498,10.4675-12.5709
  c4.3038-3.9025,8.7534-7.7321,13.6528-10.8931c1.228,5.9449,1.4468,12.0844,3.1488,17.9323
  c0.5593,2.0668,1.9088,4.1577,4.1823,4.5956c5.252,1.5682,10.6013,2.9054,16.0112,3.9269
  c-1.1304-4.243-5.0088-6.8325-6.7473-10.7352c-1.5684-3.173-2.2612-6.6622-3.4648-9.9569
  c-1.4711-3.9755-5.7384-6.4313-6.0544-10.8687c3.5134-1.8967,7.4041-4.1943,11.5254-2.6504
  c0.146,2.4558,0.0242,4.9116,0.1943,7.3552c0.4741,7.5013,7.2703,13.4097,14.3459,14.8322
  c-1.9089-7.8536-2.0425-15.9506-4.1458-23.7434c3.4771,0.3768,6.8933,1.1427,10.3826,1.6532
  c-0.3525,6.5531-4.8386,13.4341-1.1184,19.7925c2.103,5.5803,8.4373,6.5529,13.2759,8.6804
  c1.7749,0.7295,5.0698,1.7386,4.5957-1.4103c-1.7266-3.0515-3.6475-6.0909-3.8176-9.7017
  c-0.4011-4.6807-3.9634-8.4982-3.7446-13.2274c3.854-0.8145,5.6047,3.0637,7.1487,5.8357
  c2.7842,6.0179,8.4495,11.8899,15.5737,11.5618c6.48,0.085,12.9841,0.4497,19.4644-0.0731
  c-0.3162-2.0182-0.146-4.3645-1.7632-5.8721c-5.0815-5.0575-9.4097-11.0754-15.5493-14.905
  c-5.2156-0.5471-10.5161,0.6809-15.6831-0.3527c-2.6868-0.4739-3.4285-3.2458-4.4741-5.3247
  c4.7659-1.2766,10.1638-1.4469,14.1514-4.7173c2.7476-2.5531,5.009-5.5803,7.5256-8.3765
  c1.3738-1.3374,0.6443-3.3796,0.7659-5.0575c-11.1606-0.839-22.4185,2.2369-32.1445,7.6105
  c-1.9329,2.1763-3.5742,4.5835-5.4463,6.7961c-3.781,0.1094-7.562,0.1581-11.3186-0.1945c0.1821-1.4103,0.304-2.8448,0.7051-4.2186
  c3.2947-2.3708,7.9023-3.6716,9.4949-7.793c2.0669-5.3129,6.2368-10.5526,4.5469-16.5706
  c-8.9358,2.5406-18.0415,6.9297-23.3057,14.905c-1.8726,4.4253-2.9546,9.1423-4.6565,13.6287
  c-5.8356,0.2552-10.8688,2.9541-16.0236,5.3491c0.5349-5.8965,3.9513-11.0391,4.8143-16.8623
  c1.0942-7.1001-2.6381-13.6772-6.0908-19.5615c-3.161,1.6412-6.0059,4.3525-6.8325,7.9023
  c-0.8511,4.6807-3.6958,8.6804-4.4861,13.3854c-1.5928,3.7203,1.4954,6.9054,3.0637,10.0299
  c2.2247,3.1125,1.4224,7.1,1.556,10.6743c-2.9298,2.4801-5.4222,5.3856-8.109,8.0967
  c-7.6349,6.5773-12.6316,15.9143-21.385,21.2393c-11.4158,6.942-24.7649,9.3856-37.4207,13.1057
  c-2.772,0.2795-5.7263,0.8754-8.4131,0.0122c-2.1033-3.2703-2.7476-7.4403-5.7018-10.1272
  c-2.8329-3.1488-7.2946-3.9148-10.3461-6.7595c0.4133-5.1306,4.0728-9.6775,3.562-14.9294
  c-2.5408-5.1061-6.9419-10.2122-12.9233-10.8931c-0.3647,1.8479-0.6686,3.7201-0.9604,5.5801
  c-1.5317,6.2368,1.3981,12.4128,4.4133,17.7014c-2.4802,0.9727-5.6168,0.9604-7.5985-0.9727
  c-4.8995-3.8781-8.1942-11.0023-15.3307-10.8322c-3.4648,0.1823-10.03-0.7296-10.4312,3.9633
  c0.8269,2.4559,2.8936,4.3038,4.5957,6.2126c3.5256,3.8538,9.0938,3.3068,13.7378,4.559c1.3131,1.1552,1.7751,2.9178,2.5166,4.4618
  c-4.3523,1.3374-8.7654,2.4558-12.9598,4.1699c-4.3888,2.7476-6.5773,8.182-6.6744,13.2152
  c3.0393,1.5074,6.6501,2.4073,10.042,1.6655c7.9512-2.3584,12.583-9.884,18.1755-15.4764
  c4.0364-0.0122,8.5588-0.7173,12.1089,1.7141c3.866,2.4072,5.5559,7.246,5.0574,11.647
  c-7.2336,4.486-13.6771,10.0786-20.6555,14.9294c-3.6836,2.5408-8.3157,2.6501-12.3397,4.3281
  c-7.4891,2.3341-15.5131,2.4436-22.8197,5.4465c-9.7502,0.9724-17.0447,8.425-22.9897,15.5615
  c-0.7051,0.0608-2.1155,0.2067-2.8206,0.2798c-0.8389-6.8328,3.0515-12.8141,4.5105-19.2455
  c1.9573-7.9998,8.2427-13.7987,11.4644-21.2269c6.3584-10.4678,8.2428-22.7589,13.3854-33.7615
  c3.3312-4.2915,6.4679-9.2639,11.6349-11.5618c7.197-3.9998,15.9871-3.1489,23.1963-7.1486
  c4.7659-1.7264,8.5834-5.7505,13.7988-6.2368c1.7263,3.4042,2.541,7.951,6.3584,9.7139c3.7324,2.3221,8.2671,1.5682,12.4128,2.3584
  c2.3099,0.7052,4.5591-0.2917,6.7959-0.8754c-2.7961-8.0846-8.0117-15.7925-16.0842-19.2817
  c-1.9939-0.4741,0.0728-3.1367,1.0333-3.7201c3.9634-1.9818,7.2216-5.4952,11.7806-6.1395
  c1.1793,4.2795,1.9209,9.4099,6.3706,11.5983c5.2032,4.3037,12.5221,3.9512,18.4792,1.4833
  c-5.6775-6.2976-12.3154-11.8171-17.0326-18.9294c5.8113-0.5227,10.5406,3.3312,15.5739,5.5439
  c4.4375,1.4346,10.1514,3.3433,13.8959-0.6079c3.2704-3.4163,7.7443-6.8203,8.4739-11.7197
  c-4.0243-3.4041-9.2399-4.9724-14.2001-6.3827c-7.0392,0.2797-12.4857,5.0819-18.9534,7.2216
  c-1.7021-0.6809-3.3921-1.3374-5.0698-1.9695c1.1307-4.3159,4.4983-7.6835,5.1426-12.1575
  c-0.316-3.4404-2.4436-6.4434-4.3158-9.2397c-1.7872-0.2432-3.5743-0.2432-5.337-0.3281c-0.0122-0.146-0.0366-0.45-0.0609-0.6079
  c1.5319,0.0973,3.0758,0.1943,4.6442,0.304c2.6625-3.6959,10.188-5.5317,8.4008-11.0757
  c-3.9875-0.3645-7.9508-1.1304-11.4766-3.0393c-3.5013-1.4832-7.2701-4.6926-11.1241-2.2976
  c-6.8569,2.3098-9.8597,10.3824-17.1664,11.5496c-0.316-3.015,0.4498-5.9087,1.3495-8.7534
  c0.158-4.3889,0.2189-8.8142,1.313-13.0935c0.5836-2.4437,0.8024-6.3099,4.1091-6.3828c4.6321,0.9604,9.6045,2.0425,14.1514,0.0364
  c5.787-2.3828,11.7321-6.4067,13.9204-12.5464c0.4133-4.7173,0.3889-9.4707,0.8389-14.1877
  c-0.5349,0.0122-1.6047,0.0364-2.1398,0.0364c-4.8508,3.161-9.7382,6.2733-15.2941,8.0361
  c-4.2551,1.1306-6.103,7.0393-10.7959,6.2854c-0.4741-5.8113,1.7628-11.8049,5.9451-15.8655
  c1.3982-1.5076,2.8326-3.2095,2.5652-5.4224c0.8511-5.9327-3.4771-10.6377-5.5439-15.8047
  c-1.9451-3.5742-3.0149-8.498-7.4281-9.7625c-1.6899,4.0363-1.6777,8.7656-4.4861,12.2913
  c-4.097,5.9937-8.2793,12.7898-6.4679,20.3882c3.6472,4.6199,7.8418,9.6042,8.0242,15.7925
  c-0.7175,2.4194-0.3649,7.489-4.1945,5.5317c-3.6107-5.1305-4.5591-12.5466-11.1605-14.9537
  c-5.4102,0.3405-10.7959,0.924-16.2183,0.766c0.0122,1.1062,0.0974,2.2003,0.2555,3.3068
  c3.0879,4.4618,6.7473,8.8992,12.0238,10.7958c5.7383,2.152,12.4856,5.3857,14.2607,11.7684
  c0.2188,5.1427-1.0093,10.2247-1.082,15.3793c-1.1793-0.0608-3.5258-0.1703-4.7051-0.2311
  c-1.5439-6.2489,1.9331-14.5283-4.0242-19.0873c-5.5315-3.2582-11.9143-5.0454-18.151-6.3706
  c0.8022,9.5924,5.4465,18.8441,13.0206,24.9106c3.7444,2.7963,6.6016,7.0758,5.6775,11.9631
  c-1.9087,9.4342-7.866,17.4216-14.1028,24.5337c-7.6226,8.1941-8.3157,20.145-14.832,29.0564
  c-3.8904-3.1366-4.1335-8.4374-4.8021-12.9965c-1.1185-5.5316,0.2308-11.1483-0.62-16.7045
  c-0.766-3.4524,2.9907-5.2518,4.3036-7.9873c2.4801-6.2854,6.2489-12.899,4.0729-19.8531
  c-1.9817-3.9147-3.1732-8.6077-6.8083-11.3552c-0.9968,7.027-2.6746,13.9689-5.5315,20.4855
  c-0.9241,0.4133-1.848,0.778-2.7598,1.1184c-1.848-5.5681-5.2156-10.5405-6.7598-16.1815
  c-1.4343-4.8873,3.9513-8.267,3.0394-13.033c-0.6686-8.4495-5.8354-16.6191-13.5555-20.3152
  c-3.2095-1.4344-6.2367-4.4252-9.9934-3.392c0.3647,3.5988,2.8571,6.6746,2.626,10.3826
  c-0.1824,5.6169,3.5745,10.2852,3.9999,15.7683c-7.0879-2.6503-13.1423-7.8293-17.604-13.8838
  c-3.9634-3.6958-10.0177-2.6139-14.8566-1.9089c-5.3857,2.7842-11.1362,5.009-16.1086,8.4982
  c0.8267,2.9666,4.17,3.4894,6.5894,4.6322c3.4893,1.4222,6.3098,4.0485,9.6409,5.7747c5.094,0.5105,10.2853-0.2432,15.1361-1.933
  c1.702-0.6686,3.6107-0.9604,5.3978-0.2676c0.8268,1.3253,1.5684,2.6991,2.2615,4.1337c-3.3555,1.2279-7.3188,1.7263-9.6775,4.6682
  c-3.9268,4.1216-5.8964,10.0542-4.6077,15.6833c3.2825-2.2007,6.4313-4.7173,10.1272-6.2249
  c4.1213-0.9724,8.3765-0.085,12.583-0.4133c2.626-0.2795,5.2156-2.0911,7.9146-1.1914c5.0576,3.1853,7.574,9.2883,7.5255,15.1362
  c-7.1486,0.2065-10.6136-8.4618-17.7622-8.0605c-6.7108-0.0486-14.9049,2.699-17.215,9.6531
  c8.7656,3.7688,18.674,6.7595,28.1689,4.0728c6.0302-0.1458,10.0786,5.5684,12.6681,10.3461
  c-3.4042,8.8142,0.8268,17.7621,1.5563,26.6006c-0.5594,10.4188-2.699,20.8988-6.9785,30.4545
  c-0.4376,1.3617-1.24,2.4193-2.4192,3.1975c-2.7964-1.6536-5.24-3.9756-6.3098-7.0879c2.5895-2.6624,6.7595-4.7535,7.027-8.8994
  c0.6322-6.7473,0.4011-15.1726-5.7992-19.3912c-1.7385,7.7808-7.2944,13.9933-7.7078,22.1024
  c-1.5198-0.1216-3.0272-0.2554-4.5105-0.3889c-0.8511-3.088-2.4436-6.3342-1.3979-9.5681
  c1.6047-3.8173,4.7048-6.8203,6.0786-10.7593c1.8601-4.0607,0.073-8.5225-1.6047-12.3157
  c-3.7688-4.2551-7.9875-9.4099-13.969-10.0906c-0.5349,7.562-0.9119,15.1118-0.3284,22.6738
  c-3.2095-1.3862-6.3826-2.8937-9.3733-4.693c-4.1335-2.4193-6.3827-7.2701-10.8445-9.1667
  c-8.6685-0.4862-17.6406,0.0243-25.543,4.0243c1.3131,2.4922,3.8783,3.7568,6.3463,4.8751
  c6.4677,2.6261,10.1271,9.5316,17.1786,11.1483c-3.6108,4.8873-11.793,7.3188-10.7717,14.6255
  c3.4407-0.8022,6.8083-2.2004,10.407-2.2491c5.4707-0.2067,10.5161,2.6017,15.9993,2.5895
  c3.6472,0.3162,7.9509-0.8022,10.978,1.8601c0.4985,1.5441,0.5593,3.1853,0.7781,4.8022c-4.6685,0.304-8.5344-3.0759-13.2029-2.699
  c-3.3313,0.1581-7.4282-1.3374-9.9935,1.459c-3.6229,3.2947-6.5895,7.2338-9.2274,11.3186
  c2.5165,1.4833,5.1426,3.0515,8.1819,3.1125c6.9663,0.2917,14.5281,0.8022,20.6069-3.3434
  c3.9877-2.0303,8.7534-6.5651,13.3003-3.2339c4.632,2.0304,6.4071,7.1852,7.0513,11.817c-0.863,3.623-1.8721,7.3311-3.3674,10.7229
  c-4.8752,5.5439-10.8567,11.0635-12.0359,18.7346c-0.2676,5.7263-0.3649,11.5983-2.4559,17.0327
  c-1.775,4.7292-2.4193,9.8718-4.7415,14.3944c-2.5287,3.9877-4.7415,8.2306-7.8293,11.8292
  c-3.9999,1.0457-7.7565-3.7687-6.1031-7.3917c4.6321-3.6107,9.4221-8.1455,9.8599-14.4066
  c1.0454-6.4192-4.5713-10.8566-7.939-15.5251c-2.3342,3.0637-4.5469,6.3462-5.6533,10.0664
  c-0.4497,4.2794,0.4985,8.571,0.2917,12.8628c-5.811-0.1582-4.1455-7.3431-7.0754-10.6743c-4-6.3342-12.5103-5.7383-18.8928-4.4375
  c2.8083,3.4407,6.0544,6.5651,7.8174,10.7351c2.0181,4.9846,10.9417,4.5955,9.5922,11.343
  c-3.4285,0.1458-6.7474-2.1519-10.0664-0.8511c-2.2614,0.6565-4.5469,1.1793-6.6622,2.1639
  c-3.2705,1.3007-3.9027,5.1305-5.9816,7.6105c-1.3373,2.2856-3.9877,4.2794-3.3798,7.2097
  c4.3159,0.0483,7.9631-2.9666,12.2913-3.1368c6.103-0.4498,12.9113-1.0336,16.8745-6.3827
  c2.8813,2.2007,4.9116,5.2278,6.6259,8.3885c-5.4343,12.8141-20.449,18.4674-25.397,31.5731
  c-1.4589,3.8905-3.3555,7.5862-5.5924,11.0754c-3.2705-1.6899-5.1062-4.9238-7.635-7.4038
  c-4.4375-2.3953-9.4949-3.0637-14.4674-2.7598c-3.6472,0.1824-10.0907-1.7509-7.72-6.5651
  c3.5378-0.1337,7.1608,0.462,10.6986-0.1823c6.0179-2.7112,9.8477-8.7656,12.0117-14.7715c-0.4136-0.4131-1.228-1.24-1.6415-1.6533
  c-6.0057,0.9362-12.2059,0.8389-18.0903,2.48c-5.5924,1.8843-8.6925,7.1851-12.4492,11.2944
  c-4.0485-0.6687-7.6957-3.1244-11.9386-2.8204c-4.1213-0.6687-8.0604,1.1305-12.1453,0.8265
  c-0.304-0.4135-0.9241-1.24-1.2402-1.6533c3.4164-4.5591,8.4982-7.6958,12.0967-12.1941
  c8.4375-7.5011,12.0725-18.8684,20.7529-26.1021c4.4497,2.5409,8.0239,7.1608,13.446,7.6957
  c5.2036,0.0243,10.1759-2.9176,13.0209-7.2338c2.3341-3.5499,5.3613-6.6622,7.0391-10.6256
  c-2.5895-0.0608-5.1548,0.2308-7.6227,0.9362c-5.0696,1.6776-9.9813-1.3861-14.9415-2.006
  c-2.7598,0.6079-5.3372,2.1276-8.2428,1.7506c0.8267-3.3918,3.5013-5.7505,5.2885-8.6075
  c2.9178-4.243,4.4497-9.2034,6.8325-13.7379c3.6472,0.4984,6.8446,2.3828,10.0055,4.1577c2.8207,1.6656,6.1763,0.219,9.1548-0.2795
  c8.6804-2.468,13.6165-11.8051,13.3003-20.4369c-3.9268,1.3008-7.9146,2.8448-12.1089,2.1276
  c-5.4832-1.3616-9.5802,3.2217-14.2122,5.1913c-0.4376-5.6289,1.8358-10.9174,2.699-16.4005
  c0.9971-7.0999,9.337-9.738,10.9905-16.4731c1.4465-7.1854-3.0637-13.5437-5.9208-19.7439
  c-6.0059,4.8872-10.1879,11.5859-14.1027,18.1632c-2.0425,3.7203,0.8145,7.3917,2.3342,10.6986
  c2.8569,4.6196,1.5197,10.4796-0.5593,15.136c-0.6443,2.2734-3.1487,1.0457-4.7535,0.9604
  c-0.1946-2.9543,0.8633-5.8113,0.8268-8.7534c-1.0455-2.9545-3.2218-5.3129-5.8235-6.9785
  c-6.0667-5.0574-14.5647-3.9147-21.8591-3.4526c-0.9119,4.3767,3.3555,6.03,5.9451,8.5466
  c3.1365,5.4952,5.9329,11.6954,11.4888,15.209c3.8052,2.2612,6.3948,7.2946,4.7535,11.6226
  c-1.6777,4.9846-3.8539,9.957-7.1608,14.066c-0.9362,0.219-2.8083,0.6444-3.7444,0.8512
  c-0.1703-3.9148,0.7781-8.2794-1.4834-11.7686c-2.2856-3.5864-4.4374-7.6106-8.2792-9.7504
  c-4.3281-2.5043-8.8263-5.4586-14.0663-5.0209c1.0212,4.3765,2.6504,8.6438,2.9299,13.1543
  c-0.6807,3.1122-4.2307,2.8691-6.6865,3.015c-3.939-0.0121-7.0879,3.0515-9.0817,6.1394
  c-1.1307,4.5834-0.0365,9.3129-0.389,13.9689c-0.6565,2.991-2.5895,5.4832-4.1699,8.0364c-0.7539,0.0486-2.2614,0.1337-3.0151,0.17
  c-0.4376-5.9572-1.4832-12.7896-6.7474-16.4733c-3.1001-3.1853-7.8293-3.6958-12.0116-2.9666
  c1.3131,5.24,3.6228,10.188,5.6289,15.209c1.6292,4.4984,5.9694,7.5134,7.1608,12.2061c0.4012,3.9512-2.9301,6.772-4.4618,10.0786
  c-1.0941,5.1426-1.4589,10.3582-1.3008,15.6223c-0.5107,3.7932,0.6079,8.4009-2.4072,11.343
  c-5.3737,6.7961-12.9355,11.343-18.4187,18.0297c-7.4646,6.5649-11.3672,15.9749-15.4399,24.7889
  c-5.3372,8.3281-13.896,15.124-23.8287,16.7045c0.4985-4.401,1.921-8.6073,3.6473-12.6559
  c1.6777-3.5498,1.2036-7.562,1.6047-11.3552c0.0366-6.9783,2.4922-13.7256,2.152-20.7162
  c-0.2797-4.7172,0.8022-9.337,1.702-13.9326c0.9968-4.036,1.86-8.5344,5.3613-11.2335
  c9.179-6.8811,16.3762-18.0293,14.8445-29.8708c3.866-1.24,8.5344-1.3008,11.355-4.6563
  c5.6046-4.8022,2.6382-12.8019,2.9421-19.1602c-5.5803,1.9938-9.7625,6.407-14.2607,10.0785
  c-1.0699,0.2186-2.1155,0.4133-3.1609,0.5592c-1.3252-4.3037-1.775-8.7776-1.4954-13.2638
  c4.0726-1.0455,8.9478-2.2491,10.1271-6.9662c1.4103-6.6501,0.377-13.8596-2.1884-20.1449
  c-3.5864,3.5377-6.8446,7.3795-9.9691,11.3185c-2.2369,2.9788-1.7628,7.0756-3.7567,10.1394
  c-4.5833-0.1094-6.4919-6.2124-6.7959-10.0785c0.304-5.5439-3.4529-11.2943-9.0817-12.2791
  c-4.8873-0.7539-9.3978-4.1335-14.4796-2.7719c1.9209,2.2369,4.5713,3.8904,6.0544,6.5042
  c1.0455,2.7961,1.4587,5.8113,2.6504,8.5831c1.6047,5.3251,9.4341,3.8662,11.7197,8.7657c0.6565,1.3251,0.9849,2.796,1.4103,4.2185
  c-4.3158-1.0333-8.3766-3.3796-12.9235-3.1121c-3.7931-0.1339-7.2579,1.9692-9.738,4.6685
  c4.9481,6.6257,13.981,7.343,21.616,6.9419c6.2124-0.6079,11.8535,5.1062,10.4069,11.3916
  c-3.7081-0.6687-6.4313-4.2673-10.4191-3.5378c-3.5985,0.2432-8.3035,0.5227-9.9568,4.3402
  c6.2488,2.1276,10.3459,7.6591,16.3152,10.261c2.3342,1.2765,5.3615,2.9786,5.24,6.0424
  c0.9968,7.0754-6.5774,11.0266-11.9873,13.4097c-0.4014-6.4922-2.3951-12.6683-4.2673-18.8441
  c-4.8508-9.0209-15.5251-12.972-20.0842-22.297c-7.0027-9.1182-5.1304-21.1296-3.2217-31.6094
  c0.851-10.3096,8.2063-18.3943,14.6741-25.8591c4.3767,3.0273,2.5531,9.4707,6.8203,12.4858
  c4.5833,3.3918,10.0178,5.483,15.5981,6.5771c2.322-5.8964-1.4832-11.5861-3.9391-16.7651c-1.6777-3.939-6.03-5.7383-7.8781-9.5679
  c2.1519-1.1672,4.3159-2.3584,6.5164-3.4893c6.2247,4.9724,5.009,14.0662,10.2853,19.6343
  c4.4862,4.2551,10.7472,6.2246,16.8138,6.6501c2.6625-7.7563-4.9846-13.2515-6.5529-20.2057
  c6.6501,1.6777,10.8322,9.2396,18.224,8.0847c6.4313-0.7781,12.802-4.4496,16.8016-9.5801
  c-4.3645-4.3767-9.9812-7.5741-15.598-10.0664c-9.7869-0.6686-20.3151,2.2247-29.251-3.161
  c0.5229-1.4343,0.7417-3.1609,2.2493-3.9512c3.9147-2.3584,8.2549-5.1912,9.4341-9.9204
  c1.6049-5.7384-3.3674-10.1272-6.3584-14.3457c-3.72,4.9479-9.726,7.9751-12.2059,13.835
  c-1.7872,3.5745-2.0669,7.6228-3.4286,11.3188c-2.9299,4.0486-7.5376,6.8568-12.522,7.4646
  c-0.1703-4.8022,4.7048-8.7412,2.7354-13.592c-2.4194-4.948-5.9207-9.2518-9.0088-13.7745
  c-0.4619,0.3282-1.4103,0.9846-1.8723,1.313c-2.1396,6.7231-6.3705,13.5436-4.4009,20.8259
  c0.8267,5.1183,4.3037,9.8717,3.0879,15.2333c-3.0393,3.9755-6.6016,7.5498-9.7015,11.4889
  c-1.3009-0.0243-2.5653-0.0365-3.8297-0.0365c2.7598-10.3337,3.4893-21.0812,4.8022-31.646
  c0.8875-3.2581,2.0425-6.4434,2.5774-9.799c1.2644-7.3309,5.7141-13.4583,9.3369-19.7803
  c2.9301-5.3492,7.3066-9.6407,11.4645-14.0297c3.8418-3.8662,7.4039-8.0725,11.8657-11.2578
  c4.9725-3.5256,9.2762-7.963,14.7958-10.6863c4.7535-3.3433,10.7837-3.2948,15.9871-5.5804
  c9.6165-2.0911,19.5127-3.5255,29.3967-2.5531c4.5227,1.1672,8.7048,3.3434,12.4858,6.0789
  c-3.0759,4.1823-7.8173,6.8811-10.577,11.3184c-0.2675,3.6108,0.0365,7.2581,0.4498,10.8568
  c0.3405,1.4712,0.3767,5.3859,2.7961,4.3888c5.7627-4.7777,9.9448-11.4521,11.9995-18.6616
  c4.9846-0.6079,6.9663,5.1304,7.1729,9.1665c-1.8479,4.4863-7.3674,5.6414-10.6378,8.8142
  c-1.0698,2.152-1.8722,4.5591-1.5562,6.9906c0.9484,6.6866,2.9301,13.1788,5.2887,19.4884
  c4.6683-1.7871,8.2913-6.0665,9.8961-10.735c0.4498-3.9633-2.7234-9.7382,2.2247-11.817
  c5.4709,3.9268,3.5742,11.7562,6.8812,17.0205c4.4982,2.7109,8.9844,5.5315,12.8748,9.1301
  c1.3129,1.5317,3.4161,1.2522,5.252,1.4833c2.6869-4.547,2.4923-9.7745,0.8876-14.6617
  c-0.9604-5.8601-7.8416-6.4923-12.1455-8.9602c-4.3279-1.9453-6.6743-6.7354-5.884-11.3794
  c4.2063,0.7051,6.7959,4.3401,10.5161,6.0422c4.8387,0.3284,10.03,0.8633,14.4797-1.5682
  c-2.5288-4.8751-6.8933-8.486-11.0754-11.8778c-3.9878-3.3555-9.641-0.766-13.9326-3.2339
  c-4.7534-2.0669-8.7047-6.8691-8.4495-12.2305c4.5591,0.8386,8.6563,3.0271,12.9235,4.7291
  c3.7931,1.3374,6.9906-1.6898,9.9813-3.5499c2.7841-2.2006,6.6259-4.1335,6.8081-8.1332
  c-5.6897-4.0121-12.7654-4.6565-19.5005-4.1823c-5.6655-0.146-10.5894,3.1121-15.9872,4.1823
  c-6.5286,0.3524-10.8446-6.0665-17.0813-6.9906c-4.17-0.4622-8.3157-1.0942-12.3641-2.1276
  c-0.0852-1.4347-0.158-2.8693-0.1946-4.3037c2.4316-0.9484,5.0332-1.7506,7.5254-0.462c4.8024,2.0669,10.407,2.2371,15.0026-0.4863
  c6.1516-3.708,12.7653-7.9875,15.3671-15.0388c-1.0579,0.0122-3.1611,0.0608-4.2188,0.0729
  c-7.4404,2.1641-15.0632,3.9755-22.7345,4.8508c-0.0608-3.9512,3.4041-6.3097,5.8356-8.8992
  c2.4314-2.1276,4.6807-5.5194,8.2671-5.3981c5.5562,0.9119,11.2578,1.8237,16.8746,0.8391
  c3.6351-1.7388,7.2095-3.7446,10.4554-6.1516c1.4712-3.0515-1.5925-5.4102-3.6229-7.173
  c-5.8357-2.7113-12.4857,0.62-18.3578-1.5319c1.2158-7.5741,9.7017-9.5801,14.3337-14.6255
  c6.6257-4.7413,10.6741-15.4764,4.5955-22.1631c-1.9696-2.4922-5.5073-1.6898-8.2063-1.3616
  c-3.2947,2.3341-4.6563,6.2611-6.7595,9.5315c-3.2097,5.0332-3.3433,11.1851-3.8054,16.9353
  c-2.3585,4.4253-6.334,7.8171-9.2639,11.8779c-0.6444,0.0243-1.933,0.0486-2.5774,0.0608
  c-0.2673-7.5499-0.9119-16.0359-6.0544-22.0173c-4.5591,3.0029-6.1274,8.498-7.8173,13.3733
  c-1.313,6.4312,4.7292,11.428,4.2308,17.8228c0.7902,4.4132-1.5684,8.3158-4.012,11.7441c-1.301,0.1216-2.5774,0.0729-3.8296-0.146
  c0.8145-4.5469,4.0239-9.8838,1.082-14.2485c-3.2948-3.562-7.1852-6.6624-11.4038-9.0696
  c-1.8723,2.4072-2.8691,5.3613-3.854,8.1942c-1.5074,7.8293,3.3799,14.6742,5.5681,21.8104
  c0.4014,9.4585-8.1819,16.9719-17.1663,17.7014c-9.0939,3.9877-18.4916,7.4282-27.0261,12.6073
  c-6.2368,4.1702-10.954,10.1272-16.3883,15.2333c-2.1884,1.7388-3.7931,5.4951-7.0634,4.7903
  c-0.766-4.9604,0.2551-11.5011,5.568-13.4949c6.4556-2.152,12.0724-7.9998,12.6317-14.9661
  c0.5105-4.9722,1.8235-10.2-0.0244-15.0508c-1.8723,1.4222-3.6959,2.8691-5.2885,4.5712
  c-4.62,4.3766-6.2003,11.7562-12.6925,14.0784c-0.2795-5.787-2.8813-11.5497-1.5439-17.3365
  c2.5288-1.5198,5.5559-1.5925,8.2671-2.7112c2.7476-2.5774,5.8113-6.1394,4.7537-10.2244
  c-1.386-3.781-2.8571-7.5254-3.8906-11.4158c-5.021,2.6501-6.5164,8.7534-9.118,13.4095
  c-1.2643,0.0365-2.5044,0.0729-3.7323,0.1094c-0.9727-4.9967-3.7688-13.5797,3.3555-15.0387
  c7.2458-1.8723,10.9053-9.1061,13.3246-15.5494c0.7172-3.8417-0.2067-7.7566-1.0333-11.4889
  c-7.562-0.5836-13.2761,5.4102-16.7043,11.4524c-1.3252,1.9087-2.0061,4.6563-4.6078,5.1182
  c-2.8326-4.3767-3.7201-9.592-5.5559-14.3944c0.8632-2.6991,3.3918-5.3981,2.0668-8.3522
  c-1.3374-4.5713-4.6199-8.3887-8.2914-11.2943c-5.3981-2.2128-11.0999-4.2308-17.0084-4.3767
  c1.009,7.9025,3.708,15.428,7.3918,22.467c2.6989,4.0728,7.2214,6.322,10.9904,9.264c0.1459,1.0334,0.4376,3.1124,0.5836,4.1458
  c-0.6931-0.1338-2.0911-0.4255-2.7842-0.5715c-5.0576-4.2793-11.6956-5.7018-17.9324-7.343
  c3.1731,10.0298,10.65,19.2329,20.522,23.1964c4.8264,2.7355,8.0847,8.012,8.3521,13.5677
  c-0.6929,0.0122-2.0789,0.0365-2.7719,0.0486c-2.6259-2.9177-5.1305-5.9814-8.3158-8.3033
  c-4.0847-1.1066-8.4008-0.1703-12.5342,0.0363c0.3162,1.6656,0.2673,3.5378,1.4832,4.8875
  c2.9907,3.9268,6.1881,8.1697,11.1605,9.6409c5.2035,2.6016,13.5922,3.7567,13.7015,11.124
  c-0.7173-0.085-2.152-0.2795-2.8691-0.3647c-5.8235-4.9114-13.7258-2.9055-20.5098-5.1061
  c1.301,4.1335,3.5378,7.8781,5.7019,11.6105c4.1335,6.0422,12.3276,7.8049,16.1694,14.0905
  c2.7354,6.2246,0.7781,13.0936-0.3284,19.4641c-1.9816,5.8356-3.3555,11.963-6.6257,17.2638
  c-2.7231,5.8721-6.407,11.4521-7.3796,17.9686c-0.7416,6.5408-1.313,13.1425-2.9541,19.525
  c-3.4286-3.4648-6.2247-7.4646-8.948-11.4768c-3.319-5.0452-4.1091-11.1726-6.6501-16.5583
  c-1.6655-3.8175-4.6321-6.942-5.9937-10.8811c-0.6929-2.4192-0.8875-5.5316,1.1914-7.3308
  c3.2218-3.2218,6.249-6.942,7.3553-11.4768c-2.0547-7.1606-6.7961-13.1057-9.9813-19.7681c-1.5927,1.86-3.161,3.7688-4.3524,5.8965
  c-1.8114,4.7534,1.1429,10.3217-2.0182,14.6863c-2.2856-4.3281-3.6472-9.0332-5.5681-13.5193
  c-2.3098-6.2366,4.4983-10.6377,4.863-16.6191c0.231-4.4741,0.4863-9.1182-0.5106-13.4827
  c-1.1427-1.5925-1.9452-5.4346-4.4982-4.0728c-2.6262,2.5408-4.4254,5.8842-5.5439,9.3369
  c-0.9238,5.3372,0.1459,10.7473-0.3281,16.1208c-5.094-1.0577-8.5955-5.1062-11.6835-8.948
  c-4.0363-4.4739-4.4496-11.4888-9.8839-14.8076c-5.1548-1.1915-10.2002,1.1062-15.1726,2.1154
  c-2.9666,1.0092-6.249,1.7993-8.5103,4.097c2.5408,3.0393,5.6046,5.6411,8.9358,7.793c7.9266,3.781,17.8473,3.2217,24.6553,9.3613
  c1.9453,1.7627,4.7902,3.9025,3.8905,6.9175c-3.3555,0.7539-5.4465-2.4679-8.0482-3.9148
  c-6.3099-4.3887-14.3824-1.1671-20.7163,1.4832c5.3492,2.991,10.042,7.1731,15.9263,9.179
  c6.9299,2.7476,15.4524,1.3738,21.1782,6.9056c3.3312,3.7201,6.7595,8.0118,7.0149,13.2395
  c-5.1305-0.8997-8.9358-5.5074-14.3093-5.4587c-3.8783-0.146-7.3553,1.775-10.9539,2.869
  c-3.0394-5.6653-9.2278-8.4617-14.9537-10.662c-3.6837-1.2522-5.9205-5.2156-10.0056-5.5194
  c-0.1945,4.1213,0.3771,8.2427,1.3253,12.2424c2.2612,5.6289,8.2063,7.7809,13.0693,10.5771
  c-0.4865,0.936-0.9484,1.8721-1.3861,2.8203c-5.1548,0.4136-10.4918-1.2886-15.4764,0.5472
  c-2.8082,0.8876-5.787,0.6443-8.656,0.6808c-3.2826-3.2095-4.9603-7.7686-8.6563-10.5647
  c-3.3069-2.8693-8.4617-2.6018-12.1332-0.6079c-4.6322,2.334-9.3856,4.5712-13.4464,7.8171
  c6.7961,3.0393,13.2883,7.3918,21.0813,7.0391c-0.4741,1.5076-0.9604,3.0273-1.3982,4.547
  c-5.0819,1.5927-10.504,1.3374-15.5615,2.8571c-2.5653,1.7263-5.1548,3.6351-6.9783,6.1516
  c-0.8633,4.6807-0.8633,9.5071-0.4744,14.2365c3.7568-1.0699,7.3918-2.5287,11.1484-3.6229
  c5.3982-1.8479,6.1516-10.0421,12.2061-10.261c0.219,5.094-1.4711,9.9084-2.6504,14.7837c0.45,0.4254,1.3496,1.2643,1.7993,1.6897
  c6.2004-1.0454,9.8842-7.0513,13.2031-11.8899c3.0272-3.7323,3.8904-8.7412,7.0513-12.3641
  c5.1427-4.0729,12.1333-4.4132,18.4187-3.6837c-0.0608,0.7174-0.1582,2.1519-0.2188,2.8691
  c-3.0393,1.1917-6.2004,2.1034-9.0574,3.6959c-3.4893,4.024-5.2156,9.2883-6.553,14.37c5.0089,0.1096,9.3247-2.6259,13.7625-4.5347
  c5.9084-2.5046,9.2397-8.9358,15.3911-11.0026c4.1458-0.8387,8.9845-1.8114,12.6926,0.8997
  c5.5437,3.7811,12.0237,6.3827,16.4612,11.6227c7.9266,6.5894,16.3032,13.507,20.6798,23.0992
  c3.6594,8.5834,6.6138,17.8107,5.6411,27.2571c-0.9725-0.1216-2.9056-0.3771-3.8661-0.5106
  c-3.8418-4.6321-9.3856-6.9784-14.2122-10.3218c-0.9846-6.8689,3.7324-14.2728-0.547-20.5339
  c-3.8296-6.5771-12.4008-6.9297-19.1481-6.9055c1.7144,3.7688,1.7628,7.8295,1.3252,11.8657
  c-0.8752,6.0181,6.7838,8.3523,6.6987,14.1636c-2.249,0.0242-4.5347-0.1826-6.407-1.5319
  c-3.8053-2.468-8.5588-2.7719-12.3398-5.2035c-0.7173-3.8783-0.6687-7.8295-1.2765-11.7078
  c-3.5013-6.2245-9.3248-12.3154-16.826-12.9475c-4.6443-0.5227-9.4828-0.7903-13.8596,1.1426
  c3.2219,5.1426,6.5652,10.334,11.27,14.2607c3.0515,2.0547,6.8083,3.0273,9.5316,5.6047c0.766,1.7871,0.316,3.1244-1.3375,4.024
  c-5.3977,0.085-10.7593-0.4012-16.072-1.2765c-5.0696-1.0212-10.03,1.0454-14.5891,3.0029
  c-3.8904,3.088-5.5923,7.9998-7.5862,12.3762c-0.4862,3.9512-0.4135,8.012,0.2673,11.9509
  c0.6323,2.3829,2.9788,2.1884,4.9604,2.0181c7.9509-5.9692,13.2759-14.6985,20.4246-21.4944
  c1.9086-1.1426,6.5649-2.2006,6.2002,1.4224c-5.5679,4.085-7.9265,10.9175-9.6528,17.3245c2.7476-0.0486,5.7017,0.45,8.2671-0.8389
  c7.6714-3.5986,15.3914-7.4646,21.6646-13.2637c3.3433-1.313,7.0757-0.2432,10.5527-0.0243
  c0.4255,0.8265,1.2644,2.4922,1.6899,3.319c-3.4407,0.8511-7.2095,0.6686-10.407,2.2126
  c-5.3856,5.1669-6.5529,12.9598-6.03,20.1205c5.0576-0.0607,10.2245-0.924,14.8687-3.0029
  c5.2399-3.2701,7.3674-9.4949,11.6345-13.665c6.6382-2.8933,15.0024,1.6293,17.4946,8.2185
  c4.1094,7.1486,2.3831,15.9142,5.6533,23.3303c2.8328,5.0939,6.1396,9.9813,10.103,14.2728
  c3.7931,4.3401,9.6528,6.6138,12.4005,11.9021c6.7719,8.7899,8.6561,20.2544,8.3279,31.099
  c-0.5957,5.4587-4.1337,10.1151-4.5591,15.598c-0.2552,3.2705-1.4711,6.3342-2.3585,9.4585
  c-0.9849,0.3768-2.9543,1.1187-3.939,1.4954c-0.1581-3.1121,0.085-6.2124,0-9.3247c-0.8268-2.4922-2.0791-4.8265-3.3555-7.1
  c-2.6382-4.8508-8.3401-6.4554-12.6804-9.3126c-3.6594-1.8115-6.4797-5.3005-10.6985-5.8113
  c-0.0486,7.246,2.7598,14.0176,4.5591,20.9231c1.0577,3.9998,4.5347,6.5165,6.9663,9.6532
  c-0.1703,0.62-0.5107,1.8843-0.6931,2.5043c-6.553,1.4469-13.0327,3.0273-19.1116,5.9816
  c2.1277,6.0544,7.8417,10.3461,13.8109,12.2424c4.62,0.3889,9.2883-1.4103,13.8839-0.1337
  c3.6107,0.8997,7.2336,1.8115,11.0146,1.7262c0.8511,2.9178,1.9695,6.0665,0.8511,9.0697
  c-1.7993,5.2397-3.5986,10.6135-6.9784,15.0874c-4.7535,6.103-5.6532,14.127-6.6136,21.5674
  c-0.693,5.0452-2.1277,10.0907-1.7993,15.2212c0.9117,3.416,2.9299,7.6227,0.7172,10.8687
  c-4.2793-0.7173-6.4069-5.7505-10.2365-7.6715c-7.5499-3.9147-15.0145-8.899-23.7192-9.4949
  c-6.6746-0.9967-12.4858-4.6807-19.0022-6.1759c-3.1975-1.386-5.9086-3.6353-8.6926-5.6655c0-1.2157,0.0243-2.4314,0.0608-3.6472
  c5.4951-3.4161,12.1089-6.5286,14.4553-13.0571c0.6685-5.6167,0.1337-11.3064-0.1824-16.9355
  c-0.6201-0.474-1.8723-1.4344-2.5046-1.9086c-3.7931,2.9178-7.3673,6.1273-10.8442,9.4099
  c-2.8571,2.7233-3.5867,6.7594-5.155,10.2123c-4.5955,1.9209-3.3798-3.9755-2.4193-6.5166
  c1.9453-4.7412,2.006-9.9691,3.8297-14.759c2.3342-6.3705-0.6443-13.6041-5.167-18.2119
  c-3.781-2.2128-7.9509-3.8418-12.0237-5.4587c-5.3615,0.6929-2.9542,7.7079-3.3556,11.428
  c-0.9603,7.9023,4.9725,14.115,9.8478,19.525c0.4254,4.9603,0.5957,9.9813,0.1821,14.9537
  c-3.4282-2.4923-5.4587-6.9056-9.7625-8.0605c-5.5679-1.3494-11.5981-0.7537-16.5095,2.322
  c3.4402,5.155,6.7229,10.65,11.5496,14.6255c3.7687,3.2339,8.9233,3.1731,13.4338,4.5834
  c2.2734,2.6261,3.5624,6.0302,6.1882,8.4008c1.7263,1.7629,4.9725,2.9542,3.6351,6.0057
  c-6.4435,0.2797-14.6134,2.1033-19.2819-3.6229c-4.6685-5.3127-13.4581-5.7505-16.2544-12.8262
  c-2.4437-6.2732-2.4316-13.288-5.7992-19.2574c1.4711-3.6108,5.8356-5.1791,8.5588-7.8781
  c5.9086-4.1702,6.3096-12.1211,6.2731-18.6738c-1.0454-5.9694-4.2065-11.9509-9.5311-15.1119
  c-3.5503,5.0332-7.3188,10.1149-9.0696,16.1086c-0.6442,5.2885,0.9484,10.6014-0.0607,15.902
  c-2.7963-1.8843-4.6077-4.6804-6.1761-7.5741c-1.848-3.4528-5.5803-5.6774-6.5165-9.6044
  c3.781-3.7688,8.4375-6.6987,11.7808-10.8445c1.2766-9.1909-1.775-18.4917-7.9631-25.3605
  c-3.7688,7.027-8.7898,13.3611-11.8169,20.7772c-0.304,3.5012-0.0487,7.027-0.1218,10.5405
  c-1.9814-0.1096-3.9146-0.5352-5.6166-1.5319c-4.7172-2.1761-9.9935-3.4042-13.9204-6.9906
  c-2.0181-1.3252-3.1366-4.0729-1.8843-6.2732c2.8082-4.0607,6.808-7.0635,10.2122-10.5894
  c6.4679-6.0908,12.0967-13.0085,18.6619-19.0021c2.6136-2.5775,5.1305-5.4708,8.7047-6.7352
  c0.2307,2.4558,0.2186,4.9238,0.4133,7.3918c3.0273,7.1484,10.4675,10.9539,17.7742,12.2424
  c1.4224-4.7656,1.2888-9.7988,1.4711-14.7104c-3.1122-4.4741-8.0238-7.0514-12.3153-10.176
  c0.0364-0.5713,0.1094-1.7141,0.1458-2.2733c4.7657-1.8479,9.7261-1.0699,14.5283,0.1945
  c0.2065,4.9846-0.1946,9.9691-0.1581,14.9536c4.9847,7.0149,12.048,12.2549,19.9625,15.5496
  c-1.0212-4.3159-2.8448-8.3887-3.7931-12.729c-1.1671-5.5073-5.7626-9.4342-6.869-14.9536
  c4.1213-0.0608,7.416,2.6501,10.4919,5.0574c4.6686,4.5713,6.0179,11.5011,10.3824,16.2911
  c7.1364,3.9391,15.9628,3.8295,23.6705,1.775c1.6899-0.4379,1.1428-2.845,0.1338-3.7203
  c-3.9756-4.936-7.2458-10.4919-11.9265-14.8077c-4.243-3.9755-10.3824-3.0758-15.5615-4.5469
  c-3.1367-1.9453-6.4558-3.708-9.2034-6.1639c0.0244-0.7174,0.0608-2.1641,0.0852-2.8934c3.1248-0.4255,6.5286,0.1094,9.4465-1.3982
  c5.5193-2.1276,7.355-8.729,7.3429-14.1027c-5.3735-4.1823-13.7135-4.6564-18.99-0.0851
  c-3.4406,3.161-4.7777,7.7808-7.3917,11.5376c-7.1122,0.085-13.9934,2.4558-21.0933,2.334
  c1.7141-4.8872,5.7505-8.34,8.6196-12.4978c0.3406-6.7839-3.0515-12.8868-4.2793-19.4033
  c-6.5166,0.851-11.2942,7.0757-12.4614,13.2759c-1.5804,4.2915,2.0181,8.0847,2.0181,12.2913
  c-1.301,5.4951-3.7446,10.7109-6.8932,15.3914c-2.8571,3.623-6.4923,7.4768-11.5133,7.2825
  c0.085-12.4131-0.6807-25.0323-4.5469-36.9224c-0.7537-2.1396-1.6169-4.2794-1.7141-6.5649
  c2.8572-3.6838,7.5984-5.082,10.8931-8.2429c3.6351-4.1091,5.0579-10.1149,3.9512-15.4521
  c-1.6654-5.9692-7.9993-8.583-13.0935-10.9904c-0.219,6.6625-0.2432,13.349-1.5803,19.9139
  c-1.386-0.1458-2.7476-0.2917-4.0972-0.4497c-1.2644-4.8145-3.866-9.0454-6.8689-12.9478
  c4.7292-4.9237,11.0632-8.948,13.4824-15.6102c0.5229-6.0302-2.2612-11.7198-3.8781-17.3973
  c-4.182-0.766-9.0087,0.5471-12.6801,2.5895c-4.4133,5.6047-1.678,12.8871-0.3284,19.0753
  c-4.8022,1.009-8.3158-2.541-11.5862-5.4346c-4.1819-4.012-6.4678-9.6165-10.6377-13.5554
  c-8.5834-2.3101-18.37-2.7356-26.5156,1.2522c2.4071,4.8143,7.9998,7.1606,11.4523,11.2578
  c3.0516,3.7688,7.793,5.1669,12.0116,7.1848c-5.5316,2.5654-10.6621,5.9331-14.1025,11.1121
  c6.0422,1.8967,11.9993,4.3281,18.3335,5.167c5.325,0.7902,10.9661-2.7841,16.0355,0.1337
  c4.766,1.9818,6.9056,7.2458,8.0118,11.9266c-4.0364-0.8387-6.3096-4.6565-10.042-6.0789
  c-4.1943-0.1823-8.571-0.3281-12.5345,1.228c-5.2277,1.7141-8.5952,6.3948-12.8746,9.5557
  c-3.7445,0.766-7.6348-0.231-11.3308-0.8875c-3.088-2.468-4.5347-6.5164-7.9268-8.7047
  c-1.0092,6.5406-3.8296,12.583-5.7505,18.8805c-0.7537-0.0608-2.2493-0.1945-3.0028-0.2552
  c-0.1582-4.9847-3.3068-9.2762-3.0637-14.2487c4.9362-3.2947,9.3125-8.4495,9.568-14.5769
  c-1.1917-5.8477-0.8269-11.8777-2.0303-17.7256c-4.7415-0.3525-9.1426,1.6169-13.0208,4.1213
  c-1.2401,6.0544-1.2158,12.2183-1.5806,18.37c-5.3855,0.9119-6.5527-5.4222-6.1881-9.5072
  c0.4254-4.863,0.8024-10.7595-3.1366-14.3701c-5.9087-5.6045-13.2275-9.3977-19.8168-14.1027
  c-0.8145,4.6807,1.3617,9.1788,0.924,13.8716c-0.4012,5.0089,0.6201,10.2123,3.3799,14.4554
  c2.8691,3.9875,8.4371,4.3279,11.5253,8.0845c1.8358,1.3132,0.681,3.3434,0.2797,5.0698
  c-5.4832-3.9392-12.4371-4.7173-18.771-2.4924c-0.0487,1.1917-0.0244,2.3708,0.0605,3.5623
  c3.623,3.246,7.2704,6.8933,11.9995,8.4373c3.5256,0.219,7.0758-0.3282,10.6013,0.2067c2.7598,1.1914,5.2034,3.1244,7.1365,5.4222
  c2.6624,3.623,5.884,8.2185,2.8569,12.6316c-2.6016-3.4407-4.8993-9.0938-10.0299-8.8142
  c-4.5831-0.6078-7.8538,3.3069-12.1089,4.0728c-6.5527-5.7626-9.7987-14.3457-14.5404-21.5552
  c-4.2185-9.2761-8.3158-18.8562-9.5073-29.0807c-0.4011-5.0696-4.2915-8.7289-7.0754-12.6559
  c-4.2186-3.7932-9.4463-6.7717-12.2913-11.8657c-3.2703-7.9265-2.395-17.2148,1.5928-24.7284
  c3.2581-6.7716,7.1364-13.7621,13.7379-17.8107c8.5103-5.556,15.5494-13.434,25.2511-17.0447
  c4.6929-1.2644,9.6287-0.7417,14.4066-0.3284c0.0243,1.2644,0.0731,2.5411,0.1339,3.8054
  c-2.8086,1.5317-6.0181,2.6016-8.0485,5.1912c-5.1913,5.325-2.8691,13.3975-2.8085,20.0112
  c4.5834-2.5287,8.4011-6.1881,12.9355-8.802c2.5652-1.4347,4.0608-4.0605,5.8599-6.2854c1.0942,1.1063,2.1276,2.2979,3.0759,3.55
  c-1.1064,6.7838-10.1637,5.4951-14.0784,9.8475c-2.3342,2.8085-4.9727,5.7626-5.3618,9.5558
  c-0.3159,4.2551-0.0969,8.5953,1.1309,12.7168c4.486-2.5774,8.1211-6.2854,11.8049-9.8477
  c1.0454-1.1062,2.7234-0.9724,4.1091-1.3979c0.0365,5.8964-4.8386,10.1636-5.9327,15.7073
  c0.0487,7.6228,5.3615,13.811,9.8839,19.452c3.2826-7.3551,11.4404-12.9476,10.7473-21.7133
  c-2.0911-5.179-7.2336-9.4463-5.4222-15.6589c0.7174,0.0731,2.152,0.1946,2.869,0.2676c2.9666,3.6108,5.9818,8.9115,11.3311,8.6805
  c4.1335-0.146,10.8687,1.5438,11.963-4.1094c-3.465-3.9025-6.0909-8.5345-10.0543-11.9872
  c-3.5745-4.012-9.3735-3.6472-14.1271-5.1306c-0.8876-5.0332-3.6351-9.7137-3.0028-14.9414
  c3.9026,1.2158,6.723,4.2188,9.8599,6.6746c6.3096,1.1914,12.7046-0.4257,18.3577-3.2827
  c2.8693-1.3252,8.0483-2.5896,6.9783-6.7715c-3.9996-2.4316-8.6802-3.5865-12.4248-6.5044
  c-4.0607-3.1609-9.349-3.2703-14.2485-2.9907c-3.6472,1.0819-6.8811,3.2339-10.5526,4.2551
  c-3.2338-3.1489-5.1669-7.8051-9.7017-9.3735c-3.8419-2.4558-8.498-1.1792-12.7047-2.2249
  c8.571-9.3735,23.0994-7.9753,32.7158-15.8048c3.7688-3.1366,8.6682-4.9845,11.5496-9.106
  c4.4375-5.7384,9.4343-10.9904,14.2607-16.3883c5.8479,0.0365,11.7319,0.4741,17.5676-0.1823
  c3.9026-1.5197,7.4647-3.9148,10.7231-6.5287c2.8326-4.0969,6.9175-7.1606,9.3977-11.5132
  c-3.4287-1.3738-6.6868-3.4891-10.4313-3.781c-6.8446-1.009-10.8322,6.1394-16.5948,8.4861
  c-0.2067-5.7505,4.5105-9.8232,7.9509-13.8352c4.4617-5.1792,12.0115-4.7415,17.6527-7.8538
  c7.8051-7.2214,9.3004-18.0782,11.2457-27.9744c-6.2126-0.4984-14.1636-0.2554-17.677,5.8113
  c-2.468,3.9512-3.7689,10.4675-9.3735,10.6985c0.158-6.0908,5.1305-10.7717,5.3857-16.8503
  c0.6565-4.1821,1.4343-8.6075-0.2797-12.6559c-1.5562-5.8844-6.3341-9.9572-10.723-13.7988
  c-2.6868-1.933-5.252-4.5956-8.7777-4.5956c-0.1581,5.2886,0.8754,10.6865-0.9484,15.8047
  c-2.0667,7.3188,3.4529,13.4341,7.6838,18.6741c3.5619,3.8417,3.3433,9.3735,2.0059,14.1149
  c-0.1945,2.079-2.1033,3.1-3.5378,4.2915c-1.009-3.2948-1.301-6.7352-1.9209-10.1028c-3.2703-2.7111-7.4402-3.8783-11.1849-5.7384
  c-2.152-1.1306-4.6927-1.1672-6.9175-0.2917c2.3584,5.8113,4.7656,11.7686,8.7777,16.6802
  c2.4801,3.246,5.5195,6.5164,5.9084,10.7837c0.2312,5.4221-1.8601,10.7351-5.0574,15.0388
  c-3.3312,0.7781-2.4194-2.4072-2.4558-4.5348c0.4983-4.0728-2.6624-7.3309-5.3372-9.9572c-3.1367-0.924-6.5165-0.158-9.738-0.5105
  c1.2764-5.2034,5.9934-8.7169,7.0878-14.0054c-3.6837-2.7234-8.7169-1.2522-12.8627-2.942
  c-4.3159,2.8082-8.7413,5.4344-12.9478,8.4008c-5.252,0.8875-10.5405,1.7141-15.5129,3.6958
  c3.3191-5.6047,4.5227-12.6804,1.4833-18.6375c-3.0272-3.8905-6.5286-7.5864-11.0024-9.7988
  c-1.6293,5.325-3.6716,10.5283-5.1791,15.8774c0.7051,5.4709,4.182,10.0786,7.0271,14.6499
  c0.7169,6.0786-5.386,9.7258-9.5803,12.826c-0.1458-4.389,0.5835-9.1302-1.7263-13.1058
  c-2.3828-3.8661-5.1548-7.5253-8.5103-10.6013c-2.8328,2.1519-5.3494,4.8508-5.9087,8.4861
  c-2.6989,6.3948,2.0061,12.3396,5.082,17.5796c2.0791,6.0544-3.1367,10.7837-6.0422,15.4644
  c-2.7841,5.1792-7.8051,8.8142-9.8962,14.4189c-2.9298,5.8232,0.8022,12.2424-1.0576,18.2484
  c-7.2461-5.7992-12.4736-13.7258-15.7075-22.3578c-0.2917-1.4832,0-3.0272,1.0214-4.1699
  c2.5531-3.5134,4.9603-7.1973,6.2975-11.3673c-0.5471-7.2338-4.4253-13.7622-9.3126-19.0021
  c-0.681,0.0122-2.0548,0.0486-2.7355,0.0728c-1.2278,7.3918-1.7993,14.8567-1.3739,22.3455
  c-5.1548,0.5227-6.4434-5.0452-9.106-8.2793c-1.8358-2.7717-5.0939-7.1606-1.2765-9.7866
  c4.9238-3.5015,8.3766-9.3856,7.5498-15.5371c-2.8691-8.3159-10.5161-13.3976-16.2544-19.598
  c-0.1824,4.5956-0.7539,9.1302-2.5531,13.3976c-2.1276,5.0453-1.9331,10.6742-0.1459,15.7803
  c-4.1943-2.0422-7.4041-5.4343-10.8687-8.437c-0.1096-1.301-0.1948-2.614-0.2676-3.9148c5.325-2.2128,8.79-7.3431,9.8474-12.8749
  c1.3375-7.7322-1.4832-15.9142-6.5405-21.7861c-3.2097,0.3282-4.1335,3.8905-5.7748,6.1516
  c-3.0515,4.4862-3.1732,10.2611-6.3705,14.6862c-3.5988-1.2157-4.4254-5.8597-6.7109-8.6074
  c-2.4315-2.5896-1.4106-6.1638-1.1794-9.3005c-3.8784-3.4526-8.2183-6.407-13.0085-8.4371
  c-5.6411-1.2157-11.2578,0.5836-16.8745,0.9725c-1.6777,0.0364-3.2097,0.4863-4.6199,1.3616
  c1.1426,4.8873,4.717,8.9359,8.328,12.3035c5.6288,4.9358,14.3336,1.8965,19.9991,6.7717
  c4.875,3.2825,6.9053,9.3004,7.6227,14.8928c-1.1549-0.2067-2.2858-0.4133-3.4041-0.6079
  c-3.8053-12.0115-21.1903-12.0115-28.8497-3.8418c3.5013,4.0486,7.9875,7.1609,12.121,10.553
  c3.3312,2.8812,7.7928,3.635,12.0116,4.2064c4.012,0.4984,7.9998-1.5438,11.9995-0.4741
  c4.5347,0.9604,7.5619,4.7413,11.0997,7.4283c0.0729,0.5714,0.2067,1.7019,0.2798,2.2612
  c-3.7689-1.5441-7.4283-3.5986-11.5255-4.0363c-3.6106-0.3889-7.1486,0.8512-10.3337,2.4071
  c-3.1123,1.5806-6.5652,2.687-8.9966,5.3372c6.1519,4.1699,12.0725,11.0999,20.3152,9.568
  c5.629-1.6169,11.3187-3.2339,17.1299-4.0361c2.9177,0.5348,5.7505,1.9694,7.72,4.243c2.8693,3.1609,6.3586,5.9812,7.9752,10.0663
  c-0.4496,0.4254-1.3494,1.2765-1.7871,1.702c-4.8873-0.9484-8.1941-6.2246-13.5555-5.4102
  c-5.8479,1.3616-10.2974,5.7262-14.7957,9.3977c-2.8933,2.5168-7.0027,4.6929-6.7838,9.0817
  c7.8171,0.4014,15.6224,2.3466,23.4882,1.2523c6.322-1.2401,11.4646-6.1882,18.1147-6.1396
  c4.1943,4.8265,4.875,11.72,9.3367,16.4491c6.2126,6.8568,12.1089,14.9659,12.7047,24.5339
  c0.2311,9.495-6.1515,17.2394-12.1454,23.9017c-3.9875,4.0117-3.0758,10.9174-8.2792,13.9446
  c-2.2125-6.6016-4.0848-13.6893-2.7355-20.6799c2.8082-4.1335,7.8782-7.2214,8.3158-12.6194
  c0.1339-4.2673,1.8967-8.5831,0.2554-12.7654c-1.0941-3.0759-1.848-7.2095-5.5558-8.0847
  c-4.4984,4.4496-5.7141,11.0634-9.8113,15.8169c-2.2007,0.1216-4.3403-0.6323-6.176-1.7629
  c-2.8934-3.3796-3.1731-8.7655-7.647-10.8081c-4.8024-3.7687-11.0267-2.0422-16.6194-2.2854
  c-2.1519,0.073-6.103,0.1459-5.8234,3.1731c2.1398,6.7107,8.2306,11.1851,13.8719,14.9292
  c3.8174,0.9482,8.4858,0.219,11.4766,3.2705c2.0422,1.3374,1.7993,3.9269,2.249,6.0544
  c-4.1823-1.3982-8.6804-4.7781-13.1542-2.3342c-5.6776,4.4862-5.9208,12.1211-7.1366,18.6497
  c4.8145,0.0365,9.3735-1.7019,14.0054-2.7719c3.8055-2.3219,8.1942-4.8873,12.7411-2.7719
  c3.246,5.7383,1.9453,12.6074,3.3312,18.8197c1.8722,5.2522,5.2643,9.8112,7.2458,15.0267
  c-5.6532,0.5836-9.2275-4.9116-13.7136-7.5011c-10.4434-5.7748-20.6677-11.9751-31.269-17.4459
  c-9.2761-5.2035-15.8774-13.9568-20.5217-23.3182c4.4736-4.2186,10.9539-8.6318,10.103-15.6588
  c-0.1582-7.866-5.3618-14.042-9.3978-20.3274c-0.62,0.0244-1.8722,0.0974-2.5045,0.1338
  c-1.3132,8.8506-5.4587,17.0933-4.9727,26.1993c-3.7808-2.3708-7.1848-5.4344-9.5557-9.2275
  c-1.459-2.0426,0.6929-4.0972,1.3373-6.0181c2.6018-4.9724,3.9877-10.6378,3.015-16.2424
  c-3.562-7.0392-10.7227-11.0026-15.9384-16.6312c-0.4622,0.2307-1.3981,0.6927-1.8722,0.9117
  c-1.3495,7.2701-1.7505,14.7833-0.0244,22.0292c-0.4012,0.45-1.1914,1.3618-1.5928,1.8115
  c-4.9844-1.0819-5.4222-6.5649-8.4249-9.7261c-3.8781-2.2976-8.0118-4.2307-12.3641-5.4099
  c-6.7719,1.1426-13.5315,2.7839-19.8776,5.4586c6.0665,3.3312,11.0391,8.2671,17.13,11.5618
  c3.72,1.848,7.9996,0.7174,11.9872,1.1793c-0.1578,1.5197-0.304,3.0393-0.4618,4.5469c-2.4437,0.7417-5.3982,0.8633-7.1123,2.9788
  c-2.7961,3.1366-4.2673,7.1851-5.4222,11.1606c8.7898,0.2797,17.3-2.7233,25.6403-5.1426
  c4.2307-0.9849,8.0481,1.5076,11.4158,3.7441c1.7385,1.3862,4.012,4.8025,0.8752,5.9574
  c-4.1091-0.8026-8.4006-3.7567-12.5829-1.7629c-4.9847,2.4679-9.9084,5.2155-14.1636,8.8263
  c-1.6776,1.0455-0.8997,3.1609-1.0212,4.7537c7.2096,0,14.3823,2.3584,21.5553,0.7537c4.2673-0.3403,7.9994-2.6382,12.0723-3.7202
  c2.7841,0.4741,5.5195,1.7509,7.1487,4.1458c3.9512,6.103,7.7808,12.3397,12.4977,17.8958
  c0.985,1.0215,1.3253,3.4529-0.2675,4.0608c-5.6409,1.1062-11.501-0.073-17.0691,1.4346c-2.3828,0.6078-4.571,1.7383-6.9419,2.3218
  c-0.9968-0.7535-1.9452-1.5316-2.8691-2.3462c-0.1702-2.8569-0.1094-5.7748-0.9725-8.5225c-2.006-3.3188-5.8844-4.644-9.3004-5.957
  c-7.3068-1.3617-14.1028,2.6382-20.1936,6.1273c4.4373,3.1731,7.9873,7.4039,12.4492,10.5284
  c3.7203,2.6624,8.6561,0.9725,12.5708,3.1366c0.2068,1.1429,0.304,2.2855,0.2917,3.4528
  c-3.5498,2.2006-7.416,3.9147-10.8079,6.3826c-2.8934,1.6901-4.7048,5.082-8.3279,5.313c-1.3617-2.5288-2.699-5.5195-5.8356-6.0787
  c-4.9847-2.1639-9.8719,0.8022-14.6376,1.9938c-1.6171,1.7993-3.319,3.5015-4.9725,5.24c2.4071,2.3706,4.5956,5.0452,7.3676,7.0149
  c2.468,1.1914,5.1792,1.8114,7.8171,2.5894c0.0974,1.0092,0.219,2.0305,0.3405,3.0515c-2.7841,2.1034-6.0909,3.7324-8.0724,6.6866
  c-2.614,3.5743-3.4407,8.486-7.562,10.8202c-2.2128-4.4498-2.1033-9.6409-2.7112-14.4797
  c0.3889-16.8136-7.1851-32.74-6.0422-49.5903c2.2856,0.4741,4.5469,1.1185,6.796,1.8358c2.5167-1.7142,5.6776-3.0394,6.7841-6.1394
  c3.7566-7.1609,4.0118-16.1329-1.0579-22.7589c-2.5044,5.1791-3.5499,13.7258-10.5161,14.4431
  c0.0486-6.1152,3.5255-11.4644,5.6775-17.0083c1.6534-4.7051-1.532-9.1667-3.4648-13.2517
  c-3.2097-2.8813-6.6018-5.7383-10.4434-7.7566c-1.6655-0.5105-5.2765-1.2278-4.8386,1.6536
  c0.5225,8.1333-1.9211,17.9202,4.2672,24.4608c2.9176,2.6869,2.3098,6.9662,1.775,10.4799
  c-0.8145,0.0122-2.4558,0.0242-3.2703,0.0242c-1.0942-3.1487-1.4105-6.6016-3.1732-9.4949
  c-2.1031-2.4193-4.6686-4.4011-7.2338-6.2854c-4.9724-1.6412-10.3704-1.8115-15.5493-1.5076c0,5.0211,4.9724,8.5831,3.9268,13.6287
  c-0.6927-0.0852-2.0911-0.2432-2.7839-0.316c-2.8325-3.6229-5.6896-7.4161-7.0148-11.8778
  c-3.161-10.0543-7.3673-20.2059-7.0756-30.8922c1.5198-5.9572,0.9119-14.2001,7.2581-17.3973
  c2.7476,5.1062,4.5834,10.7837,7.9509,15.5251c6.1516,4.6926,14.674,7.8049,22.3696,5.7747
  c-5.6045-6.7594-5.9084-16.1206-11.1361-23.1112c-3.5499-2.5775-7.7201-4.3768-10.6134-7.7687
  c3.2216-2.4072,6.0543-5.5073,10.0055-6.7354c1.848,4.7781,3.1366,10.4556,7.8292,13.3491
  c3.8054,2.0059,7.9878,3.1609,11.9751,4.7415c3.5624,0.3889,7.1851,0.2186,10.7716,0.1458
  c-0.8024-5.1062-3.9269-9.3856-6.7474-13.5555c-2.1154-3.2703-7.4646-3.8418-7.8901-8.036
  c5.4951-2.7235,12.0117,0.1215,17.5676-2.7113c4.3401-2.6381,9.9326-4.632,11.8413-9.8109
  c1.7874-4.7659,4.9604-9.3613,3.9634-14.7107c-3.7688,1.7506-6.5042,5.4587-10.7715,6.1152
  c-3.3311,0.8875-6.8325,1.155-10.042,2.4194c-4.4254,2.8447-6.9541,7.6956-10.8566,11.0876
  c-1.9939,1.7749-4.8995,0.9602-7.3066,1.313c-0.0731-1.1793-0.1339-2.3585-0.1703-3.5378
  c1.8967-1.3374,4.5469-2.2734,5.1548-4.8022c2.7839-7.9146,2.9666-16.8381,0.073-24.7527
  c-1.2522-0.0608-2.4922-0.1095-3.7202-0.158c-0.401,2.8691,0.7539,6.3705-1.459,8.6682c-4.2307,4.8022-9.58,8.7412-12.2061,14.7714
  c-2.4072,3.1731,0.0973,7.343-1.2886,10.735c-3.1243,2.2007-7.0879,3.1489-9.5315,6.2734
  c-1.3738,1.9695-3.5015,2.796-5.8357,2.7839c0.5107-8.2671,0.2188-16.9719-4.5347-24.0962
  c-4.5225-2.553-9.4951-5.9937-14.905-5.191c-0.462,2.2003,0.4255,4.328,0.7537,6.4796c0.5352,4.6199-0.2673,9.3252,0.4742,13.9326
  c1.7141,4.632,4.5226,8.8992,8.109,12.3035c0.9603,0.9971,2.3464,2.3462,1.4346,3.8538
  c-3.1973,6.407-9.2883,11.9753-8.7776,19.7317c-0.3525,3.1123,0.6322,7.8296-2.9543,9.252
  c-6.9054-8.4128-11.8657-18.37-19.5007-26.2117c-1.5681-1.6655-3.4524-3.8174-2.5651-6.2732
  c3.015-3.0758,7.2579-6.03,7.246-10.8201c0.863-6.4314-2.2614-12.3157-4.4133-18.1514
  c-8.4858,2.6018-12.5342,11.3188-14.4187,19.2941c-4.2673-0.2069-5.0696-5.3373-6.8811-8.3888
  c1.3738-5.0817,3.7322-9.8474,5.7506-14.6981c1.0575-8.3888-2.8815-16.1573-6.4922-23.4275
  c-6.5529,3.2947-10.8687,10.0177-11.4037,17.288c0.8268,3.6108,2.2734,7.0754,2.626,10.7957
  c-1.2523-0.2186-2.4802-0.4983-3.6838-0.851c-6.3826-3.866-12.8018-7.6957-19.2817-11.3916
  c-10.1636-2.7111-19.8532,3.0637-27.2816,9.4464c-0.3767,1.0334-0.6928,2.0912-0.9483,3.1488
  c5.2643,1.6779,10.4434,4.2064,16.133,3.9147c2.0549,0.2676,4.4255-1.4344,6.2853-0.2675
  c0.2068,0.5106,0.6201,1.5197,0.8269,2.0303c-3.3918,2.6748-7.3188,4.778-10.103,8.1456c-0.8511,1.9816,1.155,3.1365,2.7719,3.5864
  c6.7352,1.9574,13.6165,4.1335,20.7163,3.866c5.3005-0.1337,10.2607-3.1243,15.6223-2.2003
  c5.252,1.1914,8.6198,5.7869,11.9509,9.641c-5.787,0.3889-9.9326-5.2034-15.8413-4.8751
  c-6.2366-1.1307-12.0237,1.6898-17.6526,3.8904c5.6775,7.6837,15.3063,10.3339,23.7192,13.8352
  c5.4708,0.5592,10.6985-3.7567,16.291-1.7386c7.4282,4.2188,13.4702,10.6135,17.9324,17.8474
  c0.4009,1.4346,0.5835,3.1852-0.5715,4.3401c-3.0515,3.7567-5.8232,7.7563-7.8538,12.1575
  c-0.4255,3.1731,2.2736,6.8811-0.5227,9.4585c-7.8538-4.3889-15.5251-9.1667-23.8651-12.6316
  c-7.1243-4.243-15.6102-5.6899-23.8043-5.009c-1.8967,1.3618-3.4165,3.1489-4.9116,4.9117
  c-1.0577,0.1459-2.1033,0.2917-3.1489,0.4254c0.0728-2.1882,0.1824-4.3767,0.2312-6.5771
  c-7.0513,1.9331-13.0086,6.2854-18.0297,11.4646c-0.4498-0.4497-1.3373-1.3494-1.7871-1.7993
  c1.0455-4.1335,4.2673-7.1243,6.1637-10.8323c2.2004-4.9967,0.4377-10.4797-0.1215-15.6345
  c-1.4466-4.1335-4.5469-7.4282-7.2214-10.7959c-1.5075-0.4135-3.0759-0.1458-4.5956-0.1702
  c-0.1824,2.2126-0.1824,4.4253-0.3404,6.6382c-1.1184,3.72-4.6199,6.2852-5.7017,9.9934
  c1.4589,5.483,4.3279,10.4917,5.7748,15.9749c0.3646,3.6958-1.5319,7.1365-2.4922,10.6013
  c-0.9362-0.1943-2.7962-0.5957-3.7325-0.7902c-2.237-5.6168-3.9633-12.0116-9.2517-15.6104
  c-4.0486-2.3341-7.7445-5.483-12.4251-6.4799c1.0699,9.3004,1.1794,19.61,7.5984,27.0869
  c3.8784,3.2704,9.5923,3.1731,13.2759,6.9055c0.3161,5.4951-0.1339,11.0024-0.2067,16.4976c-3.3555,0.1459-6.322-1.24-8.583-3.6594
  c-3.4893-3.72-8.9724-4.3279-13.7989-3.9875c-6.954,0.3038-11.4644,6.2246-16.2181,10.5161
  c0.3769,1.2644,0.7659,2.541,1.1428,3.8175c7.7079,0.4012,14.9902,5.6654,22.771,3.1122
  c4.3282-0.6685,8.7534-0.4133,13.1058-0.2065c4.3766,4.401,5.6409,10.8444,8.9721,15.9507c1.7629,2.3464,4.3159,4.17,5.24,7.0754
  c-6.8932,0.6931-13.1788-2.6138-19.5615-4.6077c-5.4709-1.5076-11.0509-2.6016-16.4733-4.2673
  c-5.8234-5.2275-10.1758-12.364-17.5798-15.6465c-5.3614-3.3069-11.6469-4.0364-17.7742-4.7173
  c0.4255,6.468,5.8477,10.5894,9.7017,15.1848c2.0424,2.8813,5.9207,2.6018,9.0087,3.3918
  c-0.0122,1.4469-0.0122,2.8815,0.0121,4.3281c-6.9297-0.1337-14.1392-0.4497-20.6069,2.468
  c-7.2216,2.7112-11.8293-6.176-18.832-6.2489c-3.4406,0.0729-6.9419-0.0608-10.3217,0.6564
  c-4.0849,2.1641-7.6957,5.1548-11.4037,7.9023c-1.6899,1.1672-1.082,3.5259-0.5714,5.1548
  c2.5531-0.5227,5.0332-1.6776,7.6957-1.5925c3.708,0.1824,6.407,3.2705,9.9448,4.0361c2.1519,0.0974,4.3038,0.1339,6.4678,0.1096
  c-0.6565,2.6501-2.006,4.9724-4.1579,6.6865c-5.9207,5.1306-9.726,12.0968-14.8322,17.9446
  c-5.4951,6.0908-7.5984,14.7349-6.2975,22.7344c7.0878-0.6686,14.9416-1.6047,20.1815-6.954
  c-0.231-4.8387,0.4741-9.6407,0.4133-14.4674c-0.5349-6.0422,2.2978-12.0847,6.4799-16.3276
  c0.5471,0.1217,1.6413,0.377,2.1884,0.4985c-0.1094,4.3281-3.8904,7.6956-3.55,12.0967c0.5471,5.1548,3.3798,9.6409,5.3372,14.3337
  c0.4376,2.1033,2.4193,1.6047,3.939,1.1305c3.3676-3.8904,4.9724-8.8993,7.2458-13.434
  c3.7446-7.5011,0.6565-16.5828,5.4952-23.7072c5.0818-3.2338,10.881-5.1426,16.5342-7.1606c0,1.1549,0.0243,3.4769,0.0243,4.632
  c-4.6198,2.2369-8.9236,5.2764-12.0237,9.4099c-3.6229,4.2793-3.3433,10.4432-2.541,15.6588
  c3.6716,0.7173,6.2125-2.6381,9.0573-4.3403c4.1457-2.5895,5.7748-7.343,8.0483-11.3915c1.9086-3.9269,5.0089-7.1731,8.4738-9.7625
  c3.9148-1.6899,8.4374-1.8236,12.6438-1.4103c9.6165,2.7111,18.832,6.5529,28.2905,9.7137
  c4.9238,0.6201,10.0056,0.6565,14.8685-0.5228c1.7019-0.2186,3.5378-1.1183,5.2278-0.3768
  c3.2338,2.2126,5.2883,5.6776,8.3278,8.1091c2.4801,2.0668,5.8356,2.0424,8.8141,2.8933
  c-0.1091,3.0394-3.7441,2.9543-5.7747,4.0486c-7.489,3.6351-15.817,4.9724-23.3425,8.4739c-3.5377,1.86-4.4983,6.0543-6.4313,9.252
  c-2.4559,4.656,2.3708,9.4827,6.3827,10.9417c5.3617-2.1155,10.6743-5.2156,13.738-10.2854
  c5.8721-6.7351,11.2334-16.4731,21.6038-15.8896c0.4255,4.717-5.0815,4.2429-7.1486,7.2458
  c-5.1547,6.2732-9.4098,13.969-9.5315,22.2239c4.6322,0.3405,7.6593-3.7568,11.6591-5.3859
  c8.802-3.9391,10.6014-14.4796,17.1055-20.7284c4.3889-4.7294,11.0757-6.7473,17.4097-6.3706
  c-0.0122,1.4102-0.0122,2.8206,0.0122,4.243c-3.3069,0.5105-7.2944-0.1703-9.7382,2.5895
  c-4.8145,4.8264-9.2277,10.6864-9.8356,17.7136c5.9573-0.3771,12.0361-1.1306,17.434-3.8782
  c3.562-2.0426,6.3705-5.167,8.7047-8.5103c3.6958-5.6047,11.1365-7.6715,17.4946-6.2249
  c11.2822,1.2644,20.9595,7.8175,29.4941,14.8687c1.9451,1.4104,2.4436,3.8784,2.9907,6.0667
  c-3.7932-0.3281-7.3311-1.7871-11.0513-2.48c-7.5254-1.009-12.741-7.2825-19.4885-10.1028
  c-5.8232-0.7174-10.0906,4.3887-11.6953,9.4221c-2.6382,4.9602-1.7993,10.6619-1.7263,16.0601
  c0.8633,0.085,2.6018,0.2673,3.4771,0.3525c4.1091-5.5562,10.407-10.334,17.434-10.9297c-0.0731,0.5835-0.219,1.7629-0.2919,2.3463
  c-3.1488,3.3312-6.553,6.5529-10.188,9.3368c-5.1548,1.6779-10.6743,3.2217-14.5769,7.2095
  c-2.5894,2.991-4.0485,7.1487-2.8203,11.0513c1.5074,6.2854,0.4497,13.3975,4.3279,18.9536
  c4.8386-5.8113,11.0756-10.407,15.3672-16.6316c0.7535-7.3309,3.5498-14.3457,7.5862-20.4611
  c1.325-2.0911,4.0481-1.0699,6.103-1.3496c0.6078,5.86-7.5377,7.2582-7.1609,13.0573c-1.009,6.0181,3.0029,11.0391,4.8508,16.4612
  c0.5471,2.1519,2.4437,3.2217,4.2917,4.1458c2.5895-4.2917,4.7534-8.8142,7.6227-12.9235
  c4.0485-5.5562,0.681-12.5709,0.1337-18.674c-0.7781-3.319,2.1398-6.6865,5.5925-5.8477
  c8.1213-0.9727,14.7712,4.8508,19.7314,10.5526c5.2156,6.2368,6.626,14.3944,9.0575,21.8956
  c1.1429,3.7688,0.6564,7.9509,2.6381,11.4644c1.6534,3.2097,2.5895,6.7961,4.7778,9.7139
  c6.0422,3.7932,12.887,6.2126,18.771,10.3096c8.6805,5.3252,15.0024,13.4828,21.5918,21.0569
  c2.5774,3.2339,7.6714,5.2278,7.0636,10.0786c-2.225,0.073-4.4132,0.1216-6.6135,0.0972
  c-2.5775-6.6987-7.2096-14.0784-14.9174-15.1482c-6.9661-1.6777-15.9991,2.5531-17.1665,10.0663
  c6.6504-0.17,12.9724,1.9818,18.2606,5.9816c-6.65,0.62-13.3854,0.8145-20.0355,0.3647c-2.626-1.8359-3.1851-5.1913-4.3888-7.939
  c-1.8478-4.9358-5.5316-9.5923-10.8324-10.9661c-3.635-0.8389-6.9905-3.1365-10.7957-3.0028
  c-4.2185,1.7142-7.7201,4.7902-11.9143,6.5651c0.0486,0.9849,0.1094,1.9937,0.1823,2.9907
  c2.6263,1.0334,5.9818,1.0092,7.8539,3.4163c2.0303,2.6625,5.1184,5.2764,4.4496,9.0087
  c-7.0028-2.0912-14.589-3.7323-21.6282-0.7052c-10.2365,4.5106-19.3302,12.6317-23.9258,22.917
  c4.1698-1.009,7.4767-4.6685,12.0115-4.2188c8.3645-0.073,17.3123,1.8479,25.0811-2.2249
  c3.6107-2.8447,6.2488-7.4403,11.1361-8.1941c2.7476-0.9119,6.8083-0.7537,7.3677,2.8571
  c-3.5865,3.0394-9.3613,3.0394-11.72,7.5132c-3.3433,5.5804-5.6045,12.0238-4.5955,18.6254
  c4.4862-2.322,7.963-6.2368,12.7654-8.024c7.9751-2.3707,11.197-10.8199,17.069-15.9626
  c4.0851-4.7051,10.8326-4.6564,16.5221-4.0729c-1.2279,6.8568-11.5131,6.6379-12.2061,13.8474
  c-0.4862,4.2188-3.2947,8.8628-0.7295,12.8625c4.8024,1.2644,9.1667-1.0576,13.2517-3.2703
  c8.7777-3.3433,8.2307-15.6223,16.3762-19.8533c3.8052-1.459,7.7563-2.7112,11.7804-3.4041
  c5.1548,1.1674,8.9602,4.9968,12.413,8.7292c5.5071,5.4952,8.3279,12.8992,12.2424,19.4763
  c6.2976,14.212,18.3457,25.8832,20.9351,41.6637c0.6565,7.2094,0.4136,14.4916-0.0485,21.7133
  c-0.0729,3.0636,0.5472,6.2125-0.3524,9.2275c-1.8845,5.8113,0.8264,11.7197,1.5074,17.5188
  c1.0697,8.717,7.2701,15.4036,10.5526,23.2454c-6.2368,3.1973-12.9355-0.0608-19.4399,0.1699
  c-7.5498,0.0487-14.3457-3.6836-21.385-5.9083c-4.5591-1.8722-8.9844-4.4742-14.0055-4.8146
  c-3.562-0.1216-6.8202-1.7751-9.4341-4.1091c0.1095-0.7053,0.3281-2.1033,0.4498-2.8086
  c6.1641-0.2186,12.7289-1.6411,17.2878-6.0908c3.9756-2.9786,4.9604-8.0481,7.1001-12.2424
  c-11.3187-0.8997-23.0627,1.6777-32.582,7.9629c-1.5562-2.48-3.3799-5.0574-2.8572-8.1576c4.6687-0.474,9.3735-0.17,14.0542-0.4255
  c2.9421-2.4435,6.3462-4.3036,9.1182-6.954c3.9756-4.2188,6.8203-10.6743,3.6716-16.1937
  c-6.3096,1.8843-12.2549,4.8022-18.1147,7.7565c-2.9177,2.541-5.6167,5.5315-9.6165,6.2368
  c-0.4012-3.7324-0.2432-7.562,1.1428-11.0757c2.8813-5.4343,8.3888-9.0206,10.5284-14.9171
  c3.3555-6.2975,1.2765-13.6771-1.155-19.9017c-5.41,2.4922-10.1149,6.4434-13.8353,11.051
  c-5.4586,7.9998-3.3066,17.9688-4.6563,27.0018c-1.3616,0.0485-2.6991,0.1216-4.0361,0.1824
  c-2.7112-3.3434-4.4011-8.0969-8.8628-9.4828c-5.0454-1.7019-10.2244-3.1002-14.9415-5.6289
  c-2.1761-1.2522-4.7172-0.5349-7.0271-0.3403c3.4894,7.4646,6.9662,15.1726,12.5345,21.3728
  c4.085,4.3889,10.5891,1.6655,15.5129,4.1458c4.7656,1.9937,5.8843,7.7319,6.3341,12.3275
  c-2.1884-0.2432-4.3646-0.5348-6.4435-1.2035c-5.6166-1.7872-11.4158,0.3892-16.7408,2.1274
  c-4.2673,2.6626-7.8781,6.2733-11.8778,9.3127c4.705,5.6045,12.814,5.6045,19.537,5.325
  c6.2368-1.1306,12.4857-2.4558,18.3822-4.8752c5.1426,4.7294,8.7412,11.1851,14.9172,14.7958
  c2.0911,0.6927,1.5197,3.0514,1.8843,4.7657c-7.1484-0.6809-14.3214-1.9697-21.5065-0.839
  c-6.8688,1.4347-13.7621-0.4255-20.6312-0.6564c-7.3676-2.1519-12.0601-8.7535-17.2391-13.9812
  c-3.7203-5.1548-8.486-9.568-11.2213-15.3916c-3.3313-4.2551-3.708-9.8232-6.4314-14.3699
  c-2.1519-3.9875-3.9026-8.1577-6.2247-12.0481c-2.1761-4.3524-5.0087-8.8871-4.6075-13.9447
  c0.0728-3.0272,3.9026-4.5104,6.115-2.6259c3.9391,3.3676,8.5225,6.5042,13.7504,7.1849
  c6.5165-1.1915,13.7988-3.2338,17.2391-9.4707c-7.5376-2.942-14.9536-7.5619-23.3545-6.8446
  c-3.088,0.9846-3.6108-2.4801-4.6927-4.4862c8.34-2.1639,17.4823-3.4406,24.2177-9.337c2.7231-2.7355,6.5771-5.6531,5.3977-10.0178
  c-7.0632-0.4983-14.1268-1.0818-21.1903-1.3616c-8.024,2.7598-9.7504,13.0935-17.5068,16.3274
  c-2.626-6.4313,0.6322-13.8837-2.8326-20.072c-1.6658-3.7931-4.0486-7.3796-7.6592-9.5436
  c-2.8936,7.0026-3.781,14.5404-3.9148,22.0416c2.0181,5.2762,6.2368,9.422,8.1577,14.7954
  c-0.6079,3.9877-1.3738,8.4497-3.9755,11.647c-1.5804-0.7417-3.4042-1.3374-4.1212-3.0636
  c-4.9116-8.34-8.2429-17.7501-15.4402-24.5458c-7.5498-6.2249-15.3914-12.1333-22.6858-18.6741
  c-6.176-5.5317-10.6257-12.5708-15.4886-19.1846c-0.1823-1.0698-0.5592-3.2095-0.7416-4.2794
  c4.4375,0.7296,8.6196,3.4286,13.2395,2.7596c1.9451-0.3281,4.863-0.2673,5.2156-2.796
  c-6.0181-8.2913-14.0055-15.829-24.1935-18.37c-2.1154-3.8296-0.5715-10.5405,4.4254-10.4677
  c3.1243,5.0089,5.033,11.6713,11.3064,13.7136c5.5923,1.4954,12.984,4.0851,17.5676-0.9603
  c-5.2765-5.5924-11.3552-10.4797-17.9202-14.4795c-1.5806-0.8754-2.6505-2.2371-2.3829-4.1215
  c5.41-1.7386,9.5677,2.5165,13.665,5.2397c7.4767,3.0516,16.4247,3.392,23.8893,1.3617c-0.4497-4.4496-0.6321-9.0208-2.176-13.2516
  c-5.9207-3.2339-12.4736-6.3341-19.3547-5.8964c-5.8965,1.5928-12.5831,6.2732-18.4307,2.2369
  c0.3525-2.8448,2.8813-4.4741,4.4009-6.6744c3.4407-4.3767,6.1884-10.0056,4.5348-15.671
  c-0.9362-2.6017-1.5562-7.5133-5.3856-6.5649c-7.1001,5.5072-12.8384,12.9962-15.8047,21.5187
  c-1.4465,3.5986-0.3284,7.5254-0.8997,11.2579c-1.6414,2.6989-3.9512,4.9479-5.3735,7.7806
  c-1.1309,1.8967-1.4712,5.0333-4.3525,4.6686c-3.0879-4.8629-4.8994-10.954-10.0177-14.1879
  c-5.0332-3.5499-10.5162-7.2701-16.6679-8.2791c0.6807,7.2581,2.1276,14.8322,6.3219,20.9474
  c4.5347,6.2731,12.206,8.486,18.2363,12.9232c0.1943,3.2705,0.3403,6.5531,1.009,9.7625
  c0.6807,4.3038,3.5623,7.7809,4.7657,11.9144c-14.735-3.088-30.0776-9.1669-38.9526-21.993
  c-4.2673-7.0026-8.5834-14.0659-11.5253-21.7253c-1.0335-1.8479,1.6049-2.5653,2.6746-3.3798
  c5.544-3.0272,9.2277-9.568,8.182-15.9264c-0.085-5.5439-1.2523-12.8748-6.9053-15.3184
  c-3.1852,2.3462-5.4465,5.6775-7.5498,8.9722c-2.6627,3.7446-2.4315,8.5466-3.0637,12.9114
  c-1.2036,0.0605-2.4072,0.1335-3.5986,0.2065c-2.5289-3.4283-5.009-7.0027-8.4617-9.568c-1.8843-1.5438-4.559-2.9666-4.6198-5.714
  c1.0819-3.7203,3.8295-6.9177,3.9026-10.9175c-0.0486-4.4375,0.778-9.5801-2.4072-13.1909
  c-3.8662-4.9481-7.8538-10.042-13.1909-13.4705c-1.3616,0.0121-2.237,0.6565-2.6381,1.9451
  c-1.3738,3.5256-2.1032,7.3553-1.6048,11.1364c0.924,6.2125,7.6713,10.2853,7.4525,16.7408
  c-6.3827-0.9849-11.1362-6.7109-17.7378-6.9055c-1.9088-0.3889-4.3402,0.1458-5.5803-1.7264
  c-3.3677-4.243-8.5953-6.5406-13.8839-7.1727c-8.2063,2.9907-12.7289,11.3672-17.3609,18.2362
  c4.4739,0.2675,8.9479-0.2067,13.3975-0.5714c5.5438-0.4257,10.504-3.7811,16.1818-3.1489c4.2186-0.4498,7.5377,2.5896,11.27,4.012
  c0.0608,0.5348,0.1824,1.605,0.2432,2.1396c-3.4284-0.5105-6.869-1.8966-10.3582-1.2278
  c-6.1395,1.8479-11.7806,5.9207-14.9537,11.5496c6.0544,3.0515,13.0693,3.246,19.6465,2.3463
  c5.2885-0.8511,10.0907-3.9634,15.4764-4.1456c7.3553,2.1033,13.0207,8.2671,16.3397,14.9658
  c-6.5164-0.766-12.8748-5.0576-19.5493-2.5531c-5.0211,0.8143-8.0726,5.0939-11.6833,8.2063
  c1.4953,1.2644,2.6017,3.5377,4.8022,3.5499c8.7777,0.3892,17.5798-0.0486,26.3573,0.0852c3.3068,1.0212,6.553,2.395,9.5923,4.0728
  c4.486,5.6532,8.0603,12.0115,10.881,18.6372c-8.7414,0.9241-17.4461-0.8387-25.6038-3.9147
  c-1.9088-1.1427-2.9786-3.173-3.8053-5.1305c-1.9209-5.0817-6.9298-7.8538-10.8566-11.2214
  c-7.2823,0.2798-14.8322-1.0576-21.8835,1.2888c5.094,5.5194,12.1818,8.4495,17.7742,13.3611
  c0.0365,0.5836,0.0973,1.7506,0.1215,2.3342c-5.5316,0.3281-11.0998-0.1216-16.5585,1.0092
  c-4.8995,1.1062-8.5953-3.088-13.1909-3.7203c-2.7719-0.5592-5.556-1.094-8.3765-1.0455
  c-2.9908,0.7052-5.8964,1.7872-8.9358,2.2491c-3.3676,0.6809-7.4282,1.1793-9.2153,4.5348
  c6.2003,6.1395,15.6588,6.4313,23.7435,7.9877c-1.6048,4.4858-6.6988,5.7505-10.3703,8.0603
  c-6.5164,3.2704-14.6619,7.4282-15.3428,15.6345c-1.6291,6.2246-1.6899,14.6255,4.2673,18.5403
  c3.9755-4.2795,8.8264-7.6471,12.6924-12.0238c3.6837-4.3036,1.544-10.9661,5.4709-15.1119
  c2.1033-2.0789,3.8661-5.3856,7.3431-4.644c-0.9604,3.6836-3.6837,6.7473-4.012,10.6378
  c-0.6322,3.6957-0.0729,7.4404,1.2765,10.9175c1.9453-0.3768,4.0242-0.62,5.5803-1.9696
  c5.1791-6.1394,7.2336-13.9689,10.3096-21.2148c2.2856-5.7383,7.7686-11.0511,14.3701-10.3215
  c0.3283,2.0789-0.4134,3.5133-2.2248,4.2915c-7.2702,2.8691-10.2853,11.0146-11.3917,18.1755
  c5.3614-0.2068,10.2123-2.7598,15.0145-4.8995c6.1882-3.1488,8.1577-10.4918,12.972-15.0995
  c2.6625-0.2311,5.3857-0.0852,7.9631,0.6929c5.6411,1.6777,11.7198,0.4255,17.3609,2.1517
  c3.6716,1.5684,7.4039,3.0516,10.9904,4.8387c4.3401,2.8206,7.7563,7.0879,12.741,8.875c6.3219,2.395,12.984,4.0242,19.7438,4.3158
  c4.1334,0.1459,7.8781,1.9696,11.4644,3.8539c0,0.5471,0.0121,1.6414,0.0121,2.1882c-4.5104,1.4225-9.5193,0.2676-13.75,2.6624
  c-3.3191,1.1672-6.3462,3.5015-9.957,3.5745c-4.5347-4.9967-7.4402-12.0603-14.4188-14.2122
  c-6.1273-3.6593-13.6772-1.8357-19.5979,1.2157c-5.3978,4.693-9.9082,10.5771-12.6801,17.1908
  c3.5499-0.5835,6.5284-2.6504,9.7139-4.1823c6.8932-2.3588,14.2607-0.1703,20.8378,2.2247c1.386,3.5989-3.173,4.2067-5.4222,5.5684
  c-6.0909,3.562-12.0844,7.3309-17.2271,12.1938c-4.3282,2.7842-7.9389-3.319-12.2914-2.8813
  c-4.7293,0.7053-9.6407,1.0699-13.9932,3.1853c-3.9998,1.8601-8.6561,3.161-11.1849,7.1001
  c-2.9664,4.2914-7.2459,8.2668-7.4282,13.8352c9.8597,0.0121,19.683-2.5046,28.1689-7.5863
  c1.8358-1.3131,5.8599-2.6625,6.2612,0.6927c-3.9755,5.082-7.9267,10.3462-13.1908,14.2002
  c-4.0241,3.4282-9.4464,6.0544-11.2213,11.3916c-1.2401,10.3215,3.6107,19.9504,3.866,30.2113
  c1.9695-1.3981,3.4649-3.2826,4.8752-5.2156c2.5652-3.5377,6.6137-5.5558,9.4586-8.8142
  c2.6625-5.1305-0.1337-10.7594,0.0729-16.1329c0.0973-3.7566-0.9604-8.0481,1.6292-11.2214
  c2.0303-2.7112,4.3768-6.5405,8.3644-5.4221c-3.0879,5.5195-3.8297,12.0723-2.1398,18.1631
  c1.5683,3.6959,3.3192,7.3311,4.8388,11.0513c0.9968,0.2188,2.0059,0.4498,3.0272,0.6809
  c1.1426-5.6289,4.7535-10.3096,6.1516-15.8655c0.6807-7.5134-0.085-15.0752,0.0728-22.6008
  c2.6627-5.7262,6.4922-11.3064,12.0726-14.5283c3.6472-2.3828,7.3066-5.4708,11.9872-4.9116
  c-0.377,4.7173-6.0909,6.9177-5.8843,11.7563c-0.0972,6.8689,1.6047,13.665,3.55,20.2179c0.778,0.0365,2.3342,0.1216,3.1122,0.1581
  c3.72-8.4375,11.1484-15.124,12.7897-24.4609c0.6079-2.8206-0.1337-5.9329,1.3252-8.5466
  c4.1337-4.717,10.0299-7.9875,16.3153-8.4617c3.9998,1.2644,7.9144,2.8934,12.0967,3.5865
  c13.5923,2.2734,25.5793,11.0997,32.9348,22.5887c2.6989,4.6805,4.5347,9.8232,7.3676,14.4308
  c3.6472,4.5591,9.7745,6.2491,13.2274,11.0635c4.8872,5.9935,11.7319,11.7075,12.218,19.9381
  c-7.9144,1.1552-15.9261-1.4465-23.8043,0.0852c-3.3796,1.532-6.9298,2.5896-10.358,3.927
  c-5.7385,3.1001-11.0878,8.4371-17.9688,8.2549c-5.0333-2.7598-8.717-7.3918-13.665-10.2852
  c-5.7749-4.0243-12.814-5.2766-19.4036-7.2217c-1.702-3.8906-1.2885-8.5103-3.793-12.0725
  c-3.1367-5.1913-9.9448-5.5559-15.355-6.1638c-10.4919,1.4712-18.5159,9.264-25.2388,16.8746
  c2.5894,2.9907,6.7473,3.8053,10.5161,4.0365c5.9935-0.1948,11.4037-4.1824,17.5189-3.3799
  c0.9238,2.2612-0.0122,4.3767-2.468,4.8265c-6.0666,1.4833-11.4402,4.7413-16.2302,8.6683
  c-5.1183,0.2917-10.2607-1.1064-15.3427-0.0973c-6.1274,1.2279-10.9418,6.0179-13.9326,11.2942
  c-4.4738,6.3949-6.4192,14.8323-4.2065,22.4186c2.8935-2.3467,5.0211-5.4709,7.9024-7.8296
  c3.2702-1.6414,6.8932-2.5165,10.0299-4.4619c4.3525-2.7717,6.9175-7.6348,11.5618-10.042
  c0.1702,3.1367,0.0853,6.4192-1.7141,9.1302c-2.1884,3.5745-2.7598,7.7445-3.8905,11.6957
  c-2.4315,1.9086-5.7141,1.6046-8.5346,2.4558c-6.6138,1.2036-12.7167,4.1943-19.379,5.2032
  c-6.4192,2.0304-12.2548,5.5802-18.4551,8.1699c-5.945,0.7294-12.1089,0.8876-17.8229-1.1429
  c-10.4311-3.1609-21.3364-1.2279-32.0106-1.702c-9.6166,0.2067-19.914,2.9421-29.0564-1.1671
  c-4.2916-4.9846-4.0849-12.51-7.3917-18.1025c-5.7626-5.3127-14.2729-4.8386-21.0566-8.109
  c-3.1488-1.2401-5.1183,1.9088-7.2458,3.5985c2.9056,5.0579,5.9815,10.0786,10.0542,14.2974
  c2.395,2.1641,2.3343,5.7626,1.313,8.6077c-2.5774,2.1396-5.7991,3.4526-9.191,3.2216c-1.9573-4.024-2.0911-8.7899-4.8387-12.4371
  c-3.55-5.5315-10.2731-7.501-14.1878-12.7046c-1.9938,1.386-3.9269,2.8693-5.8599,4.3281
  c-1.5927-0.5471-3.1609-1.0941-4.7415-1.6292c-2.4801,2.0059-5.9085,3.4407-6.4921,6.9177
  c5.3493,2.1154,8.8871,6.7107,12.3763,11.0511c-0.7538,1.5197-1.5927,3.015-2.2249,4.5956c1.2766,1.9209,2.93,3.5376,4.3646,5.3491
  c-6.9541-0.6199-13.8596,3.0029-20.6191,0.4863c-4.9603-0.4012-8.5832,3.8904-13.045,5.3492
  c-4.3645,0.8632-4.1092,6.3219-7.258,8.6196c-5.1061,3.4771-6.0787,10.2367-6.9905,15.8899
  c4.4861,1.7142,8.413-2.0546,12.7046-2.7719c5.6776-1.9574,12.9355-2.4316,16.2667-8.1943
  c2.8935-4.8387,6.4678-10.1394,12.3763-11.4038c0.0243,2.4923,0.1581,4.9846,0.1581,7.489
  c-3.4771,6.0667-5.2399,13.6165-2.5652,20.3274c0.4742,0.0243,1.4225,0.0486,1.8966,0.0608
  c5.7991-5.082,12.5101-9.1062,17.8593-14.7227c4.7172-4.1335,5.1305-11.3187,9.957-15.3551
  c2.6625-1.2036,5.5438-1.8236,8.4251-2.2977c0.8632,6.7596-6.0909,10.6014-7.3309,16.9111
  c-0.7659,5.6411-0.6443,11.4038-0.4134,17.0813c0.3769,0.5835,1.1428,1.7385,1.5197,2.322
  c4.2308-4.0969,6.5772-9.884,11.4645-13.3245c6.1882-4.231,12.0967-9.4464,15.3792-16.3398
  c1.2887-2.7961,3.9634-4.5469,6.5651-6.0056c4.8022-0.3892,9.6409,0.4011,14.4796,0.0607
  c3.4649-0.0364,6.7474-1.4225,10.2245-1.3373c0.7781,5.252-4.6928,8.1819-7.8902,11.4037
  c-4.3767,3.3555-8.2064,7.4161-12.9235,10.3096c-5.6411,2.7233-12.2791,1.3251-17.9809,3.7809
  c-7.7564,4.1094-12.5344,11.7563-18.8077,17.6284c-5.0696,6.249-10.7715,11.9265-16.4855,17.5797
  c-5.8113,6.1274-9.7867,13.6407-14.9051,20.303c-2.5531,3.4284-4.0363,7.4526-6.0909,11.1606
  c-2.9056,0.5714-5.872,0.0851-8.7899,0.2553c-4.8508,0.1215-9.4585,1.8722-13.9325,3.5623
  c-3.0029,2.6747-6.0301,5.4223-8.5467,8.5588c-3.8174,7.0392-4.5469,15.209-3.6351,23.0871
  c4.3645-1.5927,8.3035-4.1214,12.6438-5.7992c7.6592-3.088,7.1486-14.6497,15.8047-16.3275
  c-2.468,9.2154-5.9085,18.1997-7.0756,27.7312c-5.8356,4.4132-14.1635,3.4406-20.072,7.8416
  c-4.1944,3.1123-7.1851,7.5133-9.568,12.0967c-2.0303,4.3281-1.313,9.2762-1.082,13.8839
  c4.5226-1.5926,9.3005-2.2005,13.8961-3.5135c6.1638-3.1488,8.2549-10.0419,11.8292-15.4279
  c0.8997-0.0972,2.6747-0.2917,3.5621-0.3889c-0.8024,7.8051-4.2673,15.136-3.9147,23.1112
  c0.462,3.8663-2.1762,6.8204-3.8539,10.0056c-2.6382,4.3037-1.155,9.5071-1.2643,14.2485
  c-0.1581,5.8113,0.8267,11.7077,2.9421,17.1056c1.6413,1.9209,2.7355,6.1395,5.9451,5.0089
  c4.2551-2.8449,3.6837-8.571,6.3705-12.4614c3.623-5.4101,6.0423-11.6469,6.5164-18.1755
  c-1.1671-6.5407-7.1243-11.4402-7.0027-18.3092c0.0486-6.4556-1.0577-13.2516,1.3252-19.4155
  c3.5986-0.4741,3.5013,3.2339,4.2673,5.7141c1.8965,9.4828,8.1212,17.4946,15.7318,23.2208c0.7173,0,2.164-0.0244,2.8813-0.0365
  c-0.0729-5.1183,1.4711-10.1636,0.8754-15.282c-0.6565-3.708-1.3616-7.4525-2.7354-10.9661
  c-2.9178-3.6107-6.4921-6.6137-9.726-9.9084c-1.6899-1.8115-4.1457-3.3798-4.2065-6.1152
  c-0.7416-5.5924-0.4012-11.3065,1.0334-16.7531c1.3373,0.0486,2.6747,0.1215,4.0241,0.2066
  c1.2523,2.2978,2.1519,4.8508,4.0728,6.7231c3.939,3.8661,7.2945,8.7899,12.8748,10.419
  c4.8143,2.4437,10.3218,1.3008,15.1361-0.5106c-2.3343-5.0089-7.9753-7.2823-10.2002-12.3156
  c5.1791-3.2338,10.2609-7.3552,12.4128-13.2638c0.7902-2.4193,2.3343-5.252,5.3858-4.3524
  c0.3768,5.8842,1.3494,11.6955,1.6048,17.5919c2.2856,9.6287,6.6501,18.8198,12.5465,26.7586
  c3.55,3.088,7.6592,6.3948,12.425,7.0878c1.0455-10.115,1.2643-21.0568-3.939-30.1384
  c-5.5438-3.7445-12.8383-5.5803-16.1087-12.048c-0.9482-4.1822-1.3616-8.4617-1.4954-12.7289
  c1.1063-0.1337,2.2248-0.2432,3.3433-0.3525c1.7871,4.1214,3.477,8.7413,7.2458,11.4888c3.246,1.6292,6.6136,2.9786,9.9691,4.3646
  c4.3038,2.1032,9.2154,1.0942,13.8231,1.2037c-0.1337-2.006-0.3282-4.0848-1.86-5.556c-5.1791-6.2003-7.8902-14.9537-15.513-18.759
  c-3.7688-2.1397-8.498-1.3738-12.1332-3.5986c-4.8022-6.4313-4.5834-15.8533-0.7294-22.6981
  c3.3919,3.4163,5.0818,8.0726,8.2427,11.6833c3.5014,3.6837,7.8902,6.7474,13.0693,7.2458
  c2.7111,0.304,7.9753,3.4528,7.6835-1.4954c-1.7264-7.1851-5.5195-14.3701-12.2183-17.981
  c-4.9481-2.0668-10.6743-3.246-14.3458-7.4282c-2.395-4.0605-1.6656-8.9844-1.4711-13.4705
  c1.082-4.4375,4.6807-7.7565,8.1699-10.4554c6.4434-5.325,15.2941-3.2947,22.8682-5.2399
  c7.027-4.1458,11.1606-11.501,16.8503-17.1178c4.9846-4.1821,10.8809-7.1365,16.7652-9.8596
  c2.2856-1.1552,4.8994-0.5715,7.3552-0.5229c-1.2401,5.0575-5.252,8.875-6.5043,13.9082
  c-0.5592,4.4497-0.0364,9.1547,1.9573,13.2273c0.9848,2.2979,1.9695,5.2156,5.0089,5.1426
  c1.6899-5.0574,2.4558-10.5161,5.1426-15.1725c2.553-3.1123,5.8721-5.8721,9.9326-6.6379
  c1.082,7.0027-4.5104,12.583-5.0818,19.3304c-0.3405,2.626-0.9969,5.2034-1.5197,7.8051
  c-6.8082,1.6777-15.2576-0.9725-20.4975,4.8629c-5.4952,7.5742-6.6866,17.1664-6.2733,26.2968
  c0.5349,0.0607,1.6048,0.1945,2.1397,0.2553c4.3889-2.0303,9.1301-3.7324,12.7411-7.0757
  c3.6715-4.0606,4.6198-10.188,9.7138-13.0207c1.2887,5.0211-0.8997,9.8354-2.2613,14.589
  c-2.3707,6.6988-9.0452,11.1727-10.0786,18.4429c-0.7538,6.9784,1.1671,13.9203,3.0029,20.5948
  c5.2521-8.1455,16.5829-11.6955,18.759-21.7983c1.0455-2.541-0.8753-4.8144-1.6292-7.1243
  c-2.9543-5.9572-2.1641-12.9842-0.0851-19.0751c3.8053,2.9664,4.5347,7.6835,5.9936,11.9508
  c3.3919,4.6685,7.0513,9.2762,12.1697,12.1454c2.3464,1.2036,4.1822,4.9117,7.1608,3.4283
  c0.6199-6.7715-0.4012-13.6285-2.7354-19.9869c-2.8691-5.9694-9.6652-8.1212-13.6893-13.0693
  c-1.9939-2.395-4.8873-4.8265-4.5226-8.2428c0.0486-6.6866,0.5228-13.6407,3.3068-19.7802
  c0.7173-0.4012,2.1641-1.2158,2.8813-1.6292c0.6079,4.012,0.7416,8.0969,1.4468,12.0967c1.8722,3.2339,4.9238,5.6046,8.0361,7.5863
  c3.7202,2.4315,7.647,4.9845,12.2669,5.0453c2.079-9.5436-2.5652-19.5249-10.3582-25.093
  c-4.7779-3.2703-7.9024-8.9602-6.6866-14.832c1.8115-3.2218,4.863-5.5316,7.8781-7.5621c3.8417-2.7596,8.7776-2.9542,12.826-5.3127
  c5.24-2.7233,11.2215-2.6382,16.9719-2.4679c0.3525,0.8511,1.0699,2.5652,1.4224,3.4163
  c-6.9297,4.3401-13.4585,10.042-15.8778,18.1267c-0.3525,4.4983-0.1459,9.0088,0.1095,13.5071
  c4.0119-1.7144,8.0481-3.5623,11.5375-6.2368c4.863-3.9878,5.6895-10.6865,7.1242-16.4005
  c0.6931-0.1094,2.0912-0.3405,2.784-0.4498c2.3342,3.2339,2.9544,7.2823,4.1944,10.9905
  c2.3951,6.7595-1.6288,14.9414,3.5015,20.8379c3.6594,6.8203,12.4493,5.2034,18.8564,5.0575
  c4.1577-0.6444,7.501,3.2582,11.6223,2.6261c2.8569-6.9055-1.775-14.4431-7.2458-18.6497
  c-5.6046-3.4406-12.5101-4.6927-17.3245-9.4585c-4.6563-3.8175-6.3342-10.0664-6.3463-15.8778
  c0.6931-0.0121,2.0912-0.0485,2.7841-0.0729c2.9544,3.161,6.3827,5.8965,10.1638,7.9996c3.0636,0.8146,6.2488,1.1915,9.3613,1.8966
  c-0.0852-2.7842,0.4255-5.6897-0.3282-8.4009c-1.1914-2.1154-2.6139-4.1091-3.8174-6.2003
  c3.8663-2.8085,8.8021-1.3131,13.2151-1.5319c5.9451-0.2432,12.2792,0.7659,17.8108-2.0181
  c10.6256-4.8022,21.6646-10.3096,28.6187-20.0598c3.6108-4.012,7.0391-8.8628,12.4736-10.4556
  c4.4495-1.4102,8.5223-3.9147,13.0327-5.1062c10.6985-0.073,20.3274,5.7505,28.2419,12.4614
  c5.9573,7.5985,10.9418,15.9142,15.8047,24.2419c1.1185,1.775,2.2734,3.8662,4.5348,4.3282
  c8.0848,2.1398,16.3763-0.9362,24.5461,0.4012c-0.6565,1.629-0.839,3.6472-2.5289,4.5833
  c-5.1426,3.5378-9.7137,7.866-14.832,11.4038c-3.0273-1.3009-5.4709-3.7202-8.5956-4.7537
  c-3.2581-0.2432-7.1243-0.8752-9.726,1.5684c-2.7841,2.4193-5.8113,5.0088-6.8811,8.6804
  c-2.3098,5.7141,0.304,11.9751,3.562,16.741c1.1184,1.4346,1.921,4.1335,4.2429,3.4041
  c2.6624-4.8508,4.6077-10.3582,8.7899-14.1879c1.6779-0.766,3.6351-0.2917,5.4465-0.462c-1.5804,3.55-3.5499,6.9419-4.8022,10.6135
  c-1.0941,3.1001-0.5714,6.8447-2.9541,9.3734c-2.5045,3.2096-6.942,4.243-9.1425,7.7687
  c-3.9026,6.4556-2.2129,14.5647-5.313,21.2878c-3.7563-1.8358-5.1548-6.5529-9.276-7.8173
  c-8.3644-2.3221-17.5553-2.0181-25.2876,2.2248c3.9391,4.7171,10.8687,3.55,15.1483,7.6714
  c1.9331,1.5197,4.2794,2.9664,4.5347,5.6776c-7.8538,0.7902-15.8289-0.3282-23.7312,0.2067
  c-2.5653-3.4649-4.8145-7.2216-7.9146-10.2245c-5.1306-4.3038-12.1941-0.6686-17.9689-3.088
  c-2.5895-0.3647-6.2854-2.9299-7.8417,0.3647c2.5168,5.325,3.0515,11.8657,7.8175,15.8412
  c3.6593,3.3676,9.0573,2.0181,13.5798,2.3221c0.9603,1.2766,1.933,2.5652,2.8934,3.8782
  c-3.5986,2.7598-6.6016,6.2247-10.3217,8.8264c-3.2584,2.079-7.5743,2.4437-10.2975,5.3493
  c-3.8539,5.4589-5.9084,12.1089-6.2488,18.7712c5.7017-2.4435,11.0996-5.5438,16.8744-7.8538
  c5.4467-1.9451,6.7839-8.1577,9.8234-12.4128c0.9119-1.7628,4.085-1.9938,4.8022,0.0486
  c2.0547,3.3433,2.7839,7.4405,5.5073,10.3704c3.5986,2.2856,7.8171,3.3433,11.7805,4.8387
  c0.2432-8.7291-6.2002-15.5981-9.9326-22.9898c1.8479-5.4344,8.498-4.2065,12.9962-4.401c2.1761-0.3647,4.2186,2.0911,2.322,3.8904
  c-1.6534,3.246-4.8993,6.1517-4.2551,10.1394c-0.0121,7.2216,7.7079,10.8688,10.3461,16.8989l-0.3403,0.8389
  c-8.4011-0.9118-16.814-2.0911-25.2998-2.4193c-7.7444,0.7294-15.3064,2.7841-22.771,4.9359
  c-4.7173,1.5683-8.9966,4.243-13.7258,5.7626c-3.5864-0.0729-8.1332,1.313-10.7593-1.848c-2.6382-3.088-6.176-5.8234-7.2703-9.884
  c-0.7781-3.6229,3.1367-5.4587,5.094-7.8173c5.1184-4.1335,5.5803-11.3307,5.0576-17.3973
  c-1.4103-2.8813-3.4773-5.4101-5.7749-7.6227c-3.9147-0.8754-8.0118-0.8268-11.9873-0.4863
  c-1.6898,1.3373,0.7781,3.1245,1.2888,4.4861c3.3798,4.3889,3.4407,10.2488,2.9055,15.5251
  c-0.8875-0.0729-2.6747-0.2432-3.5745-0.3282c-2.4556-4.9116-4.2793-10.6378-8.8748-14.0176
  c-6.0666-2.7841-12.3765,1.848-18.5887,0.7173c-1.921-5.4952-0.1339-12.2305-4.3646-16.7895
  c-2.6259-2.6747-5.7383-4.8144-8.5103-7.3188c-2.1519-2.0181-5.3372-1.5562-8.0118-1.933
  c0.8875,5.6533,0.5714,11.3187-0.4255,16.9476c-1.1308,5.7748,5.1791,9.0816,10.0421,9.58
  c-0.1703,1.2766-0.2675,2.5652-0.5836,3.8297c-2.8693,2.1154-6.1152,4.1578-9.8112,4.0485
  c-3.3919,0.0244-6.2368,2.1154-8.8628,3.9998c-3.3555,2.1154-4.3766,6.1882-6.1031,9.5193
  c-1.4954,5.483,1.4711,10.723,3.6716,15.5616c0.462,1.1672,1.3253,1.6899,2.6017,1.5927
  c6.407-4.1944,9.2883-11.6348,12.0116-18.4429c1.6656-4.0241,4.9116-8.9114,9.9692-7.331
  c-1.4711,3.8661-4.9845,7.1243-4.4738,11.5618c-0.6201,6.1395,2.8691,11.5862,7.0513,15.744
  c2.0911-4.0242,3.5377-8.3522,5.9449-12.194c2.6627,1.5319,1.8115,4.79,2.4315,7.331c0.3647,2.9664,2.0547,5.483,3.4528,8.0726
  c4.4617-3.0637,8.3157-6.8569,12.1209-10.6743c1.7019-1.2643,3.3555-3.8175,5.7871-2.8691
  c1.7505,1.3252,2.6989,3.3555,3.4285,5.3735c1.2158,3.4163,3.5986,6.3341,4.4009,9.9084
  c-3.0879,2.0303-5.7748,4.6685-9.0815,6.3583c-3.1489,0.6201-6.4922,0.8389-9.6408,0.1945
  c-3.4285-3.5257-7.5134-6.2366-12.4371-7.1121c-5.8234-2.4922-9.7138,4.4376-15.0024,5.0818
  c-4.9967-0.9726-9.045-4.4983-13.7866-6.176c-3.8539-0.0729-7.5984-0.8997-11.3916-1.3252
  c-3.9147,1.7142-7.8294,3.4892-11.5739,5.556c0.0365,0.5228,0.0973,1.5683,0.1337,2.0911
  c4.3766,4.6442,9.9326,8.1698,16.2059,9.5679c3.9512-0.1702,7.7444-1.5927,11.7199-1.6292
  c-0.2311,1.8358,0.3767,4.0485-1.1916,5.4465c-3.5499,3.6351-5.7869,8.3522-9.5801,11.7441
  c-7.6592,0.851-16.1694-1.7507-22.9777,3.0637c-4.2673,3.9877-9.106,8.4738-10.3217,14.3702
  c2.237,2.4801,5.1304,7.9389,9.0208,5.1669c5.8234-2.1276,10.0178-7.4769,16.3275-8.413
  c1.1672,8.6926-7.1486,14.6376-8.3522,22.7709c-1.0334,8.9723,4.4375,16.9597,9.3613,23.9745
  c3.5986,0.8997,2.4922-3.4284,3.2338-5.6289c0.4257-3.5378,3.2097-6.0909,4.3525-9.3613c1.4224-3.1853,1.2766-6.7596,1.228-10.1515
  c-0.5836-3.4163-3.6716-5.6776-4.8995-8.802c-0.2796-1.9088-0.1944-3.8297-0.2187-5.7262
  c0.9725-0.5593,2.9055-1.6777,3.8781-2.2491c1.1184,7.3796,6.942,13.0207,12.8869,16.9476
  c0.8024-7.6228,2.4801-15.2455,1.9452-22.9412c-1.4832-4.7415-7.2823-8.6318-5.0574-14.1514
  c2.3342-3.1488,4.2795-7.6592,8.7899-7.7929c0.5836,4.4254-3.7446,8.8749-0.7053,12.9842
  c2.9421,5.4952,8.8142,8.1819,13.8354,11.4159c2.6868-4.0728,2.8082-9.0452,2.5406-13.7379
  c-1.8115-4.9603-6.0908-8.6561-7.9265-13.6165c-0.4742-2.0181-1.5197-4.7292,0.8875-5.9085
  c2.8813-3.3433,7.6712-1.3494,11.039,0.0973c3.465,3.5257,7.258,7.4525,12.4614,8.0118c4.5713,0.62,8.5956-2.0425,12.656-3.7081
  c5.1184-2.8448,9.8475-8.5588,16.3032-7.1486c-0.6932,5.7626-5.2401,10.6013-10.03,13.592
  c-8.5225,5.8599-18.686,10.3824-24.1934,19.5978c-1.7629,2.699-3.1852,7.0027-7.1608,6.6381
  c-5.1669,0.0365-10.2365,1.1671-15.1238,2.7111c-3.1976,1.2523-4.6198,4.8144-4.5591,8.0604
  c-0.0486,4.2308-1.301,8.2914-2.006,12.4493c4.8265-1.8844,7.3187-7.3674,12.5464-8.3887c2.541-0.6928,4.3646,1.5197,6.0787,3.0029
  c0.7053,2.8449-0.1337,5.787-0.1944,8.6805c-3.4164,2.0668-7.1972,3.4284-10.6743,5.3372
  c-3.4286,2.9421-5.1669,7.2701-6.8447,11.343c-0.8268,2.2491-2.5288,4.6685-1.2279,7.1121
  c1.6412,4.0606,2.9421,8.3522,5.325,12.0844c0.462-0.012,1.386-0.0608,1.8358-0.085c3.5013-7.0027,4.7171-15.2577,10.1515-21.1907
  c5.2642,9.957,0.5715,21.227,1.6046,31.8284c5.6412,5.6168,11.9996,11.0026,14.9538,18.6497
  c1.082-0.0365,2.176-0.0486,3.2825-0.0608c0.6079-4.401,2.0061-8.6318,2.7598-12.9963c0.2795-2.6503,1.4347-5.5924-0.3162-7.9753
  c-3.793-9.0695-15.3912-14.3094-14.3945-25.3362c0.7903-0.1459,2.3708-0.4376,3.1731-0.5957
  c3.1489,6.0302,5.5439,13.738,12.6923,16.1208c2.395,0.7781,5.4951,4.8144,7.1122,1.0091
  c-0.3892-4.6927-0.7052-9.6409-2.8812-13.9081c-3.392-7.9389-13.58-10.1758-16.3763-18.5767
  c-1.0333-3.5986-0.3889-7.5133,2.3951-10.1272c1.8479,3.2096,3.0879,6.7231,4.8022,10.0178
  c4.6561,6.8569,12.6194,10.6864,20.7285,11.5131c1.155-6.176-2.4801-11.7198-6.2732-16.206
  c-4.6077-3.9755-10.9418-5.9572-14.4431-11.197c-1.7509-3.2947-3.9753-7.5255-1.5562-11.0633
  c5.1548-10.0543,15.2333-15.8655,24.4729-21.6161c5.1548-2.7598,8.6563-7.6835,11.489-12.6438
  c2.8326-6.5042,8.7168-11.574,15.136-14.285c2.3342-1.3981,1.6899,2.7111,1.6047,3.9268c-1.459,4.8995-1.6776,9.9692-1.3979,15.051
  c-4.6444,4.1457-10.5284,6.8569-14.5769,11.6226c-2.9056,6.6259-5.4465,13.5799-6.1882,20.8015
  c1.4224,0.0608,3.1124,0.4255,4.2065-0.8145c2.8569-2.4195,5.7749-4.7657,8.571-7.258c3.3676-2.626,3.8538-7.1608,5.86-10.6743
  c3.6472-0.851,4.9967,3.0151,4.5104,5.9572c-0.6564,4.5834,2.5654,8.8749,1.2158,13.4218
  c-3.6472,2.1641-7.1606,4.5226-10.6378,6.9541c-3.8173,2.1519-6.3341,6.8447-4.8995,11.2092
  c1.848,7.2214,3.6838,15.1847,9.4586,20.3881c-0.1823-6.6137,0.6322-13.2517,2.8812-19.4763
  c0.9727-3.0516,4.012-4.4012,6.6018-5.787c1.24,5.1669,1.9814,10.5527,0.6686,15.8047
  c-1.4105,6.5286,2.2855,13.2395,7.8051,16.6436c3.7567,2.4558,7.1729,6.7474,12.1453,5.7504
  c0.4014-3.6107,0.8145-7.4404-0.8632-10.808c-2.5411-5.4587-3.6959-11.9022-8.4617-15.9872
  c-4.2308-3.5378-4.4254-9.4706-4.9116-14.516c0.4498,0.1215,1.3737,0.3768,1.8236,0.5106
  c2.6869,4.1336,5.1426,8.5832,8.8507,11.9143c3.562,2.6625,7.3795,5.0089,11.4401,6.8569
  c-0.1821-6.8203-2.0302-13.9811-5.8962-19.683c-3.9391-6.0422-13.9568-5.8234-15.8048-13.3976
  c-1.5319-4.79-3.7079-9.6044-3.0272-14.747c4.3889,0.0486,5.4709,4.6806,8.0726,7.3188c5.641,5.094,13.4218,9.5558,21.1903,6.5772
  c-3.015-4.9725-8.4249-7.9268-11.2092-13.0937c-3.6958-6.4313-13.4097-5.7018-16.2544-12.7897
  c-4.3525-8.1334-0.6321-19.0022,7.3309-23.391c2.6989-1.5927,5.3129-4.1093,8.6805-3.8539
  c6.6621-0.0121,12.7653,2.9178,19.1724,4.3038c5.3613,1.5319,10.0664,4.7171,15.5129,6.0665
  c7.8779,4.5712,15.5857,9.5316,22.868,15.051c3.8905,3.6594,9.2396,5.1669,14.3578,6.176
  c5.9573,0.0607,11.3311-3.1489,16.267-6.1517c5.1305-3.2582,11.4523-2.784,17.2635-2.9299
  c4.5957-0.5593,4.1215,7.2215,8.7656,6.176c3.854,0.3768,7.3188-1.5076,10.9297-2.4559c5.3492,0,10.4797,1.6534,15.6709,2.7598
  c-0.085,1.0699-0.158,2.1398-0.219,3.2218c-4.8143,0.8632-9.4949,2.3099-14.1876,3.6351
  c-5.4465,1.4346-7.1486,9.5801-13.5314,8.0239c-0.7781-4.7415-2.6747-10.0056-7.8174-11.4037
  c-7.4402-3.246-14.0176,3.0759-18.8562,7.9267c-1.9816,3.246-2.8569,7.1121-3.3796,10.8566
  c3.0028-1.2036,5.7747-2.9908,8.899-3.9268c3.8297-0.535,7.6471,0.2432,11.3309,1.3008c-0.0486,0.9969-0.146,3.015-0.1945,4.0241
  c-7.2582,2.541-11.4647,10.419-19.0508,11.9751c-7.0878-1.4954-12.7653-8.2671-20.5582-6.638
  c-9.6774,0.6565-14.8201,9.9934-18.7712,17.6527c0.2676,0.7659,0.8145,2.2978,1.082,3.0759
  c4.6077-0.2675,9.2034,0.2432,13.7987,0.4255c5.9086,0.2796,9.8354-5.4952,15.6223-5.556
  c-0.5226,2.5287-1.2765,5.1791-3.489,6.7839c-4.4741,3.6229-8.3645,7.8902-12.5709,11.8171
  c-3.5499,3.1123-4.5226,8.2549-4.5591,12.7653c1.2644,5.8234,5.1912,10.5284,7.3552,15.9993
  c1.9209-0.4498,3.781-1.1672,5.0939-2.7111c2.7233-2.857,6.3219-5.2155,7.6837-9.0816c1.4711-7.8173-0.9241-15.7804,0.4133-23.6098
  c0.8024-3.6959,4.6077-5.2885,7.1365-7.6835c-1.3253,4.4496-3.2096,8.96-2.626,13.7015
  c0.2312,5.3736,3.2461,10.0056,4.3888,15.1725c0.9604,0.1215,1.9332,0.2553,2.9178,0.389
  c1.6533-2.8691,3.8784-5.3372,5.7748-8.0118c2.079-4.2429,3.7201-8.802,4.2189-13.507
  c-0.8027-5.6653-2.4316-11.4159-1.3496-17.1663c2.0789-4.3403,6.4557-7.7322,11.4888-7.2459
  c-0.6443,1.6534-1.3008,3.2947-1.9694,4.9359c-4.9969,9.5801-2.0182,20.838,0.5957,30.6855
  c0.6444,0.3769,1.933,1.1063,2.5773,1.4711c5.8479-7.6957,11.4526-16.9476,9.1788-27.0383
  c-2.1154-6.2125-2.4315-14.1027,2.772-18.9171c3.6471-3.3744,8.3107-5.7705,13.1193-6.9612
  c2.4802-0.6142,5.04-0.9099,7.5952-0.8547c1.141,0.0247,2.2786,0.2838,3.395,0.2772c1.5709-0.0093,3.1882-0.2804,4.7576-0.3964
  c6.5646-0.4955,13.1714-1.3091,19.729-0.9311c4.4758,0.2581,8.926,0.8342,13.3564,1.5076
  c2.0105,0.3056,4.1292,0.4775,6.1071,0.8846c1.9963,0.4109,3.8461,1.587,5.8763,2.0275c2.1622,0.469,4.3909,0.5012,6.5996,0.6309
  c6.4222,0.3775,12.8945,1.6446,19.1099,3.2322c6.127,1.5651,12.0226,3.9658,17.6072,6.9202
  c0.5005,0.2648,1.0186,0.5161,1.5017,0.8119c3.8542,2.3464,8.4011,3.3555,11.9996,6.1638
  c7.6957,4.8508,15.9263,8.8871,23.087,14.5646c8.8386,5.5317,17.5798,11.3916,25.1782,18.6132
  c2.6018,2.2856,5.4587,4.4617,6.9541,7.7079c-3.5012,0.6078-7.0514,0.8875-10.4918,1.7142
  c-6.4192,2.0546-11.4768,6.9176-17.8838,8.9479c-3.2948,0.7537-6.6139,1.4224-9.8232,2.4558
  c-8.3645,2.8449-17.2758,1.1428-25.8954,1.386c-3.0273-0.1824-6.2854,0.5471-9.0574-0.9118
  c-1.775-1.6292-3.0637-3.8053-1.1914-5.8964c3.3433-4.6927,4.7048-10.7108,2.9785-16.3032
  c-3.781-4.3159-7.0391-9.0938-10.9417-13.3003c-3.9391,2.9908-3.8054,8.9236-6.1519,13.1301
  c-2.9663,4.79-0.2917,10.1636-0.4133,15.3063c-1.7021-0.3283-4.0728,0.4741-5.0333-1.4102
  c-3.3068-5.0697-6.261-10.7715-11.0148-14.5768c-5.7991-1.5927-12.7773-5.6289-18.4064-2.0181
  c2.5288,4.8873,6.5043,8.8262,9.3978,13.4948c3.7567,5.7626,9.118,11.6347,16.4856,11.963
  c3.0393,0.0243,4.6564,2.5409,6.1152,4.8265c-4.4862,1.3373-8.8872,3.1852-12.1575,6.6623
  c-0.073,4.8022-0.9362,9.5193-1.3131,14.2972c5.714-2.3464,10.2853-6.9297,16.2789-8.7534
  c4.9846-1.1185,7.0391-6.4192,11.2335-8.7656c3.8662-0.2917,7.5134,1.5804,10.5651,3.8175
  c-2.8572,4.8386-5.5317,10.0056-9.7869,13.7744c-5.2886,1.5319-10.9539,0.0486-16.364,0.8632
  c-3.9755,0.8997-7.1365,3.5621-10.5771,5.5682c-2.9666,8.9479-7.4646,17.835-6.6622,27.5245
  c-0.4498,4.1336,2.4315,7.3188,3.9269,10.9053c-2.1882,3.8417-4.9358,7.7929-3.5013,12.4614
  c5.2277-0.4134,10.6864-0.3525,15.6224-2.4315c3.9633-1.848,6.2974-7.0149,11.2457-6.1639
  c-0.2069,3.4892-0.9971,6.9055-1.4833,10.3704c-0.5836,4.559-5.3494,7.6227-5.0454,12.4371
  c-1.2888,9.5923,5.5681,17.4825,11.3552,24.2906c4.1455-7.2823,7.9387-14.8078,9.8596-23.002
  c-1.082-8.182-9.0938-14.0784-7.793-22.8926c1.605,0.1945,3.2339,0.4134,4.863,0.6686c-0.158,9.9692,10.03,18.0538,19.6952,15.6589
  c0.2917-6.1517-2.5165-12.0967-6.6381-16.5221c-4.3281-4.8144-12.1089-5.7505-14.4309-12.4006
  c-3.8296-5.6653,0.4255-12.0116,1.6899-17.7742c0.6079,0.0121,1.8236,0.0243,2.4193,0.0243
  c1.0941,3.246,2.0911,6.6259,4.1456,9.4221c5.4829,3.55,11.8413,4.6321,18.1755,5.5682c-0.1095-2.2978-0.2554-4.6198-0.9604-6.8082
  c-2.0305-8.2671-9.957-12.7775-15.0753-18.99c-0.2432-3.1974-1.0092-6.4435-0.316-9.6166
  c1.9696-6.0179,7.6349-9.4828,12.3765-13.1544c6.6987-2.1396,13.7499-2.8084,20.7527-2.8084
  c6.9177-1.8965,10.9175-10.2123,18.6862-9.6774c-1.3981,4.0849-4.7294,7.3918-5.1548,11.8292
  c-0.4379,2.3585-0.2797,5.3857-3.0394,6.3097c-4.1577,0.7173-8.6075-0.1337-12.4978,1.8358
  c-4.9362,2.626-7.8171,7.7929-10.2732,12.5952c-0.7661,4.4739-0.4863,9.0452-0.0486,13.5556
  c4.9116-1.1792,8.4128-5.1669,12.5587-7.8051c4.3401-2.5895,5.7141-8.1942,10.6256-10.0665
  c-1.5197,5.5682,0.1095,12.3641-4.4131,16.6922c-2.3584,0.1459-4.7051,0.1824-7.0149,0.6444
  c-8.3643,6.3948-13.0328,17.2515-10.2366,27.6948c1.459-0.2432,3.0759-0.3162,4.1577-1.5076
  c5.3615-4.632,10.176-9.7989,14.5525-15.3549c0.6079,3.6959,1.3496,7.3918,1.7388,11.1362
  c0.4375,4.9238-4.7781,8.3644-3.9148,13.3125c0.5229,6.0909,5.9451,10.0907,10.3824,13.6042
  c3.8297,2.4194,7.5499,6.3097,12.4857,5.5924c0.6444-3.8782,1.3132-7.7808,1.3374-11.7077
  c-1.2035-3.3068-2.9907-6.3583-4.4253-9.5436c-2.1274-4.79-6.8447-7.6834-9.641-11.9872c-0.474-2.4194-0.2917-4.8995-0.2797-7.3188
  c1.9574,2.2125,3.5865,4.6806,5.3129,7.1c2.3829,3.6594,6.942,4.4618,10.577,6.407c2.7112,1.4832,5.8114,1.6656,8.8386,1.2766
  c-0.2798-1.9695-0.8391-3.8904-2.2129-5.3615c-3.635-4.1093-3.7931-10.2609-7.9388-14.0054
  c-4.097-3.7324-9.422-6.3462-11.9751-11.5253c-0.535-4.2065,0.1945-8.4008,1.2886-12.4495
  c5.5682,0.3283,5.6047,6.8812,5.5804,11.0998c0.851,7.4769,8.6802,10.9783,15.3669,11.6348c2.8936,0.2553,6.48-3.0637,5.094-6.0544
  c-1.2399-4.1214-2.5042-8.3035-4.6685-12.048c-4.2307-6.3219-13.7502-7.4525-16.5343-14.9415
  c-0.9725-2.5044-2.9176-4.8144-2.6259-7.6227c1.6777-3.5621,3.866-7.2459,7.5498-9.0208
  c3.5864-1.1672,8.1213-2.4072,11.3064,0.4012c9.7869,7.1365,18.5159,15.5494,27.8528,23.2573
  c7.0758,6.1517,11.416,14.5768,16.826,22.0901c4.7172,7.2823,11.9266,12.3763,17.3488,19.0873
  c5.3613,4.7535,7.72,11.7077,11.9508,17.3366c6.5408,10.2001,11.8779,21.1176,15.902,32.5578
  c2.3829,5.3736,3.8297,11.1241,6.7961,16.2545c2.9421,5.1426,1.5441,11.3794,3.793,16.7531
  c1.9089,4.5225,3.0637,9.495,6.2858,13.361c3.319,4.0485,7.9146,6.7231,11.8778,10.0786
  c5.8234,5.7262,8.7899,13.4948,12.8018,20.4611c2.0911,4.2795-2.6504,7.2094-4.2916,10.6257
  c-0.2432,3.708-0.0243,7.4404-0.2554,11.1727c-0.8268,0.4741-2.4801,1.4224-3.307,1.8965
  c-2.8445-4.6806-4.2914-10.2123-8.121-14.2485c-4.0243-4.0849-10.0177-4.9846-15.4401-5.556
  c0.9846,4.7171,3.2339,9.0208,4.8386,13.5435c2.9663,6.8082,9.0696,11.9265,15.8534,14.7227c0,2.4801-0.1825,4.9845,0.2554,7.4525
  c0.9603,2.8206,3.088,5.325,2.6747,8.5103c-3.8904,0.4376-5.6775-3.6473-8.1211-5.9329
  c-3.6716-5.2277-8.7292-9.264-12.3521-14.5039c-3.1852-4.3037-3.7809-9.7746-5.0453-14.8078
  c-0.7416-4.3037-4.3282-7.4525-7.6349-9.9326c-4.5713-2.0181-9.106-4.9846-10.7351-9.9692
  c-0.6807-6.5164,5.8232-11.2092,6.0302-17.5798c-1.5804-7.3431-5.6412-14.358-11.5011-19.0265
  c-4.3523,3.8175-7.793,9.2762-7.1365,15.2941c-0.2673,5.3736,5.1184,9.1547,4.0365,14.6985
  c-3.3434-1.6048-6.249-3.8904-9.3977-5.7992c-4.1337-2.6868-9.3007-4.5225-11.5618-9.264
  c-0.3647-4.8995,1.8236-10.2853-1.1672-14.7227c-2.3829-6.48-9.507-10.4676-16.2423-9.872
  c-1.9574,0.1702-4.4011-0.5349-5.6655,1.4102c5.5559,4.1336,7.3433,11.1241,11.1121,16.6558
  c1.8357,4.4011-3.7567,2.699-5.6653,1.0699c-5.4224-2.8935-9.2397-8.1334-15.0146-10.3947
  c-7.7686-1.4467-16.364,0.8753-21.9321,6.6259c2.7477,3.9634,7.2825,6.3219,10.6743,9.7504
  c6.5408,1.4589,13.2153-0.0608,19.6345-1.4346c3.0393-0.7659,5.8721,1.1185,8.7046,1.9573c0,1.2157,0.0122,2.4315,0.0486,3.6716
  c-3.0879-0.1337-6.176-0.5836-9.2397-0.231c-5.8479,2.0425-9.7991,7.1365-13.3125,11.9509
  c6.322,1.8358,12.8749,3.5257,19.5006,3.8662c7.4403,1.6169,9.3004-9.5923,16.8138-8.6197
  c4.6198,1.1671,8.7169,4.0606,12.8748,6.3948c-0.0608,1.0455-0.0972,2.1154-0.1215,3.1974
  c-4.8024,0.9118-9.5802,2.006-14.285,3.2582c-6.4313,3.088-8.6926,10.6378-9.6775,17.1542
  c4.1337-0.3039,7.9998-2.2491,12.1819-2.2005c2.7476,2.699,6.9661,4.9725,7.3918,9.1303
  c-4.8145-0.1459-9.6045-0.6808-14.4189-0.5106c-3.5864,0.1945-7.0513-1.5076-9.7139-3.7809
  c-2.6138-3.1367-3.5986-7.5498-7.1121-9.9326c-5.5439-5.094-13.5557-5.0211-20.6069-5.1548
  c-0.0122,2.8083,0.936,5.4222,2.322,7.8416c1.8114,3.088,2.1761,7.0391,5.0088,9.495c3.5745,3.9512,10.5405,1.3494,13.2639,6.3948
  c-5.5195,2.0668-11.7808,4.9725-17.6284,2.5652c-3.6594-1.4711-8.4617-2.2856-11.416,0.9848
  c-3.8904,5.7626-6.03,12.4736-9.6287,18.4064c1.3132,1.2523,2.8328,2.4072,4.6199,2.9421
  c3.6716-1.2401,7.3311-2.5045,11.124-3.4163c8.024-1.5804,10.3704-12.2912,18.8927-12.741
  c0.766,4.2308-4.5226,6.3947-4.0728,10.5649c0.2067,3.8417,0.4498,7.6834,0.6079,11.5375
  c1.9938-0.1095,4.2672,0.4254,5.9449-0.9849c5.2278-3.708,9.7383-8.6926,12.0723-14.7349
  c1.9331-3.6472,2.1763-8.7534,6.5288-10.4797c4.5712-3.9147,10.1636,0.1094,15.136,0.9604
  c0.1338,1.0942,0.2917,2.2005,0.4619,3.319c-9.4221,0.7173-18.9534,6.3705-21.4458,15.9628
  c5.7505-0.0729,11.5862,0.1459,17.3002-0.6444c5.7748-2.0668,9.2396-7.6349,13.9081-11.3307
  c2.0425-2.5896,5.6897-1.4711,8.3887-0.7416c5.5316,2.2736,11.3672,4.1579,15.7805,8.4253
  c13.7499,6.6501,24.3513,18.8441,30.1747,32.8496c-0.462,0.4255-1.3739,1.2765-1.8358,1.702
  c-3.939-3.0271-8.7415-4.2916-12.9355-6.723c-0.2188-4.6929,0.2795-10.4919-4.1943-13.4463
  c-3.4651-3.9877-8.9238-5.021-13.9326-5.2399c-1.3982,0.7295-3.4528,2.079-2.5653,3.9634
  c1.3253,5.1062,4.0973,9.8597,8.571,12.8262c0.0243,1.2157,0.0607,2.4558,0.1215,3.708
  c-4.5225-0.5471-9.0696,0.2189-13.5676-0.0731c-4.012-2.249-4.1943-7.6833-7.7563-10.3947
  c-6.1882-6.3705-16.0359-4.7292-23.7072-2.553c3.9634,4.8386,7.343,12.5343,14.6498,12.2061
  c0.0121,1.2887,0.0121,2.5896-0.0244,3.9026c-8.0604,2.9786-17.1785-0.4741-24.8379,3.8418
  c-2.1519,2.0546-3.9147,4.5712-5.3369,7.1849c-0.4742,3.7446-0.8269,7.5012-1.4348,11.2335
  c5.2156,1.6292,9.9326-1.4711,14.6985-3.0879c7.489-2.1761,8.498-12.9844,17.1665-13.203
  c-2.5288,4.7535-6.8083,8.9357-6.5288,14.7469c-0.8632,2.395,1.0822,6.7474,3.9391,4.5469
  c5.5439-2.942,13.1179-4.79,15.0389-11.6226c0.9725-4.6198,4.5956-8.5588,9.5435-8.7168
  c-1.3127,2.8934-3.4402,5.4951-4.0725,8.6682c-0.5107,3.781-0.2676,7.635,0.1699,11.4281
  c3.0516,0.3405,5.4709-1.4589,7.8418-3.1123c6.6379-3.8175,9.1301-12.1332,16.1572-15.5009
  c5.6775-0.9725,10.7108,2.7841,15.2699,5.6168c6.2125,5.41,10.8444,12.6924,12.1696,20.9231
  c0.9604,6.334,7.8781,8.2428,11.5496,12.6073c0.6566,3.9026-2.2368,7.1121-4.802,9.6409c-1.9453,0.0243-2.9543-1.9331-4.2432-3.015
  c-2.48-2.9543-6.2488-4.2916-9.8232-5.3007c-6.1274,1.9817-11.3552,6.3097-15.2455,11.3915
  c0.3892,0.6201,1.155,1.8602,1.5441,2.4801c7.1122,1.9817,14.3337,0.0487,21.458-0.4863c0.0364,0.6687,0.1215,2.0182,0.158,2.699
  c-2.9421,4.1578-6.5164,7.8903-9.6528,11.9265c-4.863-1.9574-8.4618-7.2701-13.9568-7.9753
  c-6.4314,1.2523-11.2457,6.3584-14.0055,12.121c0.8265,2.3707,3.2338,4.1215,5.7626,4.2065
  c4.6077,0.7903,8.7534-1.8236,13.1543-2.6503c0.4741,0.4619,1.4103,1.3859,1.8843,1.8357
  c-1.5438,3.7932-4.2429,6.942-6.5283,10.2974c-1.7263,3.1367-6.3462,2.1033-8.3159,4.9967
  c-2.2249,3.1002-3.5619,6.7717-4.8143,10.3583c1.3373,4.5834,3.1852,9.118,5.7018,13.203
  c2.2979,0.6322,3.7202-1.5319,5.3008-2.7963c2.3462-2.249,5.0574-4.1943,6.881-6.9176c1.1549-5.8721-2.3584-11.9021,0.2919-17.6405
  c0.6443-3.0637,3.5743-3.9026,6.3583-3.9512c-0.8145,4.1821-3.2826,8.8871-0.6564,12.9478
  c2.6382,5.325,8.5708,7.2944,13.8959,8.8142c0.316-5.1426,0.6322-10.8202-2.4435-15.2577
  c-2.614-4.6927-7.2823-9.2153-6.0422-15.0388c1.4832-4.1943,3.9753-7.9631,6.954-11.2213c0.6443-0.0851,1.933-0.2554,2.5653-0.3405
  c-0.0852,5.8234-2.8572,11.9143-0.4377,17.677c1.4346,2.5288,3.3553,4.7415,5.2156,6.9905
  c3.5012-3.7566,5.5559-8.5709,7.3674-13.3003c-1.155-6.0908-6.5773-11.4037-4.3889-17.9323
  c1.8965-3.4771,3.6351-9.422,8.6685-8.413c7.2578-0.2432,13.2637,4.1215,19.4639,7.2216
  c5.0454,3.6594,7.3188,9.6774,10.5527,14.7714c4.6442,7.72,1.3739,16.8502,2.1641,25.2633
  c-0.073,4.3159,2.2493,8.0969,3.1489,12.2183c0.9727,6.5408,4.8386,12.4006,4.4618,19.2089
  c1.556,6.3705,1.1183,12.9355,0.6078,19.4154c-1.5074,7.5255,0.073,15.3793-1.9451,22.8075
  c-3.7203,8.3279-10.9297,14.3092-15.6711,21.9442c-0.936,3.6472-0.7537,7.4891-1.3738,11.2091
  c-1.7263-0.4131-3.7567-0.2308-4.9966-1.6654c-4.0364-2.9785-3.6594-8.4738-5.8601-12.5587
  c-2.5044-7.5741-11.7563-9.349-15.0996-16.3031c-2.4436-4.0973-4.2915-9.0452-8.8262-11.2822
  c-6.4436-1.1671-13.0208,0.0608-19.4156,0.8997c-0.0487,4.2673,3.5623,6.8811,6.4922,9.4099
  c2.2856,0.8389,4.8143,0.5105,7.2458,0.7294c0.2188,1.4468,0.4376,2.9178,0.6444,4.401
  c-6.6504,0.6565-11.1849,7.3188-18.0298,6.5409c-3.9998-0.1704-7.3794,2.3463-10.9539,3.7565
  c-2.322,5.0212-3.8904,10.4191-3.2825,16.0238c-0.2917,2.4677,1.7383,4.1091,3.4529,5.5193
  c6.1757-3.3917,11.6587-7.963,16.23-13.3246c2.1399-3.2582,2.6506-7.5254,5.7263-10.1514
  c2.3706-2.7233,7.1487-3.6715,9.5315-0.4257c-2.9178,3.2825-6.7476,6.1154-7.9146,10.577
  c-0.9119,5.0211,0.8268,9.9691,2.1396,14.7715c0.8147,0.0728,2.4437,0.2432,3.2583,0.3162
  c2.2491-4.4133,5.5437-8.1215,8.6925-11.8901c3.465-4.3889,0.377-12.9357,7.2582-14.2241
  c4.4253,3.3311,9.0693,7.8901,8.9844,13.8837c0.4255,6.1274,5.8964,9.9204,9.5193,14.2852
  c0.231,4.1699,0.2917,8.3519,0.0122,12.5465c-4.2795-0.0973-7.4404-3.2949-11.3066-4.6077
  c-3.2581-1.0822-6.3705-2.614-9.6895-3.4042c-7.4768-0.8997-14.5403,2.8207-20.5706,6.8204
  c6.0303,5.3005,14.1758,10.1149,22.4673,7.6469c3.562-0.3405,5.5923-3.3798,8.1819-5.4222
  c3.5864,0.9484,6.8447,3.6108,6.5894,7.6472c-4.2795,1.0092-9.033,2.6868-10.334,7.4039
  c-4.1577,6.4314-4.8264,14.6012-1.4954,21.5553c3.8418-2.6141,7.8052-5.0577,11.6106-7.7202
  c4.1942-2.6259,3.0028-8.5345,6.5891-11.6831c4.3647,6.3096,8.2185,13.0206,11.6106,19.8894
  c1.6411,11.0878-7.0149,20.376-7.2946,31.3055c0.0973,4.4618-0.6687,8.8751-1.0211,13.3246
  c-0.3284,10.954,0.547,21.9321-0.1703,32.8861c0.0121,11.3187-2.0911,22.5035-2.468,33.8221
  c-1.9208,8.0116-4.9238,15.7561-7.6593,23.549c-3.9875,10.3948-10.8079,19.8168-12.0967,31.1233
  c-5.7017,0.1823-9.6044-4.8265-15.0388-5.7018c-4.2307-0.146-8.9478,1.7749-10.7958,5.8113
  c-2.468,3.2947-0.6686,8.036-3.5499,11.1118c-3.9268,5.1913-7.8051,10.6987-9.3247,17.1544
  c-1.0334,3.6107-0.8877,7.4159-1.4712,11.1239c-0.6929,0.0974-2.0911,0.2797-2.7961,0.377
  c-4.1578-4.2551-3.8904-10.3459-4.401-15.8047c-1.5197-2.5897-3.8539-5.1913-7.0636-5.2278
  c-4.7413-1.4102-8.3279,2.9785-10.8079,6.3584c-0.9119,4.3646-0.7417,11.1726,4.1943,12.9719
  c2.553-0.1823,5.0817-0.7537,7.647-1.0819c2.48,3.8782,5.325,7.5984,7.1606,11.8535c0.6687,3.9634,1.2279,9.5316-3.015,11.7562
  c-3.7931,2.298-5.9692,6.3584-9.5922,8.8385c-2.9666-7.8416-7.6106-15.209-8.024-23.8163c2.8813-0.0487,5.787,0.0361,8.6926-0.0244
  c-3.2218-5.0698-10.1637-5.6898-12.8749-11.1608c-3.3066-4.9722-7.5134-11.2456-14.3093-10.5648
  c1.5441,3.1733,3.9756,5.8966,5.2764,9.2156c0.2798,1.7506,0.9119,4.012-1.2157,4.8994c-2.5895,0.7537-5.3859,0.8997-7.7928,2.1881
  c-4.4253,4.243-5.2765,10.6622-5.6897,16.4978c-1.4224-0.0608-2.8206-0.0852-4.2065-0.0852
  c0.2797-6.0665-4.6198-10.1757-6.8203-15.379c-2.4072-4.3038,0.4741-9.252-0.7537-13.7623
  c-1.4834-2.869-3.842-5.1062-5.8965-7.5255c-2.6382-2.5166-0.6079-7.3188-3.9634-9.349c-2.7841-0.5105-5.6653-0.4622-8.425,0.073
  c-3.0271,1.6776-4.5591,5.7992-3.1001,8.96c2.541,3.6472,7.343,3.4772,11.1726,4.6808c1.4954,1.7262,2.9543,3.8173,2.7598,6.261
  c0.3162,4.182-3.0272,8.6198,0.1338,12.4005c3.8052,4.9603,8.6804,9.7991,8.7534,16.5586
  c-1.0698-0.1581-3.1852-0.4865-4.2551-0.6443c-1.0942-2.0303-1.7141-4.2551-2.845-6.2369c-2.176-2.8812-5.6287-4.243-8.5344-6.2124
  c-3.8174-2.1519-6.4675-5.7139-9.6165-8.644c-2.4802-2.7111-6.2003-3.5742-9.3613-5.167
  c-5.3859-1.7749-11.0999-3.7078-16.814-2.4071c6.1396,4.6686,14.1636,6.1395,19.9385,11.3673
  c2.7354,3.7081,5.2518,7.6958,9.2275,10.2488c4.3159,3.0393,10.407,6.1395,9.9692,12.3522
  c-0.7295-0.0365-2.2004-0.1095-2.9421-0.146c-4.2307-4.5226-9.4829-8.0968-15.3669-10.0543
  c-5.0333-1.6532-8.8994-5.4222-13.5436-7.7806c-0.17,2.8203,0.6687,5.7991,2.8207,7.7806
  c5.5439,5.0696,13.4827,7.9753,16.5342,15.3551c-2.6017-1.5806-4.8386-4.0243-7.8901-4.6808
  c-0.6687,0.7417-2.0061,2.2371-2.6748,2.9785c-4.9358-4.4617-10.9417-7.6593-17.3365-9.422
  c-0.0122,0.8752-0.0122,2.6261-0.0122,3.5015c3.3677,2.7476,6.7352,6.261,6.3341,11.0144
  c-3.7444,0.5352-5.1426-3.5012-7.8901-5.1547c-3.7932-0.3405-1.5197,4.2551-1.9939,6.5043
  c-3.1001-0.3405-6.2002-0.7417-9.118-1.8114c2.3707-3.8295,6.2975-6.6744,7.5984-11.1361
  c2.3829-5.3252,2.468-11.2822,4.0848-16.8262c-7.5618,0.3527-11.7197,7.635-16.6799,12.3154
  c-0.7173,0.0974-2.176,0.2797-2.8936,0.377c-0.2308-5.9083,2.9786-11.1605,7.562-14.6255
  c5.325-3.8417,9.7991-9.3247,11.1726-15.8534c-2.8812-0.3162-5.3613,1.0699-7.4037,2.9667
  c-1.3253,0.1335-2.6505,0.243-3.9391,0.401c-0.0122-1.0576-0.0122-2.0911,0-3.1122c4.3522-1.2644,10.4067-2.7841,10.6378-8.2914
  c-1.3739-2.4315-3.5258-4.5713-3.3312-7.5376c4.3522-0.3405,8.887-2.395,9.6409-7.1731
  c-1.3617-3.6108-3.7205-6.7716-4.5957-10.5648c-1.0332-2.7477-4.2672-6.4436-7.4038-4.6199
  c-2.2734,2.5166-4.1946,5.4343-7.4769,6.7474c-0.4376-4.5104,1.8358-10.6865-3.088-13.4463
  c-5.2156-2.8446-9.5315,2.2615-13.8229,4.4982c-3.7933,2.2737-2.6505,7.0028-2.3951,10.65
  c-4.012-0.2795-9.6653,0.8391-9.9692,5.7992c-0.8633,2.9423,2.1033,4.4984,3.8175,6.3221
  c2.6017,2.0424,3.6594,5.3369,5.8964,7.7197c3.9755-0.5957,7.1487-3.4041,10.9783-4.4982
  c4.1213,5.3126,1.9574,12.3641,1.2522,18.4185c-3.6472-0.62-7.2338-1.4344-10.8323-2.1396
  c-1.5439-2.8569-3.5864-7.9146-7.6836-5.7384c-2.7112,2.3467-4.8145,5.483-8.2671,6.8448
  c-0.1095-3.3677,0.4622-6.7109,0.2311-10.0421c0.0485-1.4226-0.62-2.2249-2.006-2.4071c-3.6716,0.0487-6.7961,3.0637-7.8901,6.4314
  c-0.4377,3.8782,2.2855,7.6224,1.009,11.4888c-2.7598,2.6259-6.5774,3.6107-9.8113,5.4951
  c-4.2429,2.0303-6.5649,6.5042-7.3187,10.9661c-2.699,0.158-5.5682-0.0608-7.951,1.4346
  c-4.3401,1.7749-7.8538,6.0665-12.9719,4.8386c-0.3403-5.5438,6.103-6.3827,8.8628-9.9812
  c2.176-4.4377,4.5226-9.1912,3.7688-14.285c-4.9846,0.9969-8.4008,5.0939-13.1909,6.504c-0.2795-2.9298-0.6199-5.9205,0.158-8.7777
  c2.6626-2.2002,6.7352-1.6654,9.0454-4.3887c2.3342-2.0548,1.702-5.4221,1.8599-8.1576c2.0427,0.0605,4.0973,0.1458,6.1641,0.1945
  c4.3768-4.7535,6.2974-11.4402,6.48-17.6649c-1.082-5.0088-6.5894-4.2673-10.4556-4.3525
  c1.082-3.7565,2.4072-7.8171,0.6687-11.6101c-0.4741-3.1367-3.6716-4.2916-6.176-5.4225c-2.699-0.0363-4.717,2.5167-7.4282,2.4802
  c-2.4071,0.1582-5.3372-0.3162-6.8083,2.0909c-2.6501,2.4194-2.1154,6.0789-2.1519,9.3129
  c-2.9055-0.4377-5.714-1.7509-8.6682-1.5074c-2.6868,0.7292-4.5712,3.015-5.9573,5.3004c0.5957,3.0881,3.4407,6.0667,1.4712,9.2399
  c-1.4712,2.5529-0.7051,5.3613-0.085,8.0724c2.468,0.3282,5.021,0.4379,7.416,1.2888c1.4955,3.5377-2.9177,5.957-2.3828,9.2277
  c0.9849,5.0087,7.1971,3.5133,10.6134,5.8113c0.681,2.9177,0.45,5.9692-0.17,8.899c-5.6169-1.3859-9.9326-6.8932-16.1331-5.5317
  c0.79,4.9603,3.9998,9.2764,7.9875,12.2184c2.4922,1.8479,5.9449,2.0911,8.0725,4.5104c1.3738,2.3223-1.2644,5.1548-3.562,3.4163
  c-4.7292-2.8206-9.8113-6.0908-15.5861-4.3643c-0.4012,4.2792,4.2308,6.3948,7.1365,8.6681
  c5.3129,3.8295,13.13,7.0879,13.4948,14.6255c-0.7415,0.0609-2.2371,0.1823-2.9907,0.2432
  c-2.9786-5.1305-8.2794-9.6409-14.5404-9.2277c-5.4586,0.2799-10.954-0.17-16.3883,0.3525
  c-0.7661,4.4255,6.0181,5.3495,5.1062,9.8356c0.0122,2.6625-3.2703,3.4042-4.8873,4.9481
  c-0.4254-2.1276-0.8387-4.2307-1.2157-6.322c-0.4863-4.2671-3.708-7.4403-6.1641-10.7106
  c0.2676,3.9025,0.1338,8.0481-3.319,10.6013c-3.7079-5.3978-3.1608-12.121-4.8387-18.1754
  c-3.7322,0.717-5.8962,4.2673-8.9114,6.2975c-0.5106,7.5011-2.8328,14.6377-5.5559,21.5917
  c-1.1307,0.1215-2.2493,0.2552-3.3555,0.4135c-1.8843-6.0668-4.7656-11.9508-9.5802-16.2181
  c-1.4589,1.7506-2.8568,3.5742-4.2672,5.3978c-2.553-5.3004-0.6686-10.9052-0.5714-16.4126
  c-6.6866,4.0729-9.7867,12.4127-10.9418,19.902c-0.6807,0.0365-2.0544,0.097-2.7476,0.1213
  c-2.6501-4.5347-5.8599-8.9233-10.6742-11.3306c0.0485,1.9694,0.1581,3.9631,0.3524,5.9692
  c-5.8475-1.0944-5.2883,8.255-11.4036,6.7231c-0.4622-5.386,2.6382-10.0056,4.2186-14.9172
  c2.5167-6.5409,7.8171-12.5709,6.7596-20.0112c-1.2157-5.933-1.386-11.9874-1.4346-18.0175
  c-1.386,0.0122-2.7598,0.0122-4.1094,0.2188c-0.3646,10.4556-3.0515,20.6677-6.2125,30.5763
  c-3.2581,4.5834-6.5284,9.3247-10.9781,12.8502c-0.9482-4.3279,2.1882-11.0023-3.1367-13.1058
  c-4.1577,0.6201-8.3401,1.301-12.3518,2.6019c0.8145,2.2493,1.5317,4.5591,2.5652,6.7474
  c1.3494,2.7111,4.6198,3.6351,6.3218,6.0909c0.4501,3.5377,0.6932,7.1241,0.3893,10.7104
  c-3.392-1.5074-6.2611-3.9147-8.0118-7.1971c-2.0544-2.6016-2.7112-7.0757-6.6746-7.2701
  c-0.1094,2.7842-0.2917,5.6292,0.1096,8.4373c0.9725,3.1002,3.015,5.9817,2.5652,9.3977c-0.7538,0-2.2612,0.0122-3.0151,0.0122
  c-4.863-3.319-6.8446-8.9235-7.4646-14.5282c-2.1033,1.5684-4.158,3.1854-6.249,4.7657c-1.009-3.4526-1.5319-7.2581-4.0728-9.9935
  c-2.0181-2.5771-5.4465-3.1121-8.3279-4.1699c-3.927-0.9727-6.723-4.0729-9.9326-6.2731
  c-0.3405,1.9694-0.6444,3.9633-0.8997,5.9813c-6.6866,4.1943-1.8115,12.2792-2.468,18.4064
  c-5.7748,0.316-7.5498-5.9449-10.7231-9.5557c-0.3888,0.6322-1.1914,1.8966-1.5803,2.5167
  c0.3405,4.3887,2.541,8.425,3.7932,12.6193c-1.6172-0.0365-3.2217-0.0608-4.8265-0.0973c-1.8236-2.8325-4.9482-5.3613-4.401-9.0817
  c-0.3405-4.2673,1.1309-8.2911,2.6382-12.1818c-5.4346-0.2552-8.4496,4.8146-13.191,6.407
  c-0.8387-4.0121,1.2279-7.6228,2.0911-11.3915c0.3405-4.7416,0.4865-9.6045-1.1063-14.1393
  c-4.8994,1.2523-5.957,6.3705-7.4524,10.48c-3.8054-0.9241-6.0425-4.4983-5.7507-8.328
  c-0.4739-5.4709,3.8176-9.4343,6.2371-13.8717c1.3252-2.5653,4.0483-4.7777,3.6106-7.9144
  c-1.8479-1.5928-4.1819-2.3707-6.3948-3.246c-1.6655,1.6654-2.9178,4.0604-5.5195,4.3035
  c-1.7505-3.6229-2.4192-9.6895-7.8416-9.0208c-5.2522,0.8875-4.9968,7.3066-6.0179,11.416
  c-3.2584,0.5592-7.1488,2.2733-7.5985,5.9935c3.1244,4.3403,9.1182,5.3127,12.2913,9.6652
  c2.8936,3.0881,0.3525,8.0603,3.4041,11.124c3.1245,3.465,5.1912,7.854,4.486,12.6561c-5.799-2.1519-8.413-8.9359-14.7347-10.3339
  c0.8145,6.3827,5.3491,11.7562,5.1062,18.3212c0.6443,4.2672-3.9878,7.0149-7.7322,7.0514
  c0.8145-5.0453,2.8448-10.6865,0.0973-15.4886c-1.6046,2.8937-2.8204,5.9817-4.2794,8.948
  c-0.5957,0.0122-1.7871,0.0122-2.3708,0.0122c-2.0544-2.918-2.6259-7.3918-0.0364-10.1394
  c3.9875-5.4344,8.9114-11.501,8.2306-18.6011c-2.2491,0.7661-4.7294,1.4105-6.2975,3.3555
  c-2.1641,1.9818-3.7324,5.2521-7.0513,5.2521c-3.1611-6.3218-3.1246-13.9689-0.8752-20.5946
  c1.6653-4.6321,6.3583-8.2794,5.3005-13.6528c-3.4891-0.6688-6.9298-0.0487-10.2123,1.1427
  c-0.6686-1.9575-1.2765-3.9269-2.2372-5.7383c-2.7595-2.3345-6.8688-3.842-9.8351-0.9727
  c-4.389,3.0758,0.5957,7.6106,1.1793,11.3186c-2.7112,0.851-5.4709-0.0851-8.2063-0.0243
  c-2.4558,1.8358-3.9148,6.7596-0.1703,8.0116c4.778,2.8328,12.7775,4.9238,11.7076,12.0117
  c-5.4587-1.3979-10.6743-4.4982-16.4612-4.085c-2.468,0.3403,0.1824,3.319,0.5593,4.6321
  c3.4893,6.2247,11.501,6.8569,16.2061,11.7926c2.8085,2.1033,2.6746,5.8965,3.4526,9.0211
  c-1.2036-0.0122-2.4071-0.0122-3.5864,0.0122c-2.2612-4.3647-5.1184-8.9968-10.3582-9.9086
  c-0.6079,6.0544,4.3159,10.8203,4.5591,16.7408c-0.0486,2.2006-0.4498,4.4133-0.7295,6.6259
  c-1.4224-0.1093-2.8206-0.1702-4.2185-0.231c-0.6079-1.9816-1.2888-3.9269-2.0427-5.8113
  c-1.5317,2.7233-2.7476,5.7869-5.3979,7.6836c-4.2552-3.4772,0.3892-8.9359-0.1338-13.4098
  c-0.7173-4.0364-2.3465-8.7046-6.4676-10.3582c-0.0974,2.3341-0.0486,4.7051-0.0731,7.0757
  c0.2675,6.9419-6.7109,11.6227-6.261,18.6859c-0.7539,0.0365-2.2736,0.0974-3.0394,0.1339
  c-1.6779-4.4983-2.8571-9.2642-2.6869-14.0907c0.6322-2.9055,1.6655-5.7018,2.3465-8.5708
  c-6.8811,2.942-9.8477,10.6741-11.6713,17.4215c-1.2523,0.073-2.4922,0.1825-3.7201,0.2917
  c-1.9938-6.9662-8.8627-10.5648-13.5191-15.5857c-0.4498,0.3889-1.3375,1.1671-1.7871,1.5559
  c2.079,3.7689,6.261,7.1609,5.0331,11.9387c-4.5347-4.2916-7.3309-9.9204-9.3613-15.7195
  c-0.8511-4.8021,1.8967-9.3369,1.5075-14.1637c-1.8236,0.3405-4.3646-0.3523-5.2277,1.7875
  c-3.6107,4.6074-2.541,10.6982-2.9178,16.1327c-6.942-4.0969-6.5164-13.3851-11.6104-19.0021
  c-2.006-1.9209-3.1609-5.8965-6.3462-5.7383c-1.7385,4.2673,1.3252,7.7198,2.626,11.5618
  c-3.3433,3.5986-8.2671-0.8026-12.2548-0.0852c-2.1884,0.6688-2.8327,3.1611-4.1457,4.8022
  c0.9362,1.8114,1.7507,3.7202,2.8449,5.483c1.6412,1.3131,4.2186,1.9696,4.1578,4.5591c-3.0515,1.0334-6.869,1.2887-8.6805,4.3646
  c-2.0789,3.3311-2.7962,7.3188-5.0089,10.6134c-1.6413-0.0608-3.2704-0.1095-4.8995-0.1338
  c-0.7051-4.8993-0.1216-9.8231-0.2553-14.7104c-3.1488-0.7296-4.4861,2.9542-6.4191,4.6926
  c-2.6139,2.298-2.2127,5.8844-2.3586,9.0332c-1.8479,0.0605-3.6472-0.0852-5.4344-0.4135
  c-2.2126-8.1333,5.0697-15.8656,1.7993-23.8287c-2.8691,2.6262-4.2065,6.249-5.0818,9.9572
  c-1.9695-0.4012-4.7049-0.1095-4.4496-2.8937c-1.7993-5.7504-0.1216-11.744,2.5774-16.8866
  c0.7902-3.1854,0.2797-9.021-3.9876-9.021c-1.7629,1.4713-3.0151,3.4528-4.4619,5.2156c-0.6565,0.0487-1.9573,0.1582-2.6138,0.2069
  c-1.1915-2.7477-2.0668-5.933-4.6685-7.6836c-2.3951-0.1945-5.3372-0.0365-6.8204,2.1761c-2.2978,3.173,1.9086,5.8357,2.93,8.729
  c-1.7507,0.6322-3.5014,1.2766-5.2521,1.9332c0.2432,1.7019,0.389,3.4403,0.9483,5.1182
  c3.8296,1.9573,8.5832,2.2733,11.6712,5.5802c2.8326,4.1702,4.8022,9.325,3.7688,14.4432
  c-4.7779-0.0244-4.7414-7.0758-9.6773-7.088c1.0577,3.6959,4.1214,6.8083,3.6594,10.8688c-0.7659,0-2.3099,0.0243-3.088,0.0365
  c-3.6715-4.3765-5.4587-10.3339-10.5041-13.4703c-3.0029-1.8601-5.4708-5.1426-9.2761-5.1547
  c2.5652,3.1729,4.79,6.6135,6.4313,10.3822c3.0272,4.8751,4.4375,11.1852,1.3009,16.3885
  c-3.1367-3.5867-4.5226-8.17-6.7353-12.2792c-1.6776-2.541-0.2067-6.2247-2.626-8.2915c-1.775-1.9814-5.4344-2.1154-7.1851-0.0365
  c-1.8116,1.7753-1.4467,4.6443-1.0942,6.9421c2.9178,2.48,8.3522,3.2217,7.6714,8.1333
  c-6.1274-0.0121-11.1241-4.2064-16.9354-5.5438c2.2301,4.9471,6.2349,8.8719,8.9669,13.5884h-3.4191
  c-1.8539-2.5282-4.1697-4.6326-6.8002-6.3182c-2.0789,1.8845-4.1335,3.8176-6.3219,5.6047
  c-0.924-7.7931-0.462-16.1573,3.9269-22.8927c1.313-1.6414,1.4103-3.7689,1.6291-5.7261
  c-3.9025,2.0546-6.8568,5.3978-9.3369,8.9843c-0.6566-0.0609-1.9817-0.1945-2.6382-0.2552c-0.62-2.8572-1.0212-5.7505-1.1671-8.656
  c3.9147-2.6384,10.2366-4.5591,10.0663-10.2855c0.5593-3.319-1.2886-6.2245-3.5864-8.3641
  c-2.4315,0.693-4.5348,2.1519-6.8082,3.2338c-1.8601-3.0272-1.9817-7.6593-5.5925-9.203c-2.5409-1.9088-4.4253,1.5074-6.3219,2.796
  c0.9726,2.2979,2.9543,4.5106,2.3099,7.2096c-3.1366,0.1578-6.2733-0.1826-9.3978-0.1096
  c-0.2675,2.626-0.3404,5.2765-0.1945,7.9268c4.4861,1.4711,9.2883,1.7628,13.6893,3.5986
  c0.0122,1.7992-0.0607,3.6107-0.1945,5.4343c-3.55,1.5562-6.7474-2.2976-10.4068-1.9088c-2.6017-0.6079-3.8904,1.994-5.3979,3.5867
  c-4.79,5.2156-4.4618,12.7532-5.0332,19.3424c-1.2644,1.7628-2.7962,3.3312-4.3889,4.8265
  c-0.389-3.2825,0.3647-6.8202-1.2522-9.8234c-2.1033-4.2551-3.6716-9.118-7.6714-12.0358
  c-0.2188,6.103,4.9724,12.741,0.8511,18.4672c-4.4375,0.8026-3.2947-5.0331-4.8265-7.6589
  c-2.5045,2.6624-3.3433,6.2244-4.3281,9.6407c-0.7051,0.158-2.1155,0.4618-2.8327,0.6079
  c-0.2675-2.7963-0.2917-5.6047,0.1581-8.3524c0.9118-2.8933,2.6017-5.5801,2.4193-8.7046
  c-4.1093,1.7506-6.8203,5.3248-9.106,8.9965c-4.705-0.6201-4.1335-7.2823-8.9357-8.0968
  c-0.1945,3.8052,0.0486,7.6593-0.462,11.4644c-0.4984,2.6868-3.1974,3.5381-5.3128,4.5956
  c-0.0729-5.1669-1.9208-9.9204-5.0697-13.9689c-1.0698,3.635-0.3282,7.9875-2.9421,11.0391
  c-1.0699,1.5684-3.0272,2.0059-4.6685,2.7476c-0.9604-2.3344-1.7628-4.7051-2.9299-6.9056c-1.8163-2.0894-4.5219-3.058-6.765-4.585
  c-0.9565,1.6204-2.0593,3.1597-3.4323,4.5558c-3.5784,3.6385-6.9338,6.7728-11.3618,8.3872
  c-2.1514,1.4357-4.8223,2.0122-7.451,1.077c-7.0691-2.5149-9.888-8.8354-8.5985-15.8209
  c-1.8868-0.7064-3.7256-1.6442-5.5091-2.8529c0.158,5.4634-0.0488,10.9919-1.5988,16.2559
  c-3.7567-2.4071-6.6624-5.9083-8.1456-10.1147c-0.6928,0.0848-2.0668,0.2552-2.7597,0.3282
  c-0.2553,3.7565,3.6837,8.5225-0.1581,11.1848c-4.2065-2.5532-7.2094-6.9784-6.869-12.0239
  c-0.5349-4.4617,2.5409-8.0603,3.5743-12.1575c0-2.1033-1.7385-3.5255-2.857-5.0818c-2.5895,5.009-5.8964,9.7504-7.3553,15.2699
  c-1.3008,0.0487-2.6138,0.097-3.9025,0.1823c-0.6322-2.395-1.3251-4.7534-1.9209-7.1241c-0.8875-0.146-2.6625-0.4377-3.5621-0.5835
  c0.2189,4.1942,0.8997,8.3398,1.5562,12.4977c-2.4195-1.3616-4.7536-2.8325-7.027-4.3766
  c-0.6808,2.5895-1.3981,5.1426-2.1519,7.7441c-6.0788-7.5132,2.9056-14.832,3.3433-22.4426
  c-4.8508,3.465-9.2397,7.7445-12.0359,13.0571c-0.7051,0.0973-2.1276,0.304-2.8448,0.4135
  c-0.2675-4.6686-0.8875-9.3734-3.0637-13.5676c-2.5166-5.4469,2.2005-13.6045-4.3403-17.0327
  c-2.9907-0.4865-7.0635-1.3739-8.8871,1.775c-2.1397,1.9814-2.1154,4.9238-2.2005,7.6227
  c4.7779,3.5499,11.3794,7.1241,10.4189,14.1512c-4.936-2.2976-8.8993-7.4768-14.7957-6.9541
  c3.246,5.7262,7.5133,10.8201,10.6865,16.5948c0.0364,1.4105,0.0972,4.243,0.1337,5.6534
  c-5.5438-2.2249-9.0816-9.3007-15.5859-8.498c0,5.5801-3.4284,10.1027-6.0302,14.759c-0.693,1.8115-2.0424,2.6381-4.0363,2.5045
  c-1.386-4.9603-1.0334-10.4434-4.3403-14.7472c-0.9118,3.5259-1.7142,7.1123-1.8965,10.7838
  c-1.0577,0.9971-2.0911,2.0181-3.1367,3.0394c-1.2284-2.5424-1.7456-5.7528-3.9841-7.5789
  c-0.0007,0.0117,0.0005,0.0228-0.0003,0.0345c-0.1769,2.5674-0.904,4.8151-2.7656,6.6768
  c-1.7628,1.7628-4.1826,2.7656-6.6769,2.7656c-4.29,0-8.5847-3.3312-9.3223-7.6292c-0.5909-0.4194-1.1582-0.8846-1.6959-1.4126
  c-2.3617-0.1807-4.2164-1.1064-5.5681-2.4576c-0.0538-0.0076-0.1074-0.0215-0.1612-0.0299c1.3759,1.7781,2.3123,4.16,3.4642,6.057
  c1.2643,1.5924,0.7294,3.6959,0.8024,5.6044c-2.5409-0.9967-5.094-2.1641-6.942-4.2186c-2.7026-2.4645-2.6544-6.3732-2.9252-9.7196
  c-0.8823-0.2098-1.7342-0.5387-2.5259-0.984c-2.4544,2.8171-4.809,5.7273-6.3768,9.1696
  c-4.6442-2.1154-4.2186-8.5345-9.0452-10.6378c-0.1702,5.2278,0.6443,10.4678-0.1945,15.6833
  c-0.8754,0.2067-2.626,0.6201-3.5135,0.8267c-1.6534-4.9603-1.0334-10.3582-3.1487-15.1969
  c-4.2187,1.6171-0.4742,9.2395-5.2277,10.2366c-3.0151,0.9482-4.2551-2.4923-5.7019-4.3522
  c-1.848,0.17-3.6959,0.3523-5.5317,0.5835c-1.2522-3.4407-2.5773-6.8691-3.5378-10.3947
  c1.0212-4.3281,2.0181-8.8142,0.7902-13.2153c-4.7901,5.6898-5.1669,13.4097-7.0514,20.2667
  c-0.8145-0.0974-2.4315-0.304-3.246-0.4014c-0.0851-5.5317-2.2248-11.1121-0.5349-16.5464
  c1.1063-4.2065-1.7872-8.0238-2.2127-12.1089c-1.1549-4.486-0.6078-9.1302-1.2887-13.6406
  c-1.0212-0.8389-2.1154-1.5562-3.1974-2.2611c-0.5348,4.4739-1.082,8.9598-1.6899,13.4459
  c-1.8115-0.5957-4.7172,0.1217-5.2034-2.322c-1.8723-4.4374-2.9664-9.5924-6.8568-12.8019
  c-2.4072-2.0179-5.0697-4.5469-8.4859-3.8903c-3.6959,4.3159-5.9451,9.6287-7.7079,14.978
  c-4.9481,0.158-4.2916-5.2521-3.0758-8.4253c-0.6079-7.8658-6.018-14.3337-10.9661-20.1207
  c-1.7872-2.0181-4.2187,0.0365-4.5104,2.2128c2.5287,4.5591,6.8811,8.8507,5.9207,14.5525
  c-0.8875,6.2856-1.8966,12.5587-1.5927,18.9414c-5.5925-1.8966-6.0787-9.58-11.9752-10.9052
  c-4.9359-1.4224-11.1241-2.9907-15.5251,0.474c6.0179,3.6472,14.8808,5.6412,16.1451,13.8475
  c0.2189,4.8265,2.1033,11.0148-2.6625,14.2485c-0.9969-3.1124-1.4711-6.3705-2.8692-9.3125
  c-2.2977-3.7689-5.4465-6.9543-8.6561-9.9451c0.2797,4.1942,0.1094,8.4615-1.4103,12.4492
  c-1.4589,0.3284-2.9664,0.4014-4.4374,0.2069c-2.2005-4.5469-2.5652-9.6654-3.4771-14.5406
  c-0.2553-5.6896,0.4012-11.4156-0.6201-17.0444c-0.8875-1.2523-2.3342-1.8967-3.5135-2.772
  c0.0121,4.2307-0.231,8.4858-1.3009,12.6193c-1.5075-0.7657-3.866-0.8387-4.3159-2.8083
  c-2.0668-6.3341-1.7993-13.3733-5.0818-19.3181c-3.4041-3.5256-7.3674-6.8328-11.9752-8.5834
  c-3.477-1.1914-6.1274,2.6625-6.4678,5.714c-0.3282,4.0729-1.0577,9.4707,3.1609,11.8779
  c4.1457,2.8937,11.2335,4.936,10.5892,11.2213c-3.0029,0.2797-6.0787,0.2797-8.9965,1.1309
  c-0.0608,0.547-0.1945,1.6411-0.2675,2.1881c3.5986,3.2219,8.5467,2.9789,13.0206,3.4772
  c5.0576,4.1699,7.7687,10.4918,8.8993,16.814c-7.0148-1.6901-13.7501-4.8025-21.0568-5.1913
  c1.5197,7.4645,7.2094,12.7654,12.5952,17.677c2.7233,1.8721,1.7507,5.3248,1.6899,8.1211
  c-3.7688-3.0637-5.9329-8.0728-10.7593-9.7137c0.851,3.3433,1.8844,6.65,2.4072,10.1029
  c-1.8723-0.6566-3.6594-1.5076-5.5803-1.9575c-2.6017,1.848-5.2642,3.6472-8.6561,2.9785
  c1.3981-3.6594,3.5257-7.0514,4.3159-10.9174c1.2278-6.9297-0.693-13.8959-3.0515-20.3638
  c-0.9118-2.2855-3.6959-1.848-5.6533-2.3707c2.7841,8.559,3.0516,19.7682-5.4465,25.2512
  c-1.9938-1.678-2.1276-4.3768-2.8084-6.6744c-1.1671-4.8875-2.7111-10.176-0.6929-15.0269
  c2.0181-5.0209,2.2491-10.5283,1.702-15.8291c-1.3251-4.9966-5.325-8.498-8.9722-11.8535
  c3.2947-3.7443,4.4254-8.3521,4.2065-13.2394c4.4861,1.4954,10.9539,3.246,13.7623-1.9818
  c0.2918-4.3035-1.009-8.6438,0.0122-12.8989c0.8024-2.9058,3.3798-5.7748,1.86-8.875c-1.5683-2.6016-4.0484-6.2732-7.5862-4.6321
  c-4.0485,0.4135-8.3644,4.2794-12.1818,1.6779c1.3008-1.6901,2.9056-3.1245,3.9755-4.9603
  c0.7173-3.1608,0.4255-6.4314,0.5349-9.6287c-2.1762-2.0305-4.0485-4.3768-6.3948-6.1761
  c-3.9877-0.693-7.5498,2.2737-11.5496,1.7506c-2.5287,0.1217-5.4829-0.6683-7.562,1.2279
  c-4.632,2.298-3.4892,8.8142-0.5106,12.0483c1.2036,2.2002,4.3767,5.4708,1.0942,7.3309
  c-3.1609-0.0608-6.2368-1.6901-9.3978-1.0699c-3.7809,1.3374-6.9419,4.5104-8.7412,8.0118
  c-1.4103,4.0361,3.4892,5.0818,5.8478,7.0149c1.2643,3.319,2.0181,7.0271,4.5955,9.6774c3.7932,3.6229,8.644-5.6292,11.7441-0.45
  c-0.1945,3.708-2.2613,7.2336-1.4224,11.0148c0.6686,3.5015,2.2977,6.8082,2.3707,10.4556
  c-1.933,0.097-4.1457,0.0848-5.3129-1.7267c-2.7354-2.8689-5.3493-5.8718-8.1698-8.6559
  c-0.4255,4.7899,0.0121,9.9447,2.7233,14.0663c3.3798,4.5709,8.6804,6.9905,13.4219,9.823
  c2.9299,3.392,4.0728,7.9023,6.1152,11.8414c-0.4133,0.4257-1.2522,1.2766-1.6777,1.7023
  c-3.6229-1.4954-6.565-4.1946-10.0786-5.8235c-4.17-0.6201-8.4495-0.4014-12.6316,0.0486
  c-0.5471,1.9938,0.7173,3.4282,2.1884,4.5956c4.8265,5.1426,12.6924,4.4861,18.0903,8.7533
  c6.6136,3.6959,10.0542,10.8931,11.7198,18.0051c-1.0942-0.0605-3.2825-0.1945-4.3645-0.2675
  c-1.4224-2.6625-2.93-5.337-5.2521-7.2946c-1.6412,0.4744-3.2095,1.1553-4.8508,1.6293c-2.6625-2.1154-4.0485-5.4709-6.7352-7.5863
  c-2.1762-2.4558-5.6776-1.2887-8.4616-1.094c-0.1337,4.4617,4.705,7.4889,3.7445,12.048
  c-0.6565-0.1339-1.9817-0.4136-2.6382-0.5471c-6.9055-5.4343-14.2365-11.0634-23.3181-11.8778
  c0.1216,1.4344-1.7993,4.1334,0.2917,4.7412c7.6592,2.8207,13.58,9.9691,14.6133,18.1149
  c0.8875,5.6288-4.6807,10.4431-3.5622,16.0236c0.1745,0.2063,0.357,0.3811,0.5441,0.5372V1600h13.125v-0.1007h119.622
  c9.8718,0.1566,19.745,0.0782,29.6178,0h26.128c0.5346,0.0374,1.0667,0.0317,1.5972,0h16.2586c2.2372,0.099,4.4855,0.0428,6.7326,0
  H838V1600h1268.5583l0.1174,0.6772l0.23-0.6772h139.0469L2239.6616,1578z M1981.8691,574.9888
  c1.9088-1.8601,2.0061-5.0332,4.3038-6.5773c2.8448-1.0212,5.9694-0.8754,8.9844-1.009c0.0607,5.3615,1.1306,10.808,0.0851,16.1086
  c-6.2732,1.4832-12.4736-1.4103-18.4794-2.7719C1978.0154,578.4535,1979.9849,576.7394,1981.8691,574.9888z M1905.6661,173.0864
  c1.4589-4.6565,3.8539-8.9724,6.5164-13.0328c4.7415-5.8965,11.6833-10.4069,14.0297-17.9323
  c1.2158-4.6321,6.8206-6.2856,10.9539-4.5227c4.5105,1.9209,9.4586,0.9119,14.1879,0.6685
  c2.006,6.2004,7.3068,12.1575,4.8751,19.0266c-3.5743,4.2795-7.7808,8.3036-12.9233,10.5891
  c-0.5349-0.4619-1.6047-1.3738-2.1398-1.8357c1.848-2.4315,4.2794-4.3403,6.7111-6.1639
  c-0.3527-6.3584-2.3223-13.2394-7.1852-17.6162c-2.6503-0.2552-2.8813,2.7233-3.7688,4.4984
  c-2.152,6.5164-8.1333,11.8292-7.4891,19.1238c-0.2551,3.635,1.1794,7.9995-1.8721,10.8323
  c-6.869,7.027-16.5465,11.197-26.4304,10.7957C1900.949,182.2651,1904.1949,177.9373,1905.6661,173.0864z M1837.5354,195.4561
  c-0.2308-1.3616-0.3525-2.7354-0.3889-4.0969c2.5409,2.8325,4.948,5.7869,7.6105,8.5222
  C1842.3621,198.3496,1839.8455,197.061,1837.5354,195.4561z M1581.9486,182.1438c-7.4039-7.2458-17.1299-11.2457-26.6615-14.8322
  c-3.3311-0.6079-3.1609-4.6564-2.2856-7.1609c4.9727,1.2643,8.4253,5.5925,13.2761,7.2338
  c4.9602,2.4802,11.27,1.3252,15.5496,5.3008c3.4769,3.5623,5.337,8.3401,7.5254,12.741
  C1586.459,185.3774,1583.906,184.1982,1581.9486,182.1438z M1349.5459,115.8245c0.304-3.5376,0.8391-7.0511,1.6899-10.5039
  c2.7234-5.24,6.5408-9.9814,11.2457-13.5922c2.2977,2.1154,4.1578,4.8024,6.881,6.4434c5.9087,0.6078,12.3523,1.2401,17.896-1.4222
  c3.781-1.7021,8.644-2.1519,10.6499-6.3462c-4.9724-1.1187-9.0331-4.1824-13.4581-6.468c-3.611-1.9331-7.7323-1.6292-11.647-1.2765
  c0.0243-1.2279,0.0731-2.4437,0.1217-3.6594c2.1882-1.6288,4.3766-3.2703,6.6865-4.7413c1.9817,3.4528,4.4009,6.6866,7.4768,9.252
  c6.8203,0.6927,14.1636,1.2522,20.3152-2.4194c-1.7019-7.6106-8.5952-12.2791-14.5159-16.5464
  c5.483-0.9725,11.2092,0.2188,15.9991,2.9907c-0.8265,7.1487,6.3463,10.4919,11.5618,13.4098
  c3.708,1.7993,6.1031,6.0665,4.2308,10.0421c-3.5742,6.0787-6.9785,12.899-13.3002,16.5706
  c-9.3856,5.6289-19.3181,10.5164-29.9681,13.2031c-6.1394,2.3706-12.6074,4.4131-17.677,8.8142
  c-5.5194,3.9026-9.1666,9.9446-15.1846,13.1908C1348.8411,133.7933,1347.4305,124.6387,1349.5459,115.8245z M1303.1895,153.6466
  c-3.2825-2.2128-6.1882-6.2854-5.7383-10.3824c2.079,0.2186,4.1213,0.8145,6.0422,1.6777
  C1303.8096,147.8353,1304.0283,150.8259,1303.1895,153.6466z M1042.1561,57.3713c-3.3312-2.8448-7.9631-0.085-11.4889-2.1519
  c-0.2311-0.5229-0.6931-1.5682-0.924-2.0911c5.2518-1.2765,11.6346-2.2371,16.364,1.0941c2.942,1.775,5.5316,4.0363,8.182,6.2125
  C1050.0339,64.3013,1045.9977,59.1221,1042.1561,57.3713z M1099.9285,136.8206c0.4376-3.2095,0.0973-6.7109,2.0544-9.4951
  c4.9604-1.0698,7.7808,3.5015,10.2124,6.9541c2.3342,3.8906,3.8052,8.2307,5.7383,12.3278
  c-6.4313,2.6869-12.3765,6.3949-16.6923,11.963c-1.4954-5.2397-5.5316-9.3492-6.9056-14.5525
  C1095.8191,141.3552,1097.8979,139.0819,1099.9285,136.8206z M1093.5945,199.2493c-8.3767-2.2979-12.6926-11.9021-21.5554-13.045
  c-2.8691-0.1823-5.9451-2.4315-8.5344-0.2797c1.7384,3.0031,3.9269,5.7142,5.8477,8.6075
  c-4.085,0.6565-8.1456,0.0365-11.9995-1.3251c-5.2886-1.8843-11.0634-0.4012-16.3273-2.2856
  c-7.1244-2.2856-14.808-2.4436-21.7256-5.3613c-5.0089-4.3647-8.8142-9.9084-12.814-15.1605
  c3.3798-2.2003,7.4769-2.5531,11.1607-3.9996c3.0152-2.7112,5.1547-6.2979,6.7474-10.0056
  c1.7507-4.9482,1.775-10.2732,2.9785-15.3551c-2.1274,0.0731-4.255,0.2675-6.1881,1.2157
  c-3.7203,1.7144-7.9997,2.7112-10.8564,5.8357c-1.994,2.2006-3.6231,4.7048-5.2401,7.1729
  c-0.5348,0.1094-1.6049,0.3162-2.1397,0.4133c0.5714-4.4617,1.6534-8.875,3.1488-13.1057
  c4.9725-2.1641,10.0664-4.024,14.9902-6.2854c8.5589-1.9209,16.7165,3.3677,25.3364,2.3708
  c6.2732,0.4135,10.6621-5.8357,16.9352-5.2886c6.2733-0.6564,11.1241,4.024,16.3031,6.7839
  c0.0122,0.6079,0.0486,1.8479,0.0729,2.468c-6.3096-1.7629-13.045-4.9482-19.5612-2.3829
  c-8.1094,3.8417-12.267,12.5464-13.4463,21.0446c5.0209-1.082,9.1545-4.5955,14.2971-5.3251
  c4.0608-0.3889,8.1333-0.0487,12.2062-0.2065c4.9479,0.2186,8.1577-5.4103,13.1421-4.9603
  c5.9694,3.1123,6.6139,11.0269,5.483,16.9475c-6.3706,1.082-10.3096-5.6045-16.3519-5.957
  c-8.3036,0.1824-16.0966,4.486-21.2269,10.9296c-1.848,3.2581,2.9907,4.8386,5.4951,4.559
  c5.7383-0.5105,10.9905,2.7112,16.6436,2.4924c4.863-1.6536,9.9204-3.5986,15.1482-2.3221
  c2.3831,4.0118,3.0759,8.8871,6.2249,12.4857c2.7839,4.2672,7.3676,6.723,10.8687,10.2853
  c0.1702,1.1063,0.5105,3.3434,0.6809,4.4496C1104.074,202.8966,1098.9193,200.8176,1093.5945,199.2493z M1161.3237,237.0349
  c-4.4741-2.6992-8.2427-6.322-12.267-9.5923c-7.3188-5.0818-14.8322-10.2488-20.3395-17.3489
  c-4.097-5.9691-8.3522-11.8535-13.665-16.7896c-4.6198-5.1791-11.2456-10.2731-11.27-17.7985
  c3.6959-3.5015,9.5803-3.2826,12.7411-7.5255c3.1976-2.3342,2.3342-9.1788,7.5985-7.6958
  c4.8264,5.9692,10.4434,11.2336,16.4856,15.9629c5.3007,5.811,9.9568,12.2911,14.1149,18.9778
  C1160.8739,208.1851,1161.9924,222.8835,1161.3237,237.0349z M1160.8618,177.3658c-2.7598-1.4469-5.2156-3.3433-7.5132-5.4222
  c-5.4587-5.1914-12.1697-9.3857-15.7195-16.2791c3.6594-1.24,5.9084,2.2736,8.5952,4.0486
  c4.6318,3.9878,11.3306,5.4709,14.6616,10.9175C1162.4058,172.6365,1161.0928,175.1653,1160.8618,177.3658z M841.059,606.0145
  c-1.6048,4.6077,1.009,11.5861-4.4619,14.1027c-7.5862,1.9573-15.6588,3.8661-21.1905,9.9205
  c-2.8935-2.3464-7.5864-3.8174-8.0118-8.0726c5.556-6.0423,7.7565-16.0844,16.7166-18.2606
  c7.1486-0.4255,9.5071-8.5953,15.7682-10.8323C842.8461,596.4224,841.2535,601.7229,841.059,606.0145z M1016.4673,500.5118
  c-4.4132,0.0607-8.8264,0.0363-13.2274,0.0973c-1.2401-6.0911,2.1761-11.6105,2.8813-17.531
  c4.863,1.5804,5.1791,7.7563,8.887,10.8201C1017.2454,495.4907,1016.6862,498.1411,1016.4673,500.5118z M1031.8708,492.415
  c-1.1914-2.7112-2.0061-5.6289-3.7324-8.0725c-3.5742-2.7842-8.1456-4.0851-11.4523-7.2823
  c-1.6171-3.8297,2.3829-5.1913,5.1061-6.5774c2.4194-1.1915,5.1548-1.2643,7.8051-1.2401
  c2.1761,2.7234,1.8236,6.4922,3.2705,9.5924C1034.6063,483.1997,1036.5149,489.1326,1031.8708,492.415z M1055.4928,494.0077
  c-0.4376-3.9755,0.1945-8.255-2.079-11.8049c-1.7386-4.4254-8.4253-5.6898-8.012-10.9174
  c8.8995-0.1096,11.7808,9.6165,17.8351,14.2607C1063.0791,489.3756,1059.6748,494.2874,1055.4928,494.0077z M1012.8443,642.5599
  c0.6565-5.6653-1.4102-12.2669,3.4285-16.5341c2.9299-0.0729,5.8843-0.2554,8.7899,0.2553
  c3.7446,2.2005,5.0696,6.6017,6.1882,10.5284C1024.6371,637.0649,1019.0324,640.7242,1012.8443,642.5599z M1330.8478,842.5507
  c-3.7688,0.2067-7.8171-0.8632-11.3065,0.9604c-3.1608,1.3859-6.3218,2.7598-9.5193,4.0485
  c-1.2522-4.0728-3.6958-9.6166,0.1824-12.972c3.4893-3.9147,7.6958-8.0118,13.3247-8.0239
  c4.4496-0.1702,8.1577-2.7962,12.1089-4.5347C1333.632,828.7884,1334.3005,836.2409,1330.8478,842.5507z M1509.5386,1002.762
  c-4.3279-1.6656-9.2639-2.0181-12.9719-5.0575c-0.4258-3.1367,0.8264-6.3098,0.1699-9.3734
  c-1.5195-2.8206-3.3676-5.7262-2.7354-9.0938c1.0334-1.2158,2.1396-2.3707,3.3311-3.4648
  c3.9878,2.8813,7.6106,6.2975,12.0239,8.571c2.395,1.0091,3.2825,3.5743,4.1456,5.8356
  c1.9453,5.3128,4.2672,10.4797,6.2854,15.7682C1516.0308,1006.1053,1512.9304,1003.9535,1509.5386,1002.762z M365.0081,1533.6432
  c0.1337,1.9209-0.8389,4.8873-3.2704,4.6564c-0.9118-1.4224-1.4832-2.9542-1.7142-4.5956
  c-1.1428-5.8965-3.4771-11.4644-4.863-17.2758c2.0425-3.6837,6.6866,0.2552,9.9692-0.243
  C364.8865,1521.9965,365.1661,1527.8197,365.0081,1533.6432z M457.3199,1541.3024c2.5652,2.3829,4.5347,5.3009,5.3857,8.7534
  C457.405,1551.8551,456.6877,1544.8888,457.3199,1541.3024z M497.3667,1575.6473c-1.1671,0-3.4892,0.0122-4.6442,0.0243
  c-1.933-5.556-1.4711-11.4888-1.5197-17.2513c-1.4954,0.1213-2.9907,0.243-4.4861,0.401c-0.2188,1.9696-0.3769,3.939-0.5228,5.9573
  c-3.4771,0.0487-6.9662,0.0609-10.4554,0.1702c-2.5045-6.5894-4.5347-13.4946-3.9269-20.6191
  c3.5135-3.015,6.8325-6.261,10.5528-9.0085c1.8723-0.5957,2.9421,1.5924,4.2551,2.5286c3.8539,3.5624,6.6745,8.024,9.6287,12.3279
  c1.5197,1.8236,1.0091,4.2794,1.1793,6.4918C497.6099,1562.9915,497.2938,1569.3134,497.3667,1575.6473z M624.2422,1583.124
  c-1.5197-0.024-3.0394-0.0605-4.5469-0.0848c-1.0334-3.9999-3.3919-9.2155-0.1459-12.6439
  c0.6565,0.0608,1.9817,0.1825,2.6503,0.2433C623.5858,1574.6504,624.607,1578.8328,624.2422,1583.124z M819.3943,1578.1641
  c-4.1214-4.1581-6.2855-10.407-6.0787-16.2305c3.6229-1.2035,4.3767,2.699,5.714,5.1062
  C820.7195,1570.4803,819.7225,1574.5046,819.3943,1578.1641z M825.8135,1565.3257c-4.1335-2.942-8.8022-5.6288-11.5496-10.0178
  c2.7474-3.8295,7.562,0.0974,9.7625,2.6625C826.4943,1559.6725,825.8135,1562.7362,825.8135,1565.3257z M1144.1573,1572.0974
  c-5.957-5.7748-10.7347-13.1058-12.2911-21.3364c4.4252-0.2675,7.4039,3.2338,10.0906,6.2488
  C1145.288,1561.2649,1144.9233,1567.0519,1144.1573,1572.0974z M1334.252,1562.6024c-0.4255-0.4984-1.2888-1.4833-1.7141-1.9818
  c2.48-4.6561,5.8962-8.729,8.4253-13.3611C1344.999,1552.7063,1340.209,1561.3866,1334.252,1562.6024z M1394.0909,1534.8954
  c-1.2888,4.5591-1.5441,9.3125-2.006,14.0177c-4.863-1.9697-8.8142-6.2249-14.4066-5.9937
  c-0.6078,4.5348,1.7141,8.8751,4.9237,11.9385c2.2612,1.8236,5.2278,3.1124,6.3584,6.0425
  c1.8115,2.0059-0.2917,4.1334-1.0942,6.1028c-0.839,0.0487-2.5288,0.1704-3.3798,0.2191
  c-0.9481-3.5137-2.8448-6.6257-4.9479-9.5316c-0.9363,1.0336-1.8359,2.1154-2.699,3.2338
  c-2.614-5.8231-6.006-11.2697-9.5316-16.5583c-1.0697,2.5895-1.0577,7.6837-5.1548,5.4587
  c-1.0577-3.9269-0.1459-8.024-1.0092-11.9509c-3.416,0.0609-3.3433,4.4498-4.7292,6.7719
  c-0.5347,2.7598-2.8691,4.3281-5.4829,4.7899c-1.2402-5.3856-0.5715-12.2183,4.8508-15.0386
  c3.1123-1.3983,6.2124-4.0729,9.7866-3.6351c4.1578,2.3585,6.8933,6.9054,11.647,8.2913c3.416-0.7902,6.626-3.1122,7.7322-6.5406
  c-1.3374-3.1367-4.8145-3.7202-7.6471-4.7899c1.2279-3.9513,5.1669-5.7384,8.0968-8.2794
  c2.6382,3.3312,5.9694,6.0422,8.5588,9.4221C1395.3312,1530.5916,1394.5045,1532.9382,1394.0909,1534.8954z M1511.3743,1561.2166
  c-5.0938,0.4618-6.6379-5.7629-10.3704-8.2306c-1.3979,3.015-0.2308,9.422-5.1062,8.5345
  c-3.8779-4.4985-2.6016-10.723-2.7354-16.1453c-0.2065-3.2218,1.3131-6.1273,2.9178-8.7776
  c2.0181-0.3527,4.1456-0.4257,6.1515,0.231c2.5532,4.9116,4.936,9.9082,7.7445,14.7103
  C1511.8972,1554.3961,1511.4961,1557.9338,1511.3743,1561.2166z M1569.0859,1516.7931c-2.395,4.6682-3.8418,9.9325-7.6227,13.762
  c-0.1581-2.4919-0.304-4.9844-0.5836-7.428c-2.6138,2.5771-5.4222,4.9481-8.0969,7.4645c-1.7992,1.921-3.1731,6.2614-6.5165,4.5104
  c-0.17-6.2001,0.839-12.3518,0.9604-18.528c1.4347-5.1061,5.0941-9.507,9.2155-12.7532c2.9421-1.4467,4.9238,1.6292,7.1122,3.0272
  c2.4193,0.4987,4.9237,0.4987,7.4039,0.8391C1570.7029,1510.7629,1570.4719,1513.9602,1569.0859,1516.7931z M1618.0806,1258.896
  c-1.7505,9.5194,0.5229,19.4277-2.3584,28.801c-1.5076,4.316-0.6931,8.8994-0.9604,13.3733
  c-3.4285,1.3009-5.24-2.0789-5.9208-4.8265c-0.6322-3.9633-0.4863-7.9872-0.3282-11.9628
  c0.2311-3.708-3.1609-6.7839-2.1763-10.5529c1.3495-4.4374,0.1946-9.4099,2.7112-13.4703
  c2.541-4.4982,4.4618-9.3855,7.7201-13.4341C1617.5458,1250.7749,1618.5426,1254.8113,1618.0806,1258.896z M1860.4767,1083.2932
  c-3.7323,3.0637-8.9601,2.699-12.8627,5.3737c-3.1852,1.8358-6.8689,2.5166-10.0907,4.2794
  c6.2611-6.1395,12.7897-12.267,17.1908-19.9625C1860.4038,1072.3151,1860.9508,1079.1354,1860.4767,1083.2932z M1903.7207,994.5314
  c-5.0938,10.4069-14.0173,18.0904-21.2146,26.9775c-4.5348,6.0059-11.1486,9.9692-15.7805,15.9021
  c-5.6532,6.7109-13.8838,10.3704-20.0842,16.4734c-1.0698,1.2765-2.4314,1.7385-4.0605,1.386
  c-1.6169-6.1882-3.6715-12.4249-3.1731-18.8928c-0.1702-4.4617,1.5198-8.6439,2.3707-12.9598
  c0.8754-4.4496,2.3099-8.7899,4.474-12.7775c3.4406-2.4558,5.3735,1.8844,7.4526,3.939c3.3918,3.8539,9.1665,3.6959,13.7743,2.541
  c1.2279-1.848,0.2919-4.1822-0.9362-5.7383c-2.2126-3.0516-3.939-6.5286-6.7354-9.106c-2.8934-1.5683-6.1028-2.4315-9.1544-3.5743
  c-0.535-3.0515-0.2067-6.1273,0.2919-9.1425c1.7749,0.2675,4.2063-0.3647,5.0938,1.7264c2.3223,3.5743,5.6899,6.4192,9.5802,8.2063
  c4.9845-1.386,10.6864-1.5197,14.7836-5.0818c-2.9058-4.9481-6.7839-9.2154-11.8901-11.9265
  c3.0759-4.9238,8.6805-0.7538,13.118-1.155c6.2853,0.9726,11.0389-4.2794,14.8563-8.4859c1.4469-1.313-0.0121-3.6958-1.4832-4.3037
  c-4.6199-2.5652-9.8718-4.7535-15.209-3.2217c-3.5013,1.6169-6.1274,5.1304-10.188,5.4101
  c-4.474,0.4498-8.7655,1.8844-13.2395,2.2491c0.6079-3.708,3.0272-7.7686,0.6444-11.3186
  c-1.3373-3.1245-4.4862-4.7657-7.173-6.5042c-2.8447,4.8508-7.2823,9.5557-6.6624,15.5981
  c-0.2917,3.5257,2.4802,6.2854,2.9785,9.6652c-1.4102,3.7446-3.7566,7.1365-6.1637,10.3217
  c-0.5957,0.1581-1.7871,0.4863-2.3828,0.6444c-3.234-3.7688-3.3677-9.7868-8.0604-12.2791
  c-4.8267-2.9299-11.0148-1.5319-15.4888,1.3738c-0.1094,4.9846,4.5349,8.0361,7.2095,11.7321
  c3.0028,4.7171,9.6045,3.4041,13.2517,7.3917c4.3279,2.6747,4.7899,8.4981,2.5286,12.7046
  c-3.4526,6.0909-4.9966,12.9599-7.0149,19.598c-0.8145,3.9877-2.0544,8.4858,0.1703,12.2548
  c1.3373,2.9907,3.9147,5.7383,3.4529,9.2396c-0.2919,3.9026,0.1215,7.8295-0.1217,11.7321
  c-3.9512,6.407-6.5043,14.0419-12.8748,18.601c-3.9147,2.4314-7.1608,5.9449-11.4888,7.6714
  c-9.8718,1.2765-19.5493-3.0029-27.5002-8.5103c-2.9299-2.541-6.48-4.936-7.8417-8.7169
  c-0.7172-7.2701,2.7355-14.0177,3.854-21.069c0.1824-8.1334-2.7841-16.4248-0.2433-24.4244
  c2.1398-11.416,5.7628-22.4549,9.5559-33.3966c2.0789-6.5771,6.48-12.1332,8.1699-18.8441
  c1.3372-4.012,3.866-7.5012,5.2275-11.5131c2.0304-4.4618,2.8085-9.5315,6.1639-13.2881
  c4.3766-5.1062,1.082-12.0481,1.2644-18.0296c-4.1823-1.082-8.4617,1.3495-10.5162,4.9603
  c-2.2371,5.3978-0.5957,11.3915-1.4225,17.0448c-5.8114-0.9483-8.8385-8.4859-15.1725-7.2215
  c-3.0029,0.5593-5.8113,1.7871-8.6804,2.6989c-8.1455-5.5074-19.0388-12.4979-17.5068-23.8652
  c3.088-1.6656,6.6865-4.4861,10.261-2.3707c4.3767,1.313,6.2124,5.8113,9.106,8.9236c3.8296,5.2155,9.884,8.1334,15.8776,10.1149
  c0.5106-5.9936-0.1945-13.1301-5.8234-16.5828c-3.0151-3.9512-8.024-3.9147-12.425-5.0211
  c-1.0455-4.7779,3.9634-7.2458,6.942-9.9813c5.787-1.8236,11.0997,2.2249,16.7166,3.0029
  c6.1273,1.2401,12.2791-0.4376,18.2848-1.5927c-1.0334-4.3646-5.6167-5.9692-9.2518-7.6106
  c-4.1456-1.9573-8.7777-1.2523-13.1787-1.4589c2.2734-6.8204,10.6622-7.647,16.522-5.5682
  c3.781,1.5197,7.9025,1.8601,11.9509,2.0424c3.9634-2.5287,7.8416-5.2155,12.121-7.2215
  c-6.4192-4.9603-13.4585-9.3248-21.1053-12.1089c-3.392-1.4589-5.86,1.7628-8.1942,3.6351
  c-5.0332,4.1214-9.1912,9.2154-14.3945,13.1301c-1.6412-0.4984-2.249-1.5075-1.7993-3.0516
  c0.8511-3.0515,2.1155-6.1638,1.228-9.3613c-1.1793-3.246-2.8448-6.2854-4.1821-9.4464c-2.7234-1.3738-4.158,1.7264-5.5074,3.4771
  c-3.2946,6.1152-2.6504,13.3611-2.1154,20.0477c0.4741,4.4739-3.2705,7.72-5.6046,11.0876
  c-1.0092,1.544-3.1488,0.6443-4.6199,0.851c-0.7051-7.9267,2.7111-15.1603,4.7535-22.5764
  c-6.0786,0.1215-10.2488,5.4708-12.9355,10.3217c-2.4071,4.9238,0.7781,11.732-3.8174,15.5008
  c-1.2158,1.1672-2.9542,1.2887-4.4739,1.7385c-3.1246-4.8265-6.4436-10.6621-4.1215-16.5342
  c2.3828-5.5073,5.2642-12.0845,11.5981-13.8596c3.6472-1.4467,7.951-1.933,10.7595-4.9238
  c3.173-3.2825,6.8688-6.0909,9.6042-9.7625c-3.7566-2.5287-8.6561-3.477-13.0206-2.1397
  c-3.939,0.4134-6.5164,3.8297-10.2607,4.7779c-1.3496-8.6197,7.5618-16.9354,16.0112-15.5373
  c3.4893,1.5804,6.0059,4.7415,9.337,6.6746c3.5863,2.0181,5.0453,6.0302,6.9419,9.4464c1.1792-2.2005,2.2612-4.5104,2.8085-6.954
  c-0.5715-5.4952-3.7568-10.5405-8.6077-13.2638c-0.0607-0.7294-0.1702-2.2005-0.2188-2.9299
  c3.0515-0.1215,6.3949-0.7416,9.3369,0.462c4.8022,3.5743,6.3948,9.957,11.3672,13.3489
  c3.6838,2.8935,7.1973,5.9937,11.0269,8.7169c2.468-3.7322,0.5349-8.0482,0.5471-12.1089
  c-0.0244-5.7748-2.942-11.7684-8.2063-14.4795c-4.5956-2.2613-9.9691-3.5621-15.051-2.395
  c-3.9755,0.8632-8.0967,0.4742-11.7927-1.1914c2.6625-2.9299,5.8477-5.4222,8.0239-8.7899
  c0.5957-6.334,0.0244-12.7288-0.0243-19.0751c-7.6349-0.1459-13.0693,6.638-16.8138,12.5344
  c-4.1335,5.8113-3.3188,13.203-4.2185,19.8896c-3.1731,2.6503-6.9177,4.5347-9.9814,7.331
  c-3.9998,3.1367-5.3127,8.2914-6.9905,12.8383c-0.45-0.0365-1.3374-0.1094-1.775-0.1459
  c-0.1215-7.173-2.6504-13.9933-3.0394-21.1419c-5.3492,3.3798-10.4675,8.1212-11.7563,14.589
  c-0.8997,4.705-4.486,9.4706-9.7137,9.2275c-0.6808-9.0816,0.316-18.2241,1.8966-27.1599
  c2.2734-6.8203,2.9663-14.4553,7.8416-20.0842c4.085-6.48,9.9448-11.5862,16.4491-15.5374
  c7.1122-3.4648,15.1118-3.1609,22.8075-3.1488c10.115-2.079,16.5707-10.9052,24.8741-16.2789
  c6.0302-2.9786,17.3365,1.4467,14.4674,9.5071c-6.2246,6.6136-13.6528,12.2791-18.2605,20.2543
  c-1.2644,2.2856-1.2036,5.0454-0.7538,7.562c6.0059-1.1672,13.3368-0.2675,17.7499-5.3979
  c3.9027-3.4771,4.2065-8.9357,5.6046-13.6164c4.401,3.9147,6.2004,9.957,5.6532,15.7196
  c-3.6957,7.4647-10.6863,14.0906-9.5557,23.1478c3.2339-0.851,6.5042-1.7021,9.5557-3.1001
  c2.7842-1.617,3.7324-4.863,5.4344-7.3918c3.6351,0.7173,4.5227,4.778,4.1578,7.9875c-0.9482,4.7535-3.8903,8.8993-4.6077,13.7137
  c-0.4133,4.1821,2.9908,7.3188,4.6686,10.8444c0.7172,3.1974,0.2675,6.5164,0.3768,9.7746c1.6777-0.7416,3.708-1.386,4.3888-3.3068
  c2.225-5.7505,5.3859-11.2822,6.2246-17.4582c-1.4467-7.4282-6.5648-13.9203-6.261-21.6768
  c4.9602-0.2188,5.3857,5.4709,7.8903,8.5346c3.6107,3.9877,8.9722,5.556,13.6162,8.0118c0.6566-0.535,1.9817-1.5805,2.6505-2.1154
  c2.6866,2.9056,5.1426,6.0422,8.3644,8.4373c1.629,1.0455,0.6079,3.1488,0.6442,4.6927c-6.4069,1.4711-12.4977,4.1457-17.92,7.8538
  c-0.7417,3.8417-3.0394,7.4404-2.8206,11.4402c1.1549,4.401,2.4802,8.7656,3.1245,13.2881
  c4.1091-0.8146,6.9176-4.012,9.7747-6.7961c5.1547-4.5469,4.3888-12.121,7.3794-17.8715
  c1.7144-5.1791,7.6472-7.3188,12.6682-7.3674c-2.9663,6.3097-6.3218,12.814-4.8386,20.0477
  c4.4982-0.4863,7.1851-4.9603,11.6589-5.4709c3.6838,7.562-1.9208,15.7562-0.62,23.6464
  c3.5013,10.5041,9.8961,19.6586,14.4674,29.6764c1.8967,2.6868,1.386,6.0544,1.0455,9.1302
  c-3.9633-3.0515-6.9419-7.2215-11.3307-9.726c-3.9269-2.006-2.9421-6.942-3.3677-10.5405
  c-2.0789-6.2125-6.5286-12.121-12.8019-14.4431c-2.2125,3.4892-3.55,7.7322-3.088,11.9022
  c1.0457,2.7841,3.9392,4.3282,6.0908,6.2248c0.0122,1.4102,0.0122,2.8204,0.0609,4.2551
  c-5.1791-1.4224-10.5162-2.2856-15.5858-4.0363c-3.5988-1.4102-2.1519-5.945-3.5865-8.7776
  c-2.9664-6.0058-9.2032-10.808-16.0115-10.9661c-0.1578,3.2218-0.474,6.48-0.0121,9.7017c0.9727,2.857,3.781,4.5591,6.2854,5.9937
  c-0.0244,1.082-0.0244,2.1641-0.0122,3.2582c-2.9907-0.0486-6.2246,0.4255-8.9479-1.0942
  c-2.8934-1.4832-5.2156-4.705-8.8263-3.9632c-7.4039-0.4377-13.3125,4.3159-19.1238,8.1455
  c3.5986,3.4406,7.1365,7.8294,12.0847,9.1667c8.7532,1.6412,15.1968-7.6714,23.9866-5.0575
  c-1.3494,5.325-8.6804,5.1426-12.0115,8.9966c-1.8359,2.3829-5.2278,3.8296-5.9208,6.8932
  c1.8236,1.386,3.8053,2.5895,5.9208,3.5257c4.182-0.4984,8.2548-1.8601,12.3276-2.9421c5.0574-2.8815,7.6956-8.413,11.8777-12.267
  c3.1124-2.8934,7.7202-1.933,11.6106-2.0424c-0.073,1.1185-0.1216,2.2491-0.1582,3.3919c-3.562,0.8875-7.574,1.9088-9.3855,5.4465
  c-1.9939,4.0241-2.8934,8.6926-2.3586,13.1666c8.2185-2.3342,16.5707-6.1395,21.8713-13.1301
  c1.5806-1.9938,3.5623-4.6563,6.4922-3.7567c6.3827,2.7233,10.7959,8.5468,13.9325,14.5647
  c2.4558,6.0179,5.556,12.0602,5.0941,18.759C1906.3225,979.5656,1903.891,986.933,1903.7207,994.5314z M1773.3197,960.0406
  c6.577,1.848,12.4005,5.483,18.151,9.0695c-0.3525,3.9026-2.6624,7.5377-6.3096,9.1425c-1.1429-2.7841-1.228-6.2003-3.6716-8.2428
  C1778.292,967.0798,1774.6935,964.3566,1773.3197,960.0406z M1738.853,885.6976c-1.8721,5.9085-3.2581,11.963-5.2642,17.835
  c-3.0271-1.2158-6.334-2.395-8.2913-5.1549c-0.8877-7.5498,2.4678-14.9415,6.9297-20.8501
  C1734.3914,880.251,1737.2849,882.5609,1738.853,885.6976z M1704.2894,708.4656c-5.3615,0.8753-9.6895,4.5591-14.7714,6.2733
  c-3.9999-2.0303-8.7898-3.2217-11.8049-6.6501c4.0605-3.4649,9.3613-5.0211,14.0055-7.5741
  c12.1332-6.2733,26.0778-8.5468,39.6212-7.4647c2.9785,4.0241,3.1001,9.5923,1.4589,14.2
  c-2.395,8.3157,0.8511,18.7833-5.7384,25.4942c-4.4253,0.158-8.7776,0.924-13.1665,1.3008
  c-4.79-1.6169-9.8477-3.6837-13.1544-7.6714c3.8905-4.8508,10.8931-1.5319,15.6466-4.5955c2.0305-1.9695,3.8054-4.17,5.6899-6.2854
  C1716.6656,712.198,1710.9274,708.0645,1704.2894,708.4656z M1695.5117,661.4648c-3.0273,0.5106-6.0059,1.386-9.0818,1.3495
  c2.4559-4.4011,7.3918-5.9693,11.5984-8.1091c2.0667-2.3342,2.249-5.6289,3.4404-8.4008c3.4407,1.0698,6.0667,3.6716,9.2883,5.1791
  c3.696,0.5349,7.5012,0.2796,11.2336,0.1094c4.3037-1.4346,9.8718-2.1397,13.2517,1.6534c-0.0488,7.6104-0.1582,15.6832-4,22.5034
  c-3.7322,0.0729-7.4525,0.4863-11.0267,1.5683c-6.2733,1.848-12.8625,2.2734-19.063,4.3524
  c-6.1516,2.2006-12.9841,2.2491-19.2452,0.4863c-3.854-0.6201-8.3157-1.2401-11.4524,1.6048
  c-0.7173-4.3281,2.3464-7.489,5.5925-9.8233c5.1426,1.9573,10.5161,3.5743,15.9991,4.2673
  c6.1639-2.1883,13.4098-2.6989,18.212-7.6349C1707.1951,665.1,1700.9583,663.4465,1695.5117,661.4648z M1710.6477,595.5712
  c2.772-0.1094,5.4832,0.6078,8.2185,1.155c6.9906,0.7538,13.8109-1.8601,19.5735-5.6289c4.3645-1.7264,7.3068,3.1852,8.5712,6.6623
  c1.7019,7.7079-2.5167,15.0996-5.9816,21.689c-2.9543-1.933-5.5802-4.7779-9.349-4.7292
  c-0.2919,3.5499-0.5715,7.1121-0.4257,10.6864c0.073,3.2096,1.9817,6.1031,1.5684,9.3855
  c-4.5225-0.9361-7.5134-4.9238-11.4646-7.0878c-4.2915-2.2248-5.2153-7.6835-9.3977-9.9813
  c-4.3279-1.9087-9.2396-1.4832-13.8108-1.2522c-4.3524,1.9086-8.3523,4.717-11.5497,8.2428
  c5.8114,1.2765,12.109,1.2765,17.288,4.6198c3.0029,2.0911,6.7352,2.2005,10.2366,2.7111
  c4.7659,0.3159,8.9114,3.1243,12.5952,5.945l-0.3768,1.8116c-4.2916,0.6079-8.0726-2.2126-12.3278-1.7628
  c-4.3037,0.1702-7.3309,5.6775-11.8049,3.7688c0.0486-2.4923,0.2554-4.9725,0.377-7.4526
  c-5.0698-0.1824-10.0056,0.8753-14.9294,1.8965c-1.9452-4.0485-3.3312-8.3157-5.2035-12.3763
  c-2.1517-3.6716-6.1029-5.7141-9.3612-8.2428c3.8783-3.4892,8.9236-5.3736,12.4858-9.264
  c5.3372,0.231,10.5647,2.7841,15.9626,1.4346c4.6078-1.6048,10.5406-0.3404,13.4341-5.1791
  C1712.9943,600.5924,1709.8088,598.9024,1710.6477,595.5712z M1724.2034,746.2147c2.3585,1.6656,6.0667,5.7626,1.9574,7.6957
  c-4.2916-1.1185-6.7838-5.4831-9.9572-8.3158C1718.781,743.6616,1721.9177,743.9413,1724.2034,746.2147z M1835.8943,771.7455
  c3.1853-1.4589,5.8599-3.7324,8.7533-5.6654c1.2279-0.7173,2.8085-0.3768,3.6229,0.8267
  c4.5713,4.7657,9.2518,9.7746,11.5132,16.1208c2.7477,8.0602,8.2305,14.7227,11.7927,22.4184
  c3.7323,8.1212,6.9297,16.5585,9.1547,25.2388c0.3768,6.9055,0.9484,13.8474,0.3646,20.7772
  c-5.2155-0.5835-8.8142-5.2399-14.0662-5.5924c-2.9543-0.4012-6.2976-1.5804-8.9966,0.2432
  c-1.3008,1.3981-2.322,3.0272-3.4282,4.5712c-1.9089-7.5741-1.3496-16.8868-8.3645-22.0415
  c-5.0575-4.4861-12.0237-2.541-18.0659-3.1974c-2.2493-5.6289-5.2278-11.1362-6.0059-17.1907
  c9.4099-1.6899,12.972,9.507,20.9109,11.8656c-0.0608-4.0728,1.1428-8.802-1.7993-12.1695
  c-3.854-5.0454-7.9025-11.6226-14.8809-12.121c-1.629-0.5106-3.6594-0.3769-4.8386-1.7264
  c-3.319-3.319-6.7838-6.5407-9.6652-10.2366c-0.2917-1.3981-0.2676-2.8206-0.3282-4.2186
  C1819.2142,775.7817,1827.7729,774.3471,1835.8943,771.7455z M1817.8646,750.7374c7.0027-4.7415,15.3185-0.4134,21.0325,4.3281
  c-2.2247,3.8904-6.3097,6.1273-10.5162,7.2337c-3.8296,1.0334-7.6106,2.8327-11.6589,2.3586
  C1816.2234,759.9891,1816.3086,755.2112,1817.8646,750.7374z M1928.3276,966.8488c-0.8146-0.0607-2.4314-0.1702-3.2461-0.231
  c-0.7781-15.1725-4.9724-30.0412-11.4646-43.7548c-1.8478-3.2825-2.1882-7.1-3.3674-10.6013
  c-1.9453-6.1031-4.231-12.1089-5.5925-18.3821c-1.7263-9.9448,0.2917-20.1207-1.2158-30.0776
  c-0.5591-3.8904-0.5105-7.8294-0.2552-11.732c3.3068,1.0577,6.6379,2.1519,9.6774,3.8904
  c2.7231,3.5987-2.1639,7.562-2.0789,11.4889c-0.0366,5.8356,0.3403,11.9385-3.2218,16.9719
  c4.474-0.5593,8.255-3.3798,11.8535-5.8964c3.5013-2.553,3.4407-7.1364,4.5712-10.9174
  c5.7627,5.2156,9.4099,12.1697,11.5376,19.5735c0.4497,3.9512,0.1094,7.9389,0.231,11.9143
  c0.2917,5.1304-1.8966,9.8841-3.6594,14.5768c-2.8328,11.0876-2.7354,22.6251-2.4558,34.0166
  C1929.4948,954.0715,1930.1392,960.6242,1928.3276,966.8488z M1943.2449,984.8784c7.72-8.0726,18.1267-12.6438,28.2419-16.9476
  c2.4678-0.7902,5.5072-2.3707,7.7322-0.0973c4.7656,4.4983,5.3613,11.4523,9.0085,16.6923
  c2.0303,4.2916,7.9875,2.1519,10.9783,5.3493c-1.3252,4.2673-2.0303,8.6683-1.532,13.1544
  c-4.9966,2.1883-9.969-1.2158-15.0509-1.1672c-4.8143-0.0121-9.6652-0.2919-14.4552,0.1945
  c-4.79,1.1792-9.7867,1.2401-14.6619,0.7781c-7.3918-1.8722-15.051-0.6078-22.5645-1.0577
  c-1.1306-3.4892,2.3586-5.5074,4.3403-7.7322C1938.2238,991.2489,1940.6189,987.9664,1943.2449,984.8784z M1967.4138,801.191
  c-4.0605,0.4012-8.4371-4.0972-11.9751-0.8998c-4.8508,3.5378-9.5435,7.404-13.3732,12.0602
  c-0.6444,1.3494-0.3405,2.8935-0.4741,4.3524c6.6989,0.4012,13.4341,0.62,20.1571,0.4012c4.6564-0.5349,6.9541-5.2034,9.3613-8.644
  c1.386-0.8023,3.1123-0.4012,4.6321-0.0729c1.5197,3.6716,2.8447,7.5377,2.8569,11.574c0.0729,7.173,0.7538,14.6863-2.1641,21.4459
  c-3.2703,10.2609-10.0178,19.1359-18.2605,25.9441c-5.8599,4.6685-10.5527,10.6135-14.8931,16.668
  c-2.8325-3.0272-3.939-7.0756-5.8477-10.6378c-3.0393-6.2854-6.7474-12.2061-10.3096-18.1876
  c-1.5562-4.3646,5.4951-5.4952,4.9846-9.7746c-0.6931-8.875-3.1123-18.7347-10.6135-24.3515
  c-2.1884,4.3037-4.0364,8.7776-5.7869,13.2759c-1.9575,4.6685,0.4861,9.9813-1.6536,14.6133
  c-4.1335-1.6777-8.8506-2.5774-12.2305-5.6168c-1.5804-4.1214-0.9847-8.6197-2.1761-12.814
  c-2.1398-7.331-4.2915-14.6863-7.6591-21.5552c-1.9574-7.1-2.991-15.8655,1.8235-22.0901
  c4.5591-1.1063,9.0817-2.395,13.6285-3.6473c5.5681-0.4741,11.1849-0.1337,16.7775-0.3405
  c6.3218-1.3494,10.8079,4.5105,16.9474,4.8995c5.7384,2.7476,12.7411-1.2036,17.8837,3.2096
  C1962.4902,793.5803,1968.1677,796.0847,1967.4138,801.191z M1973.0306,676.297c3.0758-1.0091,7.1244-1.4468,9.6653,1.0455
  c4.6927,3.5013,9.1182,7.3918,13.9811,10.6743c4.085,2.0181,8.0117,4.3281,11.9265,6.6744
  c0.7052-6.3098-1.4346-13.0328-6.0909-17.4217c-3.4039-2.4559-7.4767-3.8782-10.5404-6.7961
  c3.2825-1.1428,6.4192-3.2217,10.0177-3.1852c5.4708,2.4437,5.7383,9.1302,9.118,13.434c3.3191,4.5104,6.176,9.3248,9.1669,14.054
  c0.5106,0.2067,1.5317,0.6201,2.0424,0.8267c1.4104-6.5286,2.1276-14.3094-2.0667-20.0113
  c-1.2523-1.8844-2.5167-3.7567-3.6594-5.6775c3.5377-0.304,7.2092-0.5957,10.7229,0.2796c2.8691,2.322,4.1456,5.8477,5.9692,8.9479
  c3.1003,5.2642,10.9905,5.9572,12.7532,12.2061c-4.717,1.8722-9.349,4.1214-13.0814,7.5862
  c-1.702,2.5166-2.8082,5.4344-3.7081,8.3279c-0.1821,3.4528,0.4136,8.2184-3.7688,9.5315
  c-6.9905,0.6564-12.9355,4.5469-19.3911,6.8447c-1.313-4.8508,3.1123-8.2914,7.3311-9.2762
  c3.4404-0.4863,4.8386-3.9147,6.03-6.7352c0.3889-3.8053,0.6808-7.6227,1.4104-11.3794l-0.7175-0.5593
  c-0.4619-0.0244-1.3857-0.0729-1.8478-0.0973c-8.0363,4.5105-18.0297,7.562-22.4672,16.3883
  c-3.2458,4.4375-1.6777,10.1028-0.8145,15.0388c-2.772,4.8751-8.9115,6.2368-11.89,10.9052
  c-2.3466,2.3585-3.3677,6.9662-7.3917,6.4921c-0.0852-6.3827-0.3405-12.814,0.2795-19.1724
  c0.9119-5.3736,6.2125-7.3431,10.261-9.8962c2.8934-2.6625,7.2944-6.2125,5.325-10.6256
  c-3.5137-1.3616-7.5863-0.9604-11.2578-0.5714c-2.1155,2.1519-3.0637,6.018-6.5649,6.2003
  c-1.1309-3.5135-2.1277-7.1851-1.7629-10.8931c6.334-2.31,11.27-6.9662,16.4734-11.0633
  c-0.1095-3.1488-3.5986-4.1214-6.0181-4.9603c-4.8386-1.7871-8.1941,3.4041-12.7167,4.0241
  C1969.3712,683.5063,1969.5658,678.9108,1973.0306,676.297z M1937.9563,701.1347c7.5378-4.2308,12.0117-12.6073,20.2666-15.6102
  c1.3495,0.8024,2.4922,1.848,3.4406,3.1367c-2.3828,3.6353-6.1638,5.945-9.3369,8.7899c-2.9301,2.6017-5.9207,5.1913-9.495,6.8568
  c-0.0122,0.9848,0.0122,1.9695,0.0364,2.9786c3.3191,0.2674,6.5774,1.6169,9.957,1.1671
  c3.8174-1.3981,6.9663-4.0727,10.4556-6.1031c3.9512,2.1032,4.6563,6.2612,4.571,10.3947
  c-8.4615-0.7659-16.9353,0.0244-25.3969-0.231c3.6593,4.7293,7.0756,9.9692,12.1816,13.276
  c4.4497,0.6808,8.6683-1.1307,12.8506-2.3342c3.7323,4.6927,1.7264,10.9417,2.2003,16.4126
  c-0.8386,4.5956-2.3097,9.3126-5.325,12.9842c-0.7051-0.0486-2.1395-0.1337-2.8569-0.1702
  c-0.3767-3.9512-0.851-7.8658-1.8115-11.7076c-7.0876,2.4315-9.4585,10.2244-11.5496,16.6314
  c-3.5013,1.3373-5.4465-2.7962-5.2275-5.7018c0.2551-5.0818-2.0669-9.9327-4.6443-14.1392
  c-4.5469-3.3677-9.957-6.6015-15.8169-6.1517c1.1792,7.489,0.4012,16.8867,7.3796,21.7983
  c4.7292,4.4983,12.4492,8.34,12.2546,15.7682c-2.9299,3.9877-7.1243-1.0699-10.7471-1.0334
  c-5.0941-0.7416-8.7778,3.5378-13.2517,5.0089c-4.8995,0.4255-9.957-0.7538-14.7106,0.7294
  c-5.8844,2.6139-11.4403,5.8964-17.0083,9.106c-4.9238,0.7173-9.8234-2.2126-12.2911-6.3827
  c-3.6473-5.6653-7.9634-10.8688-11.5497-16.5341c-0.3282-4.632,4.4617-7.3674,8.2063-8.7656
  c4.3402-0.2554,7.5619,3.7687,9.9569,6.954c0.5349,4.4861,0.304,9.4586,3.4163,13.1424c0.8389,1.933,3.1245,1.6046,4.863,1.9815
  c1.2765-3.7322,2.0667-7.5984,1.5682-11.5616c5.1426,1.4589,10.3217,3.0637,15.744,2.9543
  c-2.5532-5.7383-3.7568-12.8019-9.0818-16.7288c-3.7809-1.2157-7.8293-0.158-11.6833-0.7537
  c-2.6016-0.7659-5.1304-1.775-7.6956-2.6017c3.9877-8.8385,12.4006-14.4795,20.8623-18.5402
  C1911.8785,717.9972,1924.5952,709.0615,1937.9563,701.1347z M1891.9646,692.8312c-1.6655-1.6778-4.5469-2.6017-5.021-5.094
  c1.3981-2.31,3.4282-4.1579,5.3005-6.0909c2.6018,1.2158,5.1426,3.3677,8.2429,2.9178c2.5044,0.1215,5.7748-1.3495,7.2822,1.5076
  c0.9725,8.2671,0,17.8229-6.7717,23.5611C1899.8427,703.2987,1897.4476,696.673,1891.9646,692.8312z M1794.6195,634.0375
  c5.1669-0.7051,8.5468,3.9026,12.7897,6.0058c2.1763,0.8632,2.1276,3.2704,2.3342,5.2399
  c-5.1426,0.1824-10.0907-1.4224-15.1239-2.1641C1794.1819,640.1042,1794.1333,637.0526,1794.6195,634.0375z M1789.0149,681.4152
  c19.452-14.3336,38.3083-30.3086,61.0186-39.2808c5.5802-2.1032,10.8809-5.3615,16.9961-5.5682
  c3.7323-0.7294,7.8781-0.1094,11.1121-2.5166c6.7351-4.4861,13.0814-9.8962,20.9473-12.3885
  c1.2036,2.9299,1.2644,6.0909,1.6533,9.2032c2.5774,4.6685,8.5712,6.0058,10.9419,10.8323
  c1.6777,3.5743,3.6958,7.2702,3.4891,11.3794c-6.0179,0.3769-9.5193-6.7109-15.5009-6.5407
  c-4.6685-0.3405-8.7898,2.1884-12.5464,4.5834c0.0364,0.4984,0.1216,1.4954,0.158,1.9816c4.8022,2.237,9.3856,5.009,14.3215,6.942
  c3.6108,1.4102,7.6349-2.9908,10.7229,0.2188c3.6229,2.7354,3.2825,8.2914-0.3647,10.8566
  c-5.2886,0.5471-9.1667-4.7171-14.4675-3.939c-3.2825,0.0121-6.5649,0.389-9.811,0.1094
  c-4.1942-2.3464-6.7716-6.5529-10.4431-9.495c-1.0699-0.2188-2.1398-0.231-3.1853-0.0365
  c-1.6171,4.1822-2.2371,8.8264-1.3374,13.2517c0.7903,3.1852,4.3767,6.176,1.9331,9.495
  c-5.1304-4.3037-8.2063-10.8323-14.3582-13.9082c-4.3766-2.4922-9.665-2.0424-14.4309-1.2765
  c-3.7322,2.2249-6.7716,5.3614-10.3094,7.8538c-1.6047,1.0699-2.8448,2.5895-3.5864,4.3646
  c6.1273,1.7142,12.3278,3.2582,18.0903,6.0787c4.7413-2.5045,9.799-6.7109,15.4886-4.8144
  c3.015,2.4923,7.3552,5.0818,7.1487,9.5193c-4.8145,0.1337-8.9723-3.55-13.8474-2.7476c-4.4497,1.3373-6.8934,5.7262-9.0696,9.495
  c3.8418,0.2067,7.6349,0.9362,11.3186,2.1398c5.4832,2.1883,9.9449-3.8417,15.4886-3.0394
  c3.2946-0.2796,4.9724,2.9542,6.9419,5.0332c-4.243-0.1702-9.5923-1.9331-12.8625,1.6169
  c-3.538,2.9786-4.474,7.6957-6.1638,11.7563c5.3492,0.2796,10.7472,0.1215,16.0721-0.5714
  c2.2004-4.0363,4.3767-8.2429,8.2063-11.0147c1.4711,1.1672,3.0029,2.3099,4.4132,3.6229
  c1.2157,5.7018-4.0121,9.9813-8.255,12.7654c-6.1881,2.8448-10.6499,8.0726-15.4885,12.6559
  c-4.4861,4.1214-6.6138,10.4312-12.1454,13.4219c-2.6626,1.4224-4.9967,4.0606-8.1212,4.2551
  c-4.7535-4.1821-10.577-9.0087-11.1606-15.6953c5.629,0.3161,12.7168-2.079,14.2974-8.0969
  c-5.2522-1.3373-9.9692-4.0485-15.1362-5.5803c-2.9056-1.2401-0.79-3.088,0.7781-4.4131c4.8386-1.3982,9.5315,1.544,14.4188,0.9604
  c6.3949-0.012,11.0026-6.2003,12.3033-11.9143c-7.0999-1.3981-15.9628-4.0728-22.0415,1.2401
  c-2.1398,1.6048-4.2186,3.5014-6.9663,3.8539c-0.8265-4.3767,3.9027-7.489,2.7355-11.8779
  c-1.933-3.9998-4.6683-8.0482-8.9723-9.6895c-1.2764,5.9814-5.1426,11.8292-2.7354,18.1025
  c2.0791,4.7779-0.0729,9.8597-3.2338,13.5677c-1.1672,0.2067-2.3099,0.2796-3.4407,0.3769
  c-3.5135-5.1913-7.2336-10.2123-11.1119-15.1118c1.6899-3.4041,3.6958-6.6866,5.094-10.2244
  c1.0942-6.7596-1.2888-13.2517-2.6626-19.7439c-1.1426,0.0244-2.2734,0.0486-3.3918,0.0973
  c-2.9543,4.1214-4.559,8.9722-6.9905,13.3976c-1.3738,2.6868-1.0942,5.787-1.4712,8.7169c-3.2581,0.4012-4.79-2.6503-6.7109-4.6076
  c-1.7263-1.9088-3.7566-3.5135-5.7627-5.0575C1787.3008,684.3331,1787.2278,682.2784,1789.0149,681.4152z M1773.5385,691.968
  c6.6259,0.304,13.7258,2.6503,17.7013,8.4008c-4.8629,1.4467-10.042-3.781-14.2849,0.1459
  c-4.4133,3.3555-9.325,7.3918-10.0059,13.2516c5.2887,0.2067,11.0635,1.0942,15.8778-1.7385
  c6.8325-3.6838,16.4735-5.3007,22.6859,0.4134c4.6077,4.3037,9.1545,9.1059,11.282,15.1725
  c0.7781,3.0394-1.2521,6.7961-4.7778,5.7262c-1.2887-3.5864-1.8235-7.3918-3.2704-10.9174
  c-1.5925-2.553-4.4252-3.939-6.7108-5.7141c-2.468,4.8144-1.2644,10.5283-1.4346,15.7561c2.0789,3.781,6.4435,5.8964,8.6196,9.726
  c0.8997,5.1061-1.5198,9.8597-2.7477,14.6863c-1.629,5.4952-3.8295,11.1849-8.267,15.0266
  c-1.5198,0.2675-3.0272,0.4742-4.5347,0.6201c-0.7175-3.6716-0.5352-7.9631-3.2583-10.8566
  c-1.5076-1.9088-3.4285-3.6107-4.1943-5.9693c2.4072-3.4406,6.5286-6.4434,6.261-11.0876
  c0.3892-4.5712-2.1396-8.6561-4.2915-12.4736c-0.4863-0.0365-1.4712-0.1337-1.9573-0.1824
  c-3.0393,4.9967-5.7991,10.2244-7.7808,15.7561c-2.0305,0.6444-3.4407,0.0122-4.243-1.9087
  c-0.8633-3.3677-0.073-6.8811-0.5106-10.2853c-3.0515-5.8356-8.7898-10.03-15.2942-11.0876
  c-1.2765,6.3219,1.4346,12.4128,1.0579,18.7226c2.3098,3.1123,5.7991,4.6563,9.1301,6.3948
  c2.8083,2.6139,6.6624,5.1304,6.9785,9.3005c-4.5591-0.0365-8.8872-1.8236-13.4707-1.5804
  c-2.4678,3.1974-7.1486,5.6046-7.5376,9.8597c3.9148,4.5347,10.4189,3.9512,15.7927,3.4284
  c2.9906-1.4954,5.0818-4.2308,7.8657-6.0422c2.0304-0.231,4.1094-0.1945,6.1641,0.1824c4.0361,4.9116,1.6168,11.8292-1.082,16.7895
  c-4.3282,5.6168-9.325,11.3916-16.5099,13.1301c-7.7323,1.8358-15.8412,3.8053-23.8167,2.626
  c-2.0179-4.243-3.9025-8.9357-2.6381-13.6893c2.1276-7.8538,0.4133-16.4491,4.085-23.8773
  c2.2004-5.1669,1.7141-10.8566,1.6898-16.3275c-0.1824-5.945,1.7384-11.6711,3.5013-17.2636
  c-0.0973-7.2094,5.0574-12.6073,8.7412-18.2728C1762.6697,701.925,1767.2288,695.8463,1773.5385,691.968z M1758.8036,692.4056
  c-3.161,2.8813-5.5802,7.1486-9.9813,8.182c-2.8328-3.4164-0.0486-7.2216,1.1306-10.6743
  c3.5623-1.3373,6.9542-4.2673,11.0026-3.1852C1763.3625,688.9043,1760.0801,690.7036,1758.8036,692.4056z M1759.5574,667.3491
  c-0.0244,0.851-0.073,2.553-0.0972,3.4162c-3.3069,0.0608-6.9299,0.8267-9.7383-1.3859c-0.1824-2.006-0.2067-3.9998-0.0973-5.9937
  C1753.4907,662.9725,1756.4814,665.416,1759.5574,667.3491z M1768.5903,541.4583c-3.0637,6.48-1.0576,13.7623-2.1031,20.5947
  c-2.0304,9.4464-2.9178,19.3427-7.7079,27.938c-0.9968,1.4954-2.4316,2.6139-3.6594,3.8782
  c-3.5986-3.3311-5.7871-7.8173-7.8051-12.1939c-0.0731-7.3796,0.0121-14.8564-1.848-22.0415
  c-1.7263-5.2642-6.4192-9.6896-12.0724-10.1029c-3.3556-0.1337-6.6379-0.8389-9.9326-1.3373
  c0.6443,5.0453,6.2368,7.1851,7.4767,11.9751c0.8633,3.2825,1.2645,6.8325,3.307,9.6895c1.4833,2.3828,3.7567,4.5956,3.3918,7.6836
  c-3.5863-0.0851-7.72-0.5107-10.4432-3.0029c-2.4802-3.7202-4.4983-7.9753-8.4009-10.4554
  c-4.0605-0.2432-8.1698-0.3405-12.2061,0.1337c-4.5105,1.8723-8.6926,4.4375-13.1544,6.3949
  c0.0365,0.9847,0.1095,2.9421,0.1459,3.9269c7.6228,2.6017,14.0542,7.9753,22.0902,9.5071
  c-2.0668,4.3281-4.9602,8.182-7.72,12.0845c-4.2672-4.2551-9.9205-9.033-16.3883-7.4525
  c-6.9663,5.2034-12.267,12.3154-19.2576,17.4946c-2.1276,1.3373-4.7048,1.5684-7.1121,1.9573
  c-0.4863-0.8632-1.4346-2.5775-1.9087-3.4406c3.6594-5.9329,10.7472-11.0511,9.6531-18.759
  c-0.1825-5.8842-2.152-11.7198-6.6016-15.7439c-2.9056,5.1912-6.1152,10.2001-9.1423,15.3184
  c-3.234,5.4952,3.4282,11.0634,1.082,16.7166c-1.3739,2.2734-3.55,3.8053-6.0667,4.5469
  c-4.4862,1.2888-7.1851,5.7383-11.8901,6.5651c-0.5957-6.4556-0.693-13.045-2.5166-19.3061
  c-0.5592-2.1276-1.2401-4.2673-1.0455-6.4799c3.9755-1.2887,8.9601-0.5593,12.0481-3.8782
  c-0.4863-5.7991-7.4525-7.2338-10.65-11.2336c-0.4497-7.647-1.4709-16.8989,5.0454-22.4548
  c5.4102-6.6501,15.0266-6.9905,20.4611-13.5313c1.5197-1.7507,2.9906-3.55,4.4984-5.3372
  c1.2399-0.0122,2.4922-0.0244,3.7566-0.0365c-0.1946,3.9269-0.535,7.9023,0.2552,11.7928
  c1.5804,4.717,6.2733,6.9905,10.3824,9.1301c2.4437,1.5198,5.4102,1.1794,8.1699,1.2401c-1.155-4.3888-2.9664-8.5709-4-12.9963
  c-0.7292-3.2947-3.5985-5.8842-3.1487-9.4464c0.7659,0.0852,2.2979,0.2554,3.0637,0.3284
  c3.1975,3.7323,5.1548,8.2549,6.8933,12.8018c1.1428,3.3553,4.7051,4.4861,7.6348,5.8235
  c4.6321,1.8843,9.0696,4.717,14.2244,4.8022c0.6323-5.24,0.7539-10.7716-1.6047-15.6345
  c-1.9331-4.4254-6.0422-7.3795-10.1516-9.6167c-3.1974-0.9238-6.8933,0.1824-9.7625-1.8722
  c-2.5408-1.7993-4.5469-4.2186-6.5286-6.5651c3.635-1.459,7.4163-2.699,10.7837-4.7415c4.1213-4.6807,2.468-11.3064,2.7233-16.9474
  c1.5806,0.1823,3.1852,0.3771,4.8145,0.5592c3.8782-3.7324,8.6683-6.8325,14.2607-6.881
  c-3.8418,5.9327-8.2306,11.6832-10.4797,18.4672c4.3523,0.2308,8.6317,1.0212,12.9478,1.6412
  c6.03-0.4498,11.8899-3.1731,16.2302-7.4161c2.9906-2.5166,5.6896-5.9207,9.568-7.0149c4.9723-0.7173,6.4919,5.2522,8.887,8.4009
  c4.6443,4.4132,12.6925,5.24,15.3186,11.8049C1774.5354,526.5532,1770.5962,534.0664,1768.5903,541.4583z M1716.9696,492.6094
  c2.3829-2.3098,4.8024-4.7535,7.8295-6.2368c5.2886-0.8386,10.9418-0.5226,15.209,3.1002
  c0.1216,0.7537,0.3647,2.2369,0.4742,2.9907C1732.6406,492.7189,1724.8113,492.0139,1716.9696,492.6094z M1726.4404,292.8135
  c3.3555-2.9421,8.5101-4.6322,9.7747-9.3613c0.4254-5.0579,0.316-10.1638,1.4465-15.1362c0.7053,0.0486,2.1277,0.1581,2.8328,0.219
  c4.1458,5.5559,9.4829,11.0024,9.957,18.3212c1.4832,4.3402-1.313,8.109-3.5623,11.5375c-3.1001-0.8875-6.0422-2.176-9.1182-3.0272
  c-3.8416-1.0212-7.343,1.5682-11.1361,1.5682C1726.3674,295.561,1726.3066,294.187,1726.4404,292.8135z M1724.2034,311.8398
  c7.9023,2.3586,12.8992-5.094,18.297-9.2518c0.62-0.316,1.8601-0.924,2.4679-1.2401c-0.2551,5.5437-4.9844,8.8992-8.4371,12.5709
  c-2.8329,2.6017-5.4344,5.8112-9.2277,6.9784c-3.5986-0.4011-6.8932-2.4072-9.7261-4.5349
  C1717.1763,312.8123,1720.9695,309.8826,1724.2034,311.8398z M1690.7095,364.0927c5.5682-5.41,11.7441-10.1392,17.7986-15.0021
  c5.41-3.5015,11.8413-4.7903,17.823-7.0149c6.1394-1.0701,12.595-0.8997,18.6372,0.7537
  c0.0852,7.7932-0.8995,15.6589,0.1825,23.4153c1.6898,5.8113,4.1335,11.4282,7.0149,16.7408
  c2.9663-3.4891,5.787-7.1243,9.106-10.3096c3.3555-3.0636,3.0515-8.0239,4.097-12.1088c-0.9849-3.9391-5.5803-6.4802-5.094-10.9661
  c5.6775,1.6655,11.5981,4.8386,13.5555,10.8564c2.0426,6.4435,8.4982,9.4099,12.2549,14.6133
  c-3.4772,19.5372-6.0546,39.3418-7.5377,59.1584c-1.7993,5.7263,0.4741,11.5254,0.231,17.3365
  c-0.1216,12.7532,1.0334,25.7496-1.8723,38.2839c-1.0576,3.9755-3.1973,9.8597-8.3887,8.6561
  c-3.6837-2.8936-8.8384-7.9388-6.1516-13.045c3.2825-4.7413,2.2004-10.8444,1.6899-16.2302
  c-1.7263-3.8539-3.5743-7.6471-5.5803-11.3429c-2.1882,1.6289-3.1731,5.0817-6.4922,4.3765
  c1.3252-6.6743,1.532-13.4338,1.7144-20.2178c3.5134-5.0453,10.4675-6.8689,12.7654-12.9114
  c0.6685-7.4888,0.6322-15.0508,0.158-22.5398c-7.6106,1.3738-10.9904,9.0575-15.5251,14.3945
  c-0.2067,2.3098-0.3403,4.6199-0.4133,6.9541c-1.1063,0.0852-3.3313,0.2673-4.4496,0.3525
  c-2.079-8.7046-7.9875-15.5981-12.7047-22.9653c-0.7778-1.0944-0.9119-2.4558-1.2764-3.6959
  c2.626-2.6748,5.7383-5.009,7.5497-8.3523c1.7629-10.4554-2.4801-20.5704-7.2701-29.6034
  c-3.1975,2.7961-3.8662,7.1484-5.7627,10.7469c-1.6411,3.4164-4.2794,6.2247-5.9207,9.641c-0.5957,4.7172,0,9.4829-0.3403,14.2123
  c-4.085-2.5775-7.9146-5.5439-11.5619-8.6683c-3.1974-2.7355-4.4375-7.793-8.8993-8.8506
  c-4.79-1.6899-10.3582-2.468-15.0874-0.2433c-2.2979,1.0699-4.8021,1.5197-7.2944,1.3496
  C1680.0352,374.8765,1685.1536,368.9314,1690.7095,364.0927z M1634.0313,436.9282c-0.1094-8.3158,2.6748-17.373,9.5923-22.5156
  c6.5771-5.0575,13.7744-9.2034,20.8623-13.4949c5.0574-3.0394,7.2822-8.7656,11.3186-12.8019
  c2.8934-1.2279,5.1547,1.5804,7.0027,3.3555c3.6837,4.0486,7.9753,7.7445,13.1908,9.6046
  c5.0941,0.5347,10.1029-2.0427,15.2212-0.681c3.3068,1.0333,7.3918,2.9663,7.4161,7.0149
  c-5.8356,0.2917-12.1576-3.1976-17.5676,0.1578c-3.9634,2.298-6.4799,6.9299-6.3826,11.5012
  c4.2915,0.9846,8.571,2.2125,12.9841,2.626c4.5712-0.8997,9.106-2.2371,13.1301-4.6443c3.0149-2.0059,7.4282-2.2734,10.2001,0.3405
  c4.6805,4.0728,7.2579,10.2609,8.2428,16.2789c-6.4314-2.541-12.3521-8.9601-19.8533-6.5651
  c-2.322,1.2522-7.4889,1.8601-5.9692,5.5316c3.3069,4.4131,7.8903,7.5498,12.5952,10.3096
  c4.6563,3.9634,12.0237-0.073,16.1086,5.1062c3.9999,6.7352-0.1459,14.6255,0.3768,21.8347
  c-0.0851,4.1824,3.0636,7.6959,2.5895,11.9388c-3.1609,0-5.4708-2.225-7.8658-3.9147c-2.5409-2.0426-5.325-3.9998-7.0149-6.8326
  c-0.389-3.4039,0.2797-6.8567-0.2675-10.2487c-2.8204-6.0544-8.8263-9.4585-14.5768-12.2305
  c-2.9056-1.9209-6.5043-1.3008-9.7626-1.5076c1.3982,5.0454,1.3982,10.3826,3.161,15.3308
  c3.1124,3.4648,8.1699,4.425,10.9661,8.3398c-4.328,0.0122-8.7168,0.2798-12.9235-0.7048
  c-3.1243-2.5897-5.8113-6.4314-10.1636-6.8328c-3.5256-0.0972-7.7687-0.8386-10.2488,2.3101
  c-4.6321,4.9116-10.0542,10.3582-10.3947,17.4946c6.8082-4.0972,15.0388-3.6108,22.4791-1.7141
  c4,0.9846,6.9907-2.6384,10.5286-3.769c4.2307-0.304,9.106-0.2552,11.574,3.866c-3.6716,0.1339-7.3433-0.5592-10.954,0.0731
  c-5.86,3.6229-7.9023,10.6378-7.173,17.2028c-9.0574-0.8022-18.1025,4.9359-22.1387,12.9844
  c-2.4437,4.6077-7.8174,6.6866-12.8262,6.2367c-2.3344-3.9633-1.6047-8.7047-2.1642-13.0814
  c-3.319-5.5559-9.118-8.7777-15.3063-10.0784c-1.8357,1.3979-3.4404,3.6472-2.2977,6.0181
  c2.9056,5.3734,4.9967,11.5374,10.0665,15.3547c1.6046,1.9817,5.4829,3.9026,3.9023,6.8811
  c-3.5742,6.9663-11.6102,10.4069-19.0508,11.0391c-0.0242-8.8264,0.7903-17.7256-0.5105-26.4913
  c-3.8418-13.7987-2.2856-28.5458-6.796-42.223c-1.7142-4.3279-0.8146-9.0815-1.8722-13.5432
  C1632.4994,446.8486,1632.8154,441.7913,1634.0313,436.9282z M1633.3384,681.2693c0.5348-3.0029,0.6564-6.1031,1.7141-8.9844
  c2.2493-6.0666,2.2126-12.6317,3.4891-18.9049c0.9969-6.0787-0.778-12.6317,1.9575-18.3821
  c2.4679-5.4344,4.9723-10.9904,8.9479-15.5251c2.5652-3.623,7.4038-1.9453,11.1483-2.1641
  c3.2096,6.3705,8.2672,11.5617,13.6165,16.1816c2.4315,2.2491,5.7018,3.0637,8.802,3.9877
  c0.1216,8.6075-0.9117,19.5857-9.8112,23.5612c-1.0333-4.3159,0.6444-8.7169-0.2552-13.0207
  c-3.234-4.9116-8.2185-9.106-14.0905-10.3217c-2.8691,6.0058-1.0942,12.5587,0.1094,18.7225
  c2.6382,4.7901,7.9509,8.9479,6.7716,15.0753c-1.4832,3.392-3.9512,6.2003-5.9692,9.264
  c-1.0698-4.6685,2.5409-11.9751-3.2826-14.2485c-4.6563-0.9847-9.4706-0.4498-14.1633-0.2188
  c1.6777,6.8447,2.249,14.4553,7.0635,20.0112c2.1031,2.857,5.1912,5.7141,4.3645,9.6409
  c-0.7539,3.6959-3.2217,7.3674-6.8811,8.7291c-6.4557,3.7932-13.8839,5.8843-21.3972,5.3979
  c-0.5227-4.9603,2.4801-9.1789,3.1973-13.9446C1629.5209,690.9468,1632.9248,686.5457,1633.3384,681.2693z M1669.5554,694.6791
  c-2.3098,0.7781-4.5833,1.6169-6.9419,2.2005c1.0333-3.3433,3.6472-5.4709,6.6987-6.9298
  C1669.3732,691.5181,1669.4583,693.0865,1669.5554,694.6791z M1533.343,452.1251c3.7322-4.1823,9.8474-3.2462,14.759-4.778
  c8.4617-2.2493,17.2273-3.2461,25.9685-2.3831c9.9691,4.8751,20.8015,8.875,28.7766,16.8868
  c4.924,3.6107,8.2064,8.8627,11.793,13.7013c3.0879,4.4619,6.5529,8.8386,8.2792,14.0662
  c2.7841,13.6287,3.9269,27.6583,2.4071,41.53c-1.0698,6.2855-0.851,12.6925-1.0818,19.0509
  c-1.3739,7.6106-12.6682,7.3187-13.5072,15.2455c-1.2399,3.088,0.5958,7.3917-2.6136,9.4342
  c-5.2035,0.3647-10.4435,0.2675-15.6467,0.1216c-0.4863-4.7657,3.0635-8.2184,5.0088-12.194
  c0.7295-2.3342,0.2917-4.8508,0.3403-7.2458c-4.3523-3.1123-8.0846-7.1851-13.0571-9.3369
  c-1.7263,6.8689-4.7534,13.665-3.9875,20.9109c0.0364,3.1853,1.6169,6.4435,0.6808,9.5923
  c-2.5044,2.9178-6.1759,4.4496-8.8142,7.2337c-3.015,3.1123-6.3097,5.9329-9.422,8.9358
  c-1.1428,0.0607-2.2734,0.1337-3.3799,0.2067c3.9148-9.1424,10.4434-17.7255,10.2854-28.0717
  c1.6777-7.0513-1.5562-14.0782-0.6079-21.2268c8.8264-0.7782,17.5675-4.5713,23.2329-11.5497
  c2.4679-3.2704,6.0057-6.261,5.3735-10.7958c-5.8232-0.1582-11.6589-0.146-17.4702,0.231c-4.012,0.4863-6.48,4.0361-8.9845,6.8083
  c-1.0941,0.1335-2.1639,0.2795-3.2216,0.4254c-0.2917-5.7262,0.936-11.2943,1.8843-16.8868
  c5.6776-0.2432,11.3916,0.1582,17.0691-0.2795c5.8721-3.2096,8.0728-10.0664,11.5984-15.3792
  c1.4709-1.8723,1.3738-4.2551,1.4346-6.4922c-3.1001,0.0729-6.4922-0.62-9.2642,1.1309
  c-3.9633,2.1396-7.9387,4.3036-12.2911,5.5558c-0.0851-6.6744,9.3005-6.322,10.2731-12.413
  c1.1915-9.58-1.5076-19.002-4.0728-28.1201c-1.0577-0.3647-2.1398-0.4985-3.2218-0.3892
  c-3.2825,4.085-5.8842,8.6682-8.9723,12.8992c-1.9329,3.4404-4.9845,6.8081-4.4495,11.0266
  c-0.2189,4.5227,2.1519,8.7656,1.5198,13.3125c-1.1309,1.532-2.2736,3.0396-3.4407,4.5227
  c-2.541-5.8354-7.489-10.2488-13.4827-12.3035c-1.301-0.0121-2.5652,0.1946-3.8175,0.3771
  c-0.3889,4.9602,1.3981,9.6774,2.3829,14.4794c1.1914,5.082,6.2488,7.6106,9.3247,11.4404
  c1.155,6.1516,0.7538,12.5707,0.2795,18.7954c-0.79,0.0486-2.3584,0.1338-3.1487,0.1702c-2.4558-3.4404-4.2673-7.489-7.8779-9.9448
  c-6.6989-2.0059-12.6196,2.6625-17.6528,6.4314c-1.8479,0.936-2.0425,3.0271-1.4102,4.8143
  c5.3252,5.8113,13.3004,7.8903,20.9231,8.2914c4.8387,3.1367,10.1394,7.3796,10.3218,13.6772
  c0.3525,6.4678,0.8145,13.1909-1.3982,19.4155c-2.0789,7.1729-7.5618,12.5465-11.4764,18.7104
  c-2.6991-3.6959-4.8873-7.7322-7.3188-11.5983c-1.5563-2.4315,0.4618-4.7171,2.0302-6.4678
  c3.7325-3.9634,4.7172-10.4312,1.9332-15.1726c-2.9299-5.6289-8.328-9.5679-14.0419-12.0359
  c-3.5135,7.5498-0.3771,16.0359,1.4711,23.6343c-1.1427-0.073-2.2736-0.1338-3.3798-0.1824
  c-1.5563-3.7445-2.468-8.1941-6.0911-10.5405c-5.9813-0.8147-12.0236-0.3892-18.0172-0.0973
  c-3.0272,0.0487-5.8721,1.2522-8.4738,2.6868c0.1703,0.5957,0.5106,1.775,0.6807,2.3585
  c4.8875,5.4344,10.3947,12.4493,18.4553,12.1332c1.9451,0.2553,3.8783,0.7173,5.7747,1.3981
  c-5.021,2.7598-10.1147,5.6654-13.9932,9.9692c0.1094,1.6777,0.5593,3.3676,2.2734,4.0972
  c3.6107,0.5471,7.3066,0.4742,10.9417,0.1094c3.8297-1.2279,6.4801-5.6411,10.8932-5.0332
  c4.8995,0.4133,8.7655,4.6685,10.1881,9.1789c0.1823,6.1881-0.5957,12.899-4.4375,17.993
  c-3.3434,4.401-7.416,8.2183-12.0237,11.2577c-3.9877-3.8661-6.0422-9.1424-9.5437-13.3975
  c-3.3918-4.1457-5.3978-9.1424-8.1942-13.665c-5.1426-7.9753-8.3158-17.1177-14.3823-24.5095
  c-4.9237-7.866-12.3765-13.5557-18.99-19.8774c-4.5226-5.3494-10.9052-9.4099-13.0449-16.4248
  c3.8539-1.8479,7.9266-3.1731,12.0358-4.4011c4.7781-6.723,6.1519-15.136,10.9539-21.8713
  c1.7993-2.2734,3.2705-6.334,6.8328-5.6772c3.1121,4.559,5.8597,9.3977,9.0938,13.9203c2.6868,3.6959,3.9998,10.4066,9.8838,9.349
  c0.1096-5.2156,1.301-10.2732,2.4316-15.3306c0.2676-4.644,1.6414-10.188-2.1884-13.7988
  c-2.2491-3.5864-6.3461-4.6321-10.2244-5.3492c1.0699-4.5106,3.1367-8.7777,6.2369-12.2548
  c3.1364-3.4285,5.252-8.1091,9.9204-9.7502c0.2065,2.7476,0.158,5.5195,0.5835,8.2549c2.3707,4.9845,4.4618,10.2122,7.9631,14.5647
  c1.1062,1.4832,3.1246,1.6534,4.7656,2.2249c-0.5226-9.2275,5.2887-17.2638,5.4832-26.2966
  c0.7295-6.0424-9.0452-6.9785-7.8051-12.9357C1529.2217,455.7603,1531.5923,454.1067,1533.343,452.1251z M1583.7478,631.2292
  c4.9117-0.3525,9.0088,3.0394,11.9751,6.6015c-5.7747,3.2096-13.4338,4.3281-19.306,0.7902
  c-2.1154-1.0333-4.632-2.4315-6.954-1.2036c-4.5957,2.7841-7.5863,7.72-9.264,12.7167c-0.5836,5.0454-0.316,10.1394-0.2675,15.2089
  c3.6472-0.4377,7.8416-0.4742,10.6257-3.246c4.9844-4.4861,8.8262-10.2731,14.5889-13.896
  c4.1699-0.5957,8.425-0.5957,12.656-0.4255c0.0364,1.2401,0.0972,3.7322,0.1338,4.9724
  c-4.8508,1.6048-10.9055,0.9847-14.3094,5.5317c-5.9449,6.1882-3.6229,15.3063-5.2762,22.9412
  c6.5286-0.9968,12.3275-4.5591,18.0294-7.72c5.1548-3.3068,3.9269-10.4433,7.9146-14.6255
  c4.3645-1.7505,7.2458,2.3707,9.1302,5.6653c0.8022,6.2125,1.1184,13.0936-2.0669,18.7468
  c-3.5985,6.7109-6.4919,13.7987-10.4553,20.3152c-2.2004,3.55-3.4893,7.5377-4.948,11.4281
  c-2.5167,7.7321-6.1882,15.1725-10.9418,21.7862c-0.6322,0.0121-1.8844,0.0243-2.5167,0.0365
  c-5.9451-7.5741-8.4006-16.9354-11.7076-25.7982c-1.9452-6.48-5.3007-12.4007-8.0117-18.5767
  c-4.4982-13.4705-14.8199-23.853-20.8744-36.5211c-1.8601-5.0453-5.3738-10.2609-3.3798-15.8047
  c2.9176-6.3219,8.9237-10.6256,11.3915-17.2515c1.7507-6.3462,8.1942-9.4464,11.1727-15.0388
  c3.5013-6.0422,8.3157-11.2214,13.8352-15.4765c2.2856-1.8479,5.3615-1.6777,8.1576-1.471c3.854,2.857,3.4164,8.96,7.9998,11.3307
  c6.4434,3.0759,13.8474,3.7445,20.9352,3.2947c-1.8844-4.2794-4.4132-8.2427-7.1-12.048
  c-2.845-4.1335-7.1365-7.0149-9.8232-11.2579c5.9572-0.3282,12.3643-0.2067,17.5311,3.2096
  c4.3402,2.2978,6.3219,7.0635,8.0117,11.4037c1.4469,4.1822,3.8783,8.2063,3.7203,12.7653
  c0.1702,9.7504-0.0609,19.4885-0.0609,29.2388c-0.9847,6.2003-0.6444,13.0571-5.2155,17.9931
  c-6.0546-9.3005-12.0481-18.6375-17.6892-28.1932c-2.5409-3.8297-4.9967-7.9631-8.5955-10.8688
  c-6.8445-2.7474-14.4309-1.0941-21.3606,0.3891c-0.4011,1.3252-0.5835,2.7111-0.8025,4.0849
  C1574.4717,625.8069,1577.7178,631.91,1583.7478,631.2292z M1503.9219,686.7889c-0.8998-4.7779-5.2522-7.5741-8.4253-10.8323
  c-1.1062-1.3616-3.72-1.9087-4.571-0.0244c-2.4923,5.094-3.55,10.7715-5.7992,15.9872c-1.5804,3.3553,0.5714,6.796,2.0911,9.726
  c1.7994,3.5135,5.0575,5.8843,7.4403,8.9722c-2.2007,3.5378-6.48,4.3038-10.0664,5.7505c-3.939,1.386-7.6228,3.3798-11.0266,5.787
  c-1.5562,1.2887-3.7688,1.775-5.6411,0.9483c-0.5957-2.6503-0.1702-5.3614,0.377-7.9753
  c1.5319-7.9024,0.158-15.9871,0.7659-23.9503c2.1396-8.0604,8.4253-14.0297,11.4404-21.689
  c4.9844-9.9448,2.48-21.3364,2.7231-31.9742c-0.2554-3.7809,3.4041-5.9938,5.7141-8.4373c0.9971-1.3373,2.7233-0.9483,4.0728-0.462
  c6.4434,6.0787,10.6135,14.0054,15.5374,21.2513c5.1426,10.0421,8.8627,20.7892,14.9414,30.3572
  c1.9939,5.0089-4.3159,5.5924-7.0513,8.1698c-4.4618,4.243-4.705,11.2335-9.3492,15.3914
  c-1.1184,0.1337-3.3798,0.4012-4.4983,0.5349C1502.3048,698.3993,1505.2107,692.7096,1503.9219,686.7889z M1474.6101,615.5583
  c-0.9362,2.9056-0.6323,6.1637-2.4558,8.7656c-7.3431-8.4251-14.2366-17.2272-21.8835-25.3847
  c-0.3889-6.5408,6.7352-9.4951,10.0786-14.2001c5.3977,3.3798,9.7137,8.1455,13.9689,12.814
  c2.0302,2.8084,5.3735,5.629,4.182,9.495C1477.2117,609.8928,1475.3153,612.4823,1474.6101,615.5583z M1468.2883,373.1865
  c2.5774-0.085,6.7352,0.8875,5.7141,4.328c-7.2094,4.389-15.6104,7.1366-21.531,13.3854c3.8904,5.24,11.3064,4.0851,17.0813,4.3647
  c7.5376,0.6078,12.437-6.5166,15.9382-12.1819c0.5836-0.146,1.751-0.4255,2.3342-0.5714
  c3.5137,3.0272,6.1882,6.8932,8.1943,11.0632c-3.7203,2.8329-8.5345,3.7203-12.0968,6.7719
  c-6.869,4.9238-10.7594,13.4585-10.6256,21.8348c6.5164,0.1217,13.3853-0.3405,19.1843-3.5986
  c4.3284-2.4923,5.7021-7.5863,8.6929-11.2943c4.1455,5.7627,4.5469,13.2031,7.9873,19.2941
  c5.4465,7.2701,15.3063,9.2153,20.8014,16.5099c-3.8297,3.8783-6.8203,8.4374-10.3339,12.5708
  c-1.3616,0.2675-2.7354,0.304-4.085,0.4014c-3.5499-3.5137-7.7443-7.7688-13.1421-7.4406
  c-8.9358,0.839-16.0117,6.8204-23.233,11.5375c-2.3829,1.155-2.1761,3.854-1.301,5.9573
  c4.7051,1.6167,9.8842,4.2915,14.8929,2.2733c5.2522-1.8358,10.8811-1.6046,16.3035-2.7355
  c1.5076,4.5956-4.0364,6.5409-6.1641,9.8113c-3.7202,4.0483-6.1882,9.0208-9.738,13.203
  c-6.322-1.9696-9.6896-11.5861-17.3367-9.5193c-8.2671,3.9633-16.4612,9.4464-21.0325,17.5919
  c9.0454,3.6594,19.3671,4.1577,28.7161,1.3007c2.468,3.7322-0.7295,7.6592-3.9025,9.6654
  c-4.4012,2.1761-8.9359,4.0969-13.6652,5.3855c-2.8328,1.0699-5.9573,2.3829-7.4526,5.1792
  c-2.9055,5.021-1.5928,11.6104-5.7383,16.0479c-2.4314-1.7749-4.9116-3.5378-6.8203-5.8843
  c-3.1121-4.4253-3.5134-9.9934-4.8267-15.0874c-1.0454-6.0179,1.0093-11.975,0.9484-17.9929
  c-0.2186-6.9905,2.7598-13.5923,2.6139-20.5828c0.1702-8.0603-0.8875-16.2424-4.2307-23.634
  c-4.0607-14.6133-5.2278-30.3816-1.3131-45.153c3.0637-7.8051,6.0422-15.6831,9.9447-23.1235
  c1.5684-2.8934,2.3099-6.7838,5.6289-8.267C1458.8296,372.2747,1463.7535,373.2959,1468.2883,373.1865z M1436.8854,270.8811
  c4.0118-0.4012,8.3887-0.5835,11.7562,2.0669c6.0303,4.1093,13.13,5.86,19.8655,8.3887c1.9453,2.4679,2.7476,5.6167,3.781,8.5344
  c2.0303,8.6318,4.8873,17.4824,3.3069,26.4426c-1.0336,7.0149-1.228,15.1362-6.7841,20.3274
  c-4.4374,4.0969-8.6075,8.4617-12.8019,12.8018c-3.2458,3.4891-5.1183,8.0482-8.5588,11.3794
  c-5.0332,0.8145-10.2001,1.1305-14.9901,2.9907c-2.5775,1.2522-4.8145,3.0393-7.1971,4.5955
  c0.0242,1.082,0.0605,2.1763,0.1215,3.2826c4.7415,1.0577,9.9691,0.7538,14.2607,3.2948
  c1.1427,4.5591-2.2614,8.3887-3.4891,12.5464c-2.5896,7.8418-6.9299,15.0752-8.4009,23.2937
  c-0.4011,2.7234-1.6654,5.2035-3.1367,7.5012c-3.72-6.9055-8.5587-13.0693-13.1421-19.4033
  c-4.7415-6.553-6.5894-14.5526-8.4128-22.2848c-1.2401-6.4557,1.9084-12.4857,4.4375-18.1998
  c2.8448-7.1851,10.4675-10.5891,16.9474-13.7502c2.5531,1.228,4.5226,3.5986,7.3066,4.377
  c4.0607,0.1943,8.1698,0.1094,12.2183-0.4014c6.4071-2.6868,10.6743-8.6563,14.6984-14.0784
  c-3.9633-1.155-7.9873-3.1244-12.1938-2.3466c-4.1943,0.8268-8.4617,1.2644-12.7168,1.1063
  c0.8391-3.2583,2.5774-6.1272,4.3279-8.9601c1.9819-3.0394,0.9119-7.088,3.1733-10.0056c5.2156,0.9362,9.6895,5.483,15.4885,4.097
  c4.8875-3.0879,8.7412-7.5377,12.7898-11.6224c-5.702-2.3586-10.9539-5.483-15.8047-9.2885
  c-3.4407,2.1155-6.4923,5.2034-10.6743,5.7141c1.1549-3.9026,2.9907-7.5255,5.2277-10.9053
  c1.0941-5.3857-1.8602-10.1758-4.4984-14.5769C1441.6877,274.9419,1435.4873,275.7686,1436.8854,270.8811z M1405.5313,298.0653
  c4.4862-3.9996,11.7927-3.8662,15.0024-9.3612c2.6381-4.2067,2.0422-9.3248,2.2371-14.0662
  c0.7292,0.1458,2.2124,0.4254,2.9541,0.5714c1.3252,4.7899,2.4193,9.9325,5.7383,13.8108
  c2.942,3.5258,5.3129,7.7809,4.7535,12.5222c-0.4498-0.0973-1.3617-0.304-1.8236-0.4012
  c-1.0212-3.4648-3.2705-6.8811-6.6746-8.2668c-5.5558-0.3406-9.6775,3.9025-13.5798,7.2213
  c-0.0365,0.8754-0.0974,2.6259-0.1338,3.5013c2.1641,0.3646,4.7657-0.2676,6.5043,1.4225
  c4.2794,3.5498,8.4617,7.2701,12.1697,11.4401c-0.8265,4.8386-2.0059,9.8718-6.0057,13.1423
  c-1.3617-6.0908-1.6899-13.5314-7.0513-17.6648c-4.2916-2.4315-10.1152-1.6536-13.8716-5.0697
  C1405.3489,303.9495,1405.5313,300.9954,1405.5313,298.0653z M1401.8599,335.7172c1.7628-4.6443,1.252-9.7018,2.5895-14.4432
  c0.681,0.2068,2.0424,0.6201,2.7355,0.8269c3.562,4.2915,8.1455,7.8174,10.7472,12.8625c1.5441,5.2765-4.9115,5.7262-8.3522,7.3552
  c-3.3677,0.7781-4.9967,4.9116-8.7411,4.5349C1400.7168,343.121,1400.4858,339.2671,1401.8599,335.7172z M1395.4526,302.3325
  c0.6685,3.9512,1.0212,8.0483,0.4011,12.036c-0.7415,0.4011-2.2124,1.2157-2.9541,1.629
  c-3.2701-7.5619-8.2063-14.2729-11.1119-21.9807C1387.7205,293.5791,1391.9148,298.26,1395.4526,302.3325z M1195.9969,300.8616
  c-6.2977-1.86-10.881-7.6227-12.7532-13.6892c0.0243-4.3401-0.6686-8.5588-1.6656-12.7654
  c4.7051,0.8875,7.9996,4.7292,12.4492,6.2124c2.1641,0.3162,4.3767,0.2554,6.553,0.6201c2.395,1.1305,4.3887,3.5255,4.6075,6.2246
  c0.9725,6.1637,1.1063,12.4249,1.4347,18.6375C1202.4404,305.7488,1199.8142,302.1016,1195.9969,300.8616z M1207.5466,268.5471
  c-3.9998,1.7262-5.9209-2.8086-8.5225-4.8267c-2.2371-2.3586-5.5925-2.6747-8.571-3.3676
  c-4.1213-1.2643-7.7322,1.8357-11.6711,2.4193c-0.4011-0.8026-1.1915-2.4193-1.5925-3.2218c0-5.3492-0.1702-10.6985-0.0608-16.048
  c1.4346-1.8235,3.6838-2.6379,5.7505-3.5376c1.8845-0.7296,3.9756-0.7904,5.9935-0.8633c3.088,4.1091,5.1791,8.9358,8.6805,12.7532
  c2.3707,2.5774,5.7262,3.9148,8.2305,6.3342C1208.3489,261.0215,1207.437,265.0698,1207.5466,268.5471z M1200.2156,233.0105
  c0.158-5.7017-0.0364-11.4888,1.6292-17.0204c2.0182,1.5927,3.8662,3.3676,5.5803,5.3007c2.468,2.699,5.9209,4.2307,8.5103,6.8206
  c0.6079,4.4858,0.6565,9.0815,1.9939,13.4581c1.1794,3.015-0.3889,6.0424-1.0698,8.9723c-1.4224-0.0852-2.845-0.1702-4.2429-0.2432
  c-0.4135-3.9877-0.9727-7.9755-1.8967-11.8657C1207.8627,235.6245,1203.7533,234.6641,1200.2156,233.0105z M1269.9752,386.9852
  c-2.553-4.7899-4.8265-9.7139-7.3309-14.516c-3.2583-6.3584-3.9512-13.5798-4.9602-20.5342
  c-0.45-4.875-0.8633-9.9812,1.3979-14.4915c1.5806,2.8328,2.4801,6.0422,4.2673,8.7776c2.2007,2.9299,5.009,5.3372,7.7201,7.793
  c2.3463-1.9938,4.8265-3.8418,7.4159-5.5315c-1.9692-4.8994-3.9512-9.7867-6.03-14.6255
  c-1.2158-2.9058-3.5742-5.7019-2.8204-9.0574c1.775-2.0061,4.328-2.8083,6.942-2.9178
  c-0.6931,5.8844,0.4131,13.3611,6.2732,16.2789c2.8813,1.3129,5.4587,4.1578,8.875,3.6473
  c-0.0486-3.9027-0.3525-7.8053-0.1339-11.6957c2.7111,1.9453,4.9847,4.4254,7.6837,6.407
  c5.714,2.4316,12.0479,1.775,18.0781,1.2888c-0.8755-5.2886-4.0728-9.8112-6.7351-14.3336
  c-2.2615-3.9391-6.6138-6.8083-11.27-6.0057c-3.7568,0.0973-10.5529,0.7537-10.5042-4.6321
  c5.483-4.1458,13.7258-5.7018,15.8778-13.2517c-1.2401-2.9421-3.3677-5.3735-5.1183-7.9995
  c-3.8297,0.1458-8.0848-0.3771-11.2822,2.1639c-5.4587,2.8934-6.5286,9.5315-8.0847,14.9172
  c-6.3706,0.0607-11.9021,3.2216-16.6802,7.1486c-0.6807,0.1338-2.0425,0.4135-2.7231,0.5592
  c-0.1095-4.1212,2.2734-8.1456,0.6807-12.2181c-1.5804-8.0239-9.5557-12.1089-16.291-15.2332
  c-0.4863,7.6348-0.1582,15.3182-0.2552,22.9775c-0.3282,6.03,4.3036,10.8323,4.8993,16.7166
  c-0.9968,2.626-2.4314,5.0453-3.7566,7.501c1.5195,5.1062,2.9419,10.2368,4.0848,15.4402c-3.161-1.0942-6.176-2.7719-7.7928-5.8356
  c-4.4861-7.5499-9.1913-15.0145-14.8931-21.7255c-2.1274-2.395-4.8022-4.2673-6.7231-6.8325
  c-3.6594-9.6896-6.6379-19.6709-8.3762-29.8953c-0.7175-2.3342,1.0696-4.1943,2.7719-5.4832
  c4.5104-0.2673,9.2396,1.0942,13.5798-0.6927c5.41-2.006,10.723-4.2794,15.89-6.8447c-1.386-5.4465-7.8174-5.4952-12.2428-6.869
  c-5.0453,1.5198-10.1516,2.8936-15.4764,2.7234c-0.8875-3.7322,2.1519-9.2275,6.4435-7.027
  c5.0574,2.2611,10.3945,0.1823,14.4794-3.0151c3.4042-2.8934,7.9878-3.7445,11.4402-6.5165
  c-2.5288-3.6836-6.0908-6.5891-9.9083-8.8384c-4.8751-2.1155-9.6165,0.9849-13.5676,3.5864
  c-0.0365-0.6565-0.0974-1.9573-0.1339-2.6138c3.939-3.939,8.0847-7.8781,10.8081-12.7776c1.082-5.6289-0.0608-11.428-1.386-16.911
  c-3.246,0.6686-6.0667,2.7113-9.349,3.1731c-2.4559-5.4587-6.2126-10.1515-11.5375-13.0328
  c2.1882-5.24,8.1211-8.644,13.7015-8.4738c1.2765,4.1943,1.2401,9.653,5.3492,12.2911c3.5623,2.2614,6.3096,5.4467,9.1423,8.5225
  c3.1975-3.5623,4.2188-8.7898,3.9026-13.4705c-2.6016-4.4984-6.2002-8.3159-8.7534-12.8384
  c1.8359-0.9604,3.5258-2.541,5.7141-2.614c8.6198-0.4741,17.5435-1.0698,25.7618,2.152
  c11.1484,4.0728,21.9442,9.5314,31.2328,17.0326c6.4069,5.2034,13.7742,9.8477,17.8958,17.2151
  c3.5012,4.6563,8.9358,7.647,11.6833,12.887c6.1517,11.5981,8.3765,24.85,14.9293,36.2657
  c1.605,3.4286,2.8693,7.1366,5.5441,9.9449c4.3281,4.5712,8.1331,9.5923,11.9872,14.5525
  c6.4313,8.5709,9.4099,18.99,13.033,28.8984c4.6927,14.1633,3.5377,29.2507,3.2826,43.9249
  c-0.5107,4.1334-4.9482,4.9724-8.3766,4.1093c1.1671-2.4802,2.8326-4.644,5.0211-6.3098c0.2185-3.2339,0.4133-6.4922,0.0364-9.7139
  c-1.3618-3.635-3.5377-6.9175-5.3613-10.3218c-1.8115,0.681-3.5867,1.3739-5.3372,2.152
  c0.0243-5.8722-0.8754-12.2062-4.1093-17.1542c-4.7902,3.2217-8.6927,7.6106-11.3307,12.741
  c-0.7661,0.0244-2.2858,0.085-3.0515,0.1094c-0.3162-5.5681-0.1217-11.1482,1.0818-16.5825
  c-1.3494-6.6139-6.2488-11.9631-11.6223-15.7563c-1.8236-1.4954-4.3524-0.9482-6.5042-1.2642
  c-1.3254,5.2761-0.8025,10.7834-2.225,16.0356c-1.5319,6.3096,2.4679,12.2668,7.3676,15.8655
  c4.6443,3.2825,5.1426,9.1787,5.8843,14.3335c-5.9817,2.9056-12.3157,5.4467-16.7166,10.6621
  c-0.1824,5.6167-1.301,11.1484-1.459,16.765c3.6108-0.8265,7.1243-2.1395,10.8932-2.079c-1.082,2.1641-2.2003,4.2917-3.2218,6.4802
  c4.6199,0.377,9.3856-0.3647,13.8839,1.0576c7.027,1.6655,13.7137-1.7142,20.3881-3.3433
  c5.6289-0.3889,11.0999,1.4347,16.7288,1.4589c6.0544,3.6473,8.0969,10.8689,10.6135,17.0205
  c4.0728,9.9572,12.1818,17.288,18.0295,26.0901c0.0243,0.7415,0.073,2.2369,0.0974,2.9907
  c-3.1248-0.0243-6.0422-1.386-9.1426-1.3496c-4.012-0.0848-8.0725,0.7418-12.0237-0.097c-3.0394-1.459-5.6654-3.7932-8.875-4.8387
  c-2.9302-0.1337-5.8354,0.1094-8.7413,0.1703c-3.8296,3.8417-8.9115,6.8446-10.8444,12.1816
  c-0.6323,2.9058-0.304,5.8965-0.0608,8.8264c3.5986-1.2888,6.7717-3.4407,10.261-4.9604c2.0791-0.9846,4.1578,0.8269,6.1638,1.301
  c-3.3918,3.2581-7.3309,5.8843-10.6257,9.2397c-3.3677,3.4282-8.2063,5.7869-9.7139,10.6621
  c-3.5498,7.6227,0.7661,16.7407,7.7688,20.6677c2.3707,1.3252,4.9844,4.1943,7.9023,2.5896
  c0.3525-3.5015,0.3767-7.0514,1.094-10.5042c1.0336-5.6899-0.2795-11.4768,0.462-17.1664
  c2.5896-3.2218,5.5437-7.027,10.2002-6.4679c-1.2643,2.9788-3.2581,5.6047-4.486,8.5833c-0.146,2.395-0.1582,4.8873,0.5713,7.2092
  c2.0424,4.3647,6.4556,6.6746,9.8597,9.8477c1.6656,0.0122,3.3553,0,5.0575-0.0121c1.5076-5.8356,3.9634-11.9388,2.1519-17.993
  c-1.4468-4.9358-2.2979-10.0907-1.6292-15.2212c1.7021-5.1912,8.4128-3.0393,12.5222-2.7476
  c3.9147,3.781,7.8779,7.7322,10.2607,12.7168c1.6777,4.5591,2.772,9.3856,2.8086,14.2729
  c0.316,11.0024,0.2186,22.1996-2.9299,32.8495c-1.3738,3.5135,0.4863,7.1122,0.1216,10.7228
  c-7.8171-0.3647-15.6467-0.4983-23.4641-0.3889c-3.2825,0.3281-5.4707-2.6504-8.3398-3.6837
  c-2.4436,1.2765-4.3768,3.8905-7.3431,3.8053c-4.1093,0.2065-8.2184-0.1702-12.3278-0.2795
  c-3.5745-0.1703-6.5649-2.4437-9.957-3.3191c-3.7688-0.7174-7.562,0.4255-11.3307,0.0728
  c-3.0758-1.0454-5.6289-3.2095-8.6198-4.4496c-8.4858-1.8722-17.0569-4.1698-24.8621-8.1455
  c-4.9724-3.015-8.6685-7.7928-11.9509-12.4857c-2.7109-6.6987-5.9813-13.1665-8.8628-19.7925
  c-4.012-13.3124-2.4314-27.3787-2.8933-41.08c0.5471-6.5043-2.6505-12.413-3.9391-18.6251
  C1280.1876,401.635,1274.5342,394.5715,1269.9752,386.9852z M1350.0931,377.8671c-2.7111,0.1581-5.4102,0.2432-8.0968,0.2065
  c-0.1216-2.9907,1.7507-4.9966,4.1824-6.4191C1347.5643,373.6729,1348.8289,375.7639,1350.0931,377.8671z M1147.7559,656.6505
  c-5.7384,0.693-9.7137-4.0241-14.8201-5.6046c-2.6138-1.0333-5.8113-1.3373-7.4402-3.9026
  c1.5924-4.2916,3.5864-8.5467,4.1212-13.1787c-1.0212-4.6685-3.0516-9.1911-2.5653-14.0906
  c3.1245-0.0244,6.8691-0.8267,9.1909,1.9088c7.3188,5.6897,8.7657,15.282,10.9176,23.6949
  C1148.3274,649.1007,1147.8896,652.9302,1147.7559,656.6505z M1155.5002,633.4418c-0.5593-0.1215-1.6899-0.3647-2.2491-0.4984
  c0.0973-5.6411-3.5377-10.2001-5.7262-15.1361c-2.0791-4.5104-7.1364-6.103-10.4797-9.3856
  c-0.1824-1.9209-0.1702-3.9755,1.5562-5.2156c6.2976,0.4863,13.0693-1.9209,16.8502-7.1729
  c1.4469-1.9817,3.246-3.6837,5.483-4.7414c3.6958,7.0148-1.0212,14.4674-4.863,20.2908
  C1151.4519,618.1234,1154.3333,626.3176,1155.5002,633.4418z M1195,666.2671c-3.7688,1.5682-7.1971,4.1335-11.2578,4.9116
  c-3.2461-0.0244-7.1122,0.6929-9.6409-1.8358c-3.5377-3.4528-7.8416-5.9815-11.3186-9.4586
  c-2.2129-5.0332-4.6807-10.4675-3.8905-16.0843c4.5591-6.4556,11.9751-11.1971,13.8229-19.3304
  c2.3224-6.3341-1.2764-12.5222-2.5652-18.6375c-0.6443-4.5712-1.8113-9.0816-1.8721-13.7258
  c3.0879-0.8875,6.8688-0.6687,8.8872,2.1883c4.2429,3.6593,5.6775,9.2154,7.7441,14.2121
  c3.6716-1.5318,4.5226-5.6167,6.1395-8.8384c2.8207,5.9815,5.0575,12.2426,8.2306,18.0782
  c1.4954,2.2978,1.2158,5.1183,1.2644,7.7443c-0.1217,3.8539,0.9119,7.6349,0.7415,11.501
  c-4.7898,1.3494-7.9508-3.0272-11.902-4.7899c-2.1154-0.4134-4.2915-0.3405-6.3949-0.0731
  c-6.6744,3.2218-13.9568,7.0513-17.1055,14.1635c4.6564,4.401,11.4036,4.4739,17.3853,3.6473
  c5.0696-1.0577,9.3613-5.5317,14.8198-4.3281c3.0272,1.0455,6.8932,2.006,7.7444,5.6046c1.252,4.1214,5.5193,6.6015,5.8232,11.0998
  C1206.1605,663.9207,1200.1912,663.641,1195,666.2671z M1212.3486,640.8701c-3.1001-3.1973-3.1001-8.0726-2.1641-12.1453
  c3.5258-0.9118,6.8325,1.0333,9.8718,2.5774C1219.9349,635.7761,1214.7195,637.5753,1212.3486,640.8701z M1384.7786,719.2251
  c-0.0486-1.1185-0.1339-3.3677-0.1825-4.4861c5.4708-0.1215,11.6957-1.4589,16.4979,1.9573
  c2.4922,1.4346,5.1182,3.3798,5.0574,6.6015C1398.9785,722.8237,1390.2129,725.3038,1384.7786,719.2251z M1427.512,751.3208
  c-5.5682-5.3007-10.4192-11.501-17.3367-15.136c0.1703-0.6444,0.5227-1.921,0.6932-2.5652
  c3.9755-1.4832,8.6075-1.1063,11.1483,2.626c5.2643,6.5164,11.4888,13.2881,13.1545,21.6768
  C1431.4874,757.351,1430.0044,753.6064,1427.512,751.3208z M1453.9666,724.5258c-0.9604,8.1698-7.8292,14.1027-8.7776,22.2725
  c-0.2554,1.0455-0.7659,3.1367-1.0214,4.1822c-5.3856-4.2551-7.8292-10.9904-12.8381-15.5859
  c-3.161-3.1002-7.4525-6.4313-6.5165-11.4402c-0.3892-5.3857,4.8995-9.2032,4.1821-14.6133
  c0.1945-2.9664-1.6898-5.3979-2.9786-7.8781c-3.8539-7.3796-11.0754-13.6407-19.4034-14.9902
  c0.4497,4.8508,1.7384,9.568,2.4922,14.3824c0.5229,4.9967,6.3707,7.9753,5.4468,13.3124
  c-3.1489-0.0851-5.4224-2.3707-7.9755-3.8661c-2.4801-1.7021-5.6168-2.8691-7.1123-5.6411
  c0.5715-3.7567,2.5897-7.2338,2.6018-11.0755c-0.45-7.3796-2.9178-15.0753-8.7413-19.9747c-1.386-0.5958-2.9421-0.45-4.3401-0.0608
  c-1.5319,4.2551-3.088,8.5346-4.1335,12.9356c-1.4956,6.5894,8.1941,9.4099,6.0786,16.0236
  c-2.1155,1.0577-3.9148-0.8389-5.6411-1.6656c-4.2185-2.395-9.2517-3.4892-14.0542-2.7233
  c-3.0393,1.386-5.3734,3.8539-8.2305,5.5074c-2.468,0.5593-5.4224,0.8387-7.5499-0.7781
  c-5.1183-3.319-10.2365-7.3796-16.5221-7.9875c-8.1577-0.8632-15.4521-5.1304-23.6221-5.8356
  c0.0371-0.3831-1.0406-0.9349-1.6848-0.9349c-2.9958,0-16.7495-4.4688-21.3894-7.0892c-4.5591-2.857-10.103-3.1731-14.9779-5.2521
  c0.0414-0.3995-14.59-4.3287-18.6252-5.2281c-7.465-0.7777-14.2729-4.1332-21.6525-5.3611
  c-5.1427-0.6201-9.1548-4.0728-13.3977-6.6866c-3.7446-2.6503-8.1697-5.4343-8.8628-10.3582
  c7.5012-0.7416,15.8655-2.2248,21.0448-8.2671c3.7445-3.6229,4.6686-8.9844,4.9967-13.9567
  c-2.5896,0.0365-5.167,0.1215-7.7322,0.1579c3.1731-5.6897,8.948-9.9692,10.1394-16.6679
  c-4.6563-0.3282-9.3734-0.8875-14.0052-0.2067c-2.6869,1.082-5.0822,2.7355-7.6715,4.012
  c-0.4863-5.945,5.7017-7.8537,9.1912-11.3065c2.6501-2.7841,5.1304-6.3583,4.7899-10.3703
  c-0.4379-7.8659,1.3981-16.7288-4.0243-23.3545c-6.2366,6.2732-14.4187,11.9507-16.3395,21.154
  c-0.4863,5.8477,2.8085,11.8292,0.5226,17.6284c-1.3857,2.0424-3.3799,3.7444-5.957,3.7931
  c-0.0244-3.1487,0.7051-6.1881,1.1062-9.2761c-1.9452-9.4341-11.4644-13.9811-19.0873-18.3092
  c-2.5289,3.9391,1.0333,8.6318-0.1703,13.0207c-4.7656-7.0149-7.866-15.4522-14.9536-20.607
  c-3.1731-3.2947-8.5955-2.4315-11.5254-6.1274c-1.6046-4.79,0.304-9.7502,2.6382-13.9446
  c0.9971,0.0122,1.9937,0.0243,3.0029,0.0365c1.082,2.2247,1.9573,4.6077,3.3918,6.6622c2.468,2.772,6.0909,4.3281,8.3157,7.3553
  c1.6899,2.5774,2.8936,5.4344,4.2551,8.2063c0.9241-0.3889,2.2856-0.1702,2.845-1.1793
  c4.6686-7.6956,4.1213-17.3123,1.8236-25.6888c-2.942-3.939-8.0846-5.2278-12.0236-7.9023
  c-0.5957-3.3434,2.5894-5.2886,4.5104-7.4648c4.5348-4.8508,10.4799-7.9752,16.1451-11.2699
  c11.9631-2.006,23.9016-4.9967,36.1321-3.8905c13.6528,2.9177,27.5122,4.7415,41.299,7.0149
  c9.1547,0.3892,18.5037,1.4589,26.9288,5.3129c3.781,1.7264,7.6713,3.6472,11.9386,3.5256
  c2.1154-0.0607,4.0365,0.8511,6.0056,1.5197c-3.5377,7.2946-11.5616,10.3704-17.1053,15.8169
  c-5.4224,4.0242-9.5437,9.3857-13.5557,14.7471c-3.1244-1.5925-5.021-4.8508-8.2913-6.1881
  c-4.012-2.2369-8.8628-2.2247-13.2517-1.4589c-4.863,1.8235-10.4797,2.8569-14.0298,6.9662
  c-3.4041,3.8783-6.2124,8.3887-7.6833,13.3611c2.9056,0.5836,5.8962,0.3769,8.498-1.155c6.2611,1.7628,12.814,1.0212,19.233,1.1063
  c3.2096,0.0608,6.9298-4.863,9.3251-0.924c-1.0701,3.8782-3.0759,7.5497-3.4894,11.5861
  c0.7903,3.6594,1.4225,7.3796,1.1549,11.1606c-7.6591,0.0972-17.385,2.1762-20.1083,10.4432
  c-3.4771,5.556-4.8267,13.0815,0.1215,18.2362c5.325-3.5135,12.0603-4.9967,16.2546-10.0907
  c2.9421-2.7476,4.2432-6.6379,6.4675-9.8962c0.7297-0.0851,2.1882-0.2675,2.9178-0.3526c-0.5392,0.0629,2.996,9.835,3.245,10.6675
  c0.5149,1.722,0.8521,3.5406,0.5654,5.3363c-0.313,1.9617-1.4741,3.7217-2.3531,5.4708c1.308,1.2161,2.6063,2.7939,4.0817,3.7949
  c1.1643,0.79,2.8035,0.5507,4.1627,0.6628c1.3007,6.0059-2.6989,11.9751-0.4012,17.8108
  c2.2614,4.2429,4.5347,8.4859,7.0636,12.5952c2.6501-8.1698,8.899-14.9659,9.4586-23.7679
  c0.4739-4.8387,0.4739-10.6257-3.3677-14.1756c-2.5288-1.6777-5.3372-2.9178-7.7445-4.7657
  c1.5074-1.5197,2.9667-3.1123,4.6077-4.4983c5.8114-1.1428,13.4218,2.1519,17.7622-3.2096
  c-2.2491-4.4861-5.3129-8.5103-9.1788-11.7198c-3.3555-2.3707-0.2554-8.0604,3.3918-8.0118
  c3.9148-0.085,7.8416,0.1702,11.7562-0.2796c-0.1459-3.8296-0.6078-7.7565-2.5531-11.1241
  c-3.3315-12.2446-7.1523-13.3348-7.2821-13.1665c-6.4073,0.1215-12.8507,0.4497-19.2455,0.0122
  c0.8752-2.8082,2.8931-4.9358,4.8506-7.0391c3.0278-3.2532,5.8035-6.7046,8.967-9.8408c3.1779-3.1505,6.5684-6.1006,10.251-8.6494
  c1.3087-0.9059,2.7339-2.0342,4.2062-2.6538c0.6218-0.2616,1.139-0.0944,1.7826-0.0995c0.9063-0.0072,1.8291-0.194,2.7395-0.2301
  c3.5852-0.1428,7.1958,0.3479,10.6097,1.4536c1.2024,0.3894,2.4139,0.8595,3.6776,0.8401c1.6487-0.0253,3.168-0.8785,4.7917-1.1643
  c1.6328-0.2875,3.3059,0.0077,4.9342,0.3193c3.6644,0.7012,7.3124,1.4885,10.9401,2.3606c1.821,0.4377,3.6597,1.077,5.5029,1.3931
  c0.7465,0.1279,1.5337,0.1926,2.2954,0.2673c0.2922,0.0286,1.053,0.2189,1.3225,0.0288c6.9297-0.219,13.9689-0.4133,20.8014,1.0698
  c4.7172,1.0212,9.422,2.1274,14.2242,2.7234c6.1516,2.468,13.4827,2.4801,18.37,7.5741c3.1854,3.7445,8.3645,5.2156,11.1971,9.3004
  c0.8268,2.4558-0.6321,4.936-1.0334,7.3432c-1.2888,4.0241-0.7781,8.2914-1.0942,12.4371
  c-1.556-1.0577-3.0879-2.1641-4.5955-3.2461c-1.0212,0.9362-2.0181,1.8723-2.9907,2.8327
  c1.6169,2.2856,3.5745,4.6685,3.2097,7.6835c-6.7476-1.1915-13.0693-4.9967-20.072-4.9967
  c-4.6077,1.6048-9.2521,3.8053-12.571,7.489c3.8904,2.0911,8.0725,3.5135,12.1697,5.1305
  c7.3918,2.4922,15.2821,1.4224,22.9292,1.7507c7.9509,2.7111,14.2849,8.6318,19.16,15.3063
  c0.0243,0.7539,0.0607,2.2612,0.085,3.0273c-3.9391,0.2553-7.8292-2.9664-11.5253-0.4255
  c-3.9756,1.2401-2.8204,6.1273-3.3191,9.2761c-2.7354,5.9086-8.9114,10.1029-9.6042,16.9354
  c0.2797,3.1123,1.2158,6.1273,1.3738,9.2761c-6.0057-3.939-12.0603-8.2306-16.3519-14.0662
  c2.0911-3.8053,5.3007-6.9054,7.0636-10.8931c3.1244-8.6683,1.3981-18.2727-2.4192-26.4304
  c-4.3038,3.2096-7.6958,7.562-10.2245,12.267c-0.9362,5.2764,0.4376,10.65-0.2919,15.9628
  c-5.5437-3.5013-5.8356-10.3339-8.2792-15.7925c-1.8722-6.176-6.8811-11.5132-13.2638-12.9842
  c-5.8721-0.7538-11.7927,0.1459-17.5797,1.1428c0.2308,1.9209,0.462,3.9512,1.9331,5.4101
  c4.4496,5.2035,8.6317,10.7837,14.2728,14.7957c1.1914,1.0577,2.772,1.3738,4.3525,1.386
  c6.6865-0.4377,12.0237,5.3857,14.3457,11.1362c-6.1516,0.1459-10.7715-5.5074-16.984-5.1426
  c-5.8964-0.8753-10.1515,3.9632-14.7955,6.6866c-0.0366,1.313-0.0608,2.6382-0.0608,3.9632
  c7.2581,0.5714,14.0054,3.5378,21.0812,5.082c5.5316-1.0942,12.984-3.5743,16.9962,1.8966
  c5.6287,6.0302,15.2332,9.2761,17.1177,18.0295c-5.1184-1.848-8.8385-7.3674-14.7349-6.638
  c-6.4192,0.2067-13.0327,3.2704-17.13,8.2063c6.2491,2.2249,11.89,5.7505,17.7865,8.7169c1.702,0.9604,3.5378-0.1702,5.2034-0.6808
  c3.3191-1.4224,6.9905-1.9816,10.6014-1.6777c3.8781-0.0244,6.0179,3.8053,8.6194,6.1274
  c4.3405,3.9877,5.8356,9.8232,8.6564,14.8199C1455.304,708.721,1454.696,716.8787,1453.9666,724.5258z M1309.0616,596.3736
  c-3.1976,4.6199-8.8749,2.6747-13.5677,2.7476c-2.5165-0.0243-4.1213-2.2734-5.7263-3.8539
  c1.8115-4.2551,3.854-8.4251,6.5771-12.1818C1299.9678,588.0944,1305.0862,591.6808,1309.0616,596.3736z M1453.0305,686.6309
  c-2.0911-6.2246-8.2063-10.7837-8.6196-17.5189c5.4587-4.9846,9.957-11.6711,10.0541-19.2939
  c0.4987-5.1912-1.2399-10.1758-1.7262-15.2698c3.8052,2.5166,7.3066,5.5924,11.6226,7.2945
  c4.1091,1.7263,8.3765,4.8265,9.2155,9.5071c0.4135,4.7899,0.304,10.2488-3.2338,13.9568
  c-4.0486,4.4618-8.3765,8.6683-12.3521,13.203c-0.3281,4.8873,1.6169,9.5923,1.0576,14.4796
  C1454.8663,694.4725,1454.4773,689.2568,1453.0305,686.6309z M1549.8285,851.5107c-3.015,4.5469-7.0879,10.5162-13.3975,9.191
  c-5.7141-2.0546-10.3097-6.4313-13.8353-11.27c-4.8875-6.7717-9.9569-13.4097-15.5009-19.6586
  c-3.489-3.8297-8.0118-6.5286-11.501-10.3704c-5.3856-5.2034-9.3613-11.8171-15.4764-16.2424
  c-5.5804-3.1609-9.0452-8.7412-13.9446-12.7288c-5.9087-6.7231-11.9509-15.3671-9.2278-24.8013
  c2.8937-6.9905-2.0059-13.9691-0.2795-21.0811c4.3159-4.5347,8.5955-10.7108,15.2821-11.428
  c5.0331,2.3464,9.2518,6.7231,11.1118,11.9994c0.7661,3.7202-0.1337,7.6228,0.6687,11.3794
  c1.3738,3.1974,4.0607,5.556,6.5043,7.9632c3.0879-6.2003,6.8811-11.9873,10.4675-17.908c1.8358-2.8692,1.301-6.7474-0.8875-9.2642
  c-3.0028-4.2551-8.5952-4.6928-12.8262-7.1c-1.3615-5.2277,4.6444-7.2337,8.2429-9.264c2.5896-1.1428,4.863,0.4012,6.4679,2.3707
  c3.3434,3.9632,7.6958,6.8932,12.1818,9.4586c4.9968,0.5714,10.0786,0.462,15.063,1.3373
  c-1.0332-5.1426-4.9358-8.9479-7.7805-13.1422c-2.3101-3.6473-6.942-4.1702-10.2124-6.5651
  c-0.1459-5.0332,6.006-4.6927,9.5558-4.401c5.0453,0.9604,7.8781-4.4739,12.3641-5.3493c2.8569-0.6201,3.5864,2.6747,4.875,4.4618
  c7.8904,12.3033,13.191,25.9684,19.8291,38.9283c4.3037,7.7929,6.8446,16.4005,11.0146,24.2785
  c4.4739,7.7321,2.8328,17.4581-0.8633,25.166c-2.8569,8.413-5.5559,16.9719-6.8081,25.7982
  C1558.8616,833.2259,1554.339,842.4656,1549.8285,851.5107z M1582.7996,1088.3265c-4.8386-4.2917-9.1909-9.3248-11.6467-15.355
  c-2.5288-6.9298-6.553-13.2152-8.8021-20.2422c-2.3099-8.4617-4.4496-16.9719-6.1881-25.5552
  c-0.8268-3.7322-3.6472-6.8568-3.465-10.7837c3.5013-1.2157,6.7474,0.5106,8.2306,3.781
  c2.7476,6.0787,6.9906,12.3278,13.3368,14.8564c2.4073-2.1761,1.3981-5.5924,1.6414-8.4374
  c0.462-6.6744-3.2948-12.6316-6.6503-18.0903c-2.2249-4.1943-8.3885-1.5683-10.4553-5.8964
  c2.7961-2.8691,6.0664-5.1791,9.4098-7.3431c9.1547,10.2853,17.9323,21.4337,22.3212,34.6732
  c0.7659,2.2856,2.0182,4.7657,1.1306,7.2338c-1.1428,3.015-2.8934,5.8235-3.5256,9.0209
  c1.0334,4.9359,0.9971,10.1272,3.0759,14.8077c2.3099,5.167-1.459,10.2732-0.8997,15.5494
  c-0.1581,5.787,2.8691,10.9418,4.3037,16.4126C1589.9846,1094.0648,1586.3009,1090.7823,1582.7996,1088.3265z M1609.923,907.5446
  c0.0122,4.0728-1.6412,8.0239-0.9969,12.1332c1.1063,6.4313,0.9727,12.9842,0.9849,19.4885
  c0.1458,5.3979-2.9543,10.2244-6.8569,13.6893c-5.3857,0.6808-11.5253,2.6747-16.2302-1.1308
  c-7.4041-5.8234-15.124-11.4645-21.1418-18.7711c-4.6077-8.1334-5.021-17.7379-7.0149-26.6736
  c-0.6929-7.3795,1.3494-14.5768,2.4558-21.7983c0.6444-6.6866,3.7932-12.741,5.3616-19.2088
  c1.8599-6.8569,9.2031-11.112,9.1909-18.6132c-0.0122-3.8296,1.6412-7.331,2.8326-10.8809
  c2.2614-6.322,3.4042-12.972,5.9694-19.1967c1.082,0.0851,3.2583,0.2553,4.3403,0.3405
  c3.0635,9.4706,8.6924,17.9809,10.9902,27.7313c0.4377,9.0209,4.1823,17.361,6.8083,25.8833
  c2.7111,4.7171,1.7263,10.2366,2.8326,15.3671C1610.2876,893.0772,1610.1055,900.3352,1609.923,907.5446z M1669.6406,906.2194
  c-2.0061,4.8873-5.6898,8.6805-9.3492,12.3641c-3.4648-3.1609-5.3613-7.5619-7.3795-11.6833
  c-4.2916-7.9267-7.2458-16.4977-10.2732-24.9351c1.3618-5.6653,7.0879-1.8722,10.2976-0.1337
  c4.4131,3.2704,10.6012,2.8813,15.209,0.2189c3.7566-2.1276,8.0725,0.7659,10.5769,3.5743
  c-5.6045,5.556-12.0845,10.3217-16.3639,17.0934C1664.802,903.8243,1667.2212,905.0037,1669.6406,906.2194z M1664.2791,864.8961
  c-2.9663-0.62-6.03-0.693-8.9844-1.313c-5.483,0.4741-10.0178,5.325-15.7561,4.4496c-5.4951-11.963-9.4585-24.6554-15.5615-36.3387
  c-2.699-7.3067-6.9419-13.896-10.03-21.0203c-3.0637-5.325-4.8142-11.2335-7.4282-16.7531
  c-2.3584-5.556-5.3127-10.8688-7.416-16.5342c-0.863-4.632,1.2036-9.1546,2.2612-13.592
  c2.4316-5.6533,3.161-12.3278,8.0117-16.6558c3.3799-2.6746,7.4041-5.483,11.9631-4.3646c2.6138,1.5197,4.79,3.7688,6.5043,6.2612
  c0.4011,2.2856,0.2308,4.6198,0.2552,6.942c-3.3555,3.5378-7.7565,6.7231-8.5588,11.8655
  c-1.8357,5.4465-1.2522,11.3186,0.5105,16.7045c5.386-2.237,11.6349-3.9633,15.051-9.0938
  c1.0698-3.5986-0.316-7.4769,0.0244-11.1727c4.948-0.6444,6.8325,5.2399,8.2549,8.9844c2.4194,6.018-2.5653,11.0512-4.5105,16.364
  c-2.8569,5.8478,0.3284,12.4858,4.3647,16.911c1.7992-1.9209,3.9512-3.6229,5.0696-6.0665
  c0.5593-3.0151,0.1094-6.1395,0.6443-9.1547c6.796,5.5682,5.41,15.4522,2.3342,22.6494c2.626,3.9269,5.3615,7.8781,9.106,10.8688
  c2.322-6.9055,8.0239-12.6559,7.7688-20.3273c-1.1064-6.942-11.8293-7.9753-10.9783-15.6467
  c3.0515-0.5471,5.5073,1.5197,8.2185,2.541c3.9996,1.7871,8.498,0.7416,12.741,0.9362c0.0122-1.5562,0.1216-3.1002,0-4.6442
  c-4.0364-4.3159-9.6531-6.5894-14.2485-10.188c-2.6991-2.6989-6.5894-1.2766-9.9207-1.9088
  c-1.7507-4.5104-3.7566-8.8993-7.5012-12.1331c-0.1458-0.7416-0.4375-2.2127-0.5835-2.9543
  c3.0637,0.5106,5.8479,1.9817,8.7656,3.0029c5.021,0.2432,10.0664-0.1459,15.1118-0.231c-0.0244-4.79-3.3555-8.6561-6.4436-11.9508
  c-3.4771-4.2186-9.2882-4.5956-14.1755-5.9451c-3.6594-0.9118-7.9998,3.3312-10.9905-0.2553
  c-3.5012-3.7932-7.4524-7.027-12.2061-9.106l-0.5714-1.0455c2.0789-5.2642,8.3036-6.3583,12.6073-9.1789
  c4.6563-3.0637,10.4919-3.0272,15.4402-5.4708c3.4891-1.2158,6.0786-3.9998,9.3003-5.7141
  c6.2611-0.5228,13.4706-0.3283,18.0417,4.6685c3.9391,3.4648,1.7628,9.0452,0.936,13.3854
  c-0.304,4.6442-0.2065,9.3126,0.1339,13.9568c0.8145,2.8691,2.2976,5.4952,3.6715,8.1577c1.082,0.0121,2.1641,0.0244,3.2825,0.0608
  c2.4801-3.1489,4.6807-6.5651,6.0544-10.3459c0.6687-4.2186-0.1094-8.4982,0.0487-12.7288
  c0.5348,0.0851,1.6047,0.2553,2.1398,0.3525c3.9146,4.5834,7.2092,10.0543,7.8293,16.1694
  c-0.1946,5.5438-4.2429,9.6774-6.796,14.2365c-1.8114,5.6168,2.4193,10.6013,4.559,15.4643
  c1.0457,2.7233,3.0029,4.8995,5.2156,6.7717c7.6836-8.7291,1.7507-21.1054,5.7384-30.9772
  c1.1793,1.7021,3.6594,3.015,3.4648,5.4101c0.1703,4.863-0.4741,9.7989,0.4742,14.6376c0.4863,3.5742,2.7476,6.954,1.7749,10.6864
  c-1.1549,4.4983-2.0789,9.2032-4.7292,13.1301c-5.9814,8.8507-7.416,19.6708-9.7747,29.8466
  c-2.8571,8.5224-3.4648,17.5554-3.9512,26.4669c-1.8479,3.7931-2.6382,8.6318-6.5043,10.9539
  c-3.0028-4.2186-5.7992-8.6075-9.7382-12.0481c-2.2004-1.7263-4.1821-5.3128-7.4282-4.0849
  c-1.9087,6.9298-2.9178,14.9781,1.2644,21.3121c0.9119,2.237,4.0605,5.3007,0.3525,6.6137
  C1673.0083,873.9777,1669.5432,868.2637,1664.2791,864.8961z M1716.301,1085.5181c-5.8235-0.0365-12.0237,1.2036-17.446-1.4954
  c-5.2399-2.8812-8.1942-8.3522-10.7229-13.507c-2.1033-4.6685-4.79-9.118-5.9084-14.1512
  c-1.2888-6.176-1.8845-12.4979-1.6169-18.8076c1.7263-11.8535,1.1062-23.9625-0.2068-35.8403
  c-1.8966-8.5588-1.2888-17.3973-1.4587-26.1021c0.4983-7.3431-0.6079-14.8079,1.1914-22.0172
  c1.4709-10.419,3.3676-20.9352,8.0481-30.4302c3.9878-6.6623,7.3188-13.6893,11.1365-20.4368
  c0.9846-2.5773,3.9753-1.9451,6.1758-2.3585c1.0822,1.9452,2.699,3.7324,3.1001,6.0422
  c-6.0056,6.8203-13.5432,12.5222-17.6525,20.838c6.1516,1.933,12.7045,1.5319,19.0751,1.2401
  c1.8965-1.4346,3.7202-3.0272,5.2762-4.8387c2.6504-4.6077,0.4498-10.6135,4.231-14.7957
  c11.4279,4.5469,13.8837,17.7986,20.9716,26.5399c4.231,5.0696,5.5559,11.963,10.3582,16.5828
  c5.021,4.7171,9.3248,10.1272,14.0054,15.1968c4.863,6.2612,10.0178,13.4705,9.325,21.847
  c0.3646,5.5682-3.0759,10.2608-4.5835,15.4277c-2.468,6.0544-2.4314,12.6924-3.088,19.1115
  c-5.191,0.7538-10.2972-0.8997-14.6862-3.5621c2.7963-5.1426,6.5286-9.7867,8.8264-15.1968
  c0.0364-4.4132-0.45-9.2518-2.7842-13.0571c-3.8538-0.0244-6.7595,2.7354-9.7139,4.79c-4.3523,3.0272-5.3856,8.6561-6.2002,13.5313
  c-1.155,0.0244-2.2856,0.073-3.4043,0.1216c-3.4526-4.79-8.656-9.8719-7.0391-16.3275c7.343-2.7598,15.4888-7.4525,17.1177-15.744
  c-0.2188-5.6776,0.377-12.7167-4.1578-16.8624c-4.7172,2.2613-9.1423,5.5438-11.1848,10.5283
  c-2.4315,3.55-1.6777,7.9389-2.0911,11.9751c-1.0333-0.0972-3.1002-0.2917-4.1337-0.389
  c-2.4314-5.6897-1.9573-12.0724,0.8389-17.5189c-3.1366-6.942-9.3125-11.501-13.7866-17.4338
  c-1.1305-0.4863-2.2855-0.6565-3.5012-0.5228c-3.0273,3.7445-4.6442,8.4373-5.6655,13.0936
  c0.4985,5.3736,2.225,10.9174,7.0027,14.0054c4.8389,2.8692,8.6563,7.9146,8.717,13.7137
  c-4.3159,0.3647-5.9573-4.7292-9.811-5.8477c-6.6865-3.708-14.2609-0.5593-20.6191,2.2491
  c5.2764,8.8749,15.4885,13.1909,25.2632,14.9902c4,0.5957,7.6106,3.1731,9.3005,6.8811c1.7993,3.7324,4.0972,7.1729,5.8721,10.9296
  c-4.0728-0.3405-7.7322-2.9299-11.8414-2.553c-3.9755,0.8267-7.8903,1.9088-11.7198,3.1974
  c-0.0607,1.228-0.0972,2.468-0.1094,3.7324c3.4893,2.2249,6.8203,4.7172,10.3948,6.8326
  c3.7688,1.6412,7.9995,1.7385,12.0723,1.8236c3.3069-0.6565,6.3948-2.468,9.8354-2.5044
  c7.5498,1.8722,13.7014,8.413,21.9199,7.4647c2.2856-0.377,3.9877,1.3859,5.1062,3.1973
  c1.5562,8.2914,0.0729,17.0812-3.5378,24.6554c-5.2885,8.1333-13.2517,15.5374-23.1235,17.1785
  C1731.9843,1082.8921,1724.3615,1085.9436,1716.301,1085.5181z M1775.3986,1507.6019c-6.2976,0.7052-8.7047-6.5409-11.5497-10.8444
  c-0.5957-1.994-2.6625-1.1675-4.1458-1.0701c0.6566,4.5713,2.4438,8.9724,2.3345,13.6406
  c0.158,5.9817-2.9058,11.4282-3.0881,17.3853c-0.9725,0.1217-2.9178,0.3649-3.8904,0.4741
  c-3.4041-4.4253-4.9481-9.8231-7.4282-14.7347c-2.2371-4.316-2.6017-9.2277-4.1335-13.7745
  c-1.4225-4.4617-2.7842-9.0452-2.6504-13.7743c2.2979-3.161,5.4343-5.5803,8.0847-8.4375c4-3.2216,0.8633-8.9965,2.541-13.1058
  c1.7019-2.3341,3.8539-4.2792,5.957-6.2488c2.3223,1.0334,4.62,2.1763,6.9541,3.2703c2.1155,5.1183,2.8207,10.6256,3.5865,16.0844
  c0.9969,6.7839,5.3372,12.5831,5.9935,19.4641C1774.4502,1499.8091,1775.739,1503.6263,1775.3986,1507.6019z M1790.0969,1504.8542
  c-0.0972,3.3434-0.5957,6.6136-3.5378,8.729c-3.9147-7.2579-3.4163-15.6952-2.7112-23.6097
  c1.6293-4.3646,4.1946-8.3036,6.1274-12.5222c0.6808-1.921,2.3101-3.1124,3.9269-4.1821c0.9362,2.1033,1.6412,4.413,3.0028,6.3462
  c2.9301-0.2917,5.7141-1.3618,8.6199-1.848c2.0911,4.5226,1.386,10.5161-3.2948,13.1058
  C1796.6377,1493.8396,1791.9448,1498.7512,1790.0969,1504.8542z"/>
</g>
</svg>
  `,
  subscriptionRequired: true,
  licencePdf: '/help/copyright-and-licensing/ntm_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/ntm_eula/embeddable/',
  appGroups: [],
};
