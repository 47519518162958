<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div *ngIf="view === formView.Wait">Please wait ...</div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigateHome()">Continue</button>
    </div>

    <form class="form-horizontal" [formGroup]="updateUserForm" (ngSubmit)="updateUser()">
      <div *ngIf="view === formView.Form">
        <h3 tabindex="0">Change User Details</h3>
        <p tabindex="0">
          These are the details EDINA holds for your Digimap registration. Please check and amend them where
          appropriate. In particular, please check that your <strong>email</strong> address is accurate.
        </p>

        <div class="alert alert-warning" tabindex="0">
          If the details below do not apply to you, please close the browser and log in to Digimap again, or contact
          EDINA.
        </div>

        <p tabindex="0">
          If you are no longer a member of a subscribing institution or if you have moved from one institution to
          another, please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve
          this problem.
        </p>

        <div class="form-group" tabindex="0">
          <div id="digimapid-label" class="dm-form-label control-label">Digimap ID:</div>
          <div class="dm-form-control">
            <p aria-labelledby="digimapid-label" class="form-control-static">{{user.username}}</p>
          </div>
        </div>
        <div class="form-group" tabindex="0">
          <div id="institution-label" class="dm-form-label control-label">Institution:</div>
          <div class="dm-form-control">
            <p aria-labelledby="institution-label" class="form-control-static">{{user.institutionName}}</p>
          </div>
        </div>
        <div class="form-group">
          <label for="title" class="dm-form-label control-label"
            >Title: <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="title" class="form-control" formControlName="title" tabindex="0">
              <option *ngFor="let title of titles" value="{{title}}">{{title}}</option>
            </select>
            <dm-control-messages [control]="updateUserForm.controls['title']"></dm-control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="firstName" class="dm-form-label control-label"
            >First Name: <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input
              id="firstName"
              type="text"
              class="form-control"
              formControlName="firstName"
              tabindex="0"
              trim="blur" />
            <dm-control-messages [control]="updateUserForm.controls['firstName']"></dm-control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="surname" class="dm-form-label control-label"
            >Surname: <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input id="surname" type="text" class="form-control" formControlName="surname" tabindex="0" trim="blur" />
            <dm-control-messages [control]="updateUserForm.controls['surname']"></dm-control-messages>
          </div>
        </div>
        <div class="form-group" tabindex="0">
          <label for="email" class="dm-form-label control-label">Email:</label>
          <!-- Change email disabled to match existing form -->
          <div class="dm-form-control">
            <input
              id="email"
              type="text"
              class="form-control"
              formControlName="email"
              (change)="onChange($event)"
              [dmDisable]="user.pendingEmailChange"
              tabindex="0"
              trim="blur" />
            <dm-control-messages [control]="updateUserForm.controls['email']"></dm-control-messages>
            <p class="alert alert-warning">
              <strong
                >Please make sure this is a valid email address. You need to receive emails to complete your
                registration.</strong
              >
            </p>
            <div class="alert alert-warning" *ngIf="isNotEduEmail()">
              Your new email address isn't an academic email address. It will need to be manually verified before the
              change takes effect which may incur some delay.
            </div>
            <div class="alert alert-warning" *ngIf="user.pendingEmailChange">
              You already have a pending change email request, plese resolve the existing one before attempting to
              change it again.
            </div>

            <div class="alert alert-warning" *ngIf="emailChanged">
              You must ensure the account you are changing the email for is your account and not a session someone has
              walked away from.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="department" class="dm-form-label control-label"
            >Department: <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="department" class="form-control" formControlName="department" tabindex="0">
              <option *ngFor="let dept of user.institutionDepartments" value="{{dept}}">{{dept}}</option>
              <option value="">-- Request new department --</option>
            </select>
            <dm-control-messages [control]="updateUserForm.controls['department']"></dm-control-messages>
          </div>
        </div>
        <div *ngIf="updateUserForm.controls['department'].value === ''">
          <div class="form-group">
            <label for="newDepartment" class="dm-form-label control-label"
              >Request new department <span class="visuallyhidden">required</span></label
            >
            <div class="dm-form-control">
              <input id="newDepartment" type="text" class="form-control" formControlName="newDepartment" tabindex="0" />
              <dm-control-messages [control]="updateUserForm.controls['newDepartment']"></dm-control-messages>
            </div>
          </div>
        </div>

        <div class="dm-form-checkbox">
          <p>
            Please read our <a href="help/about/privacy_notice">Privacy Notice</a>
            which explains what information we collect from you and how we use it.
          </p>
        </div>

        <div class="form-group">
          <div class="dm-form-checkbox">
            <div class="checkbox">
              <label>
                <input id="agreeToPrivacyNotice" type="checkbox" formControlName="agreeToPrivacyNotice" tabindex="0" />
                I have read and agree to Digimap's Privacy Notice
              </label>
            </div>

            <div class="checkbox">
              <label>
                <input id="marketingContactable" type="checkbox" formControlName="marketingContactable" tabindex="0" />
                Yes, please send me details of new data, features, resources and free webinars<span class="footnote"
                  >*</span
                >.
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="footnote dm-form-checkbox">
            *&nbsp;Your details will be retained for 5 years after the last time you access Digimap but you can
            unsubscribe at any time. Note, you will still receive important Digimap service status information even if
            you don't check this box.
          </div>
        </div>

        <div class="dm-form-buttons">
          <button
            id="resetButton"
            type="button"
            class="btn btn-default"
            [disabled]="!updateUserForm.dirty"
            (click)="resetUser()">
            Reset
          </button>
          <button
            id="nextButton"
            type="button"
            class="btn btn-success"
            [disabled]="!canSubmit()"
            (click)="view = formView.Summary">
            Next
          </button>
        </div>
      </div>

      <div *ngIf="view === formView.Summary">
        <h3 tabindex="0">Please check the following details before pressing submit:</h3>

        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Title:</label>
          <p class="dm-form-control form-control-static">{{ user.title }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">First Name:</label>
          <p class="dm-form-control form-control-static">{{ user.firstName }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Surname:</label>
          <p class="dm-form-control form-control-static">{{ user.surname }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Email:</label>
          <div class="dm-form-control">
            <p class="form-control-static">{{ user.email }}</p>
          </div>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Department:</label>
          <p class="dm-form-control form-control-static">{{ user.department }}</p>
        </div>

        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Contactable:</label>
          <div *ngIf="user.marketingContactable" class="dm-form-control form-control-static">
            Yes, please send me details of new data, features, resources and free webinars.
          </div>
          <div *ngIf="!user.marketingContactable" class="dm-form-control form-control-static">
            No, please don't send me details of new data, features, resources and free webinars.
          </div>
        </div>

        <div class="dm-form-buttons">
          <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
          <button
            type="submit"
            class="btn btn-success"
            [disabled]="submitText.getSubmitting()"
            [innerHTML]="submitText.getSubmitText()"></button>
        </div>
      </div>
    </form>

    <!-- Success view: form has been submitted successfully -->
    <div *ngIf="view === formView.SuccessConfirmation" tabindex="0">
      <div class="alert alert-success"><strong>Success!</strong></div>
      <p>Thank you for changing your user details.</p>
      <p>
        Changes to your personal details will be updated immediately.
        <ng-container *ngIf="emailChanged && !isNotEduEmail()"
          >Since you have updated your email address you need to click on the verification link sent to your
          <strong>new</strong> email address, within 24 hours, to confirm the change.</ng-container
        >
        <ng-container *ngIf="emailChanged && isNotEduEmail()">
          Your new email address isn't an academic email address. It will need to be manually verified before the change
          takes effect which may incur some delay.
        </ng-container>
      </p>
      <p>
        If you have any queries, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>
      <button type="button" class="btn btn-success" (click)="navigateHome()">Continue</button>
    </div>

    <!-- Error view: form has been submitted but there's been an error -->
    <div *ngIf="view === formView.ErrorConfirmation">
      <div class="alert alert-danger" tabindex="0">
        <strong>Error!</strong> There has been a problem submitting your details:
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        Please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
      <button type="button" class="btn btn-success" (click)="navigateHome()">Continue</button>
    </div>

    <div *ngIf="view === formView.VerificationSuccess">
      <div class="alert alert-success">
        <p><strong>Success!</strong> You have successfully verified your email address.</p>
      </div>
      <p>Click on the button below to access Digimap and agree to the licences for each collection.</p>
      <div class="pull-right">
        <button type="button" class="btn btn-success" (click)="navigateHome()">Continue</button>
      </div>
    </div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.VerificationError">
      <div class="alert alert-danger">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p>
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <button class="btn btn-success" (click)="navigateHome()">Continue</button>
    </div>
  </div>
</main>
