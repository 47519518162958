import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TemplateModule } from '../template/template.module';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';

import { ContactService } from './contact-form.service';
import { ContactFormComponent } from './contact-form.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TemplateModule, ControlMessagesModule, MatExpansionModule],
  declarations: [ContactFormComponent],
  providers: [ContactService],
  exports: [ContactFormComponent],
})
export class ContactModule {}
