import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressBaseFormComponent } from './address-base-form.component';
import { CommonModule } from '@angular/common';
import { TemplateModule } from '../template/template.module';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddressBaseFormService } from './address-base-form-service';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AddressBaseFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TemplateModule,
    ControlMessagesModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
    RouterModule,
  ],
  providers: [AddressBaseFormService],
  exports: [AddressBaseFormComponent],
})
export class AddressBaseModule {}
