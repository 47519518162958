import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppGroup, Application } from '../collection';
import { ARROW_ICON } from '../collection-config';

export interface ColourScheme {
  primaryColour: string;
  primaryColourLight: string;
}

@Component({
  selector: 'dm-app-buttons',
  templateUrl: 'app-buttons.component.html',
  styleUrls: ['./app-buttons.component.scss'],
})
export class AppButtonsComponent {
  arrow = ARROW_ICON;

  @Input()
  icon: string;

  @Input()
  appGroup: AppGroup;

  @Input()
  isEnabled: (app: Application) => boolean;

  @Input()
  colourScheme: ColourScheme;

  @Output()
  appClicked: EventEmitter<Application> = new EventEmitter(false);

  onClick(app: Application) {
    this.appClicked.next(app);
  }

  getPrimaryBackgroundColour(): Record<string, string> | null {
    return this.colourScheme && this.colourScheme.primaryColour
      ? { 'background-color': this.colourScheme.primaryColour }
      : null;
  }

  getPrimaryLightBackgroundColour(): Record<string, string> | null {
    return this.colourScheme && this.colourScheme.primaryColourLight
      ? { 'background-color': this.colourScheme.primaryColourLight }
      : null;
  }
}
