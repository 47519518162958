import { Component, ViewEncapsulation } from '@angular/core';

interface ProductInfo {
  name: string; // Display name of the product
  prices: number[]; // Price bands, from 1 to 10
  link?: string;
  isBundle?: boolean;
  isDiscount?: boolean;
  daggers?: number;
}

@Component({
  templateUrl: 'subscriptions.component.html',
  styleUrls: ['subscriptions.component.scss'],
  encapsulation: ViewEncapsulation.None, // Required so we can adjust some css properties of mat tab
})
export class SubscriptionsComponent {
  readonly bands = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  readonly productInfo: ProductInfo[] = [
    {
      name: 'Ultimate Bundle',
      prices: [14961, 13680, 11584, 10899, 8656, 8513, 5001, 3994, 3782, 3670],
      isBundle: true,
    },
    {
      name: 'Premium Bundle',
      prices: [13662, 12476, 10598, 9976, 7940, 7557, 4543, 3674, 3528, 3411],
      isBundle: true,
    },
    {
      name: 'Standard Bundle',
      prices: [10640, 9941, 8480, 8201, 6488, 6347, 3526, 2827, 2827, 2827],
      isBundle: true,
    },
    {
      name: 'OS',
      prices: [6807, 6503, 5707, 5452, 4717, 4418, 2468, 1677, 1677, 1677],
      link: '/os',
    },
    {
      name: 'Marine',
      prices: [3495, 3495, 2237, 2097, 979, 911, 590, 529, 529, 517],
      link: '/marine',
    },
    {
      name: 'aerial-lidar-special-case',
      prices: [3039, 2553, 2126, 1787, 1458, 1216, 1021, 852, 705, 590],
    },
    {
      name: 'geology-special-case',
      prices: [1390, 1390, 826, 826, 515, 515, 271, 271, 271, 271],
      link: '/geology',
    },
    {
      name: 'Global',
      prices: [1094, 1045, 918, 877, 758, 710, 396, 270, 270, 270],
      link: '/global',
    },
    { name: 'Historic', prices: [833, 833, 772, 772, 675, 675, 377, 377, 377, 377], link: '/historic', daggers: 1 },
    {
      name: 'Society',
      prices: [730, 697, 612, 585, 506, 473, 265, 180, 180, 180],
      link: '/society',
      daggers: 1,
    },
    {
      name: 'Environment',
      prices: [322, 280, 256, 237, 225, 194, 182, 140, 140, 140],
      link: '/environment',
    },
    {
      name: 'Verisk',
      prices: [2258, 2157, 1893, 1808, 1565, 1465, 818, 557, 557, 557],
      link: '/verisk',
    },
    {
      name: 'agCensus',
      prices: [1196, 1196, 996, 996, 797, 797, 597, 597, 398, 398],
      link: '/agcensus',
    },
    {
      name: 'OS National Geographic Database (NGD)',
      prices: [4084, 3902, 3424, 3271, 2830, 2651, 1481, 1006, 1006, 1006],
      link: '/whats-new#ngd',
      daggers: 1,
    },
    {
      name: 'Discounted OS National Geographic Database (NGD) bought with Ultimate*',
      prices: [2859, 2731, 2397, 2290, 1981, 1856, 1036, 704, 704, 704],
      isDiscount: true,
      link: '/whats-new#ngd',
    },
    {
      name: 'Discounted OS National Geographic Database (NGD) bought with Premium*',
      prices: [3267, 3121, 2739, 2617, 2264, 2121, 1184, 805, 805, 805],
      isDiscount: true,
      link: '/whats-new#ngd',
    },
    {
      name: 'Discounted OS National Geographic Database (NGD) bought with Standard*',
      prices: [3676, 3511, 3082, 2944, 2547, 2386, 1332, 905, 905, 905],
      isDiscount: true,
      link: '/whats-new#ngd',
    },
    {
      name: 'National Tree Map',
      prices: [2520, 2352, 1688, 1386, 1092, 840, 588, 504, 462, 420],
      link: '/help/our-maps-and-data/national_tree_map/',
      daggers: 1,
    },
    {
      name: 'Discounted National Tree Map bought with Ultimate*',
      prices: [2016, 1882, 1350, 1109, 874, 672, 470, 403, 370, 336],
      isDiscount: true,
      link: '/help/our-maps-and-data/national_tree_map/',
    },
    {
      name: 'Discounted National Tree Map bought with Premium*',
      prices: [2142, 1999, 1435, 1178, 928, 714, 500, 428, 393, 357],
      isDiscount: true,
      link: '/help/our-maps-and-data/national_tree_map/',
    },
    {
      name: 'Discounted National Tree Map bought with Standard*',
      prices: [2268, 2117, 1519, 1247, 983, 756, 529, 454, 416, 378],
      isDiscount: true,
      link: '/help/our-maps-and-data/national_tree_map/',
    },
    {
      name: 'Aerial Extra bought with Aerial & Lidar',
      prices: [1139, 958, 798, 670, 547, 456, 383, 319, 265, 222],
      link: '/help/our-maps-and-data/aerial_products/#aerial-extra',
      daggers: 2,
    },
    {
      name: 'Discounted Aerial Extra bought with Ultimate*',
      prices: [998, 838, 698, 586, 479, 399, 335, 279, 231, 193],
      isDiscount: true,
      link: '/help/our-maps-and-data/aerial_products/#aerial-extra',
    },
    {
      name: 'Discounted Aerial Extra bought with Premium*',
      prices: [1068, 898, 748, 628, 512, 427, 359, 299, 248, 207],
      isDiscount: true,
      link: '/help/our-maps-and-data/aerial_products/#aerial-extra',
    },
    {
      name: 'Landclan',
      prices: [1250, 1194, 1048, 1001, 866, 811, 453, 308, 308, 308],
      link: '/whats-new#landclan',
      daggers: 1,
    },
    {
      name: 'Discounted Landclan bought with Ultimate*',
      prices: [1000, 955, 838, 801, 693, 649, 362, 246, 246, 246],
      isDiscount: true,
      link: '/whats-new#landclan',
    },
    {
      name: 'Discounted Landclan bought with Premium*',
      prices: [1063, 1015, 891, 851, 736, 690, 385, 262, 262, 262],
      isDiscount: true,
      link: '/whats-new#landclan',
    },
    {
      name: 'Discounted Landclan bought with Standard*',
      prices: [1125, 1075, 943, 901, 780, 730, 408, 277, 277, 277],
      isDiscount: true,
      link: '/whats-new#landclan',
    },
  ];

  getBundles(): ProductInfo[] {
    return this.productInfo.filter(product => product.isBundle);
  }

  getProducts(): ProductInfo[] {
    return this.productInfo.filter(product => !product.isBundle);
  }
}
