<div class="row no-gutters no-local-info">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <div class="alert alert-warning">
      <h2>No information provided by institution</h2>
      <p>Talk to your academic department, IT or library services for advice.</p>
      <p>
        Staff and/or site reps: please provide link(s) to any appropriate advice to
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>
    </div>
  </div>
</div>
