import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from './user';
import { map, publishReplay, refCount } from 'rxjs/operators';

const API = 'api/user';

interface UserBody {
  userDto: User;
  loggedIn: boolean;
  serverTime: Date;
}

@Injectable()
export class UserService {
  private replayObservable: ReplaySubject<User | null>;
  private userObservable: Observable<User | null>;

  constructor(private http: HttpClient) {
    this.replayObservable = new ReplaySubject<User>();
  }

  getUser(): Observable<User | null> {
    if (!this.userObservable) {
      this.doGetUser();
      this.userObservable = this.replayObservable.pipe(publishReplay(1), refCount());
    }
    return this.userObservable;
  }

  forceRefresh() {
    this.doGetUser();
  }

  private doGetUser(): void {
    this.http
      .get<UserBody>(API)
      .pipe(
        map(userBody => {
          const user = userBody.userDto;
          if (userBody.loggedIn) {
            if (user.verificationExpiry) {
              user.verificationExpiry = new Date(user.verificationExpiry);
            }
            if (userBody.serverTime) {
              user.serverTime = new Date(userBody.serverTime);
            }
            return user;
          }
          return null;
        })
      )
      .subscribe(
        user => {
          this.replayObservable.next(user);
        },
        (error: Error) => {
          this.replayObservable.error(error);
        }
      );
  }
}
