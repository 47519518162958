<mat-tab-group>
  <mat-tab label="About">
    <p>
      Aerial Digimap provides access to some of the highest quality aerial photography available for Great Britain,
      created and licensed by Getmapping Ltd. Access is convenient and on-demand with a range of interactive tools,
      allowing interrogation and analysis of the data online and offline. Data is available to view, query and print in
      from online maps and also available to search and download.
    </p>
    <p>Aerial Digimap allows you to view detailed aerial imagery at various fixed scales. You can also:</p>
    <ul>
      <li>add annotations (text, point, lines and areas)</li>
      <li>identify image capture date by clicking on the map</li>
      <li>generate PDF, PNG or JPG files for printing</li>
      <li>save maps to go back to or print later</li>
    </ul>
    <p>
      You can view a list of all the available data in our help pages
      <a href="/help/our-maps-and-data/aerial_products/" target="aerial_data">here</a>.
    </p>
    <p>
      Aerial photography has a multitude of uses including backdrop mapping and context for a wide range of studies.
      Ideal for looking at the reality of a location in great detail, the aerial photography available is 25cm vertical
      ortho-photography and is available for the whole of Great Britain. The dates of each location will vary, according
      to what was flown by Getmapping in any given year. By default, the latest version for any location is shown in the
      map panel. Earlier versions are available for download.
    </p>
    <p>
      Aerial Digimap has found uses in coastal zone studies, archaeology, urban studies and town planning, historical
      studies looking at change over time and habitat change among many other uses.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps. You can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 and A3 size in PDF, PNG or JPG format</li>
      <li>compare 2 maps</li>
      <li>search for and add WMS layers</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/7klUHHhEd-w"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Downloading Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/ssEEdESGjwE"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Guides</h3>
    <p><a href="/help/learning-materials/">Application guides</a> are available for Digimap's different functions.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/aerial/">Digimap Aerial Collection</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
