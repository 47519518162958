import { ALL, Collection, GroupName } from '../collection';

import { OsDetailComponent } from '../detail-pages/os-detail.component';

/* eslint-disable max-len */

export const OS_COLLECTION: Collection = {
  id: 'os',
  name: 'Ordnance Survey',
  icon: `
    <svg x="0px" y="0px" width="68.03px" height="68.03px" viewBox="0 0 68.03 68.03" enable-background="new 0 0 68.03 68.03">
    <g>
      <g>
        <path d="M59.646,32.967l-0.168-2.457l-0.334-2.457l-0.612-2.345l-0.838-2.289l-1.06-2.177l-1.227-2.123l-1.505-1.955l-1.673-1.842
          l-0.893-0.893l-0.947-0.782l-1.004-0.781l-1.003-0.726l-1.005-0.671l-1.114-0.613l-1.06-0.558l-1.115-0.503l-1.171-0.445
          L41.747,8.96l-1.171-0.279l-1.171-0.278l-1.228-0.224l-1.226-0.167l-1.283-0.056l-1.227-0.056h-0.057l-0.612-5.191l-0.725,5.248
          l-2.453,0.279l-2.343,0.447l-2.285,0.726l-2.23,0.894l-2.064,1.116l-1.95,1.229l-1.84,1.508l-1.673,1.563l-1.562,1.786
          L13.311,19.4l-1.228,2.01l-1.003,2.123l-0.781,2.289L9.687,28.11l-0.334,2.457l-0.168,2.457l-3.902,0.502l3.902,0.501l0.223,2.402
          l0.391,2.344l0.613,2.289l0.836,2.232l1.06,2.123l1.226,2.01l1.451,1.896l1.616,1.787l0.837,0.783l0.893,0.781l0.892,0.725
          l0.948,0.672l0.948,0.613l0.947,0.559l1.004,0.559l1.058,0.445l1.005,0.447l1.059,0.391l1.115,0.336l1.116,0.334l1.114,0.223
          l1.115,0.225l1.116,0.111l1.171,0.111l0.836,6.645l0.725-6.645l1.227-0.057l1.283-0.055l1.226-0.168l1.172-0.223l1.228-0.223
          l1.17-0.336l1.171-0.391l1.115-0.447l1.115-0.445l1.114-0.559l1.06-0.615l1.005-0.67l1.003-0.668l1.004-0.783l0.947-0.838
          l0.893-0.838l1.617-1.787l1.45-1.896l1.227-2.01l1.059-2.123l0.837-2.232l0.613-2.232l0.445-2.346l0.168-2.4l4.015-0.558
          L59.646,32.967z M11.304,30.511l0.334-2.233l0.558-2.122l0.781-2.121l0.947-1.955l1.171-1.897l1.338-1.787l1.506-1.675l0.78-0.726
          l0.78-0.727l0.837-0.614l0.835-0.613l0.838-0.615l0.892-0.502l0.948-0.502l0.948-0.446l0.948-0.447l0.946-0.335l1.004-0.335
          l1.005-0.279l1.002-0.223l1.005-0.223l1.06-0.111l1.06-0.113l-0.447,3.015l-1.839,0.28l-1.786,0.446l-1.727,0.558l-1.673,0.726
          l-1.562,0.894l-1.45,1.006l-1.395,1.116l-1.282,1.228l-1.17,1.397l-1.06,1.451l-0.892,1.508l-0.837,1.619l-0.613,1.731
          l-0.503,1.786l-0.333,1.787l-0.169,1.897l-2.954,0.391L11.304,30.511z M29.146,30.456l-13.104,1.674l0.167-1.674l0.335-1.62
          l0.501-1.619l0.614-1.564l0.725-1.451l0.892-1.396l1.005-1.34l1.17-1.284l0.558-0.558l0.558-0.503l0.612-0.502l0.614-0.448
          l0.613-0.39l0.613-0.447L25.688,17l0.671-0.391l0.669-0.279l0.724-0.279l0.67-0.278l0.726-0.224l0.724-0.224l0.726-0.167
          l0.78-0.111l0.726-0.112L30.482,27.05l-7.304-5.081L29.146,30.456z M30.65,39.332l1.451,12.172l-0.726-0.113l-0.78-0.111
          l-0.726-0.223l-0.724-0.168l-0.726-0.223l-0.67-0.279l-0.724-0.279l-0.669-0.336l-0.671-0.334l-0.668-0.391l-0.613-0.391
          l-0.613-0.447l-0.614-0.445l-0.612-0.447l-0.558-0.502l-0.558-0.561l-1.115-1.172l-0.948-1.283l-0.836-1.34l-0.781-1.396
          l-0.612-1.451l-0.447-1.451l-0.39-1.564l-0.224-1.563l12.323,1.73l-5.466,7.928L30.65,39.332z M31.654,56.359l-1.004-0.111
          l-1.003-0.223l-1.004-0.223l-2.008-0.559l-0.948-0.391l-0.947-0.391l-0.947-0.447L22.9,53.514l-0.892-0.504l-0.838-0.613
          l-0.835-0.613l-0.837-0.613l-0.78-0.729l-0.78-0.725l-1.45-1.619l-1.338-1.73l-1.115-1.787l-0.948-1.898l-0.78-2.01l-0.558-2.064
          l-0.391-2.123l-0.223-2.176l3.012,0.445l0.223,1.787l0.39,1.785l0.502,1.732l0.669,1.619l0.837,1.617l0.947,1.508l1.116,1.451
          l1.227,1.342l0.612,0.613l0.67,0.559l0.669,0.557l0.724,0.561l0.726,0.447l0.725,0.445l0.78,0.447l0.782,0.391l0.78,0.334
          l0.779,0.336l0.837,0.277l0.781,0.281l0.892,0.166l0.836,0.225l0.837,0.111l0.892,0.111l0.335,3.016L31.654,56.359z M33.94,36.875
          l-0.725-0.055l-0.669-0.225l-0.614-0.334l-0.501-0.447l-0.447-0.502l-0.334-0.615l-0.223-0.67l-0.056-0.725l0.056-0.726
          l0.223-0.669l0.334-0.615l0.447-0.558l0.501-0.447l0.614-0.334l0.669-0.224l0.725-0.055l0.725,0.055l0.67,0.224l0.613,0.334
          l0.502,0.447l0.446,0.558l0.334,0.615l0.224,0.669l0.056,0.726l-0.056,0.725l-0.224,0.67l-0.334,0.615l-0.446,0.502l-0.502,0.447
          l-0.613,0.334l-0.67,0.225L33.94,36.875z M40.296,36.709l12.379-1.676l-0.224,1.676l-0.39,1.617l-0.502,1.564l-0.668,1.508
          l-0.78,1.395l-0.893,1.34l-1.003,1.285L47.1,46.59l-1.227,1.061l-1.339,0.951l-1.337,0.836l-1.506,0.67l-1.506,0.613l-1.561,0.449
          l-1.673,0.277l-1.673,0.168l1.506-13.344l9.59,6.42L40.296,36.709z M39.572,30.456l6.021-8.486l-8.697,6.141l-1.617-13.288
          l0.837,0.056l0.836,0.111l0.835,0.112l0.838,0.167l0.835,0.224l0.781,0.224l0.78,0.279l0.781,0.335l0.781,0.335l0.725,0.391
          l0.724,0.446l0.726,0.503l0.669,0.445l0.67,0.558l0.668,0.56l0.613,0.614l1.171,1.284l1.005,1.34l0.892,1.396l0.725,1.451
          l0.614,1.564l0.445,1.619l0.334,1.62l0.168,1.674L39.572,30.456z M57.471,36.596l-0.445,2.178l-0.614,2.123l-0.836,2.064
          l-1.005,1.898l-1.171,1.844l-1.338,1.73l-1.449,1.563l-1.616,1.395l-1.729,1.285l-1.896,1.117l-1.951,0.949l-2.063,0.725
          l-2.119,0.559l-2.229,0.391l-2.286,0.111l0.335-2.957l0.947-0.057l0.948-0.113l0.947-0.111l0.948-0.223l0.947-0.223l0.894-0.281
          l0.892-0.277l0.892-0.391l0.837-0.391l0.836-0.393l0.837-0.502l0.835-0.502l0.781-0.613l0.725-0.559l0.726-0.67l0.725-0.67
          L50,46.254l1.114-1.451l0.947-1.508l0.836-1.617l0.67-1.619l0.502-1.732l0.39-1.785l0.223-1.787l3.012-0.445L57.471,36.596z
          M54.682,32.353l-0.166-1.897l-0.335-1.843l-0.502-1.786L53.01,25.04l-0.836-1.676l-0.949-1.619l-1.171-1.507l-1.281-1.452
          l-0.725-0.67l-0.726-0.614l-0.78-0.614l-0.78-0.558l-0.78-0.502l-0.837-0.502l-0.836-0.446l-0.894-0.39l-0.892-0.335l-0.891-0.335
          l-0.893-0.279l-0.949-0.224l-0.892-0.167l-0.947-0.168l-1.004-0.056l-0.949-0.055l-0.334-3.016l1.115,0.056l1.172,0.056
          l1.115,0.167l1.059,0.223l1.115,0.223l1.06,0.335l1.061,0.335l1.059,0.39l1.003,0.447l1.004,0.559l1.003,0.559l0.948,0.614
          l0.947,0.614l0.894,0.726l0.835,0.782l0.838,0.782l1.505,1.675l1.338,1.787l1.171,1.897l0.948,1.955l0.78,2.121l0.558,2.122
          l0.335,2.233l0.167,2.233L54.682,32.353z"/>
      </g>
    </g>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'Ordnance Survey Logo',
      file: 'assets/images/collections/supplier-logos/os_logo_min.svg',
      width: 230,
      height: 60,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: OsDetailComponent,
  licencePdf: '/help/copyright-and-licensing/os_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/os_eula/embeddable/',
  appGroups: [
    {
      groupName: 'View maps and download data',
      subHeading: `View, annotate and print maps, plus download data for GIS/CAD.`,
      apps: [
        {
          id: 'OSMAPPER',
          name: 'OS Digimap',
          link: '/roam/map/os',
        },
      ],
    },
    {
      groupName: GroupName.WEBSERVICES,
      apps: [
        {
          id: 'OSWMS',
          name: 'Web Services',
          link: '/os/wms',
          isInternalLink: true,
          wmsLinks: ALL,
        },
      ],
    },
  ],
};
