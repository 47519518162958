import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Icons } from './icons';
import { Notification } from './notification';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'dm-simple-notification',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="simple-notification"
      (click)="removeSelf()"
      [class]="theClass"
      [ngClass]="{
        alert: item.type === 'alert',
        error: item.type === 'error',
        success: item.type === 'success',
        info: item.type === 'info',
        bare: item.type === 'bare',
        'rtl-mode': rtl
      }"
      [attr.role]="getAriaRole()"
      (mouseenter)="onEnter()"
      (mouseleave)="onLeave()">
      <div *ngIf="!item.html">
        <a href="#" class="close" tabindex="-1" aria-hidden="true" data-dismiss="alert">&times;</a>
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.content | dmMax: maxLength }}</div>
        <div *ngIf="item.type !== 'bare'" [innerHTML]="icons[item.type]"></div>
      </div>
      <div *ngIf="item.html" [innerHTML]="item.html"></div>

      <div class="progress" *ngIf="showProgressBar">
        <span [ngStyle]="{ width: progressWidth + '%' }"></span>
      </div>
    </div>
  `,
})
export class NotificationComponent implements OnInit, OnDestroy {
  public icons: any = Icons;

  ////// Public inputs
  @Input() public item: Notification;
  @Input() public maxLength: number;
  @Input() public showProgressBar: boolean;
  @Input() public theClass: string;
  @Input() public theHtml: any;
  @Input() public rtl: boolean;

  public overrides: any;

  ////// Locals

  // Progress bar variables
  public progressWidth = 0;

  ////// Private inputs
  @Input() timeOut: number;
  @Input() position: number;
  @Input() clickToClose: boolean;
  @Input() pauseOnHover: boolean;

  private stopTime = false;
  private timer: any;
  private steps: number;
  private speed: number;
  private count = 0;
  private start: any;
  private diff: any;

  constructor(private noteService: NotificationsService) {}

  ngOnInit() {
    if (this.item.override) {
      this.attachOverrides();
    }
    if (this.timeOut !== 0) {
      this.startTimeOut();
    }
  }

  /**
   * Attempts to map notifcation types to ARIA roles, with alerts and errors being
   * marked up as the role "altert" and info and success as role "status".
   */
  getAriaRole() {
    switch (this.item.type) {
      case 'alert':
      case 'error':
        return 'alert';
      case 'info':
      case 'success':
        return 'status';
      default:
    }
    return null;
  }

  startTimeOut() {
    this.steps = this.timeOut / 10;
    this.speed = this.timeOut / this.steps;
    this.start = new Date().getTime();
    this.timer = setTimeout(this.instance, this.speed);
  }

  onEnter() {
    if (this.pauseOnHover) {
      this.stopTime = true;
    }
  }

  onLeave() {
    if (this.pauseOnHover) {
      this.stopTime = false;
      setTimeout(this.instance, this.speed - this.diff);
    }
  }

  setPosition() {
    return this.position !== 0 ? this.position * 90 : 0;
  }
  removeSelf() {
    if (this.clickToClose) {
      this.noteService.set(this.item, false);
    }
    if (this.item.click) {
      this.item.click();
    }
  }

  // Attach all the overrides
  attachOverrides() {
    Object.keys(this.item.override).forEach(a => (this[a] = this.item.override[a]));
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  private instance = () => {
    this.diff = new Date().getTime() - this.start - this.count * this.speed;
    if (this.count++ === this.steps) {
      this.noteService.set(this.item, false);
    } else if (!this.stopTime) {
      if (this.showProgressBar) {
        this.progressWidth += 100 / this.steps;
      }
      this.timer = setTimeout(this.instance, this.speed - this.diff);
    }
  };
}
