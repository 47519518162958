import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ErrorResponse } from '../../commons/error-response';
import { User } from '../../modules/user/user';
import { UserService } from '../../modules/user/user.service';
import { VerifyService } from './verify.service';

@Component({
  templateUrl: 'verify.template.html',
})
export class VerifyComponent implements OnInit {
  success: boolean;
  errorMessage: string;

  // Indicates that we're still waiting for the reply to the verify service. Need
  // to wait for this before rendering the header (which contains a resend email button)
  waitForVerification: boolean;
  user: User | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private verifyService: VerifyService,
    private userService: UserService
  ) {
    this.waitForVerification = true;
  }

  navigateLicence() {
    this.router.navigate(['/activate']);
  }

  navigateHome() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const token = params['token'];
      this.verifyService.verify(token).subscribe({
        next: () => {
          this.userService.forceRefresh();
          this.success = true;
          this.waitForVerification = false;
        },
        error: (error: ErrorResponse) => {
          this.errorMessage = error.message;
          this.success = false;
          this.waitForVerification = false;
        },
      });
    });

    this.userService.getUser().subscribe(user => {
      this.user = user;
    });
  }
}
