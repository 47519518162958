import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsModule } from '../notifications/notifications.module';

import { VerifyNotificationComponent } from './verify-notification.component';

@NgModule({
  imports: [CommonModule, NotificationsModule],
  declarations: [VerifyNotificationComponent],
  exports: [VerifyNotificationComponent],
})
export class VerifyModule {}
