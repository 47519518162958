import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { ErrorResponse } from '../../commons/error-response';
import { FormView } from '../../commons/form-view';
import { NotUserDto } from './not-user';
import { NotUserService } from './not-user.service';
import { Router } from '@angular/router';
import { ValidationService } from '../controlmessages/validation.service';

@Component({
  selector: 'dm-not-user-form',
  templateUrl: 'not-user-form.template.html',
})
export class NotUserFormComponent implements OnInit {
  user: NotUserDto;

  // Unfortunately enum's need to be properties of components to be usable in templates:
  // https://github.com/angular/angular/issues/2885
  readonly formView = FormView;

  // Which of the views is currently being displayed
  view: FormView;

  // If an error has occured, the message from the server
  errorMessage: string | null;

  notUserForm: FormGroup;

  // Original state of the DTO.
  private original: NotUserDto;

  constructor(fb: FormBuilder, private notUserService: NotUserService, private router: Router) {
    this.view = FormView.Wait;
    this.errorMessage = null;
    this.notUserForm = fb.group({
      name: ['', [Validators.required, Validators.maxLength(64)]],
      email: ['', [Validators.required, Validators.maxLength(255), ValidationService.emailValidator]],
      institution: ['', Validators.required],
      comments: '',
    });

    this.notUserForm.valueChanges.subscribe(values => {
      this.user.name = values.name;
      this.user.email = values.email;
      this.user.institution = values.institution;
      this.user.comments = values.comments;
    });
  }

  /**
   * Run when form submitted.
   */
  setNotUser() {
    this.notUserService.setNotUser(this.user).subscribe(
      (success: boolean) => {
        this.view = FormView.SuccessConfirmation;
      },
      (error: any) => {
        this.view = FormView.ErrorConfirmation;
      }
    );
  }

  /** Run when form is first initialised or reset */
  getNotUser() {
    this.notUserService.getNotUser().subscribe(
      (data: NotUserDto) => {
        this.user = data;
        this.original = cloneDeep(data);
        this.view = FormView.Form;
        this.notUserForm.reset(this.user);
      },
      (err: ErrorResponse) => {
        this.errorMessage = err.message ? err.message : 'There has been an internal error.';
        this.view = FormView.Error;
      }
    );
  }

  /**
   * Run to reset form to original state.
   *
   * This retrieves the data from the backend again. This is simpler than
   * caching the original data returned when initialised. A disadvantage of
   * this is that the reset button is always active, even when the form is
   * unchanged.
   *
   * Reseting forms is an ongoing issue in angular2:
   * https://github.com/angular/angular/issues/4933
   * Some messages in the above link suggest recreating the form, although
   * this is ugly and results in a complete refresh of the form.
   */
  reset(): void {
    this.user = cloneDeep(this.original);
    this.notUserForm.reset(this.user);
  }

  /**
   * Returns true when the form data has changed from the original values.
   */
  hasChanged(): boolean {
    return this.notUserForm.dirty;
  }

  /**
   * Returns true if the form is in a state where submission of the data
   * is possible, i.e. the form is valid and there has been changes.
   */
  canSubmit(): boolean {
    return this.notUserForm.valid && this.hasChanged();
  }

  navigate() {
    this.router.navigate(['/']);
  }

  /**
   * Get the Observable of a user from the service, then assign it.
   */
  ngOnInit() {
    this.getNotUser();
  }
}
