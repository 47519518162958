import { AerialDetailComponent } from './detail-pages/aerial-detail.component';
import { AuthenticationNotificationComponent } from '../authentication/authentication-notification.component';
import { CollectionDetailComponent } from './collection-detail.component';
import { CollectionHeaderComponent } from './collection-header.component';
import { CommonModule } from '@angular/common';
import { EnvironmentDetailComponent } from './detail-pages/environment-detail.component';
import { GeologyDetailComponent } from './detail-pages/geology-detail.component';
import { GlobalDetailComponent } from './detail-pages/global-detail.component';
import { HistoricDetailComponent } from './detail-pages/historic-detail.component';
import { HomeComponent } from './home.component';
import { LayoutComponent } from './layout.component';
import { LidarDetailComponent } from './detail-pages/lidar-detail.component';
import { MarineDetailComponent } from './detail-pages/marine-detail.component';
import { NewsflashComponent } from '../newsflash/newsflash.component';
import { NgModule } from '@angular/core';
import { NotificationsModule } from '../notifications/notifications.module';
import { OsDetailComponent } from './detail-pages/os-detail.component';
import { RouterModule } from '@angular/router';
import { SvgModule } from '../svg/svg.module';
import { TemplateModule } from '../template/template.module';
import { VerifyModule } from '../verify/verify.module';
import { SocietyDetailComponent } from './detail-pages/society-detail.component';
import { WMSLinksComponent } from './wms-links/wms-links.component';
import { MyCollectionsComponent } from './my-collections/my-collections.component';
import { SupplierComponent } from './supplier/supplier.component';
import { SupplierService } from './supplier/supplier.service';
import { FormsModule } from '@angular/forms';
import { PilotDetailComponent } from './detail-pages/pilot-detail.component';
import { VeriskDetailComponent } from './detail-pages/verisk-detail.component';
import { AppButtonsComponent } from './app-buttons/app-buttons.component';
import { WhatsNewDetailComponent } from './detail-pages/whats-new-detail.component';
import { AgcensusDetailComponent } from './detail-pages/agcensus-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RegisterInterestComponent } from './register-interest/register-interest.component';

@NgModule({
  imports: [
    CommonModule,
    SvgModule,
    RouterModule,
    TemplateModule,
    VerifyModule,
    NotificationsModule,
    FormsModule,
    MatTabsModule,
  ],
  declarations: [
    CollectionDetailComponent,
    HomeComponent,
    LayoutComponent,
    CollectionHeaderComponent,
    AuthenticationNotificationComponent,
    NewsflashComponent,
    OsDetailComponent,
    HistoricDetailComponent,
    GeologyDetailComponent,
    MarineDetailComponent,
    EnvironmentDetailComponent,
    AerialDetailComponent,
    LidarDetailComponent,
    GlobalDetailComponent,
    PilotDetailComponent,
    VeriskDetailComponent,
    WhatsNewDetailComponent,
    SocietyDetailComponent,
    AgcensusDetailComponent,
    WMSLinksComponent,
    MyCollectionsComponent,
    SupplierComponent,
    AppButtonsComponent,
    RegisterInterestComponent,
  ],
  providers: [SupplierService],
  exports: [AppButtonsComponent],
})
export class CollectionsModule {}
