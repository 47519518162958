import { Collection } from '../collection';

import { LidarDetailComponent } from '../detail-pages/lidar-detail.component';

/* eslint-disable max-len */

export const LIDAR_COLLECTION: Collection = {
  id: 'lidar',
  name: 'Lidar',
  icon: `
    <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="68.029px"
      height="68.029px" viewBox="0 0 68.029 68.029" enable-background="new 0 0 68.029 68.029" xml:space="preserve">
      <metadata>
        <sfw>
          <slices></slices>
          <sliceSourceBounds  width="55.42" height="60.884" y="390.172" x="568.276" bottomLeftOrigin="true"></sliceSourceBounds>
        </sfw>
      </metadata>
      <g>
        <path d="M9.658,21.179c0.739,0.018,1.478,0.005,2.218,0.006c0.493,0,0.986,0,1.518,0h12.314c0.476-0.001,0.951-0.004,1.427-0.009
          h0.136l-0.009,0.012c0.218,0.042,0.285,0.205,0.379,0.39c1.554,3.087,4.055,4.784,7.515,4.792c3.48,0.009,6.002-1.686,7.559-4.797
          c0.113-0.23,0.232-0.353,0.455-0.386l-0.02-0.002h0.03c0.044-0.005,0.09-0.009,0.142-0.009c0.512,0.005,1.022,0.007,1.533,0.009
          h12.104c0.276,0,0.468,0,0.658,0c1.045-0.001,2.089,0.013,3.135-0.01c0.555-0.012,0.922-0.383,0.955-0.888
          c0.036-0.543-0.285-0.974-0.827-1.067c-3.774-0.649-7.548-1.293-11.323-1.938c-1.958-0.334-3.918-0.66-5.873-1.012
          c-0.176-0.032-0.42-0.201-0.467-0.356c-0.994-3.228-3.12-5.223-6.412-5.986c-0.142-0.033-0.327-0.22-0.35-0.36
          c-0.13-0.774-0.21-1.556-0.316-2.334c-0.151-1.112-0.292-2.226-0.483-3.332c-0.027-0.172-0.275-0.388-0.458-0.432
          c-0.276-0.066-0.482,0.116-0.515,0.431c-0.01,0.096-0.045,0.188-0.058,0.283c-0.233,1.749-0.477,3.496-0.688,5.247
          c-0.04,0.333-0.159,0.453-0.478,0.528c-3.169,0.745-5.254,2.687-6.249,5.778c-0.118,0.365-0.277,0.513-0.629,0.567
          c-1.235,0.193-2.468,0.399-3.699,0.61c-4.437,0.763-8.873,1.531-13.31,2.294c-0.625,0.107-0.956,0.488-0.918,1.08
          C8.687,20.807,9.068,21.163,9.658,21.179z M31.774,14.357c0.046-0.09,0.228-0.156,0.346-0.157c2.04-0.009,4.079-0.01,6.118,0.002
          c0.127,0.001,0.314,0.093,0.369,0.197c0.311,0.595,0.588,1.206,0.902,1.864c-2.896,0-5.75,0-8.66,0
          C31.161,15.612,31.453,14.977,31.774,14.357z"/>
        <path d="M25.708,21.185c-0.438,0.002-0.875,0.003-1.313,0.003h2.868c-0.004,0-0.007-0.002-0.011-0.003H25.708z"/>
        <path d="M43.17,21.187h2.68c-0.331,0-0.662,0-0.994-0.002H43.18C43.177,21.186,43.174,21.186,43.17,21.187z"/>
        <path d="M33.682,51.87c-1.875,1.577-3.908,3.037-6.054,4.209c-4.599,2.514-8.623,1.733-12.144-2.111
          c-1.064-1.16-3.054-3.633-4.173-4.939c-1.633,1.147-3.009,2.114-4.521,3.176c0.827,1.154,2.451,3.378,3.18,4.274
          c7.625,9.378,15.113,10.308,24.743,3.114c1.888-1.41,3.745-2.885,5.742-4.126c5.997-3.728,10.813-2.747,15.058,2.949
          c0.734,0.985,1.442,1.991,2.259,3.12c1.71-1.355,3.097-2.451,4.438-3.514C53.56,44.219,44.112,43.098,33.682,51.87z"/>
        <path d="M26.567,22.606c-0.308-0.176-0.7-0.071-0.877,0.236L11.771,47.024c-0.177,0.307-0.071,0.699,0.236,0.876
          c0.101,0.058,0.211,0.086,0.32,0.086c0.222,0,0.438-0.115,0.557-0.321l13.919-24.181C26.98,23.176,26.874,22.783,26.567,22.606z"/>
        <path d="M60.258,52.521c0.107,0,0.219-0.028,0.32-0.087c0.307-0.178,0.412-0.57,0.234-0.877L44.283,22.952
          c-0.177-0.307-0.57-0.412-0.877-0.235c-0.307,0.178-0.412,0.57-0.234,0.878L59.7,52.2C59.819,52.406,60.035,52.521,60.258,52.521z"
          />
        <path d="M17.841,54.469c0.075,0.028,0.152,0.042,0.228,0.042c0.259,0,0.503-0.158,0.601-0.414l10.596-27.859
          c0.126-0.331-0.041-0.702-0.372-0.829c-0.332-0.126-0.703,0.041-0.829,0.372L17.469,53.639
          C17.343,53.971,17.51,54.343,17.841,54.469z"/>
        <path d="M26.815,54.737c0.044,0.009,0.088,0.014,0.131,0.014c0.298,0,0.565-0.209,0.628-0.512l5.439-26.161
          c0.072-0.348-0.151-0.688-0.499-0.76c-0.348-0.073-0.688,0.15-0.759,0.498l-5.439,26.162
          C26.245,54.325,26.468,54.665,26.815,54.737z"/>
        <path d="M36.416,27.514c-0.353,0.042-0.603,0.362-0.561,0.715l2.193,18.179c0.039,0.326,0.316,0.565,0.637,0.565
          c0.025,0,0.052-0.001,0.078-0.004c0.353-0.043,0.604-0.363,0.561-0.716l-2.193-18.179C37.089,27.723,36.769,27.472,36.416,27.514z"
          />
        <path d="M40.516,25.93c-0.331,0.125-0.499,0.496-0.374,0.828l6.748,17.848c0.098,0.258,0.341,0.415,0.602,0.415
          c0.074,0,0.151-0.013,0.227-0.041c0.332-0.125,0.499-0.496,0.373-0.828l-6.747-17.848C41.219,25.971,40.848,25.804,40.516,25.93z"
          />
      </g>
    </svg>
  `,
  supplierLogos: [
    {
      name: 'Environment Agency Logo',
      file: '/assets/images/collections/supplier-logos/EA_logo_min.png',
      width: 220,
      height: 70,
    },
    {
      name: 'Scottish Environment Protection Agency Logo',
      file: '/assets/images/collections/supplier-logos/SEPA_logo.svg',
      width: 135,
      height: 100,
    },
    {
      name: 'Natural Resources Wales Logo',
      file: '/assets/images/collections/supplier-logos/NRW_logo_CMYK_linear.svg',
      width: 250,
      height: 45,
    },
    {
      name: 'Scottish Gouvernment Logo',
      file: '/assets/images/collections/supplier-logos/scotgovlogo.svg',
      width: 215,
      height: 45,
    },
  ],
  subscriptionRequired: true,
  collectionDetail: LidarDetailComponent,
  licencePdf: '/help/copyright-and-licensing/lidar_eula/printable/',
  licenceUrl: '/help/copyright-and-licensing/lidar_eula/embeddable/',
  appGroups: [
    {
      groupName: 'Download data (for GIS/CAD)',
      apps: [
        {
          id: 'LIDARROAM',
          name: 'Lidar Digimap',
          link: '/roam/map/lidar',
        },
      ],
    },
  ],
};
