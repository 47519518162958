import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TemplateModule } from '../template/template.module';
import { DmCommonModule } from '../../commons/dm-common.module';
import { SvgModule } from '../svg/svg.module';
import { ControlMessagesModule } from '../controlmessages/control-messages.module';
import { SafePipe } from './safe.pipe';

import { ActivateCollectionsFormComponent } from './activate-collections-form.component';
import { ActivationService } from './activation.service';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TemplateModule,
    DmCommonModule,
    SvgModule,
    ControlMessagesModule,
    MatExpansionModule,
  ],
  declarations: [ActivateCollectionsFormComponent, SafePipe],
  providers: [ActivationService, SafePipe],
  exports: [ActivateCollectionsFormComponent, SafePipe],
})
export class ActivateCollectionsModule {}
