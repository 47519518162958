<dm-simple-notifications></dm-simple-notifications>

<div
  *ngIf="collection"
  class="collection-detail-container"
  attr.id="coll-panel-{{collection.id}}"
  attr.aria-labelledby="coll-tab-{{collection.id}}"
  [class.logged-in]="user"
  role="tabpanel">
  <div class="description">
    <img
      *ngIf="!collection.isVisuallySpecial"
      class="hidden-xs collection-image"
      alt="{{ collection.name }} collection image"
      src="assets/images/collections/{{ collection.id }}.jpg" />

    <div class="title" *ngIf="!collection.isVisuallySpecial">
      <dm-dynamic-svg [svg]="collection.icon"></dm-dynamic-svg>
      <span class="title-text">{{ collection.name }}</span>
    </div>

    <ng-template #collectionDetails></ng-template>

    <div class="supplier-logos">
      <div *ngFor="let logoDetail of collection.supplierLogos" class="supplier-logo-container">
        <img
          class="supplier-logo-img"
          src="{{ logoDetail.file }}"
          alt="{{ logoDetail.name }}"
          [style.width.px]="logoDetail.width"
          [style.height.px]="logoDetail.height" />
      </div>
    </div>
  </div>

  <div class="apps" *ngIf="appGroups.length > 0">
    <div *ngIf="shouldDisplayRegisterInterestButton()" class="register-interest">
      <button (click)="showRegisterInterestForm()" type="button" class="no-gutters btn btn-lg btn-block">
        <i class="glyphicon glyphicon-exclamation-sign"></i>
        Your institution does not subscribe to {{collection.name}}. Click to register your interest.
      </button>
    </div>

    <div [hidden]="shouldRegisterInterestFormBeHidden" class="register-interest">
      <dm-register-interest
        [collectionId]="collection.id"
        collectionName="collection.name"
        (interestSubmitted)="registerInterest()"></dm-register-interest>
    </div>

    <dm-app-buttons
      *ngFor="let appGroup of appGroups"
      [appGroup]="appGroup"
      (appClicked)="accessClickedForLink($event)"
      [isEnabled]="canAccessApplication"
      [icon]="collection.icon"></dm-app-buttons>
  </div>
</div>
