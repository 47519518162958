import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TemplateModule } from '../template/template.module';
import { SvgModule } from '../svg/svg.module';

import { OpenComponent } from './open.component';
import { CollectionsModule } from '../collections/collections.module';

@NgModule({
  imports: [CommonModule, RouterModule, TemplateModule, SvgModule, CollectionsModule],
  declarations: [OpenComponent],
})
export class OpenModule {}
