<div class="dm-big-text">
  <h1>{{ getCollectionName() }} Web Map Service (WMS)</h1>
  <p>
    Web Map Services (WMS) can be used to display mapping images from the {{ getCollectionName() }} Collection in most
    GIS systems.
  </p>

  <p>
    The link(s) below provide access to the licensed and open data (where available). Within each service you are able
    to select the individual data products/layers you wish to view.
  </p>

  <p>
    In order to use this Web Map Service in a desktop GIS (e.g. Esri's ArcGIS or QGIS), copy and paste the relevant URL
    below in to the WMS Server Connection interface in your GIS:
  </p>

  <p *ngIf="hasWMSLink(LICENSED)">
    Licensed data: <a [href]="getWMSLink(LICENSED)">{{ getWMSLink(LICENSED) }}</a>
  </p>
  <p *ngIf="hasWMSLink(OPEN)">
    Open data: <a [href]="getWMSLink(OPEN)">{{ getWMSLink(OPEN) }}</a>
  </p>

  <p>Help on using Web Services in different GIS can be found <a href="/help/wms/">here</a>.</p>
</div>
