<div class="glide">
  <div class="glide__track" data-glide-el="track">
    <div class="glide__slides">
      <div class="glide__slide slide-option-1">
        <div class="slide-caption">
          Historic Collection gives rich historical mapping for multiple epochs dating back to the 1840s, invaluable for
          Digital Humanities and understanding change over time.
          <a class="btn btn-main" [routerLink]="['/historic']">Learn More</a>
        </div>
      </div>
      <div class="glide__slide slide-option-2">
        <div class="slide-caption">
          Aerial Collection for cloud-free high resolution aerial photography adds essential context to all spatial
          analysis.
          <a class="btn btn-main" [routerLink]="['/aerial']">Learn More</a>
        </div>
      </div>

      <div class="glide__slide slide-option-3">
        <div class="slide-caption">
          Ordnance Survey Collection enables access to the most detailed topographic mapping for GB, excellent for
          planning, architecture and land use cover.
          <a class="btn btn-main" [routerLink]="['/os']">Learn More</a>
        </div>
      </div>
    </div>
  </div>

  <div class="glide__bullets" data-glide-el="controls[nav]">
    <button aria-label="glide bullet 1" class="glide__bullet" data-glide-dir="=0"></button>
    <button aria-label="glide bullet 2" class="glide__bullet" data-glide-dir="=1"></button>
    <button aria-label="glide bullet 3" class="glide__bullet" data-glide-dir="=2"></button>
  </div>

  <div class="glide__arrows" data-glide-el="controls">
    <button aria-label="glide button left" class="glide__arrow glide__arrow--left" data-glide-dir="<">&lt;</button>
    <button aria-label="glide button right" class="glide__arrow glide__arrow--right" data-glide-dir=">">&gt;</button>
  </div>
</div>

<div class="digimap-main-text">
  <div>
    <h2>What is Digimap?</h2>
    <hr class="dotted" />
    <p>
      Digimap is an online map and data delivery service, available by subscription to UK Higher and Further Education
      establishments. Operated by EDINA at the University of Edinburgh, Digimap offers a number of data collections,
      including Ordnance Survey, historical, geological, LiDAR and marine maps and spatial data.
    </p>
  </div>
  <div>
    <h2>What are we offering?</h2>
    <hr class="dotted" />
    <p>
      Digimap offers access to a range of datasets for the purposes of education and research. Create or interrogate a
      map online by selecting an appropriate base map, adding annotations and customising the content, use measurement
      and query tools to learn more about any study area. Download the raw spatial data in a wide range of formats for
      use in local GIS, CAD or image processing software.
    </p>
  </div>
  <div>
    <h2>Subscription Information</h2>
    <hr class="dotted" />
    <p>
      Digimap is available to members of subscribing higher and further education institutions in the UK. Individual
      registration is required, but the service is free at the point of use. More information about subscription fees
      can be found on our
      <a [routerLink]="['/subscription']">website</a>.
    </p>
  </div>
</div>
