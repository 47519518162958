<button *ngIf="scrollButtonsRequired" class="btn btn-main btn-left" [disabled]="scrollAtFarLeft()" (click)="goLeft()">
  &lt;&lt;
</button>
<div #viewport class="viewport">
  <ul #collectionsHeader role="tablist" aria-label="collection tabs">
    <ng-container *ngFor="let c of collections">
      <li *ngIf="isCollectionTabDisplayed(c)" role="tab">
        <a
          id="coll-tab-{{ c.id }}"
          tabindex="0"
          (click)="selectCollection(c)"
          [class.collection-active]="c === selectedCollection"
          href="#">
          {{ c.name }}
          <span *ngIf="c.isNew" class="label label-success">New</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>

<button
  *ngIf="scrollButtonsRequired"
  class="btn btn-main btn-right"
  [disabled]="scrollAtFarRight()"
  (click)="goRight()">
  &gt;&gt;
</button>
