import { AfterViewInit, Component } from '@angular/core';
import Glide, { Autoplay, Controls } from '@glidejs/glide/dist/glide.modular.esm';

@Component({
  selector: 'dm-info-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  items = [
    'assets/images/carousel/slide-a.jpg',
    'assets/images/carousel/slide-b.jpg',
    'assets/images/carousel/slide-c.jpg',
  ];

  constructor() {}

  ngAfterViewInit() {
    new Glide('.glide', {
      autoplay: 5000,
    }).mount({ Autoplay, Controls });
  }
}
