<main class="row no-gutters">
  <div class="col-md-offset-1 col-md-10 dm-viewport">
    <div *ngIf="view === formView.Wait" aria-live="polite" tabindex="0">Please wait ...</div>

    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="view === formView.Error">
      <div class="alert alert-danger" aria-live="polite" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <form class="form-horizontal" [formGroup]="registerUserForm" (ngSubmit)="registerUser()">
      <!-- Main form view -->
      <div *ngIf="view === formView.Form">
        <h3 class="dm-form-heading" tabindex="0">Please enter your registration details below:</h3>
        <div class="form-group">
          <label for="title" class="dm-form-label control-label"
            >Title <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="title" class="form-control" formControlName="title" tabindex="0">
              <option value="">Please select</option>
              <option *ngFor="let title of titles" value="{{title}}">{{title}}</option>
            </select>
            <dm-control-messages [control]="registerUserForm.get('title')"></dm-control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="firstName" class="dm-form-label control-label"
            >First name(s) <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input
              id="firstName"
              type="text"
              class="form-control"
              formControlName="firstName"
              tabindex="0"
              trim="blur" />
            <dm-control-messages [control]="registerUserForm.get('firstName')"></dm-control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="surname" class="dm-form-label control-label"
            >Last name <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input id="surname" type="text" class="form-control" formControlName="surname" tabindex="0" trim="blur" />
            <dm-control-messages [control]="registerUserForm.get('surname')"></dm-control-messages>
          </div>
        </div>
        <div class="form-group" formGroupName="matchingEmail">
          <label for="email" class="dm-form-label control-label"
            >Email address <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input id="email" type="email" class="form-control" formControlName="email" tabindex="0" trim="blur" />
            <dm-control-messages [control]="registerUserForm.get('matchingEmail.email')"></dm-control-messages>
            <p class="alert alert-warning">
              <strong
                >Please make sure this is a valid email address. You need to receive emails to complete your
                registration.</strong
              >
            </p>
          </div>
        </div>
        <div class="form-group" formGroupName="matchingEmail">
          <label for="repeatEmail" class="dm-form-label control-label"
            >Repeat Email address <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <input
              id="repeatEmail"
              type="email"
              class="form-control"
              formControlName="repeatEmail"
              dmDisablePaste
              tabindex="0"
              trim="blur" />
            <dm-control-messages [control]="registerUserForm.get('matchingEmail.repeatEmail')"></dm-control-messages>
          </div>
        </div>
        <div class="form-group" tabindex="0">
          <label for="institution" class="dm-form-label control-label">Institution</label>
          <div id="institution" class="dm-form-control">
            <p class="form-control static">{{ user.institutionName }}</p>
          </div>
        </div>

        <div class="form-group" formGroupName="departmentGroup">
          <label for="department" class="dm-form-label control-label"
            >Department <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="department" class="form-control" formControlName="department" tabindex="0">
              <option value="">Please select...</option>
              <option *ngFor="let dept of user.departments" value="{{dept}}">{{dept}}</option>
              <option value="request_new_dept">-- Request new department --</option>
            </select>
            <dm-control-messages [control]="registerUserForm.get('departmentGroup.department')"></dm-control-messages>
          </div>
        </div>
        <div class="form-group" formGroupName="departmentGroup">
          <div *ngIf="registerUserForm.get('departmentGroup.department')?.value === 'request_new_dept'">
            <label for="newDepartment" class="dm-form-label control-label"
              >Request a new Department <span class="visuallyhidden">required</span></label
            >
            <div class="dm-form-control">
              <input
                id="newDepartment"
                type="text"
                class="form-control"
                formControlName="newDepartment"
                tabindex="0"
                trim="blur" />
              <dm-control-messages
                [control]="registerUserForm.get('departmentGroup.newDepartment')"></dm-control-messages>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="userType" class="dm-form-label control-label"
            >User status <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="userType" class="form-control" formControlName="userType" tabindex="0">
              <option value="">Please select...</option>
              <option *ngFor="let userType of user.userTypes" value="{{userType}}">{{userType}}</option>
            </select>
            <dm-control-messages [control]="registerUserForm.get('userType')"></dm-control-messages>
          </div>
        </div>

        <div class="form-group">
          <label for="hearabout" class="dm-form-label control-label"
            >Where did you hear about Digimap? <span class="visuallyhidden">required</span></label
          >
          <div class="dm-form-control">
            <select id="hearabout" class="form-control" formControlName="hearabout" tabindex="0">
              <option value="">Please select...</option>
              <option *ngFor="let hear of user.hearAbouts" value="{{hear}}">{{hear}}</option>
            </select>
            <dm-control-messages [control]="registerUserForm.get('hearabout')"></dm-control-messages>
          </div>
        </div>

        <div class="dm-form-checkbox">
          <p>
            Please read our <a href="help/about/privacy_notice">Privacy Notice</a>
            which explains what information we collect from you and how we use it.
          </p>
        </div>

        <div class="form-group">
          <div class="dm-form-checkbox">
            <div class="checkbox">
              <label>
                <input id="agreeToPrivacyNotice" type="checkbox" formControlName="agreeToPrivacyNotice" tabindex="0" />
                I have read and agree to Digimap's Privacy Notice
              </label>
            </div>

            <div class="checkbox">
              <label>
                <input id="marketingContactable" type="checkbox" formControlName="marketingContactable" tabindex="0" />
                Yes, please send me details of new data, features, resources and free webinars<span class="footnote"
                  >*</span
                >.
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="footnote dm-form-checkbox">
            *&nbsp;Your details will be retained for 5 years after the last time you access Digimap but you can
            unsubscribe at any time. Note, you will still receive important Digimap service status information even if
            you don't check this box.
          </div>
        </div>

        <div class="dm-form-buttons">
          <button
            id="resetButton"
            type="button"
            class="btn btn-default"
            [disabled]="!registerUserForm.dirty"
            (click)="resetUser()">
            Reset
          </button>
          <button
            id="nextButton"
            type="button"
            class="btn btn-success"
            [disabled]="!canSubmit()"
            (click)="view = formView.Summary">
            Next
          </button>
        </div>
      </div>

      <!-- Summary view -->
      <div *ngIf="view === formView.Summary">
        <h3 class="dm-form-heading" tabindex="0">Please check the following details before pressing submit:</h3>

        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Title:</label>
          <p class="dm-form-control form-control-static">{{ user.title }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">First Name(s):</label>
          <p class="dm-form-control form-control-static">{{ user.firstName }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Last name:</label>
          <p class="dm-form-control form-control-static">{{ user.surname }}</p>
        </div>
        <div class="row" tabindex="0">
          <div class="alert alert-warning clearfix">
            <label class="dm-form-label control-label">Please ensure your email is correct:</label>
            <p class="dm-form-control form-control-static">{{ user.email }}</p>
          </div>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Institution:</label>
          <p class="dm-form-control form-control-static">{{ user.institutionName }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Department:</label>
          <p *ngIf="user.newDepartment === ''" class="dm-form-control form-control-static">{{ user.department }}</p>
          <p *ngIf="user.newDepartment !== ''" class="dm-form-control form-control-static">{{ user.newDepartment}}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">User Type:</label>
          <p class="dm-form-control form-control-static">{{ user.userType }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Where did you hear about Digimap?:</label>
          <p class="dm-form-control form-control-static">{{ user.hearAbout }}</p>
        </div>
        <div class="row" tabindex="0">
          <label class="dm-form-label control-label">Contactable:</label>
          <div *ngIf="user.marketingContactable" class="dm-form-control form-control-static">
            Yes, please send me details of new data, features, resources and free webinars.
          </div>
          <div *ngIf="!user.marketingContactable" class="dm-form-control form-control-static">
            No, please don't send me details of new data, features, resources and free webinars.
          </div>
        </div>

        <div class="dm-form-buttons">
          <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
          <button
            type="submit"
            class="btn btn-success"
            [disabled]="submitText.getSubmitting()"
            [innerHTML]="submitText.getSubmitText()"></button>
        </div>
      </div>
    </form>

    <!-- Success view: form has been submitted successfully -->
    <div *ngIf="view === formView.SuccessConfirmation" tabindex="0">
      <div class="alert alert-success">
        <strong>Success!</strong>
        Thank you for registering for Digimap. A verification email has been sent to:
        <strong>{{ user.email }}</strong>
      </div>
      <p>
        Please follow the instructions in the email to complete the registration process. The link will remain valid for
        7 days.
      </p>
      <p>If you do not receive the verification email:-</p>
      <ul>
        <li>check your spam folder for the verification email.</li>
        <li>check that your email address above is correct.</li>
        <li>
          you can re-register using the correct address by deleting your current registration (using the button on the
          <a href="#" (click)="navigate()" title="Digimap Home">Digimap home page</a>) or after the verification email
          has expired (7 days).
        </li>
      </ul>
      <p>
        If you need further assistance please contact the EDINA Digimap Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <!-- Error view: form has been submitted but there's been an error -->
    <div *ngIf="view === formView.ErrorConfirmation" tabindex="0">
      <div class="alert alert-danger" aria-live="polite">
        <strong>Error!</strong> There has been a problem submitting your details.
        <ul>
          <li>{{errorMessage}}</li>
        </ul>
      </div>
      <p>
        Please contact EDINA Helpdesk at <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a> to resolve this problem.
      </p>
      <button type="button" class="btn btn-default" (click)="view = formView.Form">Back</button>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>
  </div>
</main>
