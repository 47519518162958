import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { DisableSubmit } from '../../commons/disable-submit';
import { ErrorResponse } from '../../commons/error-response';
import { FormView } from '../../commons/form-view';
import { RegisterUserDto } from './register-user';
import { RegisterUserService } from './register-user.service';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { ValidationService } from '../controlmessages/validation.service';

@Component({
  selector: 'dm-register-user-form',
  templateUrl: 'register-user-form.template.html',
})
export class RegisterUserFormComponent implements OnInit {
  user: RegisterUserDto;

  formView = FormView;
  view: FormView; // Which of the views is currently being displayed
  errorMessage: string | null; // If an error has occured, the message from the server

  // This field is used in the template
  titles: string[] = ['Mr', 'Miss', 'Mrs', 'Ms', 'Mx', 'Dr', 'Prof', 'Not specified'];

  institution: string;

  registerUserForm: FormGroup;

  submitText: DisableSubmit;

  // The original state of the update user DTO
  private original: RegisterUserDto;

  constructor(
    fb: FormBuilder,
    private registerUserService: RegisterUserService,
    private userService: UserService,
    private router: Router
  ) {
    this.view = FormView.Wait;
    this.errorMessage = null;

    this.registerUserForm = fb.group({
      title: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      surname: ['', [Validators.required, Validators.maxLength(32)]],
      matchingEmail: fb.group(
        {
          email: ['', [Validators.required, Validators.maxLength(255), ValidationService.emailValidator]],
          repeatEmail: ['', [Validators.required, Validators.maxLength(255), ValidationService.emailValidator]],
        },
        { validator: ValidationService.matchingEmailAddressesValidator('email', 'repeatEmail') }
      ),
      departmentGroup: fb.group(
        {
          department: ['', Validators.required],
          newDepartment: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
        },
        { validator: this.departmentValidator }
      ),
      userType: ['', Validators.required],
      hearabout: ['', Validators.required],
      agreeToPrivacyNotice: ['', Validators.requiredTrue],
      marketingContactable: '',
      supportContactable: '',
      supplier: '',
    });

    // observe the full form as a whole, apply complex cross field validations. Pre-save the form
    // in the background and easy implementation of requirements like undo/redo of valid states.
    this.registerUserForm.valueChanges.subscribe(value => {
      // Map the form elements onto the RegisterUserDto
      this.user.title = value.title;
      this.user.firstName = value.firstName;
      this.user.surname = value.surname;
      this.user.email = value.matchingEmail.email;
      this.user.userType = value.userType;
      this.user.hearAbout = value.hearabout;
      this.user.marketingContactable = value.marketingContactable;
      this.user.supportContactable = value.supportContactable;
      this.user.supplier = value.supplier;

      // Update user with department
      if (value.departmentGroup.department !== '' && value.departmentGroup.department !== 'request_new_dept') {
        this.user.department = value.departmentGroup.department;
        // Need to reset newDepartment to empty string if it was set earlier.
        this.user.newDepartment = '';
      } else if (value.departmentGroup.newDepartment !== '') {
        this.user.newDepartment = value.departmentGroup.newDepartment;
        // Send 'request_new_dept' for department to get round validation,
        // we check this in backend then add new department
        this.user.department = 'request_new_dept';
      }
      return value;
    });

    this.submitText = new DisableSubmit();
  }

  /**
   * Checks the validity of the existing department and new department fields. If the existing department
   * is empty then the other department must be valid. If the existing department is non-empty then we
   * don't care about the value of new department.
   *
   * @param group
   *
   * @returns boolean
   */
  departmentValidator(group: FormGroup): { [key: string]: any } | null {
    const deptControl = group.controls['department'];
    const newDeptControl = group.controls['newDepartment'];

    if (deptControl.value === 'request_new_dept' && !newDeptControl.valid) {
      return newDeptControl.errors;
    } else {
      newDeptControl.setErrors(null);
      return null;
    }
  }

  /**
   * Run when form submitted.
   */
  registerUser() {
    this.submitText.setSubmitting(true);
    this.registerUserService.registerUser(this.user).subscribe({
      next: () => {
        this.submitText.setSubmitting(false);
        this.userService.forceRefresh();
        this.view = FormView.SuccessConfirmation;
      },
      error: (err: ErrorResponse) => {
        this.submitText.setSubmitting(false);
        this.view = FormView.ErrorConfirmation;
        this.errorMessage = err.message;
      },
    });
  }

  getUser() {
    this.registerUserService.getRegisteringUser().subscribe(
      data => {
        this.user = data;
        this.original = cloneDeep(data);
        this.view = FormView.Form;
      },
      err => {
        this.errorMessage = err.message;
        this.view = FormView.Error;
      }
    );
  }

  /**
   * Returns true if the form is in a state where submission of the data
   * is possible.
   */
  canSubmit(): boolean {
    return this.registerUserForm.valid;
  }

  /**
   * Run to reset form to original state.
   */
  resetUser(): void {
    this.user = cloneDeep(this.original);
    this.registerUserForm.reset();
  }

  /**
   * Get the Observable of a user from the service, then assign it.
   */
  ngOnInit() {
    this.getUser();
  }

  navigate() {
    this.router.navigate(['/']);
  }
}
