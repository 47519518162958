import { Injectable, EventEmitter } from '@angular/core';
import { NotificationClickHandler } from './notification';

@Injectable()
export class NotificationsService {
  public emiter: EventEmitter<any> = new EventEmitter();

  set(notification: any, to: boolean) {
    this.emiter.emit({ command: 'set', notification, add: to });
  }
  getChangeEmitter() {
    return this.emiter;
  }

  //// Access methods
  success(title: string, content: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type: 'success', override, click }, true);
  }

  error(title: string, content: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type: 'error', override, click }, true);
  }

  alert(title: string, content: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type: 'alert', override, click }, true);
  }

  info(title: string, content: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type: 'info', override, click }, true);
  }
  bare(title: string, content: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type: 'bare', override, click }, true);
  }

  // With type method
  create(title: string, content: string, type: string, override?: any, click?: NotificationClickHandler) {
    this.set({ title, content, type, override, click }, true);
  }

  // HTML Notification method
  html(html: any, type: string, override?: any, click?: NotificationClickHandler) {
    this.set({ html, type, override, title: null, content: null, click }, true);
  }

  // Remove all notifications method
  remove(id?: string) {
    if (id) {
      this.emiter.emit({ command: 'clean', id });
    } else {
      this.emiter.emit({ command: 'cleanAll' });
    }
  }
}
