import { AfterViewInit, ElementRef, QueryList, ViewChildren, Directive } from '@angular/core';

@Directive()
export abstract class AbstractCollectionDirective implements AfterViewInit {
  @ViewChildren('youtube') videos: QueryList<ElementRef>;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.videos.forEach(v => {
        const youtubeElement = v.nativeElement as HTMLElement;
        const dataSrc = youtubeElement.getAttribute('data-src');

        if (!dataSrc) {
          return;
        }

        youtubeElement.setAttribute('src', dataSrc);
      });
    }, 1000);
  }
}
